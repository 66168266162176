import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../../components/basic';
import { View } from '../../../shared/components';


import './style.scss';

const Contact = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();
    
    const [IDNumber, setIDNumber] = useState('');
    const[contact, setContact] = useState({
        Mobile: '',
        Landline: '',
        WorkEmail: '',
        PrivateEmail: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        setIDNumber(commonStore.employeeDetail.IDNumber);
        let cnt = contact;
        cnt.Mobile = commonStore.employeeDetail.employeecontact.Mobile;
        cnt.Landline = commonStore.employeeDetail.employeecontact.Landline;
        cnt.WorkEmail = commonStore.employeeDetail.employeecontact.WorkEmail;
        cnt.PrivateEmail = commonStore.employeeDetail.employeecontact.PrivateEmail;
        setContact(cnt);
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function goBack(){
        navigate({pathname: '/home', search: 'More'});
    }

    function mobileChange(e){
        setError('');
        setSuccess('');
        let cnt = contact;
        cnt.Mobile = e.target.value;
        setContact(cnt);
        forceUpdate();
    }

    function landlineChange(e){
        setError('');
        setSuccess('');
        let cnt = contact;
        cnt.Landline = e.target.value;
        setContact(cnt);
        forceUpdate();
    }

    function workEmailChange(e){
        setError('');
        setSuccess('');
        let cnt = contact;
        cnt.WorkEmail = e.target.value;
        setContact(cnt);
        forceUpdate();
    }

    function emailChange(e){
        setError('');
        setSuccess('');
        let cnt = contact;
        cnt.PrivateEmail = e.target.value;
        setContact(cnt);
        forceUpdate();
    }

    function updateContact(){
        
        if (!contactValid()){
            return
        }
        commonStore.setLoading('True');
        commonStore.updateEmployeeContact(IDNumber, contact).then((res) => {
            if (!res){
                setError('An error occured, please try again later.');
                commonStore.setLoading(false);
            }else{
                setSuccess('Your contact information has been updated successfully.');
                commonStore.setLoading(false);
            }
        });
    }

    function contactValid(){

        let { Mobile, Landline, WorkEmail, PrivateEmail } = contact;
        
        if (Mobile.length < 10){
            setError('Please enter a valid mobile number.');
            return false
        }

        if (Landline.length < 10 ){
            setError('Please enter a valid landline number.');
            return false
        }

        if (!(WorkEmail.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))){
            setError('Please enter a valid work email.');
            return false
        }

        if (!(PrivateEmail.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))){
            setError('Please enter a valid private email.');
            return false
        }

        return true
    }

        if (isMobile()){
            return(
                <div id="mobileDashView" className="view">
                    <Card>
                        <CardBody>
                            <header className="site-head d-flex align-items-center justify-content-between" style={{backgroundColor: '#ffffff'}}>
                                <div className="wrap mr-4">
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/back.png" alt='mnu' onClick={goBack} style={{cursor: 'pointer', width: '18px', height: '18px'}}/>
                                </div>
                                <div className="right-elems ml-auto d-flex wrap mr-4">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" style={{cursor: 'pointer', width: '125px'}}/>  
                                </div>
                            </header>
                        </CardBody>
                    </Card>
                    
                    <div className="viewContent view-pages view-session d-flex flex-column" style={{overflow: 'scroll'}}>
                        <p className="txtlead txtleft"><i className="fa fa-address-book" aria-hidden="true"/>Contact</p>
                        <Form>
                            <label className="custom-field">
                                <input className='inp' type="text" id="mobile" value={contact.Mobile} onChange={mobileChange} required/>
                                <span className="placeholder">Mobile</span>
                            </label> 

                            <label className="custom-field">
                                <input className='inp' type="text" id="landline" value={contact.Landline} onChange={landlineChange} required/>
                                <span className="placeholder">Landline</span>
                            </label> 

                            <label className="custom-field">
                                <input className='inp' type="text" id="workEmail" value={contact.WorkEmail} onChange={workEmailChange} required/>
                                <span className="placeholder">Work Email</span>
                            </label> 

                            <label className="custom-field">
                                <input className='inp' type="text" id="privateEmail" value={contact.PrivateEmail} onChange={emailChange} required/>
                                <span className="placeholder">Private Email</span>
                            </label> 

                            {(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}
                            {(success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}
                            <Button className='cornered-button' block size="lg" onClick={updateContact}>Update contact</Button>
                        </Form>
                    </div>
                    
                </div>
            );

        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                    <p className="txtlead"><i className="fa fa-address-book" aria-hidden="true"/>Contact</p>
                                    <br/>
                                </header>
                                <Form>
                                    <label className="custom-field">
                                        <input className='inp' type="text" id="mobile" value={contact.Mobile} onChange={mobileChange} required/>
                                        <span className="placeholder">Mobile</span>
                                    </label> 

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="landline" value={contact.Landline} onChange={landlineChange} required/>
                                        <span className="placeholder">Landline</span>
                                    </label> 

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="workEmail" value={contact.WorkEmail} onChange={workEmailChange} required/>
                                        <span className="placeholder">Work Email</span>
                                    </label> 

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="privateEmail" value={contact.PrivateEmail} onChange={emailChange} required/>
                                        <span className="placeholder">Private Email</span>
                                    </label> 

                                    {(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}
                                    {(success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}
                                    <Button className='cornered-button' block size="lg" onClick={updateContact}>Update contact</Button>
                                    <p className="backButton" onClick={goBack}>Back</p>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                   
                </View>
            );
        }
        
})
export default Contact
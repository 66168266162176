import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../../components/basic';
import { View, MobileTopBarClean } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

const ConfirmBankAccount = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [method, setMethod] = useState('');
    const [bankAccounts, setBankAccounts] = useState([]);

    useEffect(() => {
        loadData()
        if (isMobile()) {
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function loadData() {
        let accounts = commonStore.retrieveBankAccounts();
        if (accounts === '[]') {
            accounts = [];
        }
        setBankAccounts(accounts);
        if (accounts.length > 0) {
            setMethod(0);
        }
    }

    function optionSelected(e) {

        console.log(e.target.value);
        setMethod(e.target.value);
    }

    function nextClicked() {
        navigate({ pathname: '/baseline', search: "pre" }); //used to be home, baseline compulsory
        return
    }

    function goBack() {

        navigate('/verifiedid')
    }

    function addBankAccount() {

        navigate('/newbankaccount');
    }

    if (isMobile()) {
        return (
            <div id="mobileDashView" className="view" style={{ justifyContent: 'flex-start' }}>
                <div>
                    <MobileTopBarClean />
                </div>

                <div id="mainView" className="viewContent view-pages view-session d-flex flex-column">
                    <p className="txtlead">Confirm withdrawal payment method</p>
                    <p className="txtsub">This is where the money you withdraw will go.</p>
                    <Form className="align-items-center">
                        {(bankAccounts.length > 0 && bankAccounts !== '[]') ?
                            <div onChange={optionSelected}>
                                {bankAccounts.map((bankAccount, i) => {
                                    let lastDigits = bankAccount.accountnumber.slice(-4);
                                    return (
                                        <div key={`${i}`}>
                                            <input className="checkbox-tools" name="withdrawalOption" type="radio" id={`bankAccount${i}`} value={i} />
                                            <label htmlFor={`bankAccount${i}`}>{`Bank account (**** ${lastDigits})`}</label>
                                        </div>
                                    );
                                })}

                            </div> :
                            <p>We couldn't find any bank accounts. Once your profile is set up, you can contact support to add a bank account.</p>
                        }
                        <Button block size="lg" onClick={nextClicked}>Next &gt;</Button>
                    </Form>
                </div>
            </div>
        );
    } else {
        return (
            <View>
                <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                    <Card className="mb-3 form-card loginCard" style={{ maxHeight: `${window.innerHeight - 100}px` }}>
                        <CardBody style={{ display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
                            <header className="mb-4 topLogin">
                                <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                <p className="txtlead">Confirm withdrawal payment method</p>
                                <p className="txtsub">This is where the money you withdraw will go.</p>
                            </header>
                            <Form className="align-items-center">
                                {(bankAccounts.length > 0 && bankAccounts !== '[]') ?
                                    <div onChange={optionSelected}>
                                        {bankAccounts.map((bankAccount, i) => {
                                            let lastDigits = bankAccount.accountnumber.slice(-4);
                                            return (
                                                <div key={`${i}`}>
                                                    <input className="checkbox-tools" name="withdrawalOption" type="radio" id={`bankAccount${i}`} value={i} />
                                                    <label htmlFor={`bankAccount${i}`}>{`Bank account (**** ${lastDigits})`}</label>
                                                </div>
                                            );
                                        })}
                                    </div> :
                                    <p>We couldn't find any bank accounts. Once your profile is set up, you can contact support to add a bank account.</p>
                                }
                                <Button block size="lg" onClick={nextClicked}>Next &gt;</Button>
                            </Form>
                        </CardBody>
                    </Card>
                </div>

            </View>
        );
    }
})

export default ConfirmBankAccount
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';
import { FaUserAlt, FaAngleDown, FaAngleRight, FaAddressBook, FaCreditCard, FaLock, FaRegComments, FaFileContract, FaSignOutAlt } from 'react-icons/fa';

import '../../style.scss';
import './style.scss';




const More = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [IDNumber, setIDNumber] = useState('');
    const [profile, setProfile] = useState( {FirstName: '', LastName: ''});
    const [contact, setContact] = useState({
        Mobile: '',
        Landline: '',
        WorkEmail: '',
        PrivateEmail: ''
    });
    const [bankAccounts, setBankAccounts] = useState([]);
    const [webauthn, setWebauthn] = useState({
        err: '',
        success: ''
    });
    const [activePage, setActivePage] = useState('');
    const [err, setErr] = useState({
        profile: '',
        contact: '',
        homeAddress: '',
        workAddress: ''
    });
    const [success, setSuccess] = useState({
        profile: '',
        contact: '',
        homeAddress: '',
        workAddress: ''
    });

    useEffect(() => {
        loadPage();
    }, []);

    function loadPage(){
        console.log(commonStore.employeeDetail);
        setIDNumber(commonStore.employeeDetail.IDNumber);
        //Profile
        let prf = profile;
        prf.FirstName = commonStore.employeeDetail.employeeinfo.FirstName ;
        prf.LastName = commonStore.employeeDetail.employeeinfo.LastName;
        setProfile(prf);

        //Contact 
        let cnt = contact;
        cnt.Mobile = commonStore.employeeDetail.employeecontact.Mobile;
        cnt.Landline = commonStore.employeeDetail.employeecontact.Landline;
        cnt.WorkEmail = commonStore.employeeDetail.employeecontact.WorkEmail;
        cnt.PrivateEmail = commonStore.employeeDetail.employeecontact.PrivateEmail;
        setContact(cnt);

        // Bank Account
        let bankAccounts = commonStore.retrieveBankAccounts();
        setBankAccounts(bankAccounts);
    }

    // #region Profile
    function profileClicked(){
        navigate('/profile');
    }

    function firstNameChange(e){
        let er = err;
        let sc = success;
        let prf = profile;

        er.profile = '';
        sc.profile = '';
        prf.FirstName = e.target.value;
        setErr(er);
        setSuccess(sc);
        setProfile(prf);
    }

    function lastNameChange(e){
        let er = err;
        let sc = success;
        let prf = profile;

        er.profile = '';
        sc.profile = '';
        prf.LastName = e.target.value;
        setErr(er);
        setSuccess(sc);
        setProfile(prf);
    }

    function updateProfile(){
        
        
        if (profile.FirstName === ''){
            let er = err;
            er.profile = 'Please fill in your first name.';
            setErr(er);
            return
        }
        if (profile.LastName === ''){
            let er = err;
            er.profile = 'Please fill in your last name.';
            setErr(er);
            return
        }
        commonStore.setLoading(true);
        commonStore.updateEmployeeInfo(IDNumber, profile).then((res) => {
            if (!res){
                let er = err;
                er.profile = 'An error occured, please try again later.';
                setErr(er);
                commonStore.setLoading(false);
            }else{
                let sc = success;
                sc.profile = 'Your profile has been updated successfully.';
                setSuccess(sc);
                commonStore.setLoading(false);
            }
        });
    }
    // #endregion

    // #region Authentication
    function authenticationClicked(){
        let wan = webauthn;
        wan.err = '';
        wan.success = '';
        setWebauthn(wan);
        if (activePage === 'authentication'){
            setActivePage('');
        }else{
            setActivePage('authentication');
        }
    }

    function changePasswordClicked(){
        navigate('/changepassword');
    }

    function addPasswordClicked(){
        navigate('/addpassword');
    }

    function addAuthenticationClicked(){
        commonStore.setLoading(true);
        commonStore.addWebauthn().then((ret) => {
            console.log(ret);
            if (ret !== true){
                alert('Unable to add device authentication.');
                commonStore.setLoading(false);
                return
            }
            alert('Authentication added successfully');
            commonStore.setLoading(false);
        })
    }
    // #endregion

    // #region Contact
    function contactClicked(){
        navigate('/contact');
        return
    }

    function mobileChange(e){
        let er = err;
        let sc = success;
        let cnt = contact;

        er.contact = '';
        sc.contact = '';
        cnt.Mobile = e.target.value;
        setErr(er);
        setSuccess(sc);
        setContact(cnt);
    }

    function landlineChange(e){
        let er = err;
        let sc = success;
        let cnt = contact;

        er.contact = '';
        sc.contact = '';
        cnt.Landline = e.target.value;
        setErr(er);
        setSuccess(sc);
        setContact(cnt);
    }

    function workEmailChange(e){
        let er = err;
        let sc = success;
        let cnt = contact;

        er.contact = '';
        sc.contact = '';
        cnt.WorkEmail = e.target.value;
        setErr(er);
        setSuccess(sc);
        setContact(cnt);
    }

    function emailChange(e){
        let er = err;
        let sc = success;
        let cnt = contact;

        er.contact = '';
        sc.contact = '';
        cnt.PrivateEmail = e.target.value;
        setErr(er);
        setSuccess(sc);
        setContact(cnt);
    }

    function updateContact(){
        
        
        if (!contactValid()){
            return
        }
        commonStore.setLoading('True');
        commonStore.updateEmployeeContact(IDNumber, contact).then((res) => {
            if (!res){
                let er = err;
                er.contact = 'An error occured, please try again later.';
                setErr(er);
                commonStore.setLoading(false);
            }else{
                let sc = success;
                sc.contact = 'Your contact information has been updated successfully.';
                setSuccess(sc);
                commonStore.setLoading(false);
            }
        });
    }

    function contactValid(){
        let { Mobile, Landline, WorkEmail, PrivateEmail } = contact;
        let er = err;
        
        if (Mobile.length < 10){
            er.contact = 'Please enter a valid mobile number.';
            setErr(er);
            return false
        }

        if (Landline.length < 10 ){
            er.contact = 'Please enter a valid landline number.';
            setErr(er);
            return false
        }

        if (!(WorkEmail.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))){
            er.contact = 'Please enter a valid work email.';
            setErr(er);
            return false
        }

        if (!(PrivateEmail.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))){
            er.contact = 'Please enter a valid private email.';
            setErr(er);
            return false
        }

        return true
    }
    // #endregion



    // #region Bank Accounts
    function bankClicked(){
        navigate('/bankaccounts');
    }

    function addBankAccount(){
        
        navigate({pathname: '/newbankaccount', search: 'More'});
    }
    // #endregion

    // #region Contact Us
    function contactUsClicked(){
        
        navigate({pathname: '/contactus', search: 'More'});
        // navigate('/employeesupportrequest');
    }
    // #endregion

    // #region Logout
    function logout(){
        commonStore.logout();
    }
    // #endregion

    // #region Agreements
    function agreementsClicked(){
        navigate('/downloadagreements');
    }
    // #endregion

        if (isMobile()){
            return(
                <View>
                    <div className="viewContent view-pages view-session d-flex flex-column">
                        <Card id="dashCard" className="menuCard"> {/*Profile*/}
                            <div className="menuCardDiv" onClick={profileClicked}>
                                <p className="txtlead txtLeft mobileMoreTabP"><FaUserAlt style={{marginRight: '5px', marginLeft: '5px'}}/>Profile</p>
                                {(activePage === 'profile')? <p className="txtlead">&or;</p>: <p className="txtlead"><FaAngleRight style={{width:'25px'}}/></p>}
                            </div>
                            
                        </Card>

                        <Card id="dashCard" className="menuCard"> {/*Contact*/}
                            <div className="menuCardDiv" onClick={contactClicked}>
                                <p className="txtlead txtLeft mobileMoreTabP"><FaAddressBook style={{marginRight: '5px', marginLeft: '5px'}}/>Contact information</p>
                                {(activePage === 'contact')? <p className="txtlead">&or;</p>: <p className="txtlead"><FaAngleRight style={{width:'25px'}}/></p>}
                            </div>
                            
                        </Card>


                        <Card id="dashCard" className="menuCard"> {/*Bank Accounts*/}
                            <div className="menuCardDiv" onClick={bankClicked}>
                                <p className="txtlead txtLeft mobileMoreTabP"><FaCreditCard style={{marginRight: '5px', marginLeft: '5px'}}/>Bank accounts</p>
                                {(activePage === 'Bank')? <p className="txtlead">&or;</p>: <p className="txtlead"><FaAngleRight style={{width:'25px'}}/></p>}
                            </div>
                        </Card>

                        <Card id="dashCard" className="menuCard"> {/*Authentication*/}
                            <div className="menuCardDiv" onClick={authenticationClicked}>
                                <p className="txtlead txtLeft mobileMoreTabP"><FaLock style={{marginRight: '5px', marginLeft: '5px'}}/>Authentication</p>
                                {(activePage === 'authentication')? <p className="txtlead"><FaAngleDown style={{width:'25px'}}/></p>: <p className="txtlead"><FaAngleRight style={{width:'25px'}}/></p>}
                            </div>
                            {(activePage === 'authentication')?
                            <div style={{overflow: 'auto', height: '300px'}}>
                                
                                <hr/>
                                {(commonStore.user.password)?
                                <Button className='cornered-button'  block size="lg" onClick={changePasswordClicked} style={{marginTop: '10px', marginBottom: '10px'}}>Change password</Button>:
                                <Button className='cornered-button' block size="lg" onClick={addPasswordClicked} style={{marginTop: '10px', marginBottom: '10px'}}>Add password</Button>
                                }
                                
                                <Button className='cornered-button' block size="lg" onClick={addAuthenticationClicked} style={{marginTop: '10px', marginBottom: '10px'}}>Add device authentication</Button>
                                
                                
                                {(webauthn.err.length > 0)? <p style={{color: 'red'}}>{webauthn.err}</p>:null}
                                {(webauthn.success.length > 0)? <p style={{color: 'green'}}>{webauthn.success}</p>:null}
                            </div>
                            :null}
                        </Card>

                        <Card id="dashCard" className="menuCard"> {/*Contact Us*/}
                            <div className="menuCardDiv" onClick={contactUsClicked}>
                                <p className="txtlead txtLeft mobileMoreTabP"><FaRegComments style={{marginRight: '5px', marginLeft: '5px'}}/>Contact us</p>
                                {(activePage === 'contactUs')? <p className="txtlead">&or;</p>: <p className="txtlead"><FaAngleRight style={{width:'25px'}}/></p>}
                            </div>
                            
                        </Card>

                        <Card id="dashCard" className="menuCard"> {/*Legal*/}
                            <div className="menuCardDiv" onClick={agreementsClicked}>
                                <p className="txtlead txtLeft mobileMoreTabP"><FaFileContract style={{marginRight: '5px', marginLeft: '5px'}}/>Agreements</p>
                                {(activePage === 'agreements')? <p className="txtlead">&or;</p>: <p className="txtlead"><FaAngleRight style={{width:'25px'}}/></p>}
                            </div>
                            
                        </Card>

                        <Card id="dashCard" className="menuCard" onClick={logout}>
                            <div>
                                <p className="txtlead txtLeft mobileMoreTabP"><FaSignOutAlt style={{marginRight: '5px', marginLeft: '5px'}}/>Logout</p>
                            </div>
                        </Card>

                    </div>
                </View>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <div id="Profile" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <p className="txtsub txtLeft"><i style={{paddingRight: '5px'}} className="fa fa-user"></i>Profile</p>
                            <Button className="transparentButton" block size="sm" onClick={profileClicked} style={{maxWidth: '20%'}}>Edit</Button>
                        </div>
                        <div>
                            <hr/>
                            <Form>
                                <label className="custom-field">
                                    <input className='inp' type="text" id="firstName" value={profile.FirstName} onChange={firstNameChange} required disabled/>
                                    <span className="placeholder">First name</span>
                                </label> 

                                <label className="custom-field">
                                    <input className='inp' type="text" id="lastName" value={profile.LastName} onChange={lastNameChange} required disabled/>
                                    <span className="placeholder">Last name</span>
                                </label> 

                                {(err.profile.length > 0)? <p style={{color: 'red'}}>{err.profile}</p>:null}
                                {(success.profile.length > 0)? <p style={{color: 'green'}}>{success.profile}</p>:null}

                            </Form>
                        </div>
                    </div>

                    <div id="Contact" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <p className="txtsub txtLeft"><i style={{paddingRight: '5px'}} className="fa fa-address-book"></i>Contact information</p>
                            <Button className="transparentButton" block size="sm" onClick={contactClicked} style={{maxWidth: '20%'}}>Edit</Button>
                        </div>
                        <div>
                            <hr/>
                            <Form>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <label className="custom-field">
                                        <input className='inp' type="text" id="mobile" value={contact.Mobile} onChange={mobileChange} required disabled/>
                                        <span className="placeholder">Mobile</span>
                                    </label> 

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="landline" value={contact.Landline} onChange={landlineChange} required disabled/>
                                        <span className="placeholder">Landline</span>
                                    </label> 
                                </div>

                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <label className="custom-field">
                                        <input className='inp' type="text" id="workEmail" value={contact.WorkEmail} onChange={workEmailChange} required disabled/>
                                        <span className="placeholder">Work Email</span>
                                    </label> 

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="privateEmail" value={contact.PrivateEmail} onChange={emailChange} required disabled/>
                                        <span className="placeholder">Private Email</span>
                                    </label> 
                                </div>
                                

                                {(err.contact.length > 0)? <p style={{color: 'red'}}>{err.contact}</p>:null}
                                {(success.contact.length > 0)? <p style={{color: 'green'}}>{success.contact}</p>:null}

                            </Form>
                        </div>
                    </div>

                    <div id="Bank" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <p className="txtsub txtLeft"><i style={{paddingRight: '5px'}} className="fa fa-credit-card"></i>Bank accounts</p>
                            {(commonStore.employeeDetail.bankaccount && commonStore.employeeDetail.secondaryBankAccount)? null : 
                                <Button className="transparentButton" block size="sm" onClick={bankClicked} style={{maxWidth: '20%'}}>Add</Button>
                            }
                        </div>
                        <div>
                            <hr/>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{width: '45%'}}>
                                    <p>Primary Account</p>
                                    {(commonStore.employeeDetail.bankaccount) ?
                                    <Card className='bankaccCard'>
                                        <div>
                                            <p className='bankaccHeader'>{commonStore.employeeDetail.bankaccount.bankname || ''}</p>
                                            <p className='bankaccSub'>{`**** ${commonStore.employeeDetail.bankaccount.accountnumber.substr(commonStore.employeeDetail.bankaccount.accountnumber.length -4)}`}</p>
                                        </div>
                                    </Card>
                                    : <p style={{fontWeight: 'lighter'}}>No primary account added</p>}
                                </div>
                                <div style={{width: '45%'}}>
                                    <p>Secondary Account</p>
                                    {(commonStore.employeeDetail.secondaryBankAccount) ?
                                    <Card className='bankaccCard'>
                                        <div>
                                            <p className='bankaccHeader'>{commonStore.employeeDetail.secondaryBankAccount.bankname || ''}</p>
                                            <p className='bankaccSub'>{`**** ${commonStore.employeeDetail.secondaryBankAccount.accountnumber.substr(commonStore.employeeDetail.secondaryBankAccount.accountnumber.length -4)}`}</p>
                                        </div>
                                    </Card>
                                    : <p style={{fontWeight: 'lighter'}}>No secondary account added</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="password" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <p className="txtsub txtLeft"><i style={{paddingRight: '5px'}} className="fa fa-lock"></i>Authentication</p>
                            {/* <Button className="transparentButton" block size="sm" onClick={changePasswordClicked} style={{width: '10%'}}>Change</Button> */}
                        </div>
                        <div style={{backgroundColor: '#ffffff'}}>
                            <hr/>
                        {(commonStore.user.password)?
                                <Button  block size="lg" onClick={changePasswordClicked} style={{marginTop: '10px', marginBottom: '10px'}}>Change password</Button>:
                                <Button  block size="lg" onClick={addPasswordClicked} style={{marginTop: '10px', marginBottom: '10px'}}>Add password</Button>
                                }
                                
                                <Button  block size="lg" onClick={addAuthenticationClicked} style={{marginTop: '10px', marginBottom: '10px'}}>Add device authentication</Button>
                                
                                
                                {(webauthn.err.length > 0)? <p style={{color: 'red'}}>{webauthn.err}</p>:null}
                                {(webauthn.success.length > 0)? <p style={{color: 'green'}}>{webauthn.success}</p>:null}
                        </div>
                    </div>

                    <div id="agreements" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <p className="txtsub txtLeft"><i style={{paddingRight: '5px'}} className="fa fa-download"></i>Agreements</p>
                            <Button className="transparentButton" block size="sm" onClick={agreementsClicked} style={{maxWidth: '20%'}}>View</Button>
                        </div>
                        <div style={{backgroundColor: '#ffffff'}}>
                            <hr/>
                        </div>
                    </div>


                </View>
            );
        }

})

export default More;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Link } from 'react-router-dom';
import { Form, Label, FormGroup, Button, FormText, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean, AndroidInstallPrompt, IOSInstallPrompt, CustomInput } from '../../shared/components';


import '../style.scss';


const SignIn = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [data, setData] = useState({
        id: '',
        pass: null
    });

    const [IDNumber, setIDNumber] = useState('');
    const [Password, setPassword] = useState('');
    const [webauthn, setWebauthn] = useState(true);
    const [usePassword, setUsePassword] = useState(false);
    const [browserAgent, setBrowserAgent] = useState('');
    const [displayPrompt, setDisplayPrompt] = useState(true);
    const [device, setDevice] = useState('');
    const [prompt, setPrompt] = useState({});

    const [err, setErr] = useState(null);
    const [success, setSuccess] = useState('');
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    function setStateData(val) {
        if (Object.keys(val)[0] === 'data') {
            setData(val.data)
        }

        if (Object.keys(val)[0] === 'err') {
            setErr(val.err)
        }

        if (Object.keys(val)[0] === 'success') {
            setSuccess(val.success)
        }
    }

    useEffect(() => {
        console.log(process.env);
        console.log(process.env.REACT_APP_MIDDLEWARE_URL);
        if (typeof window.PublicKeyCredential !== 'undefined') {
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available) {
                    //Webauthn Available
                    setWebauthn(true);
                } else {
                    //Webauthn not available
                    setWebauthn(false);
                }
            }).catch((e) => {
                //Webauthn not available
                setWebauthn(false);
            })
        } else {
            setWebauthn(false);
        }

        if (isMobile()) {
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }

        if (isMobile()) { //Install Prompt

            if (window.matchMedia('(display-mode: standalone)').matches) {
                //If already running PWA
                return
            }
            const ua = window.navigator.userAgent;
            if (!!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Mac/i)) {
                //iOS
                loadIosPrompt();
            } else {
                //Android
                window.addEventListener('beforeinstallprompt', (event) => {
                    console.log('[beforeinstallprompt] Called');
                    // Prevent Chrome <= 67 from automatically showing the prompt
                    event.preventDefault();
                    // Stash the event so it can be triggered later.
                    setPrompt(event);
                    // Update the install UI to notify the user app can be installed
                    // document.querySelector('#install-button').disabled = false;
                    loadPrompt();
                });

            }
        }
    }, []);

    function loadPrompt() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            //If already running PWA
            return
        }
        const ua = window.navigator.userAgent;

        if (!!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Mac/i)) {
            //iOS
            // data.device = 'iOS';
            // setStateData({ data: data});
            return
        } else {
            //Android
            setDevice('Android');
        }
    }

    function loadIosPrompt() {
        let dt = data;
        setDevice('iOS');
    }



    function handleChange(e) {
        setIDNumber(e.target.value.toLowerCase());
    }

    function passwordChanged(e) {
        setPassword(e.target.value);
    }

    function doLogin() {

        let id = IDNumber;
        let pass = Password;


        if (id && id.length > 10) {
            if (pass && pass.length > 0) {
                setStateData({ err: null });
                commonStore.login(id, pass).then((valid) => {
                    if (valid === true) {
                        //commonStore.setLoading(false);
                        if (commonStore.user.role === "5d8e0b079f51d42bbece9202") {
                            navigate('/home', { page: 'Dashboard' });
                        } else if (commonStore.user.role === "5d8e0b079f51d42bbece9201") {
                            navigate('/admindashboard');
                        } else if (commonStore.user.role === '62bd55b7c5793941427c702d') {
                            navigate('/clientdashboard');
                        }


                    } else if (valid === 'Blocked') {
                        setStateData({ err: 'This account has been blocked, please contact support.' });
                        setStateData({ data: data });
                        commonStore.setLoading(false);
                        return
                    } else {
                        commonStore.setLoading(false);
                        setStateData({ err: 'Invalid login details' });
                    }
                }).catch(() => {
                    commonStore.setLoading(false);
                    setStateData({ err: 'Invalid login details' });
                })
                //.finally(() => commonStore.setLoading(false));
            } else {
                commonStore.setLoading(false);
                setStateData({ err: 'Invalid login details' });
            }
        } else {
            commonStore.setLoading(false);
            setStateData({ err: 'Invalid login details' });
        }
    }

    function verifyCallback(recaptchaToken) {
        // console.log('recaptchaToken: ', recaptchaToken);
        setStateData({ recaptchaToken: recaptchaToken });
    }

    function go(path) {


        // console.log('GO - Signin ', path);
        navigate(path);
    }

    function checkWebauthn(e) {


        let id = IDNumber;

        if (!id || id.length < 10) {
            setStateData({ err: 'Invalid login details' });
            return false;
        }

        commonStore.setLoading(true);
        commonStore.checkWebauthn(id).then((ret) => {
            if (ret === "nf") {
                setStateData({ err: 'User not found' });
                setStateData({ data: data });
                commonStore.setLoading(false);
            } else if (ret === "error") {
                setUsePassword(true);
                setStateData({ err: 'Invalid login details' });
                setStateData({ data: data });
                commonStore.setLoading(false);
            } else if (ret === 'Blocked') {
                setStateData({ err: 'This account has been blocked, please contact support.' });
                setStateData({ data: data });
                commonStore.setLoading(false);
                return
            } else if (ret) {
                commonStore.setLoading(false);
                if (commonStore.user.role === "5d8e0b079f51d42bbece9202") {
                    navigate('/home', { page: 'Dashboard' });
                } else if (commonStore.user.role === "5d8e0b079f51d42bbece9201") {
                    navigate('/admindashboard');
                } else if (commonStore.user.role === '62bd55b7c5793941427c702d') {
                    navigate('/clientdashboard');
                }
            } else {
                setWebauthn(false);
                setStateData({ data: data });
                commonStore.setLoading(false);
            }
        });
    }

    function UsePassword() {
        setUsePassword(false);
        setWebauthn(false);
        if (!commonStore.passwordAvailable) {
            resetPassword();
        }
        setStateData({ data: data });
        setStateData({ err: '' });
    }

    function resetPassword() {

        if (IDNumber.length < 10) {
            setStateData({ err: 'Invalid ID number.' });
            return
        }
        commonStore.setLoading(true);
        commonStore.generatePasswordReset(IDNumber).then((ret) => {
            if (ret === true) {
                commonStore.setLoading(false);
                alert('A password reset link has been emailed to your work email.');
                return
            } else {
                commonStore.setLoading(false);
                alert(ret);
                return
            }
        });
    }

    function signUp() {
        // let { history } = props;
        navigate('/signup');
    }

    function closeInstallPrompt() {
        // let { data } = state;
        setDisplayPrompt(false);
    }

    function installPromptInstall() {
        // let { data } = state;
        prompt.prompt();
        setDisplayPrompt(false);
    }


    if (isMobile()) {
        return (
            <div id="mobileDashView" className="view" style={{ justifyContent: 'flex-start' }}>
                <div>
                    <MobileTopBarClean />
                </div>

                <div id="mainView" className="viewContent view-pages view-session d-flex justify-content-center flex-column">
                    <p className="txtlead left" style={{ textAlign: 'left !important' }}>Sign in</p>
                    <p className="txtsub left">Continue to Level Finance</p>
                    <Form>
                        {(webauthn) ?
                            <FormGroup className="mb-5 inpControls">
                                <CustomInput type='text' id='id' value={IDNumber} placeholder='ID/Passport number' onChange={handleChange} onEnter={checkWebauthn} />
                                {(err && err.length > 0) ?
                                    <Label className="err">{err}</Label> : null}
                                {(success && success.length > 0) ? <p style={{ color: 'green' }}>{success}</p> : null}
                                <Button className='cornered-button' color="info" block size="lg" onClick={checkWebauthn}>Sign In</Button>
                                <br />
                                {usePassword ?
                                    <Button className='cornered-button' color="info" block size="lg" onClick={UsePassword}>Use password</Button> : null}

                                <br />
                                <Label className='txtlogin'>Don't have an account? <span style={{ color: '#4c1ba5', cursor: 'pointer' }} onClick={signUp}>Sign Up</span></Label>
                            </FormGroup> :
                            <FormGroup>
                                <CustomInput type='text' id='id' value={IDNumber} placeholder='ID/Passport number' onChange={handleChange} onEnter={checkWebauthn} />
                                <CustomInput type='password' id='pass' value={Password} placeholder='Password' onChange={passwordChanged} onEnter={doLogin} />

                                <FormText className="text-right" onClick={resetPassword}><Link to='#'>Forgot your password?</Link></FormText>
                                {(err && err.length > 0) ?
                                    <Label className="err">{err}</Label> : null}
                                {(success && success.length > 0) ? <p style={{ color: 'green' }}>{success}</p> : null}
                                <Button className='cornered-button' color="info" block size="lg" onClick={doLogin}>Sign In</Button>
                                <br />
                                <Label className='txtlogin'>Don't have an account? <span style={{ color: '#4c1ba5', cursor: 'pointer' }} onClick={signUp}>Sign Up</span></Label>
                            </FormGroup>
                        }

                    </Form>
                </div>
                {(displayPrompt) ?
                    (device === 'Android') ? <AndroidInstallPrompt onClose={closeInstallPrompt} onInstall={installPromptInstall} /> :
                        (device === 'iOS') ? <IOSInstallPrompt onClose={closeInstallPrompt} onInstall={installPromptInstall} /> : null

                    : null
                }

            </div>);
    } else {

        return (
            <View>
                <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                    <Card className="mb-3 form-card loginCard" style={{ maxHeight: `${window.innerHeight - 100}px` }}>
                        <CardBody style={{ display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
                            <header className="mb-4 topLogin">
                                <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                <p className="txtlead">Sign in</p>
                                <p className="txtsub">Continue to Level Finance</p>
                                <br />
                            </header>

                            <Form>
                                {(webauthn) ?
                                    <FormGroup className="mb-5 inpControls">
                                        <CustomInput type='text' id='id' value={IDNumber} placeholder='ID/Passport number' onChange={handleChange} onEnter={checkWebauthn} />
                                        {(err && err.length > 0) ?
                                            <Label className="err">{err}</Label> : null}
                                        {(success && success.length > 0) ? <p style={{ color: 'green' }}>{success}</p> : null}
                                        <Button className='cornered-button' color="info" block size="lg" onClick={checkWebauthn}>Sign In</Button>
                                        <br />
                                        {usePassword ?
                                            <Button color="info" block size="lg" onClick={UsePassword}>Use password</Button> : null}

                                        <br />
                                        <Label className='txtlogin'>Don't have an account? <span style={{ color: '#4c1ba5', cursor: 'pointer' }} onClick={signUp}>Sign Up</span></Label>
                                    </FormGroup> :
                                    <FormGroup className="mb-4">

                                        <CustomInput type='text' id='id' value={IDNumber} placeholder='ID/Passport number' onChange={handleChange} onEnter={checkWebauthn} />
                                        <CustomInput type='password' id='pass' value={Password} placeholder='Password' onChange={passwordChanged} onEnter={doLogin} />

                                        <FormText className="text-right" onClick={resetPassword}><Link to='#'>Forgot your password?</Link></FormText>

                                        {(err && err.length > 0) ?
                                            <Label className="err">{err}</Label> : null}
                                        {(success && success.length > 0) ? <p style={{ color: 'green' }}>{success}</p> : null}

                                        <Button className='cornered-button' color="info" block size="lg" onClick={doLogin}>Sign In</Button>
                                        <br />
                                        <Label className='txtlogin'>Don't have an account? <span style={{ color: '#4c1ba5', cursor: 'pointer' }} onClick={signUp}>Sign Up</span></Label>
                                    </FormGroup>}

                            </Form>
                        </CardBody>
                    </Card>

                </div>
            </View>
        );
    }
})

export default SignIn

import React, { useState, useEffect } from 'react';
import './styles.scss'

const MultipleChoice = (props) => {
    // const [, updateState] = useState();
    // const forceUpdate = React.useCallback(() => updateState({}), []);

    let { question, answerPrefix, questionNumber, options, onOptionChange, category } = props
    const [selectedOption, setSelectedOption] = useState(null);
    const [id, setId] = useState(null);


    const handleOptionChange = (event, question, score, answer, questionNumber, category) => {
        const value = event.target.value;
        setSelectedOption(value);
        setId(`${questionNumber}-${value}`)

        if (onOptionChange) {
            onOptionChange({ score, question, answer, questionNumber, category });
        }
    };

    return (
        <div>
            <p className='multiple-choice-question-header'>{`Question ${questionNumber}/15`}</p>
            <p>{`${question}`}</p>
            {answerPrefix !== "" && <p>{answerPrefix}</p>}
            <form className='multiple-choice-options-container'>
                {options.map((option, index) => (
                    <div className={`multiple-choice-option-card ${id === `${questionNumber}-${option.answer}` ? 'checked' : ''}`} key={index}>
                        <input
                            type="radio"
                            id={`option${index}`}
                            name={`question${questionNumber}`}
                            value={option.answer}
                            checked={id === `${questionNumber}-${option.answer}`}
                            onChange={(event) => handleOptionChange(event, question, option.score, option.answer, questionNumber, category)}
                        />
                        <label style={{ width: '100%' }} htmlFor={`option${index}`}>{option.answer}</label>
                    </div>
                ))}
            </form>
        </div>
    );
}

export default MultipleChoice;

import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card, Form } from '../../../components/basic';
import { View } from '../../../shared/components';
import { FaStop, FaPlay, FaUserPlus, FaUserCog, FaPen, FaTimes } from 'react-icons/fa';



import '../../style.scss';
import './style.scss';

const Users = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [users, setUsers] = useState([]);
    const [activePage, setActivePage] = useState('Existing');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [err, setErr] = useState('');
    const [Permissions, setPermissions] = useState([]);
    const [Role, setRole] = useState('Admin');
    const [SelectedUser, setSelectedUser] = useState({});
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        loadUsers();
    }, []);

    function loadUsers(){
        commonStore.getAdminUsers().then((ret) => {
            setUsers(ret);
        });
    }

    function addUser(){
        navigate('/newuser');
    }

    function unblockUser(e){
        
        
        let user = users[Number(e.currentTarget.id)];
        if (window.confirm(`Are you sure you want to unblock ${user.username}?`)){
            commonStore.setLoading(true);
            let updates = {blocked: false};
            let id = user.username;
            commonStore.unblockUser(id, `The user has been unblocked by ${commonStore.user.username}.`).then((ret) => {
                if (ret === true){
                    loadUsers();
                    commonStore.setLoading(false);
                }else{
                    console.log('Unable to update user');
                    commonStore.setLoading(false);
                }
            });
        }
    }

    function blockUser(e){
        
        
        let user = users[Number(e.currentTarget.id)];
        if (window.confirm(`Are you sure you want to block ${user.username}?`)){
            commonStore.setLoading(true);
            let updates = {blocked: true};
            let id = user.username;
            commonStore.blockUser(id, `The user has been blocked by ${commonStore.user.username}.`).then((ret) => {
                if (ret === true){
                    loadUsers();
                    commonStore.setLoading(false);
                }else{
                    console.log('Unable to update user');
                    commonStore.setLoading(false);
                }
            });
        }
    }

    function optionSelected(e){
        setActivePage(e.target.value);
    }

    function nameChanged(e){
        
        setErr('');
        setName(e.target.value);
    }
    
    function lastNameChanged(e){
        setErr('');
        setLastName(e.target.value);
    }

    function emailChanged(e){
        
        setErr('');
        setEmail(e.target.value);
    }

    function passwordChanged(e){
        
        setErr('');
        setPassword1(e.target.value);
    }

    function confirmPasswordChanged(e){
        
        setErr('');
        setPassword2(e.target.value);
    }

    function roleChanged(e){
        setRole(e.target.value);
    }

    function permissionChanged(e){
        let perm = Permissions;
        if (perm.includes(e.currentTarget.id)){
            const index = perm.indexOf(e.currentTarget.id);
            if (index !== -1) {
                perm.splice(index, 1);
            }
        }else{
            perm.push(e.currentTarget.id);
        }
        setPermissions(perm);
        forceUpdate();
    }

    function validatePassword(){

        if (password1.length < 6){
            window.alert('Password is too short');
            return false
        }

        if (password1 !== password2){
            window.alert('Passwords do not match.');
            return false
        }

        return true

    }

    function clearInputs(){
        setEmail('');
        setName('');
        setLastName('');
        setPassword1('');
        setPassword2('');
        setRole('Admin');
        setPermissions([]);
    }

    function addUserClicked(){
        
        
        commonStore.setLoading(true);
        if (validatePassword()){
            let user = {
                username: email,
                email: email, 
                firstname: name,
                lastname: lastName,
                password: password1,
                confirmed: true,
                blocked: false,
                role: '5d8e0b079f51d42bbece9201',
                adminPermissions: Permissions,
                adminRole: Role
            }

            commonStore.createUser(user).then((ret) => {
                if (ret === true){
                    window.alert('User created succesfully');
                    loadUsers();
                    clearInputs();
                }else{
                    window.alert(ret);
                    commonStore.setLoading(false);
                }
            });
        }else{
            commonStore.setLoading(false);
        }
    }

    function editUser(e){
        setSelectedUser(users[Number(e.currentTarget.id)]);
    }

    function cancelEdit(){
        setSelectedUser({});
    }

        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%'}}>
                    {(activePage === 'Existing') ? 
                    <div style={{width: '100%', height: '89%', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', overflow: 'scroll', padding: '10px'}}>
                        {users.map((user, i) => {
                            let name = `${user.firstname || ''} ${user.lastname || ''} (${user.username || ''})`
                            return(
                                <Card key={i} style={{marginTop: '5px', marginBottom: '5px', borderRadius: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '10px', paddingRight:'10px', width: '100%'}}>
                                    <div>
                                        <p className="txtlead txtLeft">{`${name}`}</p>
                                    </div>
                                    <div style={{display: 'flex', width: '30%'}}>
                                        <FaPen/>
                                        <div id={i}>
                                            {(user.blocked === false)? 
                                            <p><span style={{color: 'green'}}>Active </span><FaStop id={i.toString()} className="fa fa-stop" style={{top: '50%'}} aria-hidden="true" onClick={blockUser}/></p>:
                                            <p><span style={{color: 'red'}}>Blocked </span><FaPlay id={i.toString()} className="fa fa-play" style={{top: '50%'}} aria-hidden="true" onClick={unblockUser}/></p>
                                            }
                                            
                                        </div>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                    : null}

                    {(activePage === 'New') ? 
                    <div style={{width: '100%', height: '89%', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', overflow: 'scroll', padding: '10px'}}>
                        <p className="txtlead">New Admin User</p>
                        <p className="txtsub">A new admin user will be added. Admin users do not have access to requesting advances and only have access to the admin dashboard.</p>

                        <label className="custom-field">
                            <input className='inp' type="text" id="name" value={name} onChange={nameChanged} required/>
                            <span className="placeholder">First name</span>
                        </label> 

                        <label className="custom-field">
                            <input className='inp' type="text" id="lastname" value={lastName} onChange={lastNameChanged} required/>
                            <span className="placeholder">Last name</span>
                        </label> 

                        <label className="custom-field">
                            <input className='inp' type="email" id="email" value={email} onChange={emailChanged} required/>
                            <span className="placeholder">Email</span>
                        </label> 

                        <label className="custom-field">
                            <select className='inp' value={Role} onChange={roleChanged} required>
                                <option value='Admin'>Admin</option>
                                <option value='Support'>Support</option>
                            </select>
                            <span className="placeholder">Role</span>
                        </label>

                        <label className="custom-field">
                            <input className='inp' type="password" id="password" value={password1} onChange={passwordChanged} required/>
                            <span className="placeholder">Password</span>
                        </label> 

                        <label className="custom-field">
                            <input className='inp' type="password" id="confirmpassword" value={password2} onChange={confirmPasswordChanged} required/>
                            <span className="placeholder">Confirm password</span>
                        </label> 

                        <p>Permissions</p>
                                <div style={{width: '80%'}}>
                                    <label id='Reporting' className="checkboxContainer">Reporting
                                        <input type="checkbox" onChange={permissionChanged} id='Reporting' checked={Permissions.includes('Reporting')}/>
                                        <span id='Reporting' className="checkmark"></span>
                                    </label>
                                </div>

                                <div style={{width: '80%'}}>
                                    <label id='Client' className="checkboxContainer">Client
                                        <input type="checkbox" onChange={permissionChanged} id='Client' checked={Permissions.includes('Client')}/>
                                        <span id='Client' className="checkmark"></span>
                                    </label>
                                </div>

                                <div style={{width: '80%'}}>
                                    <label id='Advances' className="checkboxContainer">Advances
                                        <input type="checkbox" onChange={permissionChanged} id='Advances' checked={Permissions.includes('Advances')}/>
                                        <span id='Advances' className="checkmark"></span>
                                    </label>
                                </div>

                                <div style={{width: '80%'}}>
                                    <label id='Invoices' className="checkboxContainer">Invoices
                                        <input type="checkbox" onChange={permissionChanged} id='Invoices' checked={Permissions.includes('Invoices')}/>
                                        <span id='Invoices' className="checkmark"></span>
                                    </label>
                                </div>

                                <div style={{width: '80%'}}>
                                    <label id='Employee' className="checkboxContainer">Employee
                                        <input type="checkbox" onChange={permissionChanged} id='Employee' checked={Permissions.includes('Employee')}/>
                                        <span id='Employee' className="checkmark"></span>
                                    </label>
                                </div>

                                <div style={{width: '80%'}}>
                                    <label id='NewEmployee' className="checkboxContainer">New Employee
                                        <input type="checkbox" onChange={permissionChanged} id='NewEmployee' checked={Permissions.includes('NewEmployee')}/>
                                        <span id='NewEmployee' className="checkmark"></span>
                                    </label>
                                </div>

                                <div style={{width: '80%'}}>
                                    <label id='AdminUsers' className="checkboxContainer">Admin Users
                                        <input type="checkbox" onChange={permissionChanged} id='AdminUsers' checked={Permissions.includes('AdminUsers')}/>
                                        <span id='AdminUsers' className="checkmark"></span>
                                    </label>
                                </div>

                                <div style={{width: '80%'}}>
                                    <label id='SupportRequests' className="checkboxContainer">Support Requests
                                        <input type="checkbox" onChange={permissionChanged} id='SupportRequests' checked={Permissions.includes('SupportRequests')}/>
                                        <span id='SupportRequests' className="checkmark"></span>
                                    </label>
                                </div>

                                <div style={{width: '80%'}}>
                                    <label id='Logs' className="checkboxContainer">Logs
                                        <input type="checkbox" onChange={permissionChanged} id='Logs' checked={Permissions.includes('Logs')}/>
                                        <span id='Logs' className="checkmark"></span>
                                    </label>
                                </div>

                        {(err.length > 0) ? <p style={{color: 'red'}}>{err}</p>:null}
                        <Button block size="lg" onClick={addUserClicked}>Add user</Button>
                    </div>
                    : null}


                    <Card className="tabBar" style={{height: '11%'}}>
                        <Form className="tabBarForm" onChange={optionSelected} style={{overflow: 'hidden'}}>

                            <div className="tabBarItem">
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="existingTab" value="Existing"/>
                                <FaUserCog className={(activePage === "Existing")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="existingTab" style={{height: '100%'}}/>
                                <label className={(activePage === "Existing")? "tabSelected":null} htmlFor="existingTab">Existing</label>
                            </div>

                            <div className="tabBarItem"> 
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="newTab" value="New"/>
                                <FaUserPlus className={(activePage === "New")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="newTab" style={{height: '100%'}}/>
                                <label className={(activePage === "New")? "tabSelected":null} htmlFor="newTab">New</label>
                            </div>

                        </Form>
                        
                    </Card>

                </div>

                
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Users</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <p className="txtsub txtLeft">Admin users</p>
                            <Button className="transparentButton" block size="sm" onClick={addUser} style={{width: '10%'}}>New</Button>
                        </div>
                        <hr/>
                        {users.map((user, i) => {
                            let name = `${user.firstname || ''} ${user.lastname || ''} (${user.username || ''})`
                            return(
                                <Card key={i} style={{marginTop: '5px', marginBottom: '5px', borderRadius: '10px', display: 'flex', 'flexDirection': 'row', 'alignItems': 'center', 'justifyContent': 'space-between', 'paddingLeft': '10px', 'paddingRight':'10px'}}>
                                    <div>
                                        <p className="txtlead txtLeft">{`${name}`}</p>
                                    </div>
                                    <div style={{display: 'flex', width: '30%'}}>
                                        <FaPen id={i} onClick={editUser} style={{cursor: 'pointer'}}/>
                                        <div id={i}>
                                            {(user.blocked === false)? 
                                            <p><span style={{color: 'green'}}>Active </span><FaStop id={i.toString()} className="fa fa-stop" style={{top: '50%'}} aria-hidden="true" onClick={blockUser}/></p>:
                                            <p><span style={{color: 'red'}}>Blocked </span><FaPlay id={i.toString()} className="fa fa-play" style={{top: '50%'}} aria-hidden="true" onClick={unblockUser}/></p>
                                            }
                                            
                                        </div>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>

                    {Object.keys(SelectedUser).length === 0 ? null : 
                    <Card className='editAdminUserCardDesktop'>
                        <FaTimes onClick={cancelEdit} style={{position: 'absolute', right: '10px', top: '0px', cursor: 'pointer'}}/>
                        <p>{`Edit ${SelectedUser.username}`}</p>
                        <label className="custom-field">
                            <select className='inp' value={SelectedUser.adminRole || ''} onChange={roleChanged} required>
                                <option value='Admin'>Admin</option>
                                <option value='Support'>Support</option>
                            </select>
                            <span className="placeholder">Role</span>
                        </label>
                    </Card>
                    }

                </View>
            );
        }
        
})

export default Users;
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Card, Button } from '../../../components/basic';
import { View, CustomInput } from '../../../shared/components';
import { FaChevronRight, FaTimes } from 'react-icons/fa';

import '../../style.scss';
import './style.scss';

const Employees = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Employees, setEmployees] = useState([]);
    const [SelectedEmployee, setSelectedEmployee] = useState({});
    const [SelectedBankAccount, setSelectedBankAccount] = useState({});

    

    useEffect(() => {
        getEmployees();
    }, []);

    const getEmployees = async() => {
        commonStore.setLoading(true);
        let employees = await commonStore.getEmployeesByBusinessEntity(commonStore.user.businessentity._id);
        setEmployees(employees);
        commonStore.setLoading(false);
    }

    function employeeSelected(e){
        setSelectedEmployee(Employees[Number(e.currentTarget.id)])
        setSelectedBankAccount({...Employees[Number(e.currentTarget.id)].bankaccount || {}});
        console.log(Employees[Number(e.currentTarget.id)]);
    }
    function closeEmployee(){
        setSelectedEmployee({})
        setSelectedBankAccount({});
        forceUpdate();
    }
    function selectedSalaryChanged(e){
        let emp = SelectedEmployee;
        emp.employeesalary.Amount = Number(e.target.value);
        setSelectedEmployee(emp);
        forceUpdate();
    }

    function selectedAdvancePercentChanged(e){
        let emp = SelectedEmployee;
        let perc = Number(e.target.value);
        if (perc > 25){
            perc = 25;
        }
        emp.employeesalary.ElectedAdvancePerc = perc;
        setSelectedEmployee(emp);
        forceUpdate();
    }

    const updateSalary = async() => {
        let IDNumber = SelectedEmployee.IDNumber;
        let salaryObject = SelectedEmployee.employeesalary;
        let salaryUpdates = {
            Amount: salaryObject.Amount,
            ElectedAdvancePerc: salaryObject.ElectedAdvancePerc
        };
        commonStore.setLoading(true);
        let salaryUpdate = await commonStore.updateEmployeeSalary(IDNumber, salaryUpdates);
        window.alert('Employee has been updated');
        closeEmployee();
        getEmployees();


    }

    function getBranchCode(bank){
        var branchCode;
        switch(bank){
            case 'ABSA': branchCode = '632005'; break;
            case 'Access Bank': branchCode = ''; break;
            case 'African Bank': branchCode = '430000'; break;
            case 'Albaraka Bank': branchCode = '800000'; break;
            case 'Bank Of Athens': branchCode = '410506'; break;
            case 'Bank Windhoek': branchCode = ''; break;
            case 'Bidvest Bank': branchCode = '462005'; break;
            case 'Capitec Bank': branchCode = '470010'; break;
            case 'CitiBank': branchCode = '350005'; break;
            case 'Diamond Bank': branchCode = ''; break;
            case 'Discovery Bank': branchCode = '679000'; break;
            case 'FirstBank': branchCode = ''; break;
            case 'FNB Namibia': branchCode = ''; break;
            case 'FNB': branchCode = '250655'; break;
            case 'GTBank': branchCode = ''; break;
            case 'HSBC Bank': branchCode = '587000'; break;
            case 'Investec': branchCode = '580105'; break;
            case 'Mercantile Bank': branchCode = '450105'; break;
            case 'Nedbank': branchCode = '198765'; break;
            case 'Olympus Mobile': branchCode = '585001'; break;
            case 'RMB': branchCode = '250655'; break;
            case 'Sasfin Bank': branchCode = '683000'; break;
            case 'Standard Bank': branchCode = '051001'; break;
            case 'Standard Bank Namibia': branchCode = ''; break;
            case 'Standard Chartered Bank': branchCode = '730020'; break;
            case 'TymeBank': branchCode = '678910'; break;
            case 'United Bank for Africa': branchCode = ''; break;
            case 'Ubank Limited': branchCode = '431010'; break;
            case 'Zenith Bank': branchCode = ''; break;
            default: branchCode = ''; break;
        }
        return branchCode
    }

    function bankNameChanged(e){
        let acc = SelectedBankAccount;
        acc.bankname = e.target.value;
        let branchcode = getBranchCode(e.target.value);
        acc.branchcode = branchcode;
        setSelectedBankAccount(acc);
        forceUpdate();
    }
    
    function bankTypeChanged(e){
        let acc = SelectedBankAccount;
        acc.type = e.target.value;
        setSelectedBankAccount(acc);
        forceUpdate();
    }
    function bankAccountChanged(e){
        let acc = SelectedBankAccount;
        acc.accountnumber = e.target.value;
        setSelectedBankAccount(acc);
        forceUpdate();
    }

    function branchNameChanged(e){
        let acc = SelectedBankAccount;
        acc.branchname = e.target.value;
        setSelectedBankAccount(acc);
        forceUpdate();
    }
    const updateBankAccount = async() => {
        
        let bankAcc = SelectedBankAccount;
        bankAcc.IDNumber = SelectedEmployee.IDNumber;
        commonStore.setLoading(true);
        let backUpdate = await commonStore.updateBankAccount(bankAcc);
        window.alert('Employee has been updated');
        closeEmployee();
        getEmployees();


    }

    const terminateEmployee = async() => {
        let confirmed = window.confirm('Are you sure you want to terminate this employee?');
        if (!confirmed) {
            return
        }
        commonStore.setLoading(true);
        if (Object.keys(SelectedEmployee).includes('user')){
            let userObject = SelectedEmployee.user;
            let reason = `User blocked by ${commonStore.user.username}`;
            let userBlocked = await commonStore.blockUser(userObject._id, reason);
        }
        let IDNumber = SelectedEmployee.IDNumber;
        let salaryUpdates = {
            Amount: 0,
            ElectedAdvancePerc: 0
        };
        let salaryUpdate = await commonStore.updateEmployeeSalary(IDNumber, salaryUpdates);
        window.alert('Employee has been terminated');
        closeEmployee();
        getEmployees();
    }


        if (isMobile()){
            return(
                <View>
                    
                </View>
            );
        }else{
            return(
                <div style={{width: '100%', height: '100%', overflowY: 'scroll'}}>
                    <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Employees</p>

                    {Employees.map((employee, index) => {
                        return(
                            <Card className='employeeCard' id={index} onClick={employeeSelected}>
                                <p>{employee.IDNumber}</p>
                                <p style={{textAlign: 'left'}}>{employee.employeeinfo.FirstName || ''} {employee.employeeinfo.LastName || ''}</p>
                                <FaChevronRight style={{margin: '0px'}}/>
                            </Card>
                        )
                    })}
                    {(Object.keys(SelectedEmployee).length === 0) ? null :
                    <div className='employeePopup'>
                        <FaTimes style={{position: 'absolute', top: '10px', right: '5px', height: 'unset', margin: '0px', display: 'inherit', fontSize: '20px', cursor: 'pointer'}} onClick={closeEmployee}/>
                        <p className='employeePopupHeader'>{SelectedEmployee.employeeinfo.FirstName || ''} {SelectedEmployee.employeeinfo.LastName || ''}</p>
                        <p className='employeePopupSectionHeader'>Salary</p>
                        <div className='sectionHeaderDivider'></div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{width: '45%'}}>
                                <CustomInput type='number' id='salary' value={String(SelectedEmployee.employeesalary.Amount || 0)} placeholder='Nett Salary' onChange={selectedSalaryChanged}/>
                            </div>
                            <div style={{width: '45%'}}>
                                <CustomInput type='number' id='perc' value={String(SelectedEmployee.employeesalary.ElectedAdvancePerc || 0)} placeholder='Percentage Allowed' onChange={selectedAdvancePercentChanged}/>
                            </div>
                        </div>
                        <Button color="info" block size="lg" onClick={updateSalary}>Update Salary</Button>
                        <br/>
                        <p className='employeePopupSectionHeader'>Bank Account</p>
                        <div className='sectionHeaderDivider'></div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{width: '45%'}}>
                                <label className="custom-field" style={{width: '90%'}}>
                                    <select className='inp' value={SelectedBankAccount.bankname} onChange={bankNameChanged} required>
                                        <option value='ABSA'>ABSA</option>
                                        <option value='Access Bank'>Access Bank</option>
                                        <option value='African Bank'>African Bank</option>
                                        <option value='Capitec Bank'>Capitec Bank</option>
                                        <option value='Discovery Bank'>Discovery Bank</option>
                                        <option value='FNB'>FNB</option>
                                        <option value='Investec'>Investec</option>
                                        <option value='Nedbank'>Nedbank</option>
                                        <option value='Sasfin Bank'>Sasfin Bank</option>
                                        <option value='Standard Bank'>Standard Bank</option>
                                        <option value='TymeBank'>TymeBank</option>
                                    </select>
                                    <span className="placeholder">Bank</span>
                                </label>
                            </div>
                            <div style={{width: '45%'}}>
                                <label className="custom-field" style={{width: '90%'}}>
                                    <select className='inp' value={SelectedBankAccount.type} onChange={bankTypeChanged} required>
                                        <option value='01'>Current/Cheque</option>
                                        <option value='02'>Savings</option>
                                        <option value='03'>Transmission</option>
                                        <option value='04'>Bond</option>
                                        <option value='06'>Subscription Share</option>
                                        <option value='00'>Other</option>
                                    </select>
                                    <span className="placeholder">Account Type</span>
                                </label>
                            </div>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{width: '45%'}}>
                                <CustomInput type='text' id='accountNum' value={SelectedBankAccount.accountnumber} placeholder='Account Number' onChange={bankAccountChanged}/>
                            </div>
                            <div style={{width: '45%'}}>
                                <CustomInput type='text' id='branchName' value={SelectedBankAccount.branchname} placeholder='Branch Name' onChange={branchNameChanged}/>
                            </div>
                        </div>
                        <Button color="info" block size="lg" onClick={updateBankAccount}>Update Bank Account</Button>
                        <br/>
                        <p className='employeePopupSectionHeader'>Terminate Employee</p>
                        <div className='sectionHeaderDivider'></div>
                        <p>For reporting purposes, terminating an employee will not delete them. When an employee is terminated, their salary and percentage allowed will be set to 0 and they will be blocked from accessing the platform.</p>
                        <Button color="info" block size="lg" onClick={terminateEmployee}>Terminate Employee</Button>
                    </div>
                    }
                </div>
            );
        }
        
})

export default Employees;
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Label, Button, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';

import '../style.scss';
import './style.scss';


const Maintenance = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();


    useEffect(() => {
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);


        if (isMobile()){
            return(
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>
                    </div>
                    
                    <div id="mainView" className="viewContent view-pages view-session justify-content-center d-flex flex-column">
                        <p className="txtlead">Maintenance in Progress</p>
                        <p className="txtsub">We will be back shortly.</p>
                        
                    </div>
                </div>
            );
        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://levelfinance.azureedge.net/siteassets/logo2.png"/>
                                    <p className="txtlead">Maintenance in Progress</p>
                                    <p className="txtsub">We will be back shortly.</p>
                                </header>
                            </CardBody>
                        </Card>
                    </div>
                    
                </View>
            );
        }
})

export default Maintenance
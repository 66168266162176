import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, FormGroup, Button, Card, CardBody } from '../../../components/basic';
import { View, MobileTopBarClean } from '../../../shared/components';

import '../../style.scss';
import './style.scss';


const ConfirmProfile = observer(() => {
    const navigate = useNavigate();
    const commonStore = useStore();

    const [name, setName] = useState('');
    const [employer, setEmployer] = useState('');
    const [income, setIncome] = useState(0);
    const [percent, setPercent] = useState(25);
    const [availableAmount, setAvailableAmount] = useState(0);

    useEffect(() => {
        loadEmployeeInfo();
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    
    function loadEmployeeInfo(){
        let { employeeDetail } = commonStore;
        let firstName = employeeDetail.employeeinfo.FirstName || '';
        let lastName = employeeDetail.employeeinfo.LastName || '';
        let fullName = `${firstName} ${lastName}`;
        let empl = employeeDetail.businessentity.name || '';
        let inc = Number(employeeDetail.employeesalary.Amount) || 0;
        let perc = Number(employeeDetail.employeesalary.ElectedAdvancePerc) || 25;
        let avl = (inc * perc / 100).toFixed(2);


        setName(fullName);
        setEmployer(empl);
        setIncome(inc);
        setPercent(perc);
        setAvailableAmount(avl);
    }



    function confirm(){
        commonStore.setLoading(true)
        commonStore.loadBankAccounts().then((res)=>{
            commonStore.setLoading(false)
            navigate('/confirmbankaccount');
        })
        
    }


        if (isMobile()){
            return(
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>
                    </div>
                    
                    
                    
                    <div id="mainView" className="viewContent view-pages view-session d-flex justify-content-center flex-column">
                        <p className="txtlead">Confirm your profile</p>
                        <p className="txtsub">Please confirm your personal information below.</p>
                        <Form>
                            <FormGroup>
                                <label className="custom-field">
                                    <input className='inp' type="text" id="name" value={name}  disabled required/>
                                    <span className="placeholder">Full Name</span>
                                </label>

                                <label className="custom-field">
                                    <input className='inp' type="text" id="employer" value={employer}  disabled required/>
                                    <span className="placeholder">Employer</span>
                                </label>

                                <label className="custom-field">
                                    <input className='inp' type="text" id="income" value={`R${income}`}  disabled required/>
                                    <span className="placeholder">Monthly income (Net)</span>
                                </label>

                                <label className="custom-field">
                                    <input className='inp' type="text" id="percent" value={`R${availableAmount}`}  disabled required/>
                                    <span className="placeholder">{`Earned wage access (${percent}%)`}</span>
                                </label>
                            </FormGroup>

                            <FormGroup className="text-right">

                                <Button block size="lg" onClick={confirm}>Confirm profile</Button>
                            </FormGroup>
                        </Form>
                    </div>
                    
                </div>
            );

        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg"/>
                                <p className="txtlead">Confirm your profile</p>
                                <p className="txtsub">Please confirm your personal information below.</p>
                                </header>
                                <Form>
                                    <FormGroup className="mb-5 inpControls">
                                        <label className="custom-field">
                                            <input className='inp' type="text" id="name" value={name} disabled required/>
                                            <span className="placeholder">Full Name</span>
                                        </label>

                                        <label className="custom-field">
                                            <input className='inp' type="text" id="employer" value={employer} disabled required/>
                                            <span className="placeholder">Employer</span>
                                        </label>

                                        <label className="custom-field">
                                            <input className='inp' type="text" id="income" value={`R${income}`} disabled required/>
                                            <span className="placeholder">Monthly income (Net)</span>
                                        </label>

                                        <label className="custom-field">
                                            <input className='inp' type="text" id="percent" value={`R${availableAmount}`}  disabled required/>
                                            <span className="placeholder">{`Earned wage access (${percent}%)`}</span>
                                        </label>
                                    </FormGroup>
    
                                    <FormGroup className="mb-5">

                                    </FormGroup>
    
                                    <FormGroup className="text-right">
                                        <Button block size="lg" onClick={confirm}>Confirm profile</Button>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </View>
            );
        }
        
})

export default ConfirmProfile
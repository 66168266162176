import React from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";

const LVLLogo = require('../../assets/images/LVLLogo.png');

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COLN_WIDTH = 100/5

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  HeaderText:{
    fontSize: 12
  },
  ClientHeaderText:{
    fontSize: 12,
    textDecoration: "underline",
  },
  HeaderTextRight:{
    fontSize: 12,
    textAlign: 'right'
  },
  SubHeaderText:{
    fontSize: 10
  },
  logo: {
    width: 100
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    marginTop: 15
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },     
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  totalTableCol: { 
    width: ((100 / 7) * 6) + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 2, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  totalTableColAmount: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 2, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 12,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  },
  tableCellTotal: { 
    margin: 5, 
    fontSize: 12 
  },
  BankingDetailsView:{
	border: 1,
    padding: 5,
    marginTop: 15,
    borderRadius: 10,
    width: 60 + '%',
    alignSelf: 'center'
  },
  BankingDetailSection:{
    flexDirection: 'row'
  },
});

const PDFEmployeeAdvances = ({employeeAdvances, employee}) => {
    let date = (new Date()).toLocaleDateString()
    var totalFees = 0;
    var totalAdvances = 0;
    var total = 0;
    for (let i = 0; i < employeeAdvances.length; i++) {
      let ad = employeeAdvances[i];
      totalAdvances += Number(ad.AdvanceAmount);
      totalFees += Number(ad.AdvanceFee);
      total += (Number(ad.AdvanceAmount) + Number(ad.AdvanceFee))
    }

  return (
    <Document>
      <Page style={styles.body}>
        <View>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                <View>
                    <Text style={styles.HeaderText}>Date: <Text style={styles.SubHeaderText}>{date}</Text></Text>
                    <Text> </Text>
                    <Text style={styles.HeaderText}>{`${employee.employeeinfo.FirstName || ''} ${employee.employeeinfo.LastName || ''}`}</Text>
                    <Text style={styles.HeaderText}>{employee.businessentity.name || ''}</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'column', justifyContent: 'right', alignItems: 'flex-end'}}>
                    <Image style={styles.logo} src={LVLLogo} />
                    <Text style={styles.HeaderTextRight}>Level Finance (PTY) LTD</Text>
                    <Text style={styles.HeaderTextRight}>Statement Of Advances</Text>
                </View>
                
            </div>
        </View>
        <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Date</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Status</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Advance Amount</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Advance Fee</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Advance Total</Text> 
          </View>  
        </View>

        {employeeAdvances.map((advance) => {
            let dte = (new Date(advance.AdvanceRequestDate)).toLocaleDateString();
            let AdvanceTotal = (Number(advance.AdvanceAmount) + Number(advance.AdvanceFee)).toFixed(2);
            return (
                <View style={styles.tableRow}> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{dte}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.transactionhistory.TransactionStatus}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.AdvanceAmount}</Text> 
                    </View> 
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{advance.AdvanceFee}</Text> 
                    </View>
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{AdvanceTotal}</Text> 
                    </View> 
                </View>   
            )
        })}
            <View style={styles.tableRow}> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCell}>Total</Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCell}></Text> 
              </View>  
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCell}>{totalAdvances}</Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCell}>{totalFees}</Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCell}>{total}</Text> 
              </View> 
            </View>  
        </View>

        <View>
            <Text style={styles.ClientHeaderText}></Text>
            <Text style={styles.ClientHeaderText}>* This statement does not include VAT.</Text>
            <Text style={styles.ClientHeaderText}>* The statement only includes advances processed through the Level Finance app.</Text>
            <Text style={styles.ClientHeaderText}>* This statement does not include membership fees</Text>
        </View>

      </Page>
    </Document>
  );
};

export default PDFEmployeeAdvances;
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card, CardBody } from '../../../components/basic';
import { View, MobileTopBarClean } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

const WhatsappCode = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [code, setCode] = useState('');
    const [alertClassName, setAlertClassName] = useState('alertHidden');

    useEffect(() => {
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
        getToken();
    }, []);


    function getToken(){
        
        
        commonStore.setLoading(true);
        commonStore.getWhatsappToken().then((ret) => {
            setCode(ret);
            commonStore.setLoading(false)
        });
    }

    function copyToken(){
        
        navigator.clipboard.writeText(code);
        setAlertClassName('alertShown');
    }

    function hideAlert(){
        
        setAlertClassName('alertHidden');
    }

    function dashboardClicked(){
        navigate('/home');
    }

        
        if (isMobile()){
            return (
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>
                    </div>
                    <div id="mainView" className="viewContent view-pages view-session d-flex flex-column">
                        <div className="svgDiv" style={{margin: '1px', height: '35%'}}>
                            <img style={{maxWidth: '50%',}} src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/whatsapp.svg" alt="icon"/>
                        </div>
                        <p className="txtlead">Whatsapp Code</p>
                        <p className="txtsub">Your WhatsApp code is:</p>
                        
                        {(code === '')? null :
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline'}}>
                            <p onClick={copyToken} className="txtlead" style={{textAlign: 'center', marginRight: '10px'}}>{code}</p>
                            <i onClick={copyToken} className="fa fa-clone" aria-hidden="true"></i>
                        </div>}
                        
                        <Button color="info" block size="lg" onClick={dashboardClicked}>Go to dashboard</Button>
                        <div className={alertClassName} style={{alignSelf: 'center', marginTop: '5px'}} onTransitionEnd={hideAlert}>
                            <Card style={{width: '100px', height: '30px', alignItems: 'center', borderRadius: '10px', justifyContent: 'center'}}>
                                <p style={{margin: '0px'}}>Token copied</p>
                            </Card>
                        </div>

                    </div>
                </div>
            );
        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img  src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/whatsapp.svg" alt="icon"/>
                                    <p className="txtlead">Whatsapp Code</p>
                                    <p className="txtsub">Your WhatsApp code is:</p>
                                    <br/>
                                </header>
                                {(code === '')? null :
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline'}}>
                                    <p onClick={copyToken} className="txtlead" style={{textAlign: 'center', marginRight: '10px'}}>{code}</p>
                                    <i onClick={copyToken} className="fa fa-clone" aria-hidden="true"></i>
                                </div>}
                                <Button color="info" block size="lg" onClick={dashboardClicked}>Go to dashboard</Button>
                                <div className={alertClassName} style={{alignSelf: 'center', marginTop: '5px'}} onTransitionEnd={hideAlert}>
                                    <Card style={{width: '100px', height: '30px', alignItems: 'center', borderRadius: '10px', justifyContent: 'center'}}>
                                        <p style={{margin: '0px'}}>Token copied</p>
                                    </Card>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </View>
            );
        }
})

export default WhatsappCode
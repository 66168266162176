import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import PropTypes from 'prop-types';

import './style.scss';

class HorizontalScroll extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: 0
        };
        
        this.Menu = this.Menu.bind(this);
        this.MenuItem = this.MenuItem.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    MenuItem = ({ text, selected }) => {
        return (
          <div className="menu-item">
            {text}
          </div>
        );
    };

    Menu = (list) => list.map((el, idx) => {
        const { name } = el;
        
        return (
            <this.MenuItem
                text={name}
                key={idx}
            />
        );
    });

    onSelect = key => {
        this.setState({ selected: key });
    }

    render() {
        const { selected } = this.state;
        const { menuList } = this.props;
        // Create menu from items
        const menu = this.Menu(menuList, selected);

        return (
            <ScrollMenu
                data={menu}
                // arrowLeft={ArrowLeft}
                // arrowRight={ArrowRight}
                selected={selected}
                onSelect={this.onSelect}
            />
        );
    }
    
}

HorizontalScroll.propTypes = {
    menuList: PropTypes.array.isRequired
}

export default HorizontalScroll;
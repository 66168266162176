import React from 'react';
import PropTypes from "prop-types"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import { compileString } from 'sass';


function ClientAdvancesPerDay({className, includeFees = false, includeAdvance = false, id, offset=0, advances = [], style={}}) {
    let customStyle = style;
    let currentDate = new Date();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();
    month = month - offset;
    if (month < 0){
      month = 12 + month;
      year = year - 1;
    }
    
    let currentYear = [0,0,0,0,0,0];
    let prevYear = [0,0,0,0,0,0];
    let lbls = [];

    for (let i = 0; i < 6; i++){
        let mth = month - i;
        let yr = year
        if (mth < 0){
            mth = 12 + mth;
            yr = yr - 1;
        }
        var mthName;
        switch (mth){
            case 0: mthName = 'January'; break;
            case 1: mthName = 'February'; break;
            case 2: mthName = 'March'; break;
            case 3: mthName = 'April'; break;
            case 4: mthName = 'May'; break;
            case 5: mthName = 'June'; break;
            case 6: mthName = 'July'; break;
            case 7: mthName = 'August'; break;
            case 8: mthName = 'September'; break;
            case 9: mthName = 'October'; break;
            case 10: mthName = 'November'; break;
            case 11: mthName = 'December'; break;
        }
        lbls.unshift(mthName)
        for (let j = 0; j < advances.length; j++){
            let curAdv = advances[j];
            if ((new Date(curAdv.AdvanceRequestDate)).getMonth() === mth && (new Date(curAdv.AdvanceRequestDate)).getFullYear() === yr){
                let amt = curAdv.AdvanceAmount || 0;
                let feeAmt = curAdv.AdvanceFee || 0;
                let totalAmt = 0
                if (includeAdvance){
                    totalAmt += amt;
                }
                if (includeFees){
                    totalAmt += feeAmt;
                }
                currentYear[5 - i] = currentYear[5 - 1] + totalAmt;
                // currentYear.unshift(totalAmt);
            }else if ((new Date(curAdv.AdvanceRequestDate)).getMonth() === mth && (new Date(curAdv.AdvanceRequestDate)).getFullYear() === (yr - 1)){
                let amt = curAdv.AdvanceAmount || 0;
                let feeAmt = curAdv.AdvanceFee || 0;
                let totalAmt = 0
                if (includeAdvance){
                    totalAmt += amt;
                }
                if (includeFees){
                    totalAmt += feeAmt;
                }
                prevYear[5 - i] = prevYear[5 - 1] + totalAmt;
                // prevYear.unshift(totalAmt);
            }
            
        }
    }



    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Total Advances',
          },
        },
      };

    //   const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const labels = lbls;
      const data = {
        labels,
        datasets: [
          {
            label: 'Current Year',
            // data: [9, 8, 7, 6, 5, 4, 3],
            data: currentYear,
            // backgroundColor: 'rgba(255, 99, 132, 0.5)',
            backgroundColor: '#772FD7',
          },
          {
            label: 'Previous Year',
            // data: [1, 2, 3, 4, 5, 6, 7],
            data: prevYear,
            backgroundColor: '#FF64AB',
          },
        ],
      };
    return <Bar options={options} data={data} />;
    // return (
    //   <div className={`ClientAdvanceGraph ${className}`} style={customStyle} id={id}>

    //   </div>
    // )
    
    
}


export default ClientAdvancesPerDay;
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card, CardBody } from '../../../components/basic';
import { View, MobileTopBarClean } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

const Agreement = observer(() => {
    const navigate = useNavigate();
    const commonStore = useStore();

    const [agreed, setAgreed] = useState(false);
    const [error, setError] = useState('');
    const [privacyPolicyAgreed, setPrivacyPolicyAgreed] = useState(false);
    const [TsCsAgreed, setTsCsAgreed] = useState(false);

    useEffect(() => {
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function downloadPrivacyPolicy(){
        commonStore.downloadPrivacyPolicy();
    }

    function downloadTsCs(){
        commonStore.downloadTsCs();
    }

    function downloadAgreement(){
        commonStore.downloadAgreement();
    }

    function checkboxTicked(e){
        setAgreed(!agreed);
    }

    function policyCheckboxTicked(){
        setPrivacyPolicyAgreed(!privacyPolicyAgreed);
    }

    function TsCsTicked(){
        setTsCsAgreed(!TsCsAgreed);
    }

    function nextClicked(){

        if (!agreed || !privacyPolicyAgreed){
            setError('You need to agree to the agreement, privacy policy and terms and conditions before continuing.');
            return
        }
        commonStore.setLoading(true);
        commonStore.createAgreement()
        commonStore.setLoading(false);
        navigate('/securityoptions');
    }



        if (isMobile()){
            return(
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>
                    </div>
                    
                    <div id="mainView" className="viewContent view-pages view-session d-flex flex-column">
                        <div className="" style={{marginBottom: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40%'}}>
                            <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/clipboard.png" alt="icon" style={{height: '80%'}}/>
                        </div>
                        <p className="txtlead">Agreement</p>
                        <p className="txtsub">To register your account, we need to agree to our <span onClick={downloadAgreement} style={{color: '#4c1ba5', cursor: 'pointer'}}>agreement</span>, <span onClick={downloadPrivacyPolicy} style={{color: '#4c1ba5', cursor: 'pointer'}}>privacy policy</span> and <span onClick={downloadTsCs} style={{color: '#4c1ba5', cursor: 'pointer'}}>terms and conditions</span>.</p>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>    
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <label style={{textAlign: 'left'}}>
                                    <input id="dataAccessCheckbox" type="checkbox" onChange={checkboxTicked} checked={agreed} style={{verticalAlign: 'bottom', position: 'relative', top: '-1px', marginRight: '10px'}}/>
                                    I have read and agree to the agreement.
                                </label>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <label style={{textAlign: 'left'}}>
                                    <input id="privacyPolicyCheckbox" type="checkbox" onChange={policyCheckboxTicked} checked={privacyPolicyAgreed} style={{verticalAlign: 'bottom', position: 'relative', top: '-1px', marginRight: '10px'}}/>
                                    I have read and agree to the privacy policy.
                                </label>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <label style={{textAlign: 'left'}}>
                                    <input id="privacyPolicyCheckbox" type="checkbox" onChange={TsCsTicked} checked={TsCsAgreed} style={{verticalAlign: 'bottom', position: 'relative', top: '-1px', marginRight: '10px'}}/>
                                    I have read and agree to the terms and conditions.
                                </label>
                            </div>
                        </div>    
                        {(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}
                        {(agreed && privacyPolicyAgreed && TsCsAgreed)?<Button block size="lg" onClick={nextClicked}>Next &gt;</Button>: null}

                        
                    </div>
                </div>
            );
        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg"/>
                                    <p className="txtlead">Agreement</p>
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/clipboard.png" alt="icon" style={{height: '300px'}}/>
                                    <p className="txtsub">To register your account, we need to agree to our <span onClick={downloadAgreement} style={{color: '#4c1ba5', cursor: 'pointer'}}>agreement</span>, <span onClick={downloadPrivacyPolicy} style={{color: '#4c1ba5', cursor: 'pointer'}}>privacy policy</span> and <span onClick={downloadTsCs} style={{color: '#4c1ba5', cursor: 'pointer'}}>terms and conditions</span>.</p>
                                </header>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>    
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <label style={{textAlign: 'left'}}>
                                            <input id="dataAccessCheckbox" type="checkbox" onChange={checkboxTicked} checked={agreed} style={{verticalAlign: 'bottom', position: 'relative', top: '-1px', marginRight: '10px'}}/>
                                            I have read and agree to the agreement.
                                        </label>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <label style={{textAlign: 'left'}}>
                                            <input id="privacyPolicyCheckbox" type="checkbox" onChange={policyCheckboxTicked} checked={privacyPolicyAgreed} style={{verticalAlign: 'bottom', position: 'relative', top: '-1px', marginRight: '10px'}}/>
                                            I have read and agree to the privacy policy.
                                        </label>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <label style={{textAlign: 'left'}}>
                                            <input id="privacyPolicyCheckbox" type="checkbox" onChange={TsCsTicked} checked={TsCsAgreed} style={{verticalAlign: 'bottom', position: 'relative', top: '-1px', marginRight: '10px'}}/>
                                            I have read and agree to the terms and conditions.
                                        </label>
                                    </div>
                                </div>    
                                {(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}
                                {(agreed && privacyPolicyAgreed && TsCsAgreed)?<Button block size="lg" onClick={nextClicked}>Next &gt;</Button>: null}

                            </CardBody>
                        </Card>
                    </div>
                    
                </View>
            );
        }
})

export default Agreement
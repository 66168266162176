import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';
import EmployeeBaseLineAssessment from '../../components/BaseLine/employeeBaselineAssessment';
import baselineIcon from '../../assets/icons/baseline-assesment svg.svg'


import '../style.scss';
import './style.scss';

const BaselineAssessment = observer(() => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [questionNumber, setQuestionNumber] = useState(1);
    const [answersArray, setAnswersArray] = useState([])
    const [assessmentFinished, setAssessmentFinished] = useState(false)
    const [spendScore, setSpendScore] = useState(null)
    const [saveScore, setSaveScore] = useState(null)
    const [borrowScore, setBorrowScore] = useState(null)
    const [planScore, setPlanScore] = useState(null)
    const [overallScore, setOverallScore] = useState(null)
    const [beginAssessment, setBeginAssessment] = useState(false)
    const [baselineData, setBaselineData] = useState(null)

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [assessmentType, setAssessmentType] = useState("")

    const [searchParams] = useSearchParams();

    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };

    const handleNextQuestion = (currentQuestion) => {

        if (selectedOption === null || selectedOption === '') {
            setSelectedOption(null)
            alert("Please select an option")
            return
        }

        setAnswersArray((prevAnswers) => [...prevAnswers, selectedOption]);

        if (currentQuestion > 0 && currentQuestion < 15) {
            setQuestionNumber((prevQuestion) => prevQuestion + 1);
            setSelectedOption(null)
        }

        if (currentQuestion === 15) {
            setSelectedOption(null)
            setAssessmentFinished(true)
            return
        }
        setSelectedOption(null)
        forceUpdate()
    }

    const navigate = useNavigate();
    const commonStore = useStore();

    useEffect(() => {
        if (isMobile()) {
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
        let preOrPost = searchParams.toString();
        preOrPost = preOrPost.replace('=', '');
        setAssessmentType(preOrPost)
        setSelectedOption('')
    }, []);

    useEffect(() => {
        if (assessmentFinished) {
            const saveData = answersArray.filter(item => item.category === 'Save');
            const saveAverage = calculateAverage(saveData);
            setSaveScore(saveAverage);

            const spendData = answersArray.filter(item => item.category === 'Spend');
            const spendAverage = calculateAverage(spendData);
            setSpendScore(spendAverage);

            const borrowData = answersArray.filter(item => item.category === 'Borrow');
            const borrowAverage = calculateAverage(borrowData);
            setBorrowScore(borrowAverage);

            const planData = answersArray.filter(item => item.category === 'Plan');
            const planAverage = calculateAverage(planData);
            setPlanScore(planAverage);

        }

    }, [assessmentFinished]);

    useEffect(() => {
        if (baselineData === null) {
            return
        }

        if (baselineData.newBaselineAssessmentData.BorrowScore !== null &&
            baselineData.newBaselineAssessmentData.SpendData !== null &&
            baselineData.newBaselineAssessmentData.SaveData !== null &&
            baselineData.newBaselineAssessmentData.PlanScore !== null
        ) {
            commonStore.setLoading(true);
            commonStore.submitEmployeeBaselineAssessment(baselineData).then((ret) => {
                if (ret) {
                    console.log('Success')
                }
                commonStore.setLoading(false);
            });
        }

    }, [baselineData]);

    useEffect(() => {
        if (assessmentFinished) {
            const overall = (spendScore + saveScore + borrowScore + planScore) / 4;
            setOverallScore(overall);

            const baselineData = {
                IDNumber: commonStore.employeeDetail.IDNumber,
                BusinessEntity: commonStore.employeeDetail.businessentity._id,
                newBaselineAssessmentData: {
                    DateTaken: new Date(),
                    QuestionAnswerPairs: answersArray,
                    SpendScore: spendScore,
                    SaveScore: saveScore,
                    BorrowScore: borrowScore,
                    PlanScore: planScore,
                    OverallScore: overall
                }
            };

            setBaselineData(baselineData)

        }
    }, [answersArray, spendScore, saveScore, borrowScore, planScore, assessmentFinished]);

    const calculateAverage = (categoryData) => {
        const totalScore = categoryData.reduce((acc, item) => acc + (item.score || 0), 0);
        const average = totalScore / categoryData.length;
        return isNaN(average) ? null : average;
    };

    const getScoreColor = (score) => {
        if (score >= 80) {
            return 'green';
        } else if (score >= 40) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    function goBack() {
        navigate({ pathname: '/home', search: 'More' });
    }

    if (isMobile()) {
        return (
            <div id="mobileDashView" className="view">
                <MobileTopBarClean/>
                {
                    !beginAssessment &&
                    <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', flexDirection: 'column', padding: '10px' }}>
                        <img src={baselineIcon} style={{ height: '200px', width: '200px' }} alt='icon' />
                        <p className="txtlead ">{`Financial ${assessmentType.toUpperCase()} Baseline Assessment`}</p>
                        <p className="txtsub "><i style={{ paddingRight: '5px', fontWeight: 'bold' }} className="fa fa-wallet"></i>
                            Take a quick three question assesment to help us better understand your financial circumstances and personalise our offerings better for you.
                        </p>
                        <Button className='border-radius-button' block size="lg" onClick={() => (setBeginAssessment(true))} style={{ marginTop: '10px' }}>Begin Assessment</Button>
                    </div>
                }
                {beginAssessment &&
                    <div style={{ alignItems: 'center', width: '100%', height: '100%', padding: '10px', overflow: 'scroll', display: 'flex', flexDirection: 'column' }}>
                    {!assessmentFinished && beginAssessment && (
                        <>
                            <p className="txtlead">Financial Baseline Assessment</p>
                            <EmployeeBaseLineAssessment questionNumber={questionNumber} onOptionChange={handleOptionChange} />
                            <Button className='border-radius-button' onClick={() => handleNextQuestion(questionNumber)} block size="lg" style={{ marginTop: '10px' }}>
                                {questionNumber === 15 ? 'Submit' : 'Next'}
                            </Button>
                        </>
                    )}

                    {
                        assessmentFinished && (
                            <>
                                <div>
                                    <p className="txtlead txtLeft">Financial Baseline Assessment Results</p>
                                    <p className="txtsub txtLeft"><i style={{ paddingRight: '5px', fontWeight: 'bold' }} className="fa fa-wallet"></i>
                                        Congratulations! You've completed the level finance baseline assessment. You can now use the application. Please see below your results.
                                    </p>
                                    <p style={{ fontSize: 'larger' }}>Save Score: <span style={{ fontWeight: '800', color: getScoreColor(saveScore) }}>{saveScore}</span></p>
                                    <p style={{ fontSize: 'larger' }}>Spend Score: <span style={{ fontWeight: '800', color: getScoreColor(spendScore) }}>{spendScore}</span></p>
                                    <p style={{ fontSize: 'larger' }}>Borrow Score: <span style={{ fontWeight: '800', color: getScoreColor(borrowScore) }}>{borrowScore}</span></p>
                                    <p style={{ fontSize: 'larger' }}>Plan Score: <span style={{ fontWeight: '800', color: getScoreColor(planScore) }}>{planScore}</span></p>

                                    <Button className='border-radius-button' block size="lg" onClick={() => (navigate('/home'))} style={{ marginTop: '10px' }}>Go to dashboard</Button>

                                </div>
                            </>
                        )
                    }
                </div>}
            </div>
        );
    } else {
        return (
            <View style={{ padding: '50px', height: '100vh' }}>
                <header style={{ backgroundColor: '#ffffff' }}>

                </header>
                {
                    !beginAssessment &&
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '10px' }}>
                        <img src={baselineIcon} style={{ height: '200px', width: '200px' }} alt='icon' />
                        <p className="txtlead txtLeft">{`Financial ${assessmentType.toUpperCase()} Baseline Assessment`}</p>
                        <p className="txtsub txtLeft"><i style={{ paddingRight: '5px', fontWeight: 'bold' }} className="fa fa-wallet"></i>
                            Take a quick three question assesment to help us better understand your financial circumstances and personalise our offerings better for you.
                        </p>
                        <Button className='border-radius-button' block size="lg" onClick={() => (setBeginAssessment(true))} style={{ marginTop: '10px' }}>Begin Assessment</Button>
                    </div>
                }
                <div style={{ alignItems: 'center', width: '100%', height: '100%', padding: '10px', overflow: 'scroll', display: 'flex', flexDirection: 'column' }}>
                    {!assessmentFinished && beginAssessment && (
                        <>
                            <p className="txtlead txtLeft">Financial Baseline Assessment</p>


                            <EmployeeBaseLineAssessment questionNumber={questionNumber} onOptionChange={handleOptionChange} />
                            <Button className='border-radius-button' onClick={() => handleNextQuestion(questionNumber)} block size="lg" style={{ marginTop: '10px' }}>
                                {questionNumber === 15 ? 'Submit' : 'Next'}
                            </Button>
                        </>
                    )}

                    {
                        assessmentFinished && (
                            <>
                                <div>
                                    <p className="txtlead txtLeft">Financial Baseline Assessment Results</p>
                                    <p className="txtsub txtLeft"><i style={{ paddingRight: '5px', fontWeight: 'bold' }} className="fa fa-wallet"></i>
                                        Congratulations! You've completed the level finance baseline assessment. You can now use the application. Please see below your results.
                                    </p>
                                    <p style={{ fontSize: 'larger' }}>Save Score: <span style={{ fontWeight: '800', color: getScoreColor(saveScore) }}>{saveScore}</span></p>
                                    <p style={{ fontSize: 'larger' }}>Spend Score: <span style={{ fontWeight: '800', color: getScoreColor(spendScore) }}>{spendScore}</span></p>
                                    <p style={{ fontSize: 'larger' }}>Borrow Score: <span style={{ fontWeight: '800', color: getScoreColor(borrowScore) }}>{borrowScore}</span></p>
                                    <p style={{ fontSize: 'larger' }}>Plan Score: <span style={{ fontWeight: '800', color: getScoreColor(planScore) }}>{planScore}</span></p>


                                    <Button className='border-radius-button' block size="lg" onClick={() => (navigate('/home'))} style={{ marginTop: '10px' }}>Go to dashboard</Button>

                                </div>
                            </>
                        )
                    }
                </div>


            </View>
        );
    }
})

export default BaselineAssessment
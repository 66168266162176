import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card, CardBody } from '../../../components/basic';
import { View } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

const EmployeeSupportRequests = observer(() =>  {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [submissions, setSubmissions] = useState([]);

    useEffect(() => {
        loadData();
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function loadData(){
        commonStore.setLoading(true);
        commonStore.getContactSubmissionById().then((ret) => {
            setSubmissions(ret);
            commonStore.setLoading(false);
        });
    }

    function newRequest(){
        
        navigate({pathname: '/contactus', search: 'support'});
    }

    function goBack(){
        
        navigate({pathname: '/home', search: 'More'});
    }
        
        if (isMobile()){
            return(
                <div id="mobileDashView" className="view">
                    <Card>
                        <CardBody>
                            <header className="site-head d-flex align-items-center justify-content-between" style={{backgroundColor: '#ffffff'}}>
                                <div className="wrap mr-4">
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/back.png" alt='mnu' onClick={goBack} style={{cursor: 'pointer', width: '18px', height: '18px'}}/>
                                </div>
                                <div className="right-elems ml-auto d-flex wrap mr-4">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" style={{cursor: 'pointer', width: '125px'}}/>  
                                </div>
                            </header>
                        </CardBody>
                    </Card>
                    <div className="viewContent view-pages view-session d-flex flex-column" style={{overflow: 'scroll'}}>
                        <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Support</p>
                        <Button  block size="lg" onClick={newRequest} style={{marginBottom: '10px'}}>New</Button>
                        <p className="txtsub txtLeft">Previous Support Requests</p>
                        <div>
                            {(submissions.length > 0 && submissions !== '[]')? <div>
                                {submissions.map((submission, i) => {
                                    let msg = submission.Message.replace(/(?:\r\n|\r|\n)/g, '<br/>');
                                    let date = new Date(submission.Date);
                                    let dateString = `${date.toLocaleDateString('en-za', {weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', time: 'long'})} ${date.toLocaleTimeString('en-za', {hour: '2-digit', minute: '2-digit'})}`

                                    return(
                                        <Card id={i} key={i} style={{borderRadius: '15px', padding: '10px', marginBottom: '10px', maxHeight: '150px', overflow: 'scroll'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <p className="txtlead">{submission.Subject}</p>
                                                <p>{dateString}</p>
                                            </div>
                                            <p dangerouslySetInnerHTML={{__html: msg}}></p>
                                        </Card>
                                    )
                                })}
                            </div>: <p>You have not sent us any support requests yet.</p>}
                        </div>

                    </div>

                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Support</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <p className="txtsub txtLeft">Previous Support Requests</p>
                            <Button className="transparentButton" block size="sm" onClick={newRequest} style={{maxWidth: '20%'}}>New</Button>
                        </div>
                        <hr/>
                        <div>
                            {(submissions.length > 0 && submissions !== '[]')? <div>
                                {submissions.map((submission, i) => {
                                    let msg = submission.Message.replace(/(?:\r\n|\r|\n)/g, '<br/>');
                                    let date = new Date(submission.Date);
                                    let dateString = `${date.toLocaleDateString('en-za', {weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', time: 'long'})} ${date.toLocaleTimeString('en-za', {hour: '2-digit', minute: '2-digit'})}`

                                    return(
                                        <Card id={i} key={i} style={{borderRadius: '15px', padding: '10px', marginBottom: '10px', maxHeight: '150px', overflow: 'scroll'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <p className="txtlead">{submission.Subject}</p>
                                                <p>{dateString}</p>
                                            </div>
                                            <p dangerouslySetInnerHTML={{__html: msg}}></p>
                                        </Card>
                                    )
                                })}
                            </div>: <p>You have not sent us any support requests yet.</p>}
                        </div>
                    </div>

                </View>
            );
        }
        
})

export default EmployeeSupportRequests;
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';

import '../../style.scss';
import './style.scss';


const AdminLogs = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [errors, setErrors] = useState([]);
    const [max, setMax] = useState(0);
    const [Search, setSearch] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('All');


    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        
        

        commonStore.getErrors().then((ret) => {
            console.log(ret);
            setErrors(ret);
            if (ret.length > 5){
                setMax(5);
            }else{
                setMax(ret.length);
            }
         });
    }

    function addComment(e){
        
        
        const comment = window.prompt('Please enter your comment.');
        commonStore.setLoading(true);
        let id = errors[Number(e.target.id)]._id;
        let comments = JSON.parse(errors[Number(e.target.id)].Comments)
        let date = new Date();
        let dateString = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}-${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        let updates = {
            Date: dateString, 
            User: commonStore.user.username,
            Comment: comment
        }

        commonStore.updateError(id, comments).then((ret) => {
            loadData();
            commonStore.setLoading(false);
        });
    }

    function loadMore(){
        
        if ((max + 5) > errors.length){
            setMax(errors.length);
        }else{
            setMax(5);
        }
    }

    function searchChanged(e){
        
        setSearchTerm(e.target.value);
    }

    function search(){
        setSearch(searchTerm);
    }

    function filterChanged(e){
        
        setFilter(e.target.value);
    }

    function clearFilters(){
        
        setSearch('');
        setSearchTerm('');
        setFilter('All');
    }

        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%', padding: '10px', overflow: 'scroll'}}>
                    {errors.map((log, i) => {
                        if (Search !== '' && !log.IDNumber.includes(Search) && !log.AdditionalInformation.includes(Search) && !log.Description.includes(Search) && !log.Comments.includes(Search)){
                            return;
                        }
                        if (filter !== 'All' && log.ErrorType !== filter){
                            return;
                        }
                        let comment = JSON.parse(log.Comments);
                        let date = new Date(log.Date);
                        let dateString = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                        return(
                            <Card key={i} style={{marginBottom: '5px'}}>
                                <div style={{padding: '10px'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <p className="txtlead">{log.ErrorType}</p>
                                        <p className="txtsub">{dateString}</p>
                                    </div>
        
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <p className="txtsub">{log.IDNumber}</p>
                                        <p className="txtsub">{log.AdditionalInformation}</p>
                                    </div>
        
                                    <div>
                                        <p className="txtsub txtleft">{log.Description}</p>
                                    </div>
                                    
                                    <div>
                                        {comment.map((cm, j) => {
                                            return(<p key={j} style={{fontStyle: 'italic', color: 'grey'}}>{`${cm.Date} - [${cm.User}] ${cm.Comment}`}</p>);
                                        })}
                                        <Button id={i} block size="lg" onClick={addComment}>Add comment</Button>
                                    </div>
                                </div>
                            </Card>
                        );
                    })}
                    <Button className="transparentButton" block size="sm" onClick={loadMore}>Load more</Button>
                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Logs</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            {(search === '' && filter === 'All')?<p className="txtsub txtLeft">All Logs</p>: <p>Filters: {filter} <br/> Search: {search}</p>}
                            <Button className="transparentButton" block size="sm" onClick={clearFilters} style={{width: '10%'}}>Clear filters</Button>
                        </div>
                        <hr/>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <select value={filter} onChange={filterChanged} style={{height: '30px'}}>
                                <option value="All">All</option>
                                <option value="User Blocked">User Blocked</option>
                                <option value="User Unblocked">User Unblocked</option>
                                <option value="Advance Submitted">Advance Submitted</option>
                                <option value="Advance Failed">Advance Failed</option>
                            </select>

                            <label className="custom-field" style={{width: '50%'}}>
                                <input className='inp' type="text" id="search" value={searchTerm} onChange={searchChanged} onBlur={search} required/>
                                <span className="placeholder">Search</span>
                            </label> 
                        </div>
                        {errors.map((log, i) => {
                            if (Search !== '' && !log.IDNumber.includes(Search) && !log.AdditionalInformation.includes(Search) && !log.Description.includes(Search) && !log.Comments.includes(Search)){
                                return;
                            }
                            if (filter !== 'All' && log.ErrorType !== filter){
                                return;
                            }
                            let comment = JSON.parse(log.Comments);
                            let date = new Date(log.Date);
                            let dateString = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                            return(
                                <Card key={i} style={{marginBottom: '5px'}}>
                                    <div style={{padding: '10px'}}>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                            <p className="txtlead">{log.ErrorType}</p>
                                            <p className="txtsub">{dateString}</p>
                                        </div>
            
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                            <p className="txtsub">{log.IDNumber}</p>
                                            <p className="txtsub">{log.AdditionalInformation}</p>
                                        </div>
            
                                        <div>
                                            <p className="txtsub txtleft">{log.Description}</p>
                                        </div>
                                        
                                        <div>
                                            {comment.map((cm, j) => {
                                                return(<p key={j} style={{fontStyle: 'italic', color: 'grey'}}>{`${cm.Date} - [${cm.User}] ${cm.Comment}`}</p>);
                                            })}
                                            <Button id={i} block size="lg" onClick={addComment}>Add comment</Button>
                                        </div>
                                    </div>
                                </Card>
                            );
                        })}
                        <Button className="transparentButton" block size="sm" onClick={loadMore}>Load more</Button>
                    </div>

                </View>
            );
        
    }
})

export default AdminLogs;
import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';



const Test = observer(() => {


    if (isMobile()) {
        return(
            <div>
                <p>test</p>
            </div>
            
        );
    }else{
        return(
            <div>
                <p>Test</p>
            </div>
            
        );
    }
    
})

export default Test
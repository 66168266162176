import React, { useRef, useState } from 'react';
import PropTypes from "prop-types"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';


function ReportingUserSignUpsGraph({className, id, offset=0, employees = [], period='monthly', style={}, dataItems=6, company='all', segment='all'}) {
  const chartRef = useRef();
  const [SelectedData, setSelectedData] = useState(-1);

  let customStyle = style;

  var currentEmployees = [];
  switch (Number(segment)){
      case 1: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount <= 8000); break;
      case 2: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 8001 && emp.employeesalary.Amount <= 15000); break;
      case 3: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 15001 && emp.employeesalary.Amount <= 30000); break;
      case 4: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 30001 && emp.employeesalary.Amount <= 50000); break;
      case 5: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 50001); break;
  }

  let validEmp = [];

  for (let i = 0; i < currentEmployees.length; i++){
      validEmp.push(currentEmployees[i].IDNumber)
  }

    if (period === 'monthly'){
      let currentDate = new Date();
      let month = currentDate.getMonth();
      let year = currentDate.getFullYear();
      month = month - offset;
      if (month < 0){
        month = 12 + month;
        year = year - 1;
      }
      let signUps = [];
      for (let i = 0; i < dataItems; i++){
        signUps.push(0);
      }
      let lbls = [];



      for (let i = 0; i < dataItems; i++){
          let mth = month - i;
          let yr = year
          if (mth < 0){
              mth = 12 + mth;
              yr = yr - 1;
          }
          var mthName;
          switch (mth){
              case 0: mthName = 'January'; break;
              case 1: mthName = 'February'; break;
              case 2: mthName = 'March'; break;
              case 3: mthName = 'April'; break;
              case 4: mthName = 'May'; break;
              case 5: mthName = 'June'; break;
              case 6: mthName = 'July'; break;
              case 7: mthName = 'August'; break;
              case 8: mthName = 'September'; break;
              case 9: mthName = 'October'; break;
              case 10: mthName = 'November'; break;
              case 11: mthName = 'December'; break;
          }
          lbls.unshift(mthName);
          for (let j = 0; j < employees.length; j++){
              let employee = employees[j];
              if (company !== 'all' && employee.businessentity._id !== company){
                continue
              }
              if (segment !== 'all' && !validEmp.includes(employee.IDNumber)){
                continue;
              }
              if ((new Date(employee.user.confirmedDate)).getMonth() === mth && (new Date(employee.user.confirmedDate)).getFullYear() === yr){
                  signUps[dataItems - 1 - i] = signUps[dataItems - 1 - i] + 1;
                }
              
          }
    }


      ChartJS.register(
          CategoryScale,
          LinearScale,
          BarElement,
          Title,
          Tooltip,
          Legend
      );

      const options = {
          responsive: true,
          onClick: (e, elements) => {
            if (elements.length > 0){
              setSelectedData(elements[0]?.index);
            }
            
          },
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Monthly Sign Ups',
            },
          },
        };

      const labels = lbls;
        const data = {
          labels,
          datasets: [
            {
              label: 'Sign Ups',
              data: signUps,
              backgroundColor: '#772FD7',
            }
          ],
        };
      return(
        <div style={customStyle}>
          <Bar options={options} data={data} ref={chartRef} />
        </div>
      ) 
    }

    if (period === 'weekly'){
      let currentDate = new Date();
      if (currentDate.getDay() > 1){
        currentDate = new Date(currentDate.getTime() -  ((currentDate.getDay() - 1) * 24 * 60 * 60 * 1000));
      }
      if (currentDate.getDay() === 0){
        currentDate = new Date(currentDate.getTime() -  (6 * 24 * 60 * 60 * 1000));
      }

      let signUps = [];
      for (let i = 0; i < dataItems; i++){
        signUps.push(0);
      }
      let lbls = [];

      for (let i = 0; i < dataItems; i++){
        let startDate = new Date(currentDate.getTime() - ((7 * i) * 24 * 60 * 60 * 1000));
        let endDate = new Date(startDate.getTime() + ((6) * 24 * 60 * 60 * 1000));

        lbls.unshift(`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`);

        for (let j = 0; j < employees.length; j++){
          let employee = employees[j];
          if (company !== 'all' && employee.businessentity._id !== company){
            continue
          }
          if (segment !== 'all' && !validEmp.includes(employee.IDNumber)){
            continue;
          }
          let signUpDate = new Date(employee.user.confirmedDate);
          if (signUpDate >= startDate && signUpDate <= endDate){
            signUps[dataItems - 1 - i] = signUps[dataItems - 1 - i] + 1;
              
          }
        }
      }

      ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        onClick: (e, elements) => {
          if (elements.length > 0){
            setSelectedData(elements[0]?.index);
          }
          
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Weekly Sign Ups',
          },
        },
      };

    const labels = lbls;
      const data = {
        labels,
        datasets: [
          {
            label: 'Sign Ups',
            data: signUps,
            backgroundColor: '#772FD7',
          }
        ],
      };
    return(
      <div style={customStyle}>
        <Bar options={options} data={data} ref={chartRef} />
      </div>
    ) 
    }

    
}


export default ReportingUserSignUpsGraph;
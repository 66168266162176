import React, {useState,useEffect, useRef} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Card, Form } from '../../../components/basic';
import { View } from '../../../shared/components';
import { ReportingEWAGraph, ReportingUserSignUpsGraph, ReportingSegmentAverages, ReportingNewUsesGraph } from '../../../components';
import { FaMoneyBill, FaUsers, FaDivide } from 'react-icons/fa';
import '../../style.scss';
import './style.scss';

const Reporting = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    //EWA
    const [Companies, setCompanies] = useState([]);
    const [Advances, setAdvances] = useState([]);
    const [SuccessfulAdvances, setSuccessfulAdvances] = useState([]);
    const [EWADataItems, setEWADataItems] = useState(6);
    const [EWAPeriod, setEWAPeriod] = useState('monthly');
    const [EWACompany, setEWACompany] = useState('all');
    const [EWASegment, setEWASegment] = useState('all');

    //Employees
    const [Employees, setEmployees] = useState([]);
    const [SignedUpEmployees, setSignedUpEmployees] = useState([]);
    const [EmployeesDataItems, setEmployeesDataItems] = useState(6);
    const [EmployeesPeriod, setEmployeesPeriod] = useState('monthly');
    const [EmployeesCompany, setEmployeesCompany] = useState('all');
    const [EmployeesSegment, setEmployeesSegment] = useState('all');

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [currentFrom, setCurrentFrom] = useState('');
    const [currentTo, setCurrentTo] = useState('');
    const [signups, setSignups] = useState([]);
    
    //New Uses
    const [NewUsesDataItems, setNewUsesDataItems] = useState(6);
    const [NewUsesPeriod, setNewUsesPeriod] = useState('weekly');
    const [NewUsesCompany, setNewUsesCompany] = useState('all');
    const [NewUsesSegment, setNewUsesSegment] = useState('all');

    const [activePage, setActivePage] = useState('EWA');

    useEffect(() => {
        loadAdvances();
        
    }, []);


    function loadAdvances(){
        commonStore.setLoading(true);
        commonStore.getAdvances().then((ret) => {
            let succAdv = ret.filter(adv => adv.transactionhistory && adv.transactionhistory.TransactionStatus && adv.transactionhistory.TransactionStatus !== 'Failed');
            setSuccessfulAdvances(succAdv);
            setAdvances(ret);
            loadCompanies();
        })
    }

    function loadCompanies(){
        commonStore.setLoading(true);
        commonStore.getAllClients().then((ret) => {
            setCompanies(ret);
            loadEmployees();
        })
    }

    function loadEmployees(){
        commonStore.setLoading(true);
        commonStore.getAllEmployees().then((ret) => {
            setEmployees(ret);
            let suEmp = ret.filter(emp => emp.user && emp.user.confirmed && emp.user.confirmed === true);
            setSignedUpEmployees(suEmp);
            commonStore.setLoading(false);
        })
    }

    
    function ewaConfigChanged(e){
        if (e.currentTarget.id === 'ewaPeriod'){
            setEWAPeriod(e.target.value);
        }
        if (e.currentTarget.id === 'ewaDataItems'){
            setEWADataItems(e.target.value);
        }
        if (e.currentTarget.id === 'ewaCompany'){
            setEWACompany(e.target.value);
        }
        if (e.currentTarget.id === 'ewaSegment'){
            setEWASegment(e.target.value);
        }
        
    }

    function newUsesConfigChanged(e){
        if (e.currentTarget.id === 'NewUsesPeriod'){
            setNewUsesPeriod(e.target.value);
        }
        if (e.currentTarget.id === 'NewUsesDataItems'){
            setNewUsesDataItems(e.target.value);
        }
        if (e.currentTarget.id === 'NewUsesCompany'){
            setNewUsesCompany(e.target.value);
        }
        if (e.currentTarget.id === 'NewUsesSegment'){
            setNewUsesSegment(e.target.value);
        }
        
    }

    function employeeConfigChanged(e){
        if (e.currentTarget.id === 'employeesPeriod'){
            setEmployeesPeriod(e.target.value);
        }
        if (e.currentTarget.id === 'employeesDataItems'){
            setEmployeesDataItems(e.target.value);
        }
        if (e.currentTarget.id === 'employeesCompany'){
            setEmployeesCompany(e.target.value);
        }
        if (e.currentTarget.id === 'employeesSegment'){
            setEmployeesSegment(e.target.value);
        }
        
    }
    
    function fromDateChanged(e){
        setFromDate(e.target.value);
    }

    function toDateChanged(e){
        setToDate(e.target.value);
    }

    function loadUserSignUps(){
        
        if (fromDate === '' || toDate === ''){
            return
        }
        let frmdte = new Date(fromDate);
        let tdte = new Date(toDate);
        setCurrentFrom(frmdte);
        setCurrentTo(tdte);
        commonStore.setLoading(true);
        commonStore.getUserSignUpsByDate(frmdte, tdte).then((ret) => {
            setSignups(ret);
            commonStore.setLoading(false)
        });

    }

    function optionSelected(e){
        setActivePage(e.target.value);
    }
        
        
        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%'}}>

                    {(activePage === 'EWA') ? 
                    <div style={{width: '100%', height: '89%', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', overflow: 'scroll', padding: '10px'}}>
                        <div style={{display: 'flex', justifyContent:'space-between', width: '100%', marginBottom: '10px'}}>
                            <div>
                                <label htmlFor='ewaDataItems' style={{marginRight: '10px'}}>Data Items</label>
                                <select id='ewaDataItems' value={EWADataItems} onChange={ewaConfigChanged}>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                    <option value={11}>11</option>
                                    <option value={12}>12</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor='ewaCompany' style={{marginRight: '10px'}}>Company</label>
                                <select id='ewaCompany' value={EWACompany} onChange={ewaConfigChanged}>
                                    <option value='all'>All Companies</option>
                                    {Companies.map((company, index) => {
                                        return(
                                            <option key={index} value={company._id}>{company.name}</option>
                                        )
                                    })}
                                    
                                </select>
                            </div>
                            
                        </div>

                        <div style={{display: 'flex', justifyContent:'space-between', width: '100%', marginBottom: '10px'}}>
                            <div>
                                <label htmlFor='ewaPeriod' style={{marginRight: '10px'}}>Period</label>
                                <select id='ewaPeriod' value={EWAPeriod} onChange={ewaConfigChanged}>
                                    <option value='monthly'>Monthly</option>
                                    <option value='weekly'>Weekly</option>
                                    <option value='yearly'>Yearly</option>
                                    
                                </select>
                            </div>
                            <div>
                                <label htmlFor='ewaSegment' style={{marginRight: '10px'}}>Segment</label>
                                <select id='ewaSegment' value={EWASegment} onChange={ewaConfigChanged}>
                                    <option value={'all'}>All</option>
                                    <option value={1}>1 (R0 - R8000)</option>
                                    <option value={2}>2 (R8001 - R15000)</option>
                                    <option value={3}>3 (R15001 - R30000)</option>
                                    <option value={4}>4 (R30001 - R50000)</option>
                                    <option value={5}>5 (R50001+)</option>
                                </select>
                            </div>
                        </div>
                        <ReportingEWAGraph advances={SuccessfulAdvances} period={EWAPeriod} employees={Employees} dataItems={EWADataItems} company={EWACompany} segment={EWASegment} style={{width: '100%'}}/>
                    </div>
                    : null}

                    {(activePage === 'Users') ? 
                    <div style={{width: '100%', height: '89%', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', overflow: 'scroll', padding: '10px'}}>
                        <div className='clientAdvDiv' style={{width: '100%', marginTop: '10px', marginBottom: '10px'}}>
                            <p>Total Employees</p>
                            <p>{`${Employees.length}`}</p>
                        </div>
                        <div className='clientAdvDiv' style={{width: '100%', marginTop: '10px', marginBottom: '10px'}}>
                            <p>Total Employees Signed Up</p>
                            <p>{`${SignedUpEmployees.length}`}</p>
                        </div>
                        <div style={{display: 'flex', justifyContent:'space-between', width: '100%', marginBottom: '10px'}}>
                            <div>
                                <label htmlFor='employeesDataItems'>Data Items</label>
                                <select id='employeesDataItems' value={EmployeesDataItems} onChange={employeeConfigChanged}>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                    <option value={11}>11</option>
                                    <option value={12}>12</option>
                                </select>
                            </div>

                            <div>
                                <label htmlFor='employeesCompany' style={{marginRight: '10px'}}>Company</label>
                                <select id='employeesCompany' value={EmployeesCompany} onChange={employeeConfigChanged}>
                                    <option value='all'>All Companies</option>
                                    {Companies.map((company, index) => {
                                        return(
                                            <option key={index} value={company._id}>{company.name}</option>
                                        )
                                    })}
                                    
                                </select>
                            </div>
                        </div>

                        <div style={{display: 'flex', justifyContent:'space-between', width: '100%', marginBottom: '10px'}}>
                            <div>
                                <label htmlFor='employeesPeriod'>Period</label>
                                <select id='employeesPeriod' value={EmployeesPeriod} onChange={employeeConfigChanged}>
                                    <option value='monthly'>Monthly</option>
                                    <option value='weekly'>Weekly</option>
                                    
                                </select>
                            </div>
                            <div>
                                <label htmlFor='employeesSegment' style={{marginRight: '10px'}}>Segment</label>
                                <select id='employeesSegment' value={EmployeesSegment} onChange={employeeConfigChanged}>
                                    <option value={'all'}>All</option>
                                    <option value={1}>1 (R0 - R8000)</option>
                                    <option value={2}>2 (R8001 - R15000)</option>
                                    <option value={3}>3 (R15001 - R30000)</option>
                                    <option value={4}>4 (R30001 - R50000)</option>
                                    <option value={5}>5 (R50001+)</option>
                                </select>
                            </div>
                        </div>
                        <ReportingUserSignUpsGraph employees={SignedUpEmployees} period={EmployeesPeriod} dataItems={EmployeesDataItems} company={EmployeesCompany} segment={EmployeesSegment} style={{width: '100%'}}/>
                    </div>
                    : null}

                    {(activePage === 'Averages') ? 
                    <div style={{width: '100%', height: '89%', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', overflow: 'scroll', padding: '10px'}}>
                        <Card className='AdminClientCard' style={{width: '100%'}}>
                            <p><b>Segment 1:</b> Salary: R0 - R8000</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={1}/>
                        </Card>
                        <Card className='AdminClientCard' style={{width: '100%'}}>
                            <p><b>Segment 2:</b> Salary: R8001 - R15000</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={2}/>
                        </Card>
                        <Card className='AdminClientCard' style={{width: '100%'}}>
                            <p><b>Segment 3:</b> Salary: R15001 - R30000</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={3}/>
                        </Card>
                        <Card className='AdminClientCard' style={{width: '100%'}}>
                            <p><b>Segment 4:</b> Salary: R30001 - R50000</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={4}/>
                        </Card>
                        <Card className='AdminClientCard' style={{width: '100%'}}>
                            <p><b>Segment 5:</b> Salary: R50001+</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={5}/>
                        </Card>
                    </div>
                    : null}

                    <Card className="tabBar" style={{height: '11%'}}>
                        <Form className="tabBarForm" onChange={optionSelected} style={{overflow: 'hidden'}}>

                            <div className="tabBarItem">
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="ewaTab" value="EWA"/>
                                <FaMoneyBill className={(activePage === "EWA")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="ewaTab" style={{height: '100%'}}/>
                                <label className={(activePage === "EWA")? "tabSelected":null} htmlFor="ewaTab">EWA</label>
                            </div>

                            <div className="tabBarItem"> 
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="usersTab" value="Users"/>
                                <FaUsers className={(activePage === "Users")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="usersTab" style={{height: '100%'}}/>
                                <label className={(activePage === "Users")? "tabSelected":null} htmlFor="usersTab">Users</label>
                            </div>

                            <div className="tabBarItem"> 
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="averagesTab" value="Averages"/>
                                <FaDivide className={(activePage === "Averages")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="averagesTab" style={{height: '100%'}}/>
                                <label className={(activePage === "Averages")? "tabSelected":null} htmlFor="averagesTab">Averages</label>
                            </div>

                        </Form>
                        
                    </Card>
                    
                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Reporting</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>    
                        <hr/>
                        <p style={{fontWeight: 'bold'}}>EWA</p>
                        <Card className='AdminClientCard'>
                            <div style={{display: 'flex', justifyContent:'space-evenly'}}>
                                <div>
                                    <label htmlFor='ewaDataItems' style={{marginRight: '10px'}}>Data Items</label>
                                    <select id='ewaDataItems' value={EWADataItems} onChange={ewaConfigChanged}>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                        <option value={11}>11</option>
                                        <option value={12}>12</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='ewaPeriod' style={{marginRight: '10px'}}>Period</label>
                                    <select id='ewaPeriod' value={EWAPeriod} onChange={ewaConfigChanged}>
                                        <option value='monthly'>Monthly</option>
                                        <option value='weekly'>Weekly</option>
                                        <option value='yearly'>Yearly</option>
                                        
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='ewaCompany' style={{marginRight: '10px'}}>Company</label>
                                    <select id='ewaCompany' value={EWACompany} onChange={ewaConfigChanged}>
                                        <option value='all'>All Companies</option>
                                        {Companies.map((company, index) => {
                                            return(
                                                <option key={index} value={company._id}>{company.name}</option>
                                            )
                                        })}
                                        
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='ewaSegment' style={{marginRight: '10px'}}>Segment</label>
                                    <select id='ewaSegment' value={EWASegment} onChange={ewaConfigChanged}>
                                        <option value={'all'}>All</option>
                                        <option value={1}>1 (R0 - R8000)</option>
                                        <option value={2}>2 (R8001 - R15000)</option>
                                        <option value={3}>3 (R15001 - R30000)</option>
                                        <option value={4}>4 (R30001 - R50000)</option>
                                        <option value={5}>5 (R50001+)</option>
                                    </select>
                                </div>
                            </div>
                            <ReportingEWAGraph advances={SuccessfulAdvances} period={EWAPeriod} employees={Employees} dataItems={EWADataItems} company={EWACompany} segment={EWASegment}/>
                        </Card>
                        <p style={{fontWeight: 'bold'}}>First Time Advances</p>
                        <Card className='AdminClientCard'>
                            <div style={{display: 'flex', justifyContent:'space-evenly'}}>
                                <div>
                                    <label htmlFor='NewUsesDataItems' style={{marginRight: '10px'}}>Data Items</label>
                                    <select id='NewUsesDataItems' value={NewUsesDataItems} onChange={newUsesConfigChanged}>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                        <option value={11}>11</option>
                                        <option value={12}>12</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='NewUsesPeriod' style={{marginRight: '10px'}}>Period</label>
                                    <select id='NewUsesPeriod' value={NewUsesPeriod} onChange={newUsesConfigChanged}>
                                        <option value='monthly'>Monthly</option>
                                        <option value='weekly'>Weekly</option>
                                        {/* <option value='yearly'>Yearly</option> */}
                                        
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='NewUsesCompany' style={{marginRight: '10px'}}>Company</label>
                                    <select id='NewUsesCompany' value={NewUsesCompany} onChange={newUsesConfigChanged}>
                                        <option value='all'>All Companies</option>
                                        {Companies.map((company, index) => {
                                            return(
                                                <option key={index} value={company._id}>{company.name}</option>
                                            )
                                        })}
                                        
                                    </select>
                                </div>
                                {/* <div>
                                    <label htmlFor='NewUsesSegment' style={{marginRight: '10px'}}>Segment</label>
                                    <select id='NewUsesSegment' value={NewUsesSegment} onChange={newUsesConfigChanged}>
                                        <option value={'all'}>All</option>
                                        <option value={1}>1 (R0 - R8000)</option>
                                        <option value={2}>2 (R8001 - R15000)</option>
                                        <option value={3}>3 (R15001 - R30000)</option>
                                        <option value={4}>4 (R30001 - R50000)</option>
                                        <option value={5}>5 (R50001+)</option>
                                    </select>
                                </div> */}
                            </div>
                            <ReportingNewUsesGraph advances={SuccessfulAdvances} period={NewUsesPeriod} dataItems={NewUsesDataItems} company={NewUsesCompany} />
                        </Card>
                        
                        <p style={{fontWeight: 'bold'}}>Users</p>
                        <Card className='AdminClientCard' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <div className='clientAdvDiv'>
                                <p>Total Employees</p>
                                <p>{`${Employees.length}`}</p>
                            </div>
                            <div className='clientAdvDiv'>
                                <p>Total Employees Signed Up</p>
                                <p>{`${SignedUpEmployees.length}`}</p>
                            </div>
                        </Card>
                        <Card className='AdminClientCard'>
                            <div style={{display: 'flex', justifyContent:'space-evenly'}}>
                                <div>
                                    <label htmlFor='employeesDataItems'>Data Items</label>
                                    <select id='employeesDataItems' value={EmployeesDataItems} onChange={employeeConfigChanged}>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                        <option value={11}>11</option>
                                        <option value={12}>12</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='employeesPeriod'>Period</label>
                                    <select id='employeesPeriod' value={EmployeesPeriod} onChange={employeeConfigChanged}>
                                        <option value='monthly'>Monthly</option>
                                        <option value='weekly'>Weekly</option>
                                        
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='employeesCompany' style={{marginRight: '10px'}}>Company</label>
                                    <select id='employeesCompany' value={EmployeesCompany} onChange={employeeConfigChanged}>
                                        <option value='all'>All Companies</option>
                                        {Companies.map((company, index) => {
                                            return(
                                                <option key={index} value={company._id}>{company.name}</option>
                                            )
                                        })}
                                        
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='employeesSegment' style={{marginRight: '10px'}}>Segment</label>
                                    <select id='employeesSegment' value={EmployeesSegment} onChange={employeeConfigChanged}>
                                        <option value={'all'}>All</option>
                                        <option value={1}>1 (R0 - R8000)</option>
                                        <option value={2}>2 (R8001 - R15000)</option>
                                        <option value={3}>3 (R15001 - R30000)</option>
                                        <option value={4}>4 (R30001 - R50000)</option>
                                        <option value={5}>5 (R50001+)</option>
                                    </select>
                                </div>
                            </div>
                            <ReportingUserSignUpsGraph employees={SignedUpEmployees} period={EmployeesPeriod} dataItems={EmployeesDataItems} company={EmployeesCompany} segment={EmployeesSegment}/>
                        </Card>
                        <p style={{fontWeight: 'bold'}}>Averages</p>
                        <Card className='AdminClientCard'>
                            <p><b>Segment 1:</b> Salary: R0 - R8000</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={1}/>
                        </Card>
                        <Card className='AdminClientCard'>
                            <p><b>Segment 2:</b> Salary: R8001 - R15000</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={2}/>
                        </Card>
                        <Card className='AdminClientCard'>
                            <p><b>Segment 3:</b> Salary: R15001 - R30000</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={3}/>
                        </Card>
                        <Card className='AdminClientCard'>
                            <p><b>Segment 4:</b> Salary: R30001 - R50000</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={4}/>
                        </Card>
                        <Card className='AdminClientCard'>
                            <p><b>Segment 5:</b> Salary: R50001+</p>
                            <ReportingSegmentAverages employees={Employees} advances={SuccessfulAdvances} segment={5}/>
                        </Card>



                    </div>

                </View>
            );
        }
})

export default Reporting;
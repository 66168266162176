import React from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import Client from "../../pages/adminDash/client";
const LVLLogo = require('../../assets/images/LVLLogo.png');

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COLN_WIDTH = 100/7

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  HeaderText:{
    fontSize: 12
  },
  ClientHeaderText:{
    fontSize: 12,
    textDecoration: "underline",
  },
  HeaderTextRight:{
    fontSize: 12,
    textAlign: 'right'
  },
  SubHeaderText:{
    fontSize: 10
  },
  logo: {
    width: 100
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    marginTop: 15
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },     
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  totalTableCol: { 
    width: ((100 / 7) * 6) + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 2, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  totalTableColAmount: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 2, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 12,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  },
  tableCellTotal: { 
    margin: 5, 
    fontSize: 12 
  },
  BankingDetailsView:{
	border: 1,
    padding: 5,
    marginTop: 15,
    borderRadius: 10,
    width: 60 + '%',
    alignSelf: 'center'
  },
  BankingDetailSection:{
    flexDirection: 'row'
  },
});

const PDFInvoice = ({employeeAdvances, client, invoiceNumber, invoiceDate}) => {
    let date = (new Date(invoiceDate)).toLocaleDateString()
    let address = client.address;
    let addrArr = address.split('/ ');
    var total = 0;
    for (let i = 0; i < employeeAdvances.length; i++) {
        let advance = employeeAdvances[i];
        let ttl = ((advance.TotalAdvances) + (advance.TotalFee) + (advance.subAmount) + ((advance.subAmount) * 15 / 100));
        total += Number(ttl);
    }
  return (
    <Document>
      <Page style={styles.body}>
        <View>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                <View>
                    <Text style={styles.HeaderText}>Invoice No: <Text style={styles.SubHeaderText}>{invoiceNumber}</Text></Text>
                    <Text style={styles.HeaderText}>Date: <Text style={styles.SubHeaderText}>{date}</Text></Text>
                    <Text style={styles.HeaderText}>Payment Terms: <Text style={styles.SubHeaderText}>20 days from receipt of invoice</Text></Text>
                    <Text> </Text>
                    <Text style={styles.ClientHeaderText}>Client Details</Text>
                    <Text style={styles.HeaderText}>{client.name}</Text>
                    <Text style={styles.HeaderText}>{`VAT: ${client.registrationNumber}`}</Text>
                    <Text style={styles.HeaderText}>{addrArr[0] || ''}</Text>
                    <Text style={styles.HeaderText}>{addrArr[1] || ''}</Text>
                    <Text style={styles.HeaderText}>{addrArr[2] || ''}</Text>
                    <Text style={styles.HeaderText}>{addrArr[3] || ''}</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'column', justifyContent: 'right', alignItems: 'flex-end'}}>
                    <Image style={styles.logo} src={LVLLogo} />
                    <Text style={styles.HeaderTextRight}>Level Finance (PTY) LTD</Text>
                    <Text style={styles.HeaderTextRight}>Reg: 2018/579476/07</Text>
                    <Text style={styles.HeaderTextRight}>VAT: 4700306949</Text>
                    <Text style={styles.HeaderTextRight}>1st Floor, Building 4</Text>
                    <Text style={styles.HeaderTextRight}>Clearwater Office Park</Text>
                    <Text style={styles.HeaderTextRight}>Cnr Christian de Wet</Text>
                    <Text style={styles.HeaderTextRight}>and Millenium Road</Text>
                    <Text style={styles.HeaderTextRight}>Strubensvalley, 1735</Text>
                </View>
                
            </div>
        </View>
        <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Date</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Name</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Surname</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Advances</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Service Fee</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Membership Fee (VAT excl.)</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Total Deduction (VAT incl.)</Text> 
          </View> 
        </View>

        {employeeAdvances.map((advance) => {
            let dte = (new Date(advance.Date)).toLocaleDateString();
            let totalDeduction = (Number(advance.TotalAdvances) + Number(advance.TotalFee) + Number(advance.subAmount) + (Number(advance.subAmount) * 15 / 100)).toFixed(2);
            return (
                <View style={styles.tableRow}> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{dte}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.FirstName}</Text> 
                    </View> 
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{advance.LastName}</Text> 
                    </View>
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.TotalAdvances}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.TotalFee}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.subAmount}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{totalDeduction}</Text> 
                    </View> 
                </View>   
            )
        })}
            <View style={styles.tableRow}> 
                <View style={styles.totalTableCol}> 
                    <Text style={styles.tableCellTotal}>Total:</Text> 
                </View> 
                <View style={styles.totalTableColAmount}> 
                    <Text style={styles.tableCellTotal}>{total.toFixed(2)}</Text> 
                </View> 
            </View>  
        </View>

        <View style={styles.BankingDetailsView}>
            <Text style={styles.ClientHeaderText}>Banking Details</Text>
            <Text> </Text>
            <View style={styles.BankingDetailSection}>
                <Text style={styles.HeaderText}>Bank Name: </Text>
                <Text style={styles.HeaderText}>First National Bank</Text>
            </View>

            <View style={styles.BankingDetailSection}>
                <Text style={styles.HeaderText}>Branch Account: </Text>
                <Text style={styles.HeaderText}>62899294872</Text>
            </View>

            <View style={styles.BankingDetailSection}>
                <Text style={styles.HeaderText}>Branch Code: </Text>
                <Text style={styles.HeaderText}>250655</Text>
            </View>

            <View style={styles.BankingDetailSection}>
                <Text style={styles.HeaderText}>Swift Code: </Text>
                <Text style={styles.HeaderText}>FIRNZAJJ</Text>
            </View>
            <Text> </Text>
            <Text style={styles.ClientHeaderText}>Overdue amount may be subject to interest</Text>
        </View>

      </Page>
    </Document>
  );
};

export default PDFInvoice;
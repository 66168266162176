import React from 'react';
import PropTypes from "prop-types"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';


function ClientAdvanceGraph({className, id, offset=0, advances = [], style={}}) {
    
    let lbls = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    let numAdvances = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (let i = 0; i < advances.length; i++) {
      let advance = advances[i]
      let day = (new Date(advance.AdvanceRequestDate)).getDate();
      numAdvances[day - 1] = numAdvances[day - 1] + 1;
    }


    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Daily Advances',
          },
        },
      };

    const labels = lbls;
      const data = {
        labels,
        datasets: [
          {
            label: 'Number of Advances',
            data: numAdvances,
            backgroundColor: '#772FD7',
          }
        ],
      };
    return <Bar options={options} data={data} />;

    
}


export default ClientAdvanceGraph;
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';

import '../style.scss';
import './style.scss';


const WithdrawConfirm = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [searchParams] = useSearchParams();

    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [transactionFee, setTransactionFee] = useState(0);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [method, setMethod] = useState('');
    const [error, setError] = useState('');
    const [authMethod, setAuthMethod] = useState('webauthn');
    const [webauthnFailed, setWebauthnFailed] = useState(false);
    const [password, setPassword] = useState('');
    const [showConfirm, setShowConfirm] = useState(true)

    useEffect(() => {
        loadData(); //Commented out for debug
        if (isMobile()) {
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function loadData() {

        let amt = searchParams.toString();
        amt = amt.replace('=', '');
        setWithdrawalAmount(Number(amt));
        let perc = commonStore.employeeDetail.employeesalary.FeeAmount || 3;
        if (perc < 2.5) {
            perc = 2.5;
        }
        setTransactionFee(Number((Number(amt) * perc / 100).toFixed(2)));
        let accounts = commonStore.retrieveBankAccounts();
        if (accounts === '[]') {
            accounts = [];
        }
        setBankAccounts(accounts);
        commonStore.setLoading(false);
    }

    function optionSelected(e) {
        setMethod(e.target.value);
    }

    function addBankAccount() {
        navigate('/newbankaccount', { amount: withdrawalAmount });
    }

    function passwordChanged(e) {
        setPassword(e.target.value);
    }

    function goBack() {
        navigate({ pathname: '/home', search: 'Withdraw' })
    }

    function usePassword() {
        setAuthMethod('password');
        setShowConfirm(true)
    }

    function processWebauthn() {
        setShowConfirm(false)
        commonStore.setLoading(true);
        commonStore.authenticateAdvanceWebauthn().then((ret) => {
            if (ret === 'Password') {
                usePassword();
                commonStore.setLoading(false);
            } else if (ret === true) {
                if (method === 'primary' || method === 'secondary') {
                    processBankAccount();
                } else {
                    console.log('No method selected');
                    commonStore.setLoading(false);
                    setShowConfirm(true)
                }
            } else {
                setError('Authentication Failed');
                commonStore.setLoading(false);
                setShowConfirm(true)
            }
        });

        // commonStore.setLoading(true)
        // let paymentMethod = method;
        // let bankAccount, paymentType;
        // if (paymentMethod === 'mobileMoney'){
        //     paymentType = 'mobileMoney';
        //     console.log('Mobile Money');
        // }else if (paymentMethod === 'bankCard'){
        //     paymentType = 'mobileMoney';
        //     console.log('Bank Card');
        // }else{
        //     paymentType = 'bankAccount';
        //     paymentMethod = parseInt(paymentMethod);
        //     bankAccount = bankAccounts[paymentMethod];
        // }

        // let advance = {
        //     totalAmount: (parseFloat(withdrawalAmount) + parseFloat(transactionFee)).toFixed(2),
        //     advanceAmount: parseFloat(withdrawalAmount),
        //     feeAmount: parseFloat(transactionFee),
        //     paymentType: paymentType,
        //     bankAccount: bankAccount
        // }
        // commonStore.setLoading(true)
        // commonStore.processAdvanceWebauthn(advance).then((ret) => {
        //     if (ret === 'Success'){
        //         commonStore.setLoading(false);
        //         navigate('/withdrawsuccess');
        //     }else if (ret === 'Invalid password'){
        //         setError('Invalid password');
        //         commonStore.setLoading(false);
        //     }else if (ret === 'Invalid authentication'){
        // setError('Authentication Failed');
        // commonStore.setLoading(false);
        //     }else if(ret === 'Password') {
        // usePassword();
        // commonStore.setLoading(false);
        //         return
        //     }else{
        //         setError('An error has occured, please try again.');
        //         commonStore.setLoading(false);
        //     }
        // });
    }

    function processPassword() {
        setShowConfirm(false)
        commonStore.setLoading(true);
        commonStore.authenticateAdvancePassword(password).then((ret) => {
            if (ret === true) {
                if (method === 'primary' || method === 'secondary') {
                    processBankAccount();
                } else {
                    console.log('No method selected');
                    commonStore.setLoading(false);
                    setShowConfirm(true)
                }
            } else {
                setError('Authentication Failed');
                commonStore.setLoading(false);
                setShowConfirm(true)
            }
        });

        // commonStore.setLoading(true)
        // let paymentMethod = method;
        // let bankAccount, paymentType;
        // if (paymentMethod === 'mobileMoney'){
        //     paymentType = 'mobileMoney';
        //     console.log('Mobile Money');
        // }else if (paymentMethod === 'bankCard'){
        //     paymentType = 'mobileMoney';
        //     console.log('Bank Card');
        // }else{
        //     paymentType = 'bankAccount';
        //     paymentMethod = parseInt(paymentMethod);
        //     bankAccount = bankAccounts[paymentMethod];
        // }

        // let advance = {
        //     totalAmount: parseFloat(withdrawalAmount) + parseFloat(transactionFee),
        //     advanceAmount: parseFloat(withdrawalAmount),
        //     feeAmount: parseFloat(transactionFee),
        //     paymentType: paymentType,
        //     bankAccount: bankAccount,
        //     password: password
        // }
        // commonStore.setLoading(true)
        // commonStore.processAdvance(advance).then((ret) => {
        //     if (ret === 'Success'){
        // commonStore.setLoading(false);
        // navigate('/withdrawsuccess');
        //     }else if (ret === 'Invalid password'){
        //         setError('Invalid password');
        //         commonStore.setLoading(false);
        //     }else if (ret === 'Invalid authentication'){
        //         setError('Authentication Failed');
        //         commonStore.setLoading(false);
        //     }else{
        // setError('An error has occured, please try again.');
        // commonStore.setLoading(false);
        //     }
        // });
    }

    function processBankAccount() {
        let data = {
            bankAccount: method,
            totalAmount: parseFloat(withdrawalAmount) + parseFloat(transactionFee),
            advanceAmount: parseFloat(withdrawalAmount),
            feeAmount: parseFloat(transactionFee)
        };
        commonStore.processBankAccountAdvance(data).then((ret) => {
            if (ret === true || ret === 'True' || ret === 'true') {
                commonStore.setLoading(false);
                navigate('/withdrawsuccess');
            } else if (ret === false || ret === 'False' || ret === 'false') {
                setError('An error has occured, please try again.');
                commonStore.setLoading(false);
            } else {
                setError(ret);
                commonStore.setLoading(false);
            }
        })
    }

    if (isMobile()) {
        return (
            <div id="mobileDashView" className="view">
                <MobileTopBarClean />
                <div className="viewContent view-pages view-session d-flex flex-column" style={{ overflow: 'scroll' }}>
                    <div style={{ overflow: 'scroll' }}>
                        <p className="txtlead txtleft">Confirm withdrawal</p>
                        <p className="txtsub txtleft">You're about to withdraw some money from your earned wages.</p>

                        <div className="tableDivPrimary">
                            <div style={{ textAlign: 'center' }}>
                                <p id="withdrawalAmountP">{`R${withdrawalAmount}`}</p>
                            </div>

                            <div className="tableDivSecondary">
                                <p>Transaction fee</p>
                                <p>{`R${transactionFee}`}</p>
                            </div>

                            <div className="tableDivSecondary">
                                <p>Deduction from next pay</p>
                                <p>{`R${(parseFloat(transactionFee) + parseFloat(withdrawalAmount)).toFixed(2)}`}</p>
                            </div>
                            <div className="tableDivSecondary">
                                <p>Date</p>
                                <p>{`${(new Date()).getDate().toString()} ${(new Date()).toLocaleString('default', { month: 'short' })} ${(new Date()).getFullYear().toString()}`}</p>
                            </div>
                        </div>

                        <p className="txtlead txtleft">Where would you like us to pay the withdrawal?</p>
                        <Form className="align-items-center">
                            <div onChange={optionSelected}>
                                {(commonStore.employeeDetail.bankaccount) ?
                                    <div>
                                        <input className="checkbox-tools" name="withdrawalOption" type="radio" id='primary' value='primary' />
                                        <label htmlFor='primary'>{`${commonStore.employeeDetail.bankaccount.bankname} (${commonStore.employeeDetail.bankaccount.accountnumber.substr(commonStore.employeeDetail.bankaccount.accountnumber.length - 4)})`}</label>
                                    </div>
                                    : null}

                                {(commonStore.employeeDetail.secondaryBankAccount) ?
                                    <div>
                                        <input className="checkbox-tools" name="withdrawalOption" type="radio" id='secondary' value='secondary' />
                                        <label htmlFor='secondary'>{`${commonStore.employeeDetail.secondaryBankAccount.bankname} (${commonStore.employeeDetail.secondaryBankAccount.accountnumber.substr(commonStore.employeeDetail.secondaryBankAccount.accountnumber.length - 4)})`}</label>
                                    </div>
                                    : null}
                                {(!commonStore.employeeDetail.bankaccount && !commonStore.employeeDetail.secondaryBankAccount) ?
                                    <p className="txtsub">You haven't added any bank accounts yet. Please contact support.</p>
                                    : null}
                                {/* <div> //Commented out until integration complete
                                    <input className="checkbox-tools" name="withdrawalOption" type="radio" id='mobileMoney' value='mobileMoney'/>
                                    <label htmlFor='mobileMoney'>Mobile Money</label>
                                </div> */}
                            </div>
                            {(authMethod === 'webauthn') ?
                                <>
                                    {(method === '') ?
                                        null : <>{(error.length > 0) ? <p style={{ color: 'red' }}>{error}</p> : null}{showConfirm ? <><Button block size="lg" onClick={processWebauthn} style={{ marginBottom: '10px', marginTop: '10px', borderColor: 'transparent' }}>Confirm withdrawal</Button><Button block size="lg" onClick={usePassword} style={{ borderColor: 'transparent', marginBottom: '20px' }}>Use Password</Button></> : null}</>
                                    }
                                    <p className="backButton" onClick={goBack} style={{ paddingTop: '0px' }}>Back</p>
                                </> :
                                <>
                                    {(method === '') ?
                                        null : <>
                                            <label className="custom-field">
                                                <input className='inp' type="password" id="idpassport" value={password} onChange={passwordChanged} required />
                                                <span className="placeholder">Password</span>
                                            </label>
                                            {(error.length > 0) ? <p style={{ color: 'red' }}>{error}</p> : null}
                                            {showConfirm ? <Button block size="lg" onClick={processPassword} style={{ marginBottom: '20px' }}>Confirm withdrawal</Button> : null}
                                        </>
                                    }
                                    <p className="backButton" onClick={goBack} style={{ paddingTop: '0px' }}>Back</p>
                                </>}

                        </Form>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <View>
                <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                    <Card className="mb-3 form-card loginCard" style={{ maxHeight: `${window.innerHeight - 100}px` }}>
                        <CardBody style={{ display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
                            <header className="mb-4 topLogin">
                                <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                <p className="txtlead">Confirm withdrawal</p>
                                <p className="txtsub">You're about to withdraw some money from your earned wages.</p>
                            </header>

                            <div className="tableDivPrimary">
                                <div style={{ textAlign: 'center' }}>
                                    <p id="withdrawalAmountP">{`R${withdrawalAmount}`}</p>
                                </div>

                                <div className="tableDivSecondary">
                                    <p>Transaction fee</p>
                                    <p>{`R${transactionFee}`}</p>
                                </div>

                                <div className="tableDivSecondary">
                                    <p>Deduction from next pay</p>
                                    <p>{`R${(parseFloat(transactionFee) + parseFloat(withdrawalAmount)).toFixed(2)}`}</p>
                                </div>
                                <div className="tableDivSecondary">
                                    <p>Date</p>
                                    <p>{`${(new Date()).getDate().toString()} ${(new Date()).toLocaleString('default', { month: 'short' })} ${(new Date()).getFullYear().toString()}`}</p>
                                </div>
                            </div>

                            <p className="txtlead">Where would you like us to pay the withdrawal?</p>
                            <Form className="align-items-center">
                                <div onChange={optionSelected}>
                                    {(commonStore.employeeDetail.bankaccount) ?
                                        <div>
                                            <input className="checkbox-tools" name="withdrawalOption" type="radio" id='primary' value='primary' />
                                            <label htmlFor='primary'>{`${commonStore.employeeDetail.bankaccount.bankname} (${commonStore.employeeDetail.bankaccount.accountnumber.substr(commonStore.employeeDetail.bankaccount.accountnumber.length - 4)})`}</label>
                                        </div>
                                        : null}

                                    {(commonStore.employeeDetail.secondaryBankAccount) ?
                                        <div>
                                            <input className="checkbox-tools" name="withdrawalOption" type="radio" id='secondary' value='secondary' />
                                            <label htmlFor='secondary'>{`${commonStore.employeeDetail.secondaryBankAccount.bankname} (${commonStore.employeeDetail.secondaryBankAccount.accountnumber.substr(commonStore.employeeDetail.secondaryBankAccount.accountnumber.length - 4)})`}</label>
                                        </div>
                                        : null}
                                    {(!commonStore.employeeDetail.bankaccount && !commonStore.employeeDetail.secondaryBankAccount) ?
                                        <p className="txtsub">You haven't added any bank accounts yet. Please contact support.</p>
                                        : null}
                                    {/* <div> //Commented out until integration complete
                                    <input className="checkbox-tools" name="withdrawalOption" type="radio" id='mobileMoney' value='mobileMoney'/>
                                    <label htmlFor='mobileMoney'>Mobile Money</label>
                                </div> */}
                                </div>
                                {(authMethod === 'webauthn') ?
                                    <>
                                        {(method === '') ?
                                            null : <>{(error.length > 0) ? <p style={{ color: 'red' }}>{error}</p> : null}{showConfirm ? <><Button block size="lg" onClick={processWebauthn} style={{ marginBottom: '10px', marginTop: '10px', borderColor: 'transparent' }}>Confirm withdrawal</Button><Button block size="lg" onClick={usePassword} style={{ borderColor: 'transparent', marginBottom: '20px' }}>Use Password</Button></> : null}</>
                                        }
                                        <p className="backButton" onClick={goBack} style={{ paddingTop: '0px' }}>Back</p>
                                    </> :
                                    <>
                                        {(method === '') ?
                                            null : <>
                                                <label className="custom-field">
                                                    <input className='inp' type="password" id="idpassport" value={password} onChange={passwordChanged} required />
                                                    <span className="placeholder">Password</span>
                                                </label>
                                                {(error.length > 0) ? <p style={{ color: 'red' }}>{error}</p> : null}
                                                {showConfirm ? <Button block size="lg" onClick={processPassword} style={{ marginBottom: '20px' }}>Confirm withdrawal</Button> : null}</>
                                        }
                                        <p className="backButton" onClick={goBack} style={{ paddingTop: '0px' }}>Back</p>
                                    </>}
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </View>
        );
    }
})

export default WithdrawConfirm
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../../components/basic';
import { View, MobileTopBarClean, CustomInput } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

const VerifyIdFallback = observer(() => {
    const navigate = useNavigate();
    const commonStore = useStore();

    const [data, setData] = useState(
        {
            contact: '',
            questionsAvailable: false,
            questions: [],
            questionSet: '',
            answers: [], 
            Question: {},
            error: '',
            tries: 0,
            currentQuestion: 0
        }
    );

    const [contact, setContact] = useState('');
    const [tries, setTries] = useState(0);
    const [error, setError] = useState('');
    const [Question, setQuestion] = useState({});
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [questionSet, setQuestionSet] = useState('');
    const [questionsAvailable, setQuestionsAvailable] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);


    function contactChanged(e){
        setContact(e.target.value);
        
    }

    function confirmNumber(){

     
        if (tries >= 3){
            return
        }
        commonStore.setLoading(true);
        commonStore.getQuestions(contact).then((ret) => {
            if (ret === 'No Results Found'){
                console.log('No results found for IDNumber');
                navigate('/idverificationfailed');
                commonStore.setLoading(false);
                return
            }
            if (!ret){
                //console.log('Failed to fetch questions');
                setError('An error occured, please try again later.');
                commonStore.setLoading(false);
                return //No questions
            }
            //console.log(ret);
            setError('');
            setQuestion(ret.Question);
            let qsts = []
            let ansr = [];
            for (let i = 0; i < ret.QuestionDocument.length; i++){
                let questionObject = {
                    question: ret.QuestionDocument[i].Question,
                    answers: ret.QuestionDocument[i].Answers,
                    questionId: ret.QuestionDocument[i].ProductAuthenticationQuestionID
                }
                qsts.push(questionObject);
                ansr.push(-1);
                // setQuestions(questions.push(questionObject));
                // setAnswers(answers.push(-1));
            }
            setQuestions(qsts);
            setAnswers(ansr);
            setCurrentQuestion(0);
            setQuestionSet(ret.questionSetId);
            setQuestionsAvailable(true);
            commonStore.setLoading(false);
        })


    }

    function answerSelected(e){
        setError('');
        let questionNum = parseInt(e.target.id.substring(0, 1));
        let answerNum = parseInt(e.target.id.slice(-1));
        console.log(`Question Number: ${questionNum}. Answer Number: ${answerNum}.`);
        let ans = answers
        ans[questionNum] = answerNum;
        setAnswers(ans);
        forceUpdate();

    }

    function verifyAnswers(){


        for (let i = 0; i < answers.length; i++){
            if (answers[i] === -1){
                return false
                
            }
        }
        return true
    }

    function nextQuestion(){
        setCurrentQuestion(currentQuestion + 1);

    }

    function submitAnswers(){
        let verified = verifyAnswers();
      
        if (!verified){
            setError('Please complete all answers.');
          
            return
        }
        let questionObject = Question;
        commonStore.setLoading(true);
        for (let i = 0; i < answers.length; i++){
            questionObject.Result.QuestionDocument[i].Answers.AnswerDocument[answers[i]].IsEnteredAnswerYN = true;
        }

        commonStore.validateQuestions(questionSet, questionObject).then((ret) => {
            if (ret){
                commonStore.setLoading(false);
                navigate('/verifiedid');
            }else{
                let trs = tries + 1;
                setTries(tries + 1);
                if (trs < 3){
                    setError('Unable to verify your identity, please try again.');
                    setQuestionsAvailable(false);
                    setQuestions([]);
                    setQuestionSet('');
                    setAnswers([]);
                    setQuestions({});
                    setCurrentQuestion(0);
                    commonStore.setLoading(false);
                }else{
                    commonStore.setLoading(false);
                    navigate('/idverificationfailed');
                    // commonStore.blockUser(commonStore.user.username, 'User has failed ID verification.').then((ret) => {
                    //     setError('You have exceeded the number of tries, please contact support');
                    //     setQuestionsAvailable(false);
                    //     setQuestions([]);
                    //     setQuestionSet('');
                    //     setAnswers([]);
                    //     setQuestion({});
                    //     commonStore.setLoading(false);
                    // });
                    

                }
            }
        });
    }

    
        if (isMobile()){
            return(
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>
                    </div>
                    
                    <div id="mainView" className="viewContent view-pages view-session d-flex justify-content-center flex-column">
                        <div className="svgDiv" style={{margin: '1px', height: '35%'}}>
                            <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/verifyId.svg" alt="icon"/>
                        </div>
                        <p className="txtlead">Verify your identity</p>
                        <p className="txtsub">We will need to ask you a set of questions to verify your ID.</p>
                        <br/>

                        {(!questionsAvailable)?
                        <Form>
                            <CustomInput type='text' id='contact' value={contact} placeholder='Contact number' onChange={contactChanged} onEnter={confirmNumber} />
                            {(error.length > 0)?<p style={{color: 'red'}}>{error}</p>:null}
                            <Button block size="lg" onClick={confirmNumber}>Next &gt;</Button>
                        </Form>:
                        <Form style={{overflow: 'scroll'}}>
                            {questions.map((quest, i) => {
                                if (currentQuestion !== i){
                                    return
                                }
                                return(
                                    <div key={i}>
                                        <p>{quest.question}</p>
                                        <div onChange={answerSelected}>
                                            {quest.answers.AnswerDocument.map((ans, j) => {
                                                return(
                                                    <div key={`a${j}`}>
                                                        <input className="checkbox-tools" name={`answer-${i}`} type="radio" id={`${i}-answer-${j}`} value={JSON.stringify(ans)}/>
                                                        <label htmlFor={`${i}-answer-${j}`}>{ans.Answer}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                            {(currentQuestion === (questions.length - 1) && answers[(questions.length - 1)] !== -1)? 
                            <Button block size="lg" onClick={submitAnswers}>Submit answers &gt;</Button>:
                            null
                            }
                            {(currentQuestion < (questions.length - 1) && answers[currentQuestion] !== -1)? 
                            <Button block size="lg" onClick={nextQuestion}>Next question</Button>:
                            null
                            }
                            
                            
                        </Form>}
                    </div>
                </div>
            );
        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                    <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                        <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                            <header className="mb-4 topLogin">
                                <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg"/>
                                <p className="txtlead">Verify your identity</p>
                                <p className="txtsub">Unable to verify selfie. We will need to ask you a set of questions to verify your ID.</p>
                            </header>
                            
                            {(!questionsAvailable)?
                            <>
                            <div className="svgDiv">
                                <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/verifyId.svg" alt="icon"/>
                            </div>
                            <div>
                                <CustomInput type='text' id='contact' value={contact} placeholder='Contact number' onChange={contactChanged} onEnter={confirmNumber} />
                                {(error.length > 0)?<p style={{color: 'red'}}>{error}</p>:null}
                                <Button block size="lg" onClick={confirmNumber}>Next &gt;</Button>
                            </div></>:
                            <Form style={{overflow: 'scroll'}}>
                                {questions.map((quest, i) => {
                                if (currentQuestion !== i){
                                    return
                                }
                                return(
                                    <div key={i}>
                                        <p>{quest.question}</p>
                                        <div onChange={answerSelected}>
                                            {quest.answers.AnswerDocument.map((ans, j) => {
                                                return(
                                                    <div key={`a${j}`}>
                                                        <input className="checkbox-tools" name={`answer-${i}`} type="radio" id={`${i}-answer-${j}`} value={JSON.stringify(ans)}/>
                                                        <label htmlFor={`${i}-answer-${j}`}>{ans.Answer}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                                {(currentQuestion === (questions.length - 1) && answers[(questions.length - 1)] !== -1)? 
                                <Button block size="lg" onClick={submitAnswers}>Submit answers &gt;</Button>:
                                null
                                }
                                {(currentQuestion < (questions.length - 1) && answers[currentQuestion] !== -1)? 
                                <Button block size="lg" onClick={nextQuestion}>Next question</Button>:
                                null
                                }
                            </Form>}
                        </CardBody>
                    </Card>
                    </div>

                </View>
            );
        }
    // }
})

export default VerifyIdFallback
import React from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import Client from "../../pages/adminDash/client";
const LVLLogo = require('../../assets/images/LVLLogo.png');

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COLN_WIDTH = 100/10

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  HeaderText:{
    fontSize: 12
  },
  ClientHeaderText:{
    fontSize: 12,
    textDecoration: "underline",
  },
  HeaderTextRight:{
    fontSize: 12,
    textAlign: 'right'
  },
  SubHeaderText:{
    fontSize: 10
  },
  logo: {
    width: 100
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    marginTop: 15
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },     
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  totalTableCol: { 
    width: ((100 / 7) * 6) + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 2, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  totalTableColAmount: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 2, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 12,
    fontWeight: 500,
    wordWrap: true
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  },
  tableCellTotal: { 
    margin: 5, 
    fontSize: 12,
    fontWeight: 'bold'
  },
  BankingDetailsView:{
	border: 1,
    padding: 5,
    marginTop: 15,
    borderRadius: 10,
    width: 60 + '%',
    alignSelf: 'center'
  },
  BankingDetailSection:{
    flexDirection: 'row'
  },
});

const AllAdvancesPDF = ({employeeAdvances, fromDate, toDate}) => {
  let totalAdvances = employeeAdvances.reduce((totalAdv, currentValue) => {
    return totalAdv + currentValue.AdvanceAmount; 
  }, 0);
  let totalFee = employeeAdvances.reduce((totalF, currentValue) => {
    return totalF + currentValue.AdvanceFee; 
  }, 0);
  let totalMonthlyFee = employeeAdvances.reduce((totalF, currentValue) => {
    return totalF + currentValue.monthlyFee; 
  }, 0);
  let finalFees = totalFee + totalMonthlyFee;
  let vat = finalFees * 15 / 100;
  let finalAmount = (totalAdvances + totalFee + totalMonthlyFee + vat).toFixed(2);
  return (
    <Document>
      <Page style={styles.body} orientation="landscape">
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <Image src={LVLLogo} style={{width: '30%'}}/>
          <Text>{`${fromDate.toLocaleDateString()} - ${toDate.toLocaleDateString()}`}</Text>
        </div>
        <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Date</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>ID Number</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Name</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Company</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Method</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Advance</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Fee</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Monthly Fee</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Total (VAT excl.)</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Total (VAT incl.)</Text> 
          </View> 
        </View>

        {employeeAdvances.map((advance) => {
            let dte = (new Date(advance.AdvanceRequestDate)).toLocaleDateString();
            let totalDeduction = (Number(advance.TotalAdvances) + Number(advance.TotalFee) + Number(advance.subAmount) + (Number(advance.subAmount) * 15 / 100)).toFixed(2);
            return (
                <View style={styles.tableRow}> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{dte}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.IDNumber}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.FirstName} {advance.LastName}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.company}</Text> 
                    </View> 
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{advance.method}</Text> 
                    </View>
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.AdvanceAmount}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.AdvanceFee}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.monthlyFee}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{advance.AdvanceAmount + advance.AdvanceFee}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{(advance.AdvanceAmount + advance.AdvanceFee + advance.monthlyFee + ((advance.AdvanceFee + advance.monthlyFee) * 15 / 100)).toFixed(2)}</Text> 
                    </View> 
                </View>   
            )
            })}
            {/* <View style={styles.tableRow}> 
                <View style={styles.totalTableCol}> 
                    <Text style={styles.tableCellTotal}>Total:</Text> 
                </View> 
                <View style={styles.totalTableColAmount}> 
                    <Text style={styles.tableCellTotal}>{finalAmount}</Text> 
                </View> 
            </View>   */}
            <View style={styles.tableRow}> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCellTotal}>Total</Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCellTotal}></Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCellTotal}></Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCellTotal}></Text> 
              </View> 
              <View style={styles.tableCol}>
                  <Text style={styles.tableCellTotal}></Text> 
              </View>
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCellTotal}>{totalAdvances}</Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCellTotal}>{totalFee}</Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCellTotal}>{totalMonthlyFee}</Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCellTotal}>{totalAdvances + totalFee + totalMonthlyFee}</Text> 
              </View> 
              <View style={styles.tableCol}> 
                  <Text style={styles.tableCellTotal}>{finalAmount}</Text> 
              </View> 
          </View>

        </View>

      </Page>
    </Document>
  );
};

export default AllAdvancesPDF;
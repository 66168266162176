import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card  } from '../../../components/basic';
import { View, CustomInput } from '../../../shared/components';


import '../../style.scss';
import './style.scss';
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");


const Settings = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [OldPassword, setOldPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');

    const [WebauthnAvailable, setWebauthnAvailable] = useState(true);

    useEffect(() => {
        if (window.PublicKeyCredential){
            if (!window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable){
                setWebauthnAvailable(false);
            }else{
                setWebauthnAvailable(true);
            }
        }else{
            setWebauthnAvailable(false);
        }
    }, []);

    function passwordChanged(e){
        if (e.target.id === 'oldPassword'){
            setOldPassword(e.target.value);
        }
        if (e.target.id === 'newPassword'){
            setNewPassword(e.target.value);
        }
        if (e.target.id === 'confirmPassword'){
            setConfirmPassword(e.target.value);
        }
    }

    function changePassword(){
        if (OldPassword === ''){
            window.alert('Please enter your old password.');
            return
        }

        if (NewPassword === ''){
            window.alert('Please enter a new password.');
            return
        }

        if (!strongRegex.test(NewPassword)){
            window.alert('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return
        }

        if (ConfirmPassword === ''){
            window.alert('Please confirm your new password.');
            return
        }

        if (NewPassword !== ConfirmPassword){
            window.alert('Passwords do not match.');
            return
        }

        let data = {
            oldPassword: OldPassword,
            newPassword: NewPassword
        }

        commonStore.setLoading(true);
        commonStore.changePassword(data).then((ret) => {
            if (!ret){
                window.alert('Password change failed. Please try again.');
                commonStore.setLoading(false)
            }else{
                window.alert('Password changed successfully.');
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                commonStore.setLoading(false)
            }
        })
    }

    function addAuthenticationClicked(){
        commonStore.setLoading(true);
        commonStore.addWebauthn().then((ret) => {
            console.log(ret);
            if (ret !== true){
                alert('Unable to add device authentication.');
                commonStore.setLoading(false);
                return
            }
            alert('Authentication added successfully');
            commonStore.setLoading(false);
        })
    }

    
        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%', padding: '10px', overflow: 'scroll', display: 'flex', flexDirection: 'column'}}>
                    <p><b>Change Password</b></p>
                    <CustomInput type='password' id='oldPassword' value={OldPassword} placeholder='Old Password' onChange={passwordChanged}/>
                    <CustomInput type='password' id='newPassword' value={NewPassword} placeholder='New Password' onChange={passwordChanged}/>
                    <CustomInput type='password' id='confirmPassword' value={ConfirmPassword} placeholder='Confirm Password' onChange={passwordChanged}/>
                    <Button onClick={changePassword} style={{marginBottom: '10px'}}>Change Password</Button>
                    <p><b>Device Authentication</b></p>
                    {(WebauthnAvailable) ? <Button onClick={addAuthenticationClicked}>Add Authentication</Button> : <p>Device authentication is not available on this device.</p>}
                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Settings</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <hr/>

                        <Card className='AdminClientCard'>
                            <p><b>Change Password</b></p>
                            <CustomInput type='password' id='oldPassword' value={OldPassword} placeholder='Old Password' onChange={passwordChanged}/>
                            <CustomInput type='password' id='newPassword' value={NewPassword} placeholder='New Password' onChange={passwordChanged}/>
                            <CustomInput type='password' id='confirmPassword' value={ConfirmPassword} placeholder='Confirm Password' onChange={passwordChanged}/>
                            <Button onClick={changePassword}>Change Password</Button>
                        </Card>

                        <Card className='AdminClientCard'>
                            <p><b>Device Authentication</b></p>
                            {(WebauthnAvailable) ? <Button onClick={addAuthenticationClicked}>Add Authentication</Button> : <p>Device authentication is not available on this device.</p>}
                            
                        </Card>
                       
                    </div>

                </View>
            );
        
    }
})

export default Settings;
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card, Form } from '../../../components/basic';
import { View, CustomInput } from '../../../shared/components';
import { FaTimes } from 'react-icons/fa';



import '../../style.scss';
import './style.scss';

const DeleteEmployees = observer(() => {

    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [IDNumber, setIDNumber] = useState('');
    const [IDNumbers, setIDNumbers] = useState([]);
    const [Employees, setEmployees] = useState([]);



    function IDNumberChanged(e){
        setIDNumber(e.target.value);
    }

    function addIDNumber(){
        commonStore.setLoading(true);
        commonStore.getEmployee(IDNumber).then((ret) => {
            console.log(ret);
            if (!ret || ret === 'false' || ret === 'False' || ret === false){
                window.alert('Employee does not exist.');
                commonStore.setLoading(false);
                return
            }
            if (IDNumbers.includes(IDNumber)){
                window.alert('Employee already in list');
                commonStore.setLoading(false);
                return
            }
            let numbers = IDNumbers;
            numbers.push(IDNumber);
            let emps = Employees;
            emps.push(ret);
            setIDNumbers(numbers);
            setEmployees(emps);
            setIDNumber('');
            forceUpdate();
            commonStore.setLoading(false);
        })
    }

    function removeEmployee(e) {
        let index = Number(e.currentTarget.id);
        let emp = Employees;
        let numbers = IDNumbers;
        emp.splice(index, 1);
        numbers.splice(index, 1);
        setIDNumbers(numbers);
        setEmployees(emp);
        forceUpdate();
      }

      function deleteEmployees(){
        let verify = window.confirm(`You are about to delete ${IDNumbers.length} employees. This cannot be undone`);

        if (!verify){
            return
        }

        commonStore.setLoading(true);
        commonStore.bulkDeleteEmployees(IDNumbers).then((ret) => {
            console.log(ret);
            setIDNumbers([]);
            setEmployees([]);
            window.alert('Complete');
            forceUpdate();
            commonStore.setLoading(false);
        })
      }

        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%', padding: '10px', overflow: 'scroll'}}>
                    <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Delete Employees</p>
                    <CustomInput type='text' id='IDNumber' value={IDNumber} placeholder='ID/Passport number' onChange={IDNumberChanged} onEnter={addIDNumber} />
                    <Button className="transparentButton" block size="sm" style={{marginBottom: '10px'}} onClick={addIDNumber}>Add</Button>
                    {(IDNumbers.length > 0) ? <Button block size="sm" style={{marginTop: '10px'}} onClick={deleteEmployees}>Delete</Button> : null}
                    <hr/>
                    {IDNumbers.map((idnumber, index) => {
                        return (
                            <Card key={index} id={index} className="deleteEmpCard">
                                <p id={index}>{`${idnumber} - ${Employees[index].employeeinfo?.FirstName || ''} ${Employees[index].employeeinfo?.LastName || ''}`}</p>
                                <FaTimes id={index} onClick={removeEmployee} style={{cursor: 'pointer'}}/>
                            </Card>
                        )
                    })}
                </div>

                
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Delete Employees <span style={{color: 'red'}}>DO NOT USE FOR EMPLOYEES WITH PREVIOUS ADVANCES</span></p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <div style={{width: '60%'}}>
                                <CustomInput type='text' id='IDNumber' value={IDNumber} placeholder='ID/Passport number' onChange={IDNumberChanged} onEnter={addIDNumber} />
                            </div>
                            <Button className="transparentButton" block size="sm" style={{width: '10%'}} onClick={addIDNumber}>Add</Button>
                            {(IDNumbers.length > 0) ? <Button block size="sm" style={{width: '10%'}} onClick={deleteEmployees}>Delete</Button> : null}
                        </div>
                        <hr/>
                        {IDNumbers.map((idnumber, index) => {
                            return (
                                <Card key={index} id={index} className="deleteEmpCard">
                                    <p id={index}>{`${idnumber} - ${Employees[index].employeeinfo?.FirstName || ''} ${Employees[index].employeeinfo?.LastName || ''}`}</p>
                                    <FaTimes id={index} onClick={removeEmployee} style={{cursor: 'pointer'}}/>
                                </Card>
                            )
                        })}
                    </div>

                </View>
            );
        }
        
})

export default DeleteEmployees;
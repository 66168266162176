const BaselineAssessmentQuestions = {
    Demographic: {
        category: "Demographic",
        question: "Which demographic do you fall within",
        answerPrefix: "",
        options: [
            { answer: "Gen Z (1997-2012)", score: null },
            { answer: "Millennial (1981-1996)", score: null },
            { answer: "Gen X (1965-1980)", score: null },
            { answer: "Baby Boomers (1946-1964)", score: null },
        ],
    },

    EmploymentLength: {
        category: "EmploymentLength",
        question: "How long have you been employed?",
        answerPrefix: "",
        options: [
            { answer: "Less than a year", score: null },
            { answer: "1-3 years", score: null },
            { answer: "3-6 years", score: null },
            { answer: "6-10 years", score: null },
            { answer: "10-15 years", score: null },
            { answer: "15+ years", score: null }
        ],
    },

    HouseholdBudget: {
        category: "HouseholdBudget",
        question: "Does your household have a budget?",
        answerPrefix: "",
        options: [
            { answer: "Yes", score: null },
            { answer: "No", score: null },
            { answer: "Maybe", score: null },
        ],
    },

    HouseholdBudgetDiscipline: {
        category: "HouseholdBudgetDiscipline",
        question: "Does your household stick to your budget?",
        answerPrefix: "",
        options: [
            { answer: "Yes", score: null },
            { answer: "No", score: null }
        ],
    },

    Spend: [
        {
            category: "Spend",
            question: "Which of the following statements best describes how your household's total spending compared to total income, over the last 12 months?",
            answerPrefix: "",
            options: [
                { answer: "Spending was much less than income", score: 100 },
                { answer: "Spending was a little less than income", score: 75 },
                { answer: "Spending was about equal to income", score: 50 },
                { answer: "Spending was a little more than income", score: 25 },
                { answer: "Spending was much more than income", score: 0 }
            ],
        },
        {
            category: "Spend",
            question: "Which of the following statements best describes how your household has paid its bills over the last 12 months?",
            answerPrefix: "My household has been financially able to:",
            options: [
                { answer: "Pay all of our bills on time", score: 100 },
                { answer: "Pay nearly all of our bills on time", score: 60 },
                { answer: "Pay most of our bills on time", score: 40 },
                { answer: "Pay some of our bills on time", score: 20 },
                { answer: "Pay very few of our bills on time", score: 0 }
            ],
        },
    ],
    Save: [
        {
            category: "Save",
            question: "At your current level of spending, how long could you and your household afford to cover expenses, if you had to live on only the money you have readily available, without withdrawing money from retirement accounts or borrowing?",
            answerPrefix: "",
            options: [
                { answer: "6 months or more", score: 100 },
                { answer: "3-5 months", score: 75 },
                { answer: "1-2 months", score: 50 },
                { answer: "1-3 weeks", score: 25 },
                { answer: "Less than 1 week", score: 0 }
            ],
        },
        {
            category: "Save",
            question: "Thinking about your household's longer-term financial goals, such as saving for a vacation, starting a business, buying or paying off a home, saving up for education, putting money away for retirement, or making retirement funds last…",
            answerPrefix: "How confident are you that your household is currently doing what is needed to meet your longer-term goals?",
            options: [
                { answer: "Very confident", score: 100 },
                { answer: "Moderately confident", score: 75 },
                { answer: "Somewhat confident", score: 50 },
                { answer: "Slightly confident", score: 25 },
                { answer: "Not at all confident", score: 0 },
            ],
        },
    ],
    Borrow: [
        {
            category: "Borrow",
            question: "Thinking about all of your household's current debts, including mortgages, bank loans, student loans, money owed to people, medical debt, past-due bills, and credit card balances that are carried over from prior months…",
            answerPrefix: "As of today, which of the following statements describes how manageable your household debt is?",
            options: [
                { answer: "Do not have any debt", score: 100 },
                { answer: "Have a manageable amount of debt", score: 85 },
                { answer: "Have a bit more debt than is manageable", score: 40 },
                { answer: "Have far more debt than is manageable", score: 0 }
            ],
        },
        {
            category: "Borrow",
            question: "How would you rate your credit score?",
            answerPrefix: "Your credit score is a number that tells lenders how risky or safe you are as a borrower.",
            options: [
                { answer: "Excellent", score: 10 },
                { answer: "Very Good", score: 80 },
                { answer: "Good", score: 60 },
                { answer: "Fair", score: 40 },
                { answer: "Poor", score: 20 },
                { answer: "I don't know", score: 0 }
            ],
        },
    ],
    Plan: [
        {
            category: "Plan",
            question: "Thinking about all of the types of insurance you and others in your household currently might have, including health insurance, vehicle insurance, home or rental insurance, life insurance, and disability insurance…",
            answerPrefix: "How confident are you that those insurance policies will provide enough support in case of an emergency?",
            options: [
                { answer: "Very confident", score: 100 },
                { answer: "Moderately confident", score: 75 },
                { answer: "Somewhat confident", score: 50 },
                { answer: "Slightly confident", score: 25 },
                { answer: "Not at all confident", score: 10 },
                { answer: "No one in my household has any insurance", score: 0 },
            ],
        },
        {
            category: "Plan",
            question: "To what extent do you agree or disagree with the following statement:",
            answerPrefix: '"My household plans ahead financially."',
            options: [
                { answer: "Agree strongly", score: 100 },
                { answer: "Agree somewhat", score: 65 },
                { answer: "Neither agree nor disagree", score: 35 },
                { answer: "Disagree somewhat", score: 15 },
                { answer: "Disagree strongly", score: 0 }
            ],
        },
    ],

    HouseholdIncome: {
        category: "HouseholdIncome",
        question: "What is your approximate annual household income?",
        answerPrefix: "",
        options: [
            { answer: "Less than a 50 000 net per annum", score: null },
            { answer: "50 000 - 150 000 net per annum", score: null },
            { answer: "150 000 - 300 000 net per annum", score: null },
            { answer: "300 000 - 500 000 net per annum", score: null },
            { answer: "500 000+", score: null },
        ],
    },

    FinancialStress: {
        category: "FinancialStress",
        question: "Over the last 12 months, how much stress, if any, did your finances cause you?",
        answerPrefix: "",
        options: [
            { answer: "High stress", score: null },
            { answer: "Moderate", score: null },
            { answer: "Some stress", score: null },
            { answer: "None", score: null }
        ],
    },

    FinancialStressImpact: {
        category: "FinancialStressImpact",
        question: "Over the last 12 months, how much stress, if any, did your finances cause you?",
        answerPrefix: "",
        options: [
            { answer: "Very often", score: null },
            { answer: "Often", score: null },
            { answer: "Seldom", score: null },
            { answer: "Never", score: null }
        ],
    },

};

export default BaselineAssessmentQuestions;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';
import baselineIcon from '../../../assets/icons/baseline-assesment svg.svg'

import './style.scss';

const Dash = observer(({ setWithdraw }) => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [amountAvailable, setAmountAvailable] = useState(1);
    const [amountAllowed, setAmountAllowed] = useState(0);
    const [assessments, setAssessments] = useState([])

    useEffect(() => {

        // if (!commonStore.amountAllowed || !commonStore.amountAvailable || commonStore.amountAllowed === -1 || commonStore.amountAvailable === -1) {
        //     getHistory();
        // } else {
        //     loadData();
        // }

        getHistory();
        getAssessments();

    }, []);

    function getHistory() {
        commonStore.setLoading(true);
        commonStore.getAdvanceHistory().then((ret) => {
            setAmountAvailable(ret.amountAvailable);
            setAmountAllowed(ret.amountAllowed);
            commonStore.setLoading(false);
        });
    }

    const getAssessments = () => {
        // let { employeeDetail } = commonStore;
        console.log(commonStore.employeeDetail);
        commonStore.getBaselineAssessmentsById(commonStore.employeeDetail.IDNumber).then((ret) => {
            let assessments = ret;
            let Arr = [];
            if (Object.keys(assessments).length > 0) {
                if (!assessments.IDNumber || !assessments.BaselineAssessments || assessments.BaselineAssessments.length === 0) {
                    return
                }
                else {
                    assessments.BaselineAssessments.forEach((assess) => {
                        if (!assess.SpendScore) {
                            return
                        }
                        else {
                            Arr.push(assess)
                        }
                    })

                }
            }

            else {
                return
            }
            setAssessments(Arr);
        })
    }

    const getScoreColor = (score) => {
        if (score >= 80) {
            return '#76E1CE';
        } else if (score >= 40) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    function loadData() {
        setAmountAllowed(commonStore.amountAllowed);
        setAmountAvailable(commonStore.amountAvailable);
    }

    if (isMobile()) {
        return (
            <View>
                <div className="viewContent view-pages view-session d-flex flex-column">
                    <header>
                        <p className="txtlead txtLeft">Dashboard</p>
                    </header>

                    <div id="onDemandPay" style={{ paddingBottom: '0px' }}>
                        <p className="txtsub txtLeft"><i style={{ paddingRight: '5px', fontWeight: 'bold' }} className="fa fa-wallet"></i>On-demand pay</p>
                        <Card id="dashCard">
                            <div style={{ padding: '10px' }}>
                                <svg viewBox="13 0 100 75" xmlns="http://www.w3.org/2000/svg">
                                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
                                        <stop offset="0%" stopColor="#56c4fb" />
                                        <stop offset="100%" stopColor="#0baeff" />
                                    </linearGradient>
                                    <path className="grey" d="M22 61A40 40 0 11104 61" fill='none' />
                                    <g filter="url(#filter0_d_2414_15661)">
                                        <path id="blue" fill='none' strokeDashoffset={130 - (130 * (parseInt(amountAllowed) / parseInt(amountAvailable)))} className="blue" d="M22 61A40 40 0 11104 61" />
                                    </g>
                                    <text className="withdrawTxtHead" x="64%" y="65%" dominantBaseline="middle" textAnchor="middle">{`R ${amountAllowed.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}</text>
                                    <text className="withdrawTxtSub" x="64%" y="80%" dominantBaseline="middle" textAnchor="middle">Available balance</text>
                                    <defs>
                                        <filter id="filter0_d_2414_15661" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="0" dy="0" />
                                            <feGaussianBlur stdDeviation="10" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.223529 0 0 0 0 0.580392 0 0 0 0.4 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2414_15661" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2414_15661" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                                <Button className=" border-radius-button transparentButton" block size="lg" onClick={setWithdraw} style={{ marginTop: '10px' }}>Withdraw now</Button>
                            </div>
                        </Card>
                        <hr style={{ marginTop: '10%' }} />
                    </div>

                    <div id="assessmentsHistory">


                        {assessments.length > 0 && (
                            <>
                                <header>
                                    <p className="txtlead txtLeft">Financial Health</p>
                                </header>
                                <p className="txtsub txtLeft"><i style={{ paddingRight: '5px', fontWeight: '800', fontSize: "larger" }} className="fa fa-wallet"></i>Assessments History</p>
                                <Card id="dashCard"
                                    style={{
                                        marginBottom: '50px', background: 'linear-gradient(45deg, #4c1ba5, #eb4c94)',
                                        boxShadow: '0 4px 20px rgba(147, 51, 234, 0.3)'
                                    }}>
                                    <div style={{ padding: '10px' }}>

                                        <div className='assessmentHistory-container'>
                                            {assessments.map((assessment, index) => (
                                                <div key={index}>
                                                    <p style={{ fontSize: 'x-small', color: '#ffffff', fontWeight: '600' }}>Date Taken: {new Date(assessment.DateTaken).toLocaleDateString()}</p>
                                                    <p className="assessmentHistory-scores">
                                                        Scores
                                                        <hr />
                                                        <span>Spend - <span style={{ textShadow: '2px 2px #584141', fontWeight: '600', color: getScoreColor(assessment.SpendScore) }}>{assessment.SpendScore}</span></span>
                                                        <span>Save - <span style={{ textShadow: '2px 2px #584141', fontWeight: '600', color: getScoreColor(assessment.SaveScore) }}>{assessment.SaveScore}</span></span>
                                                        <span>Borrow - <span style={{ textShadow: '2px 2px #584141', fontWeight: '600', color: getScoreColor(assessment.BorrowScore) }}>{assessment.BorrowScore}</span></span>
                                                        <span>Plan - <span style={{ textShadow: '2px 2px #584141', fontWeight: '600', color: getScoreColor(assessment.PlanScore) }}>{assessment.PlanScore}</span></span>
                                                        <span>Overall - <span style={{ textShadow: '2px 2px #584141', fontWeight: '600', color: getScoreColor(assessment.OverallScore) }}>{assessment.OverallScore}</span></span>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>

                                    </div>

                                </Card>
                            </>
                        )}
                    </div>

                </div>

            </View>
        );
    } else {
        return (
            <View style={{ backgroundColor: '#ffffff' }}>
                <header style={{ backgroundColor: '#ffffff' }}>
                    <div>
                        <p className="txtlead txtLeft" style={{ marginBottom: '0px' }}>Dashboard</p>
                    </div>
                </header>
                <div id="onDemandPay" style={{ backgroundColor: '#ffffff' }}>
                    <div className="desktopDashHeader">
                        <p className="txtsub txtLeft"><i style={{ paddingRight: '5px' }} className="fa fa-wallet"></i>On-demand pay</p>
                        <Button className="transparentButton" block size="sm" onClick={setWithdraw} style={{ maxWidth: '20%' }}>Withdraw</Button>
                    </div>
                    <hr />
                    <div className="progressBarContainer">
                        <div className="onDemandBase"></div>
                        <div className="onDemandProgress" style={{ width: `${parseInt(amountAllowed) / parseInt(amountAvailable) * 100}%` }}></div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div></div>
                            <p className="withdrawTxtDesktop"><span style={{ color: '#868685', fontSize: '100%' }}>Amount available: </span>{`R${amountAllowed}`}</p>
                        </div>

                    </div>

                </div>

            </View>
        );
    }

})

export default Dash;
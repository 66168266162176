import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Card } from '../../../components/basic';
import { View } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

const Home = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [Advances, setAdvances] = useState([]);
    const [TotalAdvances, setTotalAdvances] = useState(0);
    const [TotalFees, setTotalFees] = useState(0);

    useEffect(() => {
        loadAdvances();
    }, []);

    function loadAdvances(){
        commonStore.setLoading(true);
        commonStore.getAdvancesForClient(commonStore.user.businessentity._id).then((ret) => {
            let successfulAdvances = ret.advances.filter(adv => adv.transactionhistory && adv.transactionhistory.TransactionStatus !== 'Failed')
            setAdvances(successfulAdvances);
            let adv = 0;
            let fee = 0;
            for (let i = 0; i < successfulAdvances.length; i++){
                adv += successfulAdvances[i].AdvanceAmount;
                fee = successfulAdvances[i].AdvanceFee;
            }
            setTotalAdvances(adv);
            setTotalFees(fee);
            commonStore.setLoading(false);
        })
    }



        if (isMobile()){
            return(
                <View>
                    
                </View>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Home</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <p className="txtsub txtLeft">{commonStore.user.businessentity.name || ''}</p>
                        </div>
                        <hr/>
                        <Card className='clientHomeCard'>
                            <div>
                                <p>Total Advances</p>
                                <p>{`R${TotalAdvances}`}</p>
                            </div>
                        </Card>
                    </div>

                </View>
            );
        }
        
})

export default Home;
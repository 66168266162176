import React, { useRef, useState } from 'react';
import PropTypes from "prop-types"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';


function ReportingNewUsesGraph({className, id, offset=0, advances = [], period='weekly', style={}, dataItems=6, company='all', segment='all'}) {
  const chartRef = useRef();
  const [SelectedData, setSelectedData] = useState(-1);
  let customStyle = style;
  let newUsers = [];
  let newAdvances = [];
  let newFees = [];
  let labels = [];



  if (period === 'weekly'){
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const timeDiff = (currentDay === 0 ? 6 : currentDay - 1) * 24 * 60 * 60 * 1000;
    const mondayDate = new Date(currentDate.getTime() - timeDiff);
    mondayDate.setHours(0, 0, 0, 0);

    for (let i = 0; i < dataItems; i++){
      let currentMonday = new Date(mondayDate.getTime() - 7 * i * 24 * 60 * 60 * 1000)
      let currentSunday = new Date(currentMonday.getTime() + 6 * 24 * 60 * 60 * 1000);
      currentSunday.setHours(23, 59, 59, 999);
      let advancesThisWeek = advances.filter((adv) => (new Date(adv.AdvanceRequestDate)).getTime() >= currentMonday.getTime() && (new Date(adv.AdvanceRequestDate)).getTime() <= currentSunday.getTime())
      let previousAdvances = advances.filter((adv) => (new Date(adv.AdvanceRequestDate)).getTime() < currentMonday.getTime());
      let users = 0;
      let weeklyAdvances = 0;
      let fees = 0;
      let IDNumbers = [];

      for (let j = 0; j < advancesThisWeek.length; j++){
        
        let currAdv = advancesThisWeek[j];
        if (company !== 'all' && company !== currAdv.businessentity){
          console.log(company)
          continue;
        }
        let prevUser = previousAdvances.filter((item) => item.IDNumber === currAdv.IDNumber);
        if (prevUser.length > 0){
          continue;
        }
        if (!IDNumbers.includes(currAdv.IDNumber)){
          users += 1;
        }
        IDNumbers.push(currAdv.IDNumber);
        weeklyAdvances += currAdv.AdvanceAmount;
        fees += currAdv.AdvanceFee;
      }
      newUsers.unshift(users);
      newFees.unshift(fees);
      newAdvances.unshift(weeklyAdvances)
      labels.unshift(currentMonday.toDateString()); 
    }
  }

  if (period === 'monthly'){
    for (let i = 0; i < dataItems; i++){
      let date = new Date();
      date.setMonth(date.getMonth() - i);
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      let nextMonth = new Date(date);
      nextMonth.setMonth(nextMonth.getMonth() + 1);

      let advancesThisWeek = advances.filter((adv) => (new Date(adv.AdvanceRequestDate)).getTime() >= date.getTime() && (new Date(adv.AdvanceRequestDate)).getTime() < nextMonth.getTime())
      let previousAdvances = advances.filter((adv) => (new Date(adv.AdvanceRequestDate)).getTime() < date.getTime());
      let users = 0;
      let weeklyAdvances = 0;
      let fees = 0;
      let IDNumbers = [];

      for (let j = 0; j < advancesThisWeek.length; j++){
        
        let currAdv = advancesThisWeek[j];
        if (company !== 'all' && company !== currAdv.businessentity){
          continue;
        }
        let prevUser = previousAdvances.filter((item) => item.IDNumber === currAdv.IDNumber);
        if (prevUser.length > 0){
          continue;
        }
        if (!IDNumbers.includes(currAdv.IDNumber)){
          users += 1;
        }
        IDNumbers.push(currAdv.IDNumber);
        weeklyAdvances += currAdv.AdvanceAmount;
        fees += currAdv.AdvanceFee;
      }
      newUsers.unshift(users);
      newFees.unshift(fees);
      newAdvances.unshift(weeklyAdvances)
      let month = date.getMonth();
      let year = date.getFullYear();
      switch (month) {
        case 0:
          month = 'January';
          break;
        case 1:
          month = 'February';
          break;
        case 2:
          month = 'March';
          break;
        case 3:
          month = 'April';
          break;
        case 4:
          month = 'May';
          break;
        case 5:
          month = 'June';
          break;
        case 6:
          month = 'July';
          break;
        case 7:
          month = 'August';
          break;
        case 8:
          month = 'September';
          break;
        case 9:
          month = 'October';
          break;
        case 10:
          month = 'November';
          break;
        case 11:
          month = 'December';
          break;
        default:
          month = 'Invalid month';
      }
      labels.unshift(`${month} ${year}`);
      
    }
  }




      ChartJS.register(
          CategoryScale,
          LinearScale,
          BarElement,
          Title,
          Tooltip,
          Legend
      );

      const options = {
          responsive: true,
          onClick: (e, elements) => {
            if (elements.length > 0){
              setSelectedData(elements[0]?.index);
            }
            
          },
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: `${period} new uses`,
            },
          },
        };

        const data = {
          labels,
          datasets: [
            {
              label: 'Users',
              data: newUsers,
              backgroundColor: '#0e3681',
            },
            {
              label: 'Advances',
              data: newAdvances,
              backgroundColor: '#772FD7',
            },
            {
              label: 'Fees',
              data: newFees,
              backgroundColor: '#ff64ab',
            }
          ],
        };
      return(
        <div style={customStyle}>
          <Bar options={options} data={data} ref={chartRef} />
          {(SelectedData === -1)? null : 
          <div>
            <p><b>Users:</b> {`${newUsers[SelectedData] || ''}`}</p>
            <p><b>Advances:</b> R{`${newAdvances[SelectedData].toFixed(2) || ''}`}</p>
            <p><b>Fees:</b> R{`${newFees[SelectedData].toFixed(2) || ''}`}</p>
          </div>}
        </div>
        
      ) 
    }

    



export default ReportingNewUsesGraph;
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button } from '../../../components/basic';
import { View, CustomInput } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

const NewEmployee = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [Clients, setClients] = useState([]);
    const [IDNumber, setIDNumber] = useState('');
    const [Client, setClient] = useState(-1);

    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');

    const [Contact, setContact] = useState('');
    const [Email, setEmail] = useState('');

    const [BankAccount, setBankAccount] = useState({
        IDNumber: '',
        accountnumber: '',
        bankname: 'ABSA',
        branchcode: '632005',
        branchname: '',
        type: ''
    });

    const [Amount, setAmount] = useState(0);
    const [ElectedAdvancePerc, setElectedAdvancePerc] = useState(25);
    const [FeeAmount, setFeeAmount] = useState(3);
    const [PayDate, setPayDate] = useState(25);

    useEffect(() => {
        loadClients();
    }, []);

    function loadClients(){
        commonStore.setLoading(true);
        commonStore.getAllClients().then((ret) => {

            setClients(ret);
            commonStore.setLoading(false);
        })
    }

    function valueChanged(e){
        if (e.currentTarget.id === 'IDNumber'){
            setIDNumber(e.target.value);
        }

        if (e.currentTarget.id === 'FirstName'){
            setFirstName(e.target.value);
        }

        if (e.currentTarget.id === 'LastName'){
            setLastName(e.target.value);
        }

        if (e.currentTarget.id === 'Contact'){
            setContact(e.target.value);
        }

        if (e.currentTarget.id === 'Email'){
            setEmail(e.target.value);
        }

        if (e.currentTarget.id === 'Amount'){
            setAmount(Number(e.target.value));
        }

        if (e.currentTarget.id === 'FeeAmount'){
            setFeeAmount(Number(e.target.value));
        }

        if (e.currentTarget.id === 'ElectedAdvancePerc'){
            setElectedAdvancePerc(Number(e.target.value));
        }

        if (e.currentTarget.id === 'PayDate'){
            setPayDate(Number(e.target.value));
        }
    }

    function clientChanged(e){
        setClient(Number(e.target.value));
    }

    function bankAccountChanged(e){
        let acc = BankAccount;
        if (e.currentTarget.id === 'accNum'){
            acc.accountnumber = e.target.value;
        }

        if (e.currentTarget.id === 'bName'){
            acc.branchname = e.target.value;
        }

        if (e.currentTarget.id === 'accType'){
            acc.type = e.target.value;
        }

        setBankAccount(acc);
        forceUpdate();
    }

    function bankNameChanged(e){
        let acc = BankAccount;
        acc.bankname = e.target.value;
        acc.branchcode = getBranchCode(e.target.value);
        setBankAccount(acc);
        forceUpdate();
    }

    function getBranchCode(bank){
        var branchCode;
        switch(bank){
            case 'ABSA': branchCode = '632005'; break;
            case 'Access Bank': branchCode = ''; break;
            case 'African Bank': branchCode = '430000'; break;
            case 'Albaraka Bank': branchCode = '800000'; break;
            case 'Bank Of Athens': branchCode = '410506'; break;
            case 'Bank Windhoek': branchCode = ''; break;
            case 'Bidvest Bank': branchCode = '462005'; break;
            case 'Capitec Bank': branchCode = '470010'; break;
            case 'CitiBank': branchCode = '350005'; break;
            case 'Diamond Bank': branchCode = ''; break;
            case 'Discovery Bank': branchCode = '679000'; break;
            case 'FirstBank': branchCode = ''; break;
            case 'FNB Namibia': branchCode = ''; break;
            case 'FNB': branchCode = '250655'; break;
            case 'GTBank': branchCode = ''; break;
            case 'HSBC Bank': branchCode = '587000'; break;
            case 'Investec': branchCode = '580105'; break;
            case 'Mercantile Bank': branchCode = '450105'; break;
            case 'Nedbank': branchCode = '198765'; break;
            case 'Olympus Mobile': branchCode = '585001'; break;
            case 'RMB': branchCode = '250655'; break;
            case 'Sasfin Bank': branchCode = '683000'; break;
            case 'Standard Bank': branchCode = '051001'; break;
            case 'Standard Bank Namibia': branchCode = ''; break;
            case 'Standard Chartered Bank': branchCode = '730020'; break;
            case 'TymeBank': branchCode = '678910'; break;
            case 'United Bank for Africa': branchCode = ''; break;
            case 'Ubank Limited': branchCode = '431010'; break;
            case 'Zenith Bank': branchCode = ''; break;
            default: branchCode = ''; break;
        }
        return branchCode
    }

    function saveEmployee(){
        if (Client === '-1' || Client === -1){
            window.alert('Please select a company.');
            return
        }
        if (IDNumber === '' || FirstName === '' || LastName === '' || Contact === '' || Email === '' || Amount === 0 || BankAccount.accountnumber === ''){
            window.alert('Please complete all fields.');
            return
        }
        let employee = {
            IDNumber: IDNumber,
            businessentity: Clients[Number(Client)]._id,
            EmployeeNumber: '',
            JobTitle: '',
            api: 'CSV'
        }
        let employeeinfo = {
            IDNumber: IDNumber,
            FirstName: FirstName,
            LastName: LastName
        }

        let employeecontact = {
            IDNumber: IDNumber,
            Mobile: Contact,
            Landline: Contact,
            WorkEmail: Email,
            PrivateEmail: Email
        }

        let employeesalary = {
            IDNumber: IDNumber,
            SalaryFreq: 'M',
            Amount: Amount,
            PayDate: PayDate,
            ElectedAdvancePerc: ElectedAdvancePerc,
            FeeAmount: FeeAmount,
            SubscriptionFee: 10
        }

        let bankaccount = BankAccount;
        bankaccount.IDNumber = IDNumber;

        commonStore.setLoading(true);
        commonStore.createEmployee({employee: employee, employeesalary: employeesalary, employeecontact: employeecontact, employeeinfo: employeeinfo, bankaccount: bankaccount}).then((ret) => {
            if (ret === 'User Exists'){
                window.alert('This employee already exists');
                commonStore.setLoading(false);
            }else if(ret === 'Success'){
                window.alert('Employee created successfully.');
                clearData();
                commonStore.setLoading(false);
            }else{
                window.alert('An error has occured, please try again.');
                commonStore.setLoading(false);
            }
        })


    }

    function clearData() {
        setIDNumber('');
        setClient(-1);
        setFirstName('');
        setLastName('');
        setContact('');
        setEmail('');
        setBankAccount({
          IDNumber: '',
          accountnumber: '',
          bankname: 'ABSA',
          branchcode: '632005',
          branchname: '',
          type: ''
        });
        setAmount(0);
        setElectedAdvancePerc(25);
        setFeeAmount(3);
        setPayDate(25);
      }
      


        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%', padding: '10px', overflow: 'scroll'}}>
                    <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>New Employee</p>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <select value={Client} onChange={clientChanged} style={{height: '30px', width: '100%'}}>
                            <option key="-1" value="-1">Please select a client</option>
                            {Clients.map((client, i) => {
                                return(
                                    <option key={i} value={`${i}`}>{client.name}</option>
                                );
                            })}
                        </select>
                        <p style={{marginTop: '15px'}}>Employee Information</p>
                        <CustomInput type='text' id='IDNumber' value={IDNumber} placeholder='IDNumber' onChange={valueChanged}/>
                        <CustomInput type='text' id='FirstName' value={FirstName} placeholder='First Name' onChange={valueChanged}/>
                        <CustomInput type='text' id='LastName' value={LastName} placeholder='Last Name' onChange={valueChanged}/>
                        <CustomInput type='text' id='Contact' value={Contact} placeholder='Contact' onChange={valueChanged}/>
                        <CustomInput type='text' id='Email' value={Email} placeholder='Email' onChange={valueChanged}/>
                        <p style={{marginTop: '15px'}}>Employee Salary</p>
                        <CustomInput type='number' id='Amount' value={String(Amount)} placeholder='Salary Amount' onChange={valueChanged}/>
                        <CustomInput type='number' id='ElectedAdvancePerc' value={String(ElectedAdvancePerc)} placeholder='Advance Percentage' onChange={valueChanged}/>
                        <CustomInput type='number' id='FeeAmount' value={String(FeeAmount)} placeholder='Fee Percent' onChange={valueChanged}/>
                        <CustomInput type='number' id='PayDate' value={String(PayDate)} placeholder='Pay Date' onChange={valueChanged}/>
                        <p style={{marginTop: '15px'}}>Employee Bank Account</p>
                        <label className="custom-field">
                            <select className='inp' value={BankAccount.bankname} onChange={bankNameChanged} required>
                                <option value='ABSA'>ABSA</option>
                                <option value='Access Bank'>Access Bank</option>
                                <option value='African Bank'>African Bank</option>
                                <option value='Albaraka Bank'>Albaraka Bank</option>
                                <option value='Bank Of Athens'>Bank Of Athens</option>
                                <option value='Bank Windhoek'>Bank Windhoek</option>
                                <option value='Bidvest Bank'>Bidvest Bank</option>
                                <option value='Capitec Bank'>Capitec Bank</option>
                                <option value='CitiBank'>CitiBank</option>
                                <option value='Diamond Bank'>Diamond Bank</option>
                                <option value='Discovery Bank'>Discovery Bank</option>
                                <option value='FirstBank'>FirstBank</option>
                                <option value='FNB Namibia'>FNB Namibia</option>
                                <option value='FNB'>FNB</option>
                                <option value='GTBank'>GTBank</option>
                                <option value='HSBC Bank'>HSBC Bank</option>
                                <option value='Investec'>Investec</option>
                                <option value='Mercantile Bank'>Mercantile Bank</option>
                                <option value='Nedbank'>Nedbank</option>
                                <option value='Olympus Mobile'>Olympus Mobile</option>
                                <option value='RMB'>RMB</option>
                                <option value='Sasfin Bank'>Sasfin Bank</option>
                                <option value='Standard Bank'>Standard Bank</option>
                                <option value='Standard Bank Namibia'>Standard Bank Namibia</option>
                                <option value='Standard Chartered Bank'>Standard Chartered Bank</option>
                                <option value='TymeBank'>TymeBank</option>
                                <option value='United Bank for Africa'>United Bank for Africa</option>
                                <option value='Ubank Limited'>Ubank Limited</option>
                                <option value='Zenith Bank'>Zenith Bank</option>   
                            </select>
                            <span className="placeholder">Bank</span>
                        </label>
                        <CustomInput type='text' id='accNum' value={(BankAccount.accountnumber)} placeholder='Account Number' onChange={bankAccountChanged} />
                        <CustomInput type='text' id='bName' value={(BankAccount.branchname)} placeholder='Branch Name' onChange={bankAccountChanged}/>
                        <CustomInput type='text' id='accType' value={(BankAccount.type)} placeholder='Account Type' onChange={bankAccountChanged}/>
                        <Button block size="md" onClick={saveEmployee} >Save</Button>
                    </div>
                    
                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>New Employee</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <p className="txtsub txtLeft"></p>
                            <Button className="transparentButton" block size="sm" onClick={saveEmployee} style={{width: '10%'}}>Save</Button>
                        </div>
                        <hr/>
                        <div className='newEmployeeSection'>
                            <div className='newEmployeeContent'>
                                <div style={{width: '60%'}}>
                                    <CustomInput type='text' id='IDNumber' value={IDNumber} placeholder='IDNumber' onChange={valueChanged}/>
                                </div>
                                <select value={Client} onChange={clientChanged} style={{height: '30px'}}>
                                    <option key="-1" value="-1">Please select a client</option>
                                    {Clients.map((client, i) => {
                                        return(
                                            <option key={i} value={`${i}`}>{client.name}</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='newEmployeeSection'>
                            <div className='newEmployeeContent'>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='text' id='FirstName' value={FirstName} placeholder='First Name' onChange={valueChanged}/>
                                </div>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='text' id='LastName' value={LastName} placeholder='Last Name' onChange={valueChanged}/>
                                </div>
                            </div>
                        </div>

                        <div className='newEmployeeSection'>
                            <div className='newEmployeeContent'>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='text' id='Contact' value={Contact} placeholder='Contact' onChange={valueChanged}/>
                                </div>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='text' id='Email' value={Email} placeholder='Email' onChange={valueChanged}/>
                                </div>
                            </div>
                        </div>

                        <div className='newEmployeeSection'>
                            <div className='newEmployeeContent'>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='number' id='Amount' value={String(Amount)} placeholder='Salary Amount' onChange={valueChanged}/>
                                </div>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='number' id='ElectedAdvancePerc' value={String(ElectedAdvancePerc)} placeholder='Advance Percentage' onChange={valueChanged}/>
                                </div>
                                
                            </div>
                            <div className='newEmployeeContent'>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='number' id='FeeAmount' value={String(FeeAmount)} placeholder='Fee Percent' onChange={valueChanged}/>
                                </div>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='number' id='PayDate' value={String(PayDate)} placeholder='Pay Date' onChange={valueChanged}/>
                                </div>
                            </div>
                        </div>

                        <div className='newEmployeeSection'>
                            <div className='newEmployeeContent'>
                                <div style={{width: '40%'}}>
                                    <label className="custom-field">
                                            <select className='inp' value={BankAccount.bankname} onChange={bankNameChanged} required>
                                                <option value='ABSA'>ABSA</option>
                                                <option value='Access Bank'>Access Bank</option>
                                                <option value='African Bank'>African Bank</option>
                                                <option value='Albaraka Bank'>Albaraka Bank</option>
                                                <option value='Bank Of Athens'>Bank Of Athens</option>
                                                <option value='Bank Windhoek'>Bank Windhoek</option>
                                                <option value='Bidvest Bank'>Bidvest Bank</option>
                                                <option value='Capitec Bank'>Capitec Bank</option>
                                                <option value='CitiBank'>CitiBank</option>
                                                <option value='Diamond Bank'>Diamond Bank</option>
                                                <option value='Discovery Bank'>Discovery Bank</option>
                                                <option value='FirstBank'>FirstBank</option>
                                                <option value='FNB Namibia'>FNB Namibia</option>
                                                <option value='FNB'>FNB</option>
                                                <option value='GTBank'>GTBank</option>
                                                <option value='HSBC Bank'>HSBC Bank</option>
                                                <option value='Investec'>Investec</option>
                                                <option value='Mercantile Bank'>Mercantile Bank</option>
                                                <option value='Nedbank'>Nedbank</option>
                                                <option value='Olympus Mobile'>Olympus Mobile</option>
                                                <option value='RMB'>RMB</option>
                                                <option value='Sasfin Bank'>Sasfin Bank</option>
                                                <option value='Standard Bank'>Standard Bank</option>
                                                <option value='Standard Bank Namibia'>Standard Bank Namibia</option>
                                                <option value='Standard Chartered Bank'>Standard Chartered Bank</option>
                                                <option value='TymeBank'>TymeBank</option>
                                                <option value='United Bank for Africa'>United Bank for Africa</option>
                                                <option value='Ubank Limited'>Ubank Limited</option>
                                                <option value='Zenith Bank'>Zenith Bank</option>   
                                            </select>
                                            <span className="placeholder">Bank</span>
                                        </label>
                                </div>
                                <div style={{width: '40%'}}>
                                <CustomInput type='text' id='accNum' value={(BankAccount.accountnumber)} placeholder='Account Number' onChange={bankAccountChanged} />
                                </div>
                            </div>
                            <div className='newEmployeeContent'>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='text' id='bName' value={(BankAccount.branchname)} placeholder='Branch Name' onChange={bankAccountChanged}/>
                                </div>
                                <div style={{width: '40%'}}>
                                    <CustomInput type='text' id='accType' value={(BankAccount.type)} placeholder='Account Type' onChange={bankAccountChanged}/>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </View>
            );
        }
        
})

export default NewEmployee;
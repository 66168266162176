import React from 'react';
import {
    Card, CardBody
} from '../../../components/basic';

export default () => (
    <Card id="mobileTopBarCard" >
        <CardBody style={{ padding: '1px' }}>
            <header className="site-head d-flex align-items-center justify-content-between" style={{ backgroundColor: '#ffffff' }}>
                <div className="wrap mr-4">
                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" style={{ cursor: 'pointer', width: '70px' }} />
                </div>
                <div className="right-elems ml-auto d-flex">
                    {/*Empty right column */}
                </div>
            </header>
        </CardBody>
    </Card>
);
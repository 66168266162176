import React from 'react';
import PropTypes from "prop-types"

import {
    Button, Card
} from '../../../components/basic';

function AndroidInstallPrompt({ onClose, onInstall }) {


    return (
        <div className="pwaPrompt" id="androidPWAPrompt">
            <Card className="pwaPromptCard">
                <div className="pwaImageDiv">
                    <img className="pwaImage" src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Icon.svg" alt='logo' />
                </div>
                <div className='pwaContent'>
                    <p><b>Add Level Finance to your home screen</b></p>
                    <Button className='cornered-button' color="info" block size="sm" onClick={onInstall}>Install</Button>
                </div>
                <i onClick={onClose} className="fa fa-times" aria-hidden="true"></i>
            </Card>
        </div>
    )
}

AndroidInstallPrompt.propTypes = {
    onClose: PropTypes.func,
    onInstall: PropTypes.func,
}

export default AndroidInstallPrompt;
import React, {useState,useEffect} from 'react';
import { useNavigate, useSearchParams }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';

import '../style.scss';
import './style.scss';
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

const ResetPassword = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();
    const [searchParams] = useSearchParams();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [err, setErr] = useState('');
    const [success, setSuccess] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [j, setJ] = useState('');
    const [t, setT] = useState('');

    useEffect(() => {
        let query = {
            j: searchParams.get('j'),
            t: searchParams.get('t')
        }
        console.log(query)
        console.log(query);
        if (!query.j){
            setErr('Invalid reset link.');
        }else if (!query.t){
            setErr('Invalid reset link.');
        }else{
            setJ(query.j);
            setT(query.t);
        }
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
      }, []);


    function newPasswordChange(e){
        setErr('');
        setSuccess('');
        setNewPassword(e.target.value);
    }

    function confirmPasswordChange(e){
        setErr('');
        setSuccess('');
        setConfirmPassword(e.target.value);
    }

    function doValidation(){
        

        if (newPassword === ''){
            setErr('Please enter a new password.');
            return false
        }

        if (!strongRegex.test(newPassword)){
            setErr('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return false
        }

        if (newPassword !== confirmPassword){
            setErr('Passwords do not match.');
            return false
        }

        return true

    }

    function changePasswordClicked(){
        

        if (t === ''){
            setErr('Invalid reset link.');
            return
        }

        if (j === ''){
            setErr('Invalid reset link.');
            return
        }

        commonStore.setLoading(true);
        if (doValidation()){
            commonStore.resetPassword(j, t, newPassword).then((ret) => {
                if (ret !== true){
                    setErr(ret);
                    commonStore.setLoading(false)
                    return
                }else{
                    setSuccess('Your password has been changed');
                    setPasswordChanged(true);
                    commonStore.setLoading(false)
                    return
                }
            });
        }else{
            commonStore.setLoading(false);
        }
    }

    function goToLogin(){
        navigate('/signin');
    }


        
        if (isMobile()){
            return(
                <div id="mobileDashView" className="view">
                    <MobileTopBarClean/>
                    <div className="viewContent view-pages view-session d-flex flex-column">
                        <p className="txtlead txtLeft">Reset password</p>
                        <Form style={{overflow: 'scroll'}}>
                            <label className="custom-field">
                                <input className='inp' type="password" id="newPassword" value={newPassword} onChange={newPasswordChange} required/>
                                <span className="placeholder">New password</span>
                            </label> 

                            <label className="custom-field">
                                <input className='inp' type="password" id="confirmPassword" value={confirmPassword} onChange={confirmPasswordChange} required/>
                                <span className="placeholder">Confirm password</span>
                            </label> 
                            {(err.length > 0)? <p style={{color: 'red'}}>{err}</p>:null}
                            {(success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}
                            {(!passwordChanged)?
                            <Button  block size="lg" onClick={changePasswordClicked} style={{marginTop: '10px', marginBottom: '10px'}}>Reset password</Button>:
                            <Button  block size="lg" onClick={goToLogin} style={{marginTop: '10px', marginBottom: '10px'}}>Proceed to login</Button>
                            }
                            
                        </Form>
                    </div>
                </div>
            );
        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                        <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                            <header className="mb-4 topLogin">
                                <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                <p className="txtlead">Reset password</p>
                                <br/>
                            </header>
                            <Form>
                                <label className="custom-field">
                                    <input className='inp' type="password" id="newPassword" value={newPassword} onChange={newPasswordChange} required/>
                                    <span className="placeholder">New password</span>
                                </label> 

                                <label className="custom-field">
                                    <input className='inp' type="password" id="confirmPassword" value={confirmPassword} onChange={confirmPasswordChange} required/>
                                    <span className="placeholder">Confirm password</span>
                                </label> 
                                {(err.length > 0)? <p style={{color: 'red'}}>{err}</p>:null}
                                {(success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}
                                {(!passwordChanged)?
                                <Button  block size="lg" onClick={changePasswordClicked} style={{marginTop: '10px', marginBottom: '10px'}}>Reset password</Button>:
                                <Button  block size="lg" onClick={goToLogin} style={{marginTop: '10px', marginBottom: '10px'}}>Proceed to login</Button>
                                }
                            </Form>
                        </CardBody>
                        </Card>
                    </div>
                </View>
            );
        }
})

export default ResetPassword
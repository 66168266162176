import React, {useState,useEffect} from 'react';
import { useNavigate, useSearchParams }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import {Form, Button, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';

import '../style.scss';
import './style.scss';

const ContactUs = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();
    const [searchParams] = useSearchParams();

    const [subject, setSubject] = useState('General Enquiry');
    const [IDNumber, setIDNumber] = useState('');
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');


    useEffect(() => {
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
        loadData();
    }, []);

    function loadData(){
        setIDNumber(commonStore.employeeDetail? (commonStore.employeeDetail.IDNumber || '') : '');
        setName(commonStore.employeeDetail? (commonStore.employeeDetail.FirstName || '') : '');
        setContact(commonStore.employeeDetail? (commonStore.employeeDetail.Mobile || '') : '');
        setEmail(commonStore.employeeDetail? (commonStore.employeeDetail.PrivateEmail || '') : '');
    }

    function subjectChanged(e){
        setError('');
        setSuccess('');
        setSubject(e.target.value);
    }

    function IDNumberChanged(e){
        setError('');
        setSuccess('');
        setIDNumber(e.target.value);
    }

    function nameChanged(e){
        setError('');
        setSuccess('');
        setName(e.target.value);
    }

    function contactChanged(e){
        setError('');
        setSuccess('');
        setContact(e.target.value);
    }

    function emailChanged(e){
        setError('');
        setSuccess('');
        setEmail(e.target.value);
    }

    function messageChanged(e){
        setError('');
        setSuccess('');
        setMessage(e.target.value);
    }

    function validateEmail(email){
        return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    function submit(){
        
        
        commonStore.setLoading(true);
        if (name === ''){
            setError('Please enter your full name.');
            commonStore.setLoading(false);
            return
        }

        if (IDNumber === '' && (subject === 'Account Query' || subject === 'Advance Query')){
            setError('ID number is required for this type of query.');
            commonStore.setLoading(false);
            return
        }

        if (contact === ''){
            setError('Please enter your contact number.');
            commonStore.setLoading(false);
            return
        }

        if (email === ''){
            setError('Please enter your email address.');
            commonStore.setLoading(false);
            return
        }
        
        if (!(email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))){
            setError('Please enter a valid email.');
            commonStore.setLoading(false);
            return 
        }

        if (message === ''){
            setError('Please enter a message.');
            commonStore.setLoading(false);
            return
        }

        let contactForm = {
            Subject: subject,
            Name: name,
            IDNumber: IDNumber,
            Contact: contact,
            Email: email,
            Message: message,
            Date: new Date()
        }

        commonStore.createContactSubmission(contactForm).then((ret) => {
            setSuccess('Your message has been sent successfully');
            commonStore.setLoading(false);
        });
    }

    function goBack(){
        if (searchParams.toString().includes('More') || searchParams.toString().includes('more')){
            navigate({pathname: '/home', search: 'More'})
        }else if (searchParams.toString().includes('support') || searchParams.toString().includes('Support')){
            navigate({pathname: '/home', search: 'support'})
        }else{
            navigate(-1);
        }
    }


        if (isMobile()){
            return (
                <div id="mobileDashView" className="view">
                    <MobileTopBarClean/>
                    <div className="viewContent view-pages view-session d-flex align-items-center flex-column" style={{overflowY: 'Scroll'}}>
                        <p className="txtlead txtleft">Contact us</p>
                        {/* <p className="txtsub">Leave us a message below and we'll get back to you.</p> */}

                        <Form>
                            <label className="custom-field">
                                <select value={subject} onChange={subjectChanged}>
                                    <option value="General Enquiry">General Enquiry</option>
                                    <option value="Account Query">Account Query</option>
                                    <option value="Advance Query">Advance Query</option>
                                </select>
                                <span className="placeholder">Subject</span>
                            </label>

                            <label className="custom-field">
                                <input className='inp' type="text" id="name" value={name} onChange={nameChanged} required/>
                                <span className="placeholder">Full name</span>
                            </label>

                            <label className="custom-field">
                                <input className='inp' type="text" id="id" value={IDNumber} onChange={IDNumberChanged} required/>
                                <span className="placeholder">ID Number</span>
                            </label>

                            <label className="custom-field">
                                <input className='inp' type="text" id="contact" value={contact} onChange={contactChanged} required/>
                                <span className="placeholder">Contact number</span>
                            </label>

                            <label className="custom-field">
                                <input className='inp' type="text" id="email" value={email} onChange={emailChanged} required/>
                                <span className="placeholder">Email address</span>
                            </label>

                            <label className="custom-field">
                                <textarea className='inp' id='message' value={message} onChange={messageChanged} required/>
                                <span className="placeholder">Message</span>
                            </label>

                            {(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}
                            {success.length > 0? <p style={{color: 'green'}}>{success}</p>:null}
                            <Button className='cornered-button' block size="lg" onClick={submit}>Submit</Button>
                            <p className="backButton" onClick={goBack}>Back</p>
                        </Form>
                    </div>

                </div>
            );
        }else{
            return (
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg"/>
                                    <p className="txtlead">Contact us</p>
                                    {/* <p className="txtsub">Enter your bank account details below.</p> */}
                                </header>

                                <Form>
                                    <label className="custom-field">
                                        <select value={subject} onChange={subjectChanged}>
                                            <option value="General Enquiry">General Enquiry</option>
                                            <option value="Account Query">Account Query</option>
                                            <option value="Advance Query">Advance Query</option>
                                        </select>
                                        <span className="placeholder">Subject</span>
                                    </label>

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="name" value={name} onChange={nameChanged} required/>
                                        <span className="placeholder">Full name</span>
                                    </label>

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="id" value={IDNumber} onChange={IDNumberChanged} required/>
                                        <span className="placeholder">ID Number</span>
                                    </label>

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="contact" value={contact} onChange={contactChanged} required/>
                                        <span className="placeholder">Contact number</span>
                                    </label>

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="email" value={email} onChange={emailChanged} required/>
                                        <span className="placeholder">Email address</span>
                                    </label>

                                    <label className="custom-field">
                                        <textarea className='inp' id='message' value={message} onChange={messageChanged} required/>
                                        <span className="placeholder">Message</span>
                                    </label>

                                    {(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}
                                    {success.length > 0? <p style={{color: 'green'}}>{success}</p>:null}
                                    <Button className='cornered-button' block size="lg" onClick={submit}>Submit</Button>
                                    <p className="backButton" onClick={goBack}>Back</p>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </View>
            );
        }
})
export default ContactUs
import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate }  from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../stores/Store';



const PrivateRouter = observer(({children}) => {
    const store = useStore();

    let { isLoggedIn } = store;
    

    return isLoggedIn ? children : <Navigate to='/signin'/>
    
})

export default PrivateRouter

import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Label, FormGroup, Button, FormText, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';


import '../style.scss';

const WhatsappLogin = observer(() => {

    let navigate = useNavigate();
    const commonStore = useStore();

    const [id, setId] = useState('');
    const [pass, setPass] = useState('');
    const [webauthn, setWebauthn] = useState(true);
    const [UsePassword, setUsePassword] = useState(false);
    const [err, setErr] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (typeof window.PublicKeyCredential !== 'undefined'){
            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available){
                    //Webauthn Available
                    setWebauthn(true);
                }else{
                    //Webauthn not available
                    setWebauthn(false);
                }
            }).catch((e) => {
                //Webauthn not available
                setWebauthn(false);
            })
        }else{
            setWebauthn(false);
        }
        
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);



    function handleChange(evt) {
        if(evt && evt.target){
            if (evt.currentTarget.id === 'id'){
                setId(evt.target.value)
            }
            if (evt.currentTarget.id === 'pass'){
                setPass(evt.target.value)
            }
            setErr('');
            setSuccess('');
        }
	}

    function doLogin(){
        
        


        if(id && id.length > 10){
            if(pass && pass.length > 0){
                setErr('');
                commonStore.login(id, pass).then((valid)=>{
                    if(valid === true){
                        if (commonStore.user.role === "5d8e0b079f51d42bbece9202"){
                            navigate('/whatsappcode', {page: 'Dashboard'});
                        }else if (commonStore.user.role === "5d8e0b079f51d42bbece9201"){
                            navigate('/admindashboard');
                        }else if (commonStore.user.role === '62bd55b7c5793941427c702d'){
                            if (commonStore.user.businessentity.active === false){
                                navigate('/clientpayrollprovider');
                            }else{
                                console.log('Already registered, push to dashboard');
                            }
                        }
                        
                        
                    }else if(valid === 'Blocked'){
                        setErr('This account has been blocked, please contact support.');
                        commonStore.setLoading(false);
                        return
                    } else {
                        commonStore.setLoading(false);
                        setErr('Invalid login details');
                    }
                }).catch(() => {
                    commonStore.setLoading(false);
                    setErr('Invalid login details');
                })
            } else {
                commonStore.setLoading(false);
                setErr('Invalid login details');
            }
        } else {
            commonStore.setLoading(false);
            setErr('Invalid login details');
        }
    }


    function checkWebauthn(e) {

        if (!id || id.length < 10){
            setErr('Invalid login details');
            return false;
        }

        commonStore.setLoading(true);
        commonStore.checkWebauthn(id).then((ret) => {
            if (ret === "nf"){
                setErr('User not found');
                commonStore.setLoading(false);
            }else if (ret === "error"){
                setUsePassword(true);
                setErr('Invalid login details');
                commonStore.setLoading(false);
            }else if (ret === 'Blocked') {
                setErr('This account has been blocked, please contact support.');
                commonStore.setLoading(false);
                return
            }else if (ret){
                commonStore.setLoading(false);
                if (commonStore.user.role === "5d8e0b079f51d42bbece9202"){
                    navigate('/whatsappcode', {page: 'Dashboard'});
                }else if (commonStore.user.role === "5d8e0b079f51d42bbece9201"){
                    navigate('/admindashboard');
                }else if (commonStore.user.role === '62bd55b7c5793941427c702d'){
                    if (commonStore.user.businessentity.active === false){
                        navigate('/clientpayrollprovider');
                    }else{
                        console.log('Already registered, push to dashboard');
                    }
                }
            }else{
                setWebauthn(false);
                commonStore.setLoading(false);
            }
        });
    }

    function usePassword(){
        setUsePassword(false);
        setWebauthn(false);
        if (!commonStore.passwordAvailable){
            resetPassword();
        }
        setErr('');
    }

    function resetPassword(){
        
        

        if (id.length < 10){
            setErr('Invalid ID number.');
            return
        }

        commonStore.generatePasswordReset(id).then((ret) => {
            if (ret === true){
                window.alert('A password reset link has been emailed to your work email.');
                return
            }else{
                window.alert(ret);
                return
            }
        });
    }

    function signUp(){
        navigate('/signup');
    }


        if (isMobile()){
            return (
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>
                    </div>
                    
                    <div id="mainView" className="viewContent view-pages view-session d-flex justify-content-center flex-column">
                        <p className="txtlead">Sign in</p>
                        <p className="txtsub">Sign in to get your WhatsApp token</p>
                        <Form>
                        {(webauthn)?
                        <FormGroup className="mb-5 inpControls">
                            <label className="custom-field">
                                <input className='inp' type="text" id="id" value={id} onChange={handleChange} onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); checkWebauthn();}}} required/>
                                <span className="placeholder">ID/Passport number</span>
                            </label>
                            {(err && err.length > 0)?
                                <Label className="err">{err}</Label>:null}
                            {(success && success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}
                            <Button color="info" block size="lg" onClick={checkWebauthn}>Sign In</Button>
                            {UsePassword? 
                            <Button color="info" block size="lg" onClick={usePassword}>Use password</Button>:null}
                            
                            <br/>
                            <Label className='txtlogin'>Don't have an account? <span style={{color: '#4c1ba5', cursor: 'pointer'}} onClick={signUp}>Sign Up</span></Label>
                        </FormGroup>:
                        <FormGroup>
                            <label className="custom-field">
                                <input className='inp' type="text" id="id" value={id} onChange={handleChange} required/>
                                <span className="placeholder">Username</span>
                            </label>
                            <label className="custom-field">
                                <input className='inp' type="password" id="pass" onChange={handleChange} onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); doLogin();}}} required/>
                                <span className="placeholder">Password</span>
                            </label>
                            <FormText className="text-right" onClick={resetPassword}>Forgot your password?</FormText>
                            {(err && err.length > 0)?
                                <Label className="err">{err}</Label>:null}
                            {(success && success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}
                            <Button color="info" block size="lg" onClick={doLogin}>Sign In</Button>
                            <br/>
                            <Label className='txtlogin'>Don't have an account? <span style={{color: '#4c1ba5', cursor: 'pointer'}} onClick={signUp}>Sign Up</span></Label>
                        </FormGroup>
                        }

 
                        </Form>
                    </div>
                    
                </div>);
        }else{
            return (
                <View>
                <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                    <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                        <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                            <header className="mb-4 topLogin">
                                <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                <p className="txtlead">Sign in</p>
                                <p className="txtsub">Sign in to get your WhatsApp token</p>
                                <br/>
                            </header>
                            
                            <Form>
                                {(webauthn)? 
                                <FormGroup className="mb-5 inpControls">
                                    <label className="custom-field">
                                        <input className='inp' type="text" id="id" value={id} onChange={handleChange} onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); checkWebauthn();}}} required/>
                                        <span className="placeholder">ID/Passport number</span>
                                    </label>
                                    {(err && err.length > 0)?
                                        <Label className="err">{err}</Label>:null}
                                    {(success && success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}
                                    <Button color="info" block size="lg" onClick={checkWebauthn}>Sign In</Button>
                                    {UsePassword? 
                                    <Button color="info" block size="lg" onClick={usePassword}>Use password</Button>:null}
                                    
                                    <br/>
                                    <Label className='txtlogin'>Don't have an account? <span style={{color: '#4c1ba5', cursor: 'pointer'}} onClick={signUp}>Sign Up</span></Label>
                                </FormGroup>:
                                <FormGroup className="mb-4">
                                    <label className="custom-field">
                                        <input className='inp' type="text" id="id" onChange={handleChange} required/>
                                        <span className="placeholder">Username</span>
                                    </label>
                                    <label className="custom-field">
                                        <input className='inp' type="password" id="pass" onChange={handleChange} onKeyDown={(e) => {if(e && e.key && e.key === 'Enter'){e.preventDefault(); doLogin();}}} required/>
                                        <span className="placeholder">Password</span>
                                    </label>

                                    <FormText className="text-right" onClick={resetPassword}>Forgot your password?</FormText>

                                    {(err && err.length > 0)?
                                    <Label className="err">{err}</Label>:null}
                                    {(success && success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}

                                    <Button color="info" block size="lg" onClick={doLogin}>Sign In</Button>
                                    <br/>
                                    <Label className='txtlogin'>Don't have an account? <span style={{color: '#4c1ba5', cursor: 'pointer'}} onClick={signUp}>Sign Up</span></Label>
                                </FormGroup>}

                            </Form>
                        </CardBody>
                    </Card>
                </div>
                </View>
            );
        }
        
})

export default WhatsappLogin

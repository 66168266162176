import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';
import { FaCheck } from 'react-icons/fa';

import '../../style.scss';
import './style.scss';


const SupportRequests = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [submissions, setSubmissions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [Search, setSearch] = useState('');
    const [filter, setFilter] = useState('');

    useEffect(() => {
        loadData();
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function loadData(){
        
        
        commonStore.setLoading(true);
        commonStore.getContactSubmissions().then((ret) => {
            let ordered = ret.sort((b, a) => (new Date(a.Date)) - (new Date(b.Date)));
            setSubmissions(ordered);
            commonStore.setLoading(false);
        });
    }

    function searchChanged(e){
        setSearchTerm(e.target.value);
    }

    function search(){
        setSearch(searchTerm);
    }

    function filterChanged(e){
        setFilter(e.target.value);
    }

    function clearFilters(){
        setSearch('');
        setSearchTerm('');
        setFilter('All');
    }

    function closeRequest(e){
        if (window.confirm('You are about to mark this request as closed.')){
            commonStore.setLoading(true);
            commonStore.updateContactSubmissionById(e.currentTarget.id, {Status: 'Closed'}).then((ret) => {
                loadData();
            })
        }
    }

        
        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%', padding: '10px', overflow: 'scroll'}}>
                    {(submissions.length > 0 && submissions !== '[]')? <div>
                        {submissions.map((submission, i) => {
                            // if (search !== '' && !submission.IDNumber.includes(search) && !submission.Name.includes(search) && !submission.Contact.includes(search) && !submission.Email.includes(search) && !submission.Message.includes(search)){
                            //     console.log('Searched Out')
                            //     return;
                            // }
            
                            // if (filter !== 'All' && submission.Subject !== filter){
                            //     console.log('Filtered Out')
                            //     return;
                            // }
                            let msg = submission.Message.replace(/(?:\r\n|\r|\n)/g, '<br/>');
                            let date = new Date(submission.Date);
                            let dateString = `${date.toLocaleDateString('en-za', {weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', time: 'long'})} ${date.toLocaleTimeString('en-za', {hour: '2-digit', minute: '2-digit'})}`
                            let status = 'Closed';
                            if (!submission.Status || submission.Status !== 'Closed'){
                                status = 'Open'
                            }
                            return(
                                <Card id={i} key={i} style={{borderRadius: '15px', padding: '10px', marginBottom: '10px', maxHeight: '250px', overflow: 'scroll'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <p className="txtlead">{submission.Subject}</p>
                                        <p>{dateString}</p>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <p>{submission.Name}</p>
                                        <p>{submission.IDNumber}</p>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <p>{submission.Contact}</p>
                                        <p>{submission.Email}</p>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <p dangerouslySetInnerHTML={{__html: msg}}></p>
                                        {(status === 'Open') ? <FaCheck id={submission._id} onClick={closeRequest} style={{color: 'green', margin: '0px', cursor: 'pointer'}} /> : null}
                                    </div>
                                </Card>
                            );
                        })}
                    </div>: <p>No support requests.</p>}
                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Support</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        {/* <div className="desktopDashHeader">
                        {(search === '' && filter === 'All')?<p className="txtsub txtLeft">All Support Requests</p>: <p>Filters: {filter} <br/> Search: {search}</p>}
                            <Button className="transparentButton" block size="sm" onClick={clearFilters} style={{width: '10%'}}>Clear filters</Button>
                        </div> */}
                        <hr/>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
                            {/* <select value={filter} onChange={filterChanged} style={{height: '30px'}}>
                                <option value="All">All</option>
                                <option value="General Enquiry">General Enquiry</option>
                                <option value="Account Query">Account Query</option>
                                <option value="Advance Query">Advance Query</option>
                            </select> */}

                            {/* <label className="custom-field" style={{width: '50%'}}>
                                <input className='inp' type="text" id="search" value={searchTerm} onChange={searchChanged} onBlur={search} required/>
                                <span className="placeholder">Search</span>
                            </label>  */}
                        </div>
                        <div>
                            {(submissions.length > 0 && submissions !== '[]')? <div>
                                {submissions.map((submission, i) => {
                                    // if (search !== '' && !submission.IDNumber.includes(search) && !submission.Name.includes(search) && !submission.Contact.includes(search) && !submission.Email.includes(search) && !submission.Message.includes(search)){
                                    //     console.log('Searched Out')
                                    //     return;
                                    // }
                    
                                    // if (filter !== 'All' && submission.Subject !== filter){
                                    //     console.log('Filtered Out')
                                    //     return;
                                    // }
                                    let msg = submission.Message.replace(/(?:\r\n|\r|\n)/g, '<br/>');
                                    let date = new Date(submission.Date);
                                    let dateString = `${date.toLocaleDateString('en-za', {weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', time: 'long'})} ${date.toLocaleTimeString('en-za', {hour: '2-digit', minute: '2-digit'})}`
                                    let status = 'Closed';
                                    if (!submission.Status || submission.Status !== 'Closed'){
                                        status = 'Open'
                                    }
                                    return(
                                        <Card id={i} key={i} style={{borderRadius: '15px', padding: '10px', marginBottom: '10px', maxHeight: '250px', overflow: 'scroll'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <p className="txtlead">{submission.Subject} - <span style={{color: `${(status === 'Open' ? 'red' : 'green')}`}}>{status}</span></p>
                                                <p>{dateString}</p>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <p>{submission.Name}</p>
                                                <p>{submission.IDNumber}</p>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <p>{submission.Contact}</p>
                                                <p>{submission.Email}</p>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <p dangerouslySetInnerHTML={{__html: msg}}></p>
                                                {(status === 'Open') ? <FaCheck id={submission._id} onClick={closeRequest} style={{color: 'green', margin: '0px', cursor: 'pointer'}} /> : null}
                                            </div>
                                        </Card>
                                    );
                                })}
                            </div>: <p>No support requests.</p>}
                        </div>
                    </div>

                </View>
            );
        }
        
})

export default SupportRequests
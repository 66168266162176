import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';

import { FaUser, FaUsers, FaFileInvoice } from 'react-icons/fa';



import '../../style.scss';
import './style.scss';


const CompanyAdvances = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [clients, setClients] = useState([]);
    const [clientSelected, setClientSelected] = useState('-1');
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [currentFromDate, setCurrentFromDate] = useState('');
    const [currentToDate, setCurrentToDate] = useState('');
    const [advances, setAdvances] = useState([]);
    const [employeeAdvances, setEmployeeAdvances] = useState([]);
    const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0);
    const [totalAmountWithFee, setTotalAmountWithFee] = useState(0);
    const [totalFeeAmount, setTotalFeeAmount] = useState(0);
    const [filter, setFilter] = useState('');
    const [GroupAdvances, setGroupAdvances] = useState(false);
    const [excelBuffer, setExcelBuffer] = useState(null);
 
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        loadDates();
        loadClients();
    }, []);

    function loadClients(){
        commonStore.setLoading(true);
        commonStore.getAllClients().then((ret) => {
            setClients(ret);
            commonStore.setLoading(false);
        })
    }

    function loadDates(){
         
        let date = new Date();
        //To Date
        let toYear = `${date.getFullYear()}`;

        let toMonth = `${date.getMonth() + 1}`;
        if (toMonth.length < 2){
            toMonth = `0${toMonth}`;
        }

        let toDay = `${date.getDate()}`;
        if (toDay.length < 2){
            toDay = `0${toDay}`
        }
        console.log(toMonth);
        let tdte = `${toYear}-${toMonth}-${toDay}`;

        setToDate(tdte);

        //From Date
        let fullFromDate = date;
        fullFromDate.setMonth(fullFromDate.getMonth() - 1);
        
        let fromYear = `${fullFromDate.getFullYear()}`;

        let fromMonth = `${fullFromDate.getMonth() + 1}`;
        if (fromMonth.length < 2){
            fromMonth = `0${fromMonth}`;
        }

        let fromDay = "26";
        let frmdte = `${fromYear}-${fromMonth}-${fromDay}`;

        setFromDate(frmdte);
    }

    function clientChanged(e){
        setClientSelected(e.target.value);
        forceUpdate();
        loadAdvances(e.target.value);
    }

    function toDateChanged(e){
        setToDate(e.target.value);
    }
    function fromDateChanged(e){
        setFromDate(e.target.value);
    }

    function loadAdvances(val = clientSelected){
        if (val === "-1"){
            return
        }
        commonStore.setLoading(true);
        setCurrentFromDate(fromDate);
        setCurrentToDate(toDate);
        let frmdte = new Date(fromDate);
        let tdte = new Date(toDate);
        // let index = Number(clientSelected);
        let index = Number(val);
        let clientId = clients[index]._id;
        console.log(clients[index].name);
        
        commonStore.getAdvanceForClientAndDate(clientId, frmdte, tdte).then((ret) => {
            console.log(ret);
            setAdvances(ret.advances);
            setEmployeeAdvances(ret.employeeAdvances);
            setTotalAdvanceAmount(ret.totalAdvanceAmount);
            setTotalAmountWithFee(ret.totalAmountWithFee);
            setTotalFeeAmount(ret.totalFeeAmount);
            commonStore.setLoading(false);
        });

    }

    function updateAdvances(){
        let val = clientSelected;
        if (val === "-1"){
            return
        }
        commonStore.setLoading(true);
        setCurrentFromDate(fromDate);
        setCurrentToDate(toDate);
        let frmdte = new Date(fromDate);
        let tdte = new Date(toDate);
        // let index = Number(clientSelected);
        let index = Number(val);
        let clientId = clients[index]._id;
        console.log(clients[index].name);
        
        commonStore.getAdvanceForClientAndDate(clientId, frmdte, tdte).then((ret) => {
            console.log(ret);
            setAdvances(ret.advances);
            setEmployeeAdvances(ret.employeeAdvances);
            setTotalAdvanceAmount(ret.totalAdvanceAmount);
            setTotalAmountWithFee(ret.totalAmountWithFee);
            setTotalFeeAmount(ret.totalFeeAmount);
            commonStore.setLoading(false);
        });

    }

    function groupAdvances(){
        setGroupAdvances(true);
    }

    function ungroupAdvances(){
        setGroupAdvances(false);
    }

    function createInvoice(){
        if (clientSelected === '-1' || employeeAdvances.length === 0 || employeeAdvances === '[]'){
            return
        }
        let clientIndex = Number(clientSelected);
        let invoiceNum = window.prompt('Invoice Number?');
        if (invoiceNum === null){
            return
        }
        commonStore.setLoading(true);
        commonStore.createInvoice(employeeAdvances, clients[clientIndex], invoiceNum, currentFromDate, currentToDate, totalAdvanceAmount, totalFeeAmount).then(() => {
            window.alert(`Invoice ${invoiceNum} created. Please select the invoice tab to view your invoice`);
            commonStore.setLoading(false);
        })
    }

    function exportToExcel(){
        if (clientSelected === '-1' || employeeAdvances.length === 0 || employeeAdvances === '[]'){
            return
        }
        let clientIndex = Number(clientSelected);
        let invoiceNum = window.prompt('Invoice Number?')

        commonStore.exportExcelStatement(employeeAdvances, clients[clientIndex], invoiceNum);
        // commonStore.createPDFInvoice(employeeAdvances, clients[clientIndex], invoiceNum);
    }

    function fileUploaded(files) {
        
        var reader = new FileReader();
        var content;
        reader.onload = function(e) {
            // Use reader.result
            content = reader.result;
            commonStore.importAdvances(content);
            // commonStore.parseCSV(content, percent).then((ret) => {
            //     // employees = ret;
            //     // app.setState({ data: data });
            //     app.updateCSV(ret);
            // });

        }
        reader.readAsText(files[0]);
        
    }

        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%', padding: '10px', overflow: 'scroll'}}>
                    <select value={clientSelected} onChange={clientChanged} style={{height: '30px', width: '100%'}}>
                        <option key="-1" value="-1">Please select a client</option>
                        {clients.map((client, i) => {
                            return(
                                <option key={i} value={`${i}`}>{client.name}</option>
                            );
                        })}
                    </select>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px'}}>
                        <label htmlFor="fromDate">From:
                            <input type="date" id="fromDate" name="fromDate" value={fromDate} onChange={fromDateChanged}/>
                        </label>
                        <label htmlFor="toDate">To:
                            <input type="date" id="toDate" name="toDate" value={toDate} onChange={toDateChanged}/>
                        </label>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px'}}>
                        <Button block size="sm" onClick={updateAdvances} style={{width: '30%'}}>Update</Button>
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', width: '60%', justifyContent: 'space-evenly'}}>
                            <p className="txtsub" id={(GroupAdvances)? 'groupViewOptionSelected': 'groupViewOption'} onClick={groupAdvances}><FaUser id={(GroupAdvances)? 'groupViewOptionSelected': 'groupViewOption'}/>Group</p>
                            <p className="txtsub" id={(!GroupAdvances)? 'groupViewOptionSelected': 'groupViewOption'} onClick={ungroupAdvances}><FaUsers id={(!GroupAdvances)? 'groupViewOptionSelected': 'groupViewOption'}/>Show All</p>
                            {(clientSelected !== "-1")? <><p style={{color: '#868685'}}>Generate Invoice: <FaFileInvoice className="fa fa-file-excel" aria-hidden="true" onClick={createInvoice} style={{cursor: 'pointer', height: '20px'}}/></p></> : null}
                        </div>
                    </div>
                    <hr/>

                    {(GroupAdvances)? <div>
                        {employeeAdvances.map((employeeadvance, k) => {
                            console.log(employeeadvance)
                            let fullName = `${employeeadvance.FirstName} ${employeeadvance.LastName}`;
                            let fee = Number(employeeadvance.TotalFee).toFixed(2);
                            let totalAdvanceAmount = employeeadvance.TotalAdvances.toFixed(2);
                            let IDNumber = employeeadvance.IDNumber;
            
                            return(
                                <Card key={k} style={{borderRadius: '15px', padding: '10px', margin: '10px'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <p>{fullName}</p>
                                        <p>{IDNumber}</p>
                                    </div>
            
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <p>{`Fee: R${fee}`}</p>
                                        <p>{`Amount: R${totalAdvanceAmount}`}</p>
                                    </div>
                                </Card>
                            );
                        })}
                    </div> 
                    : 
                    <div>
                        {advances.map((advance, j) => {
                            let fullName = `${advance.employee.employeeinfo.FirstName} ${advance.employee.employeeinfo.LastName}`;
                            let amount = advance.AdvanceAmount;
                            let fee = advance.AdvanceFee;
            
                            return(
                                <Card key={j} style={{borderRadius: '15px', padding: '10px', margin: '10px'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <p>{fullName}</p>
                                        <p className="txtsub">{advance.AdvanceRequestDate}</p>
                                    </div>
            
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <p>{`Fee: R${fee}`}</p>
                                        <p>{`Amount: R${amount}`}</p>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>}
                    
                    
                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                   <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Company Advances</p>
                        </div>
                    </header>

                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <div className="advanceDateSelection">
                                <label htmlFor="fromDate">From:
                                    <input type="date" id="fromDate" name="fromDate" value={fromDate} onChange={fromDateChanged}/>
                                </label>

                                <label htmlFor="toDate">To:
                                    <input type="date" id="toDate" name="toDate" value={toDate} onChange={toDateChanged}/>
                                </label>

                                <Button block size="sm" onClick={updateAdvances}>Update</Button>
                            </div>
                            <select value={clientSelected} onChange={clientChanged} style={{height: '30px'}}>
                                <option key="-1" value="-1">Please select a client</option>
                                {clients.map((client, i) => {
                                    return(
                                        <option key={i} value={`${i}`}>{client.name}</option>
                                    );
                                })}
                            </select>
                        </div>

                        {(clientSelected !== "-1")? 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <p>{`Showing ${clients[Number(clientSelected)].name} from ${currentFromDate} to ${currentToDate}.`}</p>
                            <div style={{display: 'flex'}}>
                                {/* <p style={{color: '#868685'}}>Export: <FaFileExcel className="fa fa-file-excel" aria-hidden="true" onClick={createInvoice} style={{cursor: 'pointer', height: '20px'}}/></p> */}
                                <p style={{color: '#868685'}}>Generate Invoice: <FaFileInvoice className="fa fa-file-excel" aria-hidden="true" onClick={createInvoice} style={{cursor: 'pointer', height: '20px'}}/></p>
                                
                            </div>
                        </div>
                        : null}
                        <hr/>
                        <div>
                            <Card style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', borderRadius: '15px'}}>
                                <div className="advanceInfoDiv">
                                    <p>{`R${totalAdvanceAmount}`}</p>
                                    <p>Total advances excluding fee amount</p>
                                </div>

                                <div className="advanceInfoDiv">
                                    <p>{`R${totalAmountWithFee}`}</p>
                                    <p>Total advances including fee amount</p>
                                </div>

                                <div className="advanceInfoDiv">
                                    <p>{`R${totalFeeAmount}`}</p>
                                    <p>Total advance fee amount</p>
                                </div>

                                <div className="advanceInfoDiv">
                                    <p>{`R${employeeAdvances.length * 10}`}</p>
                                    <p>Total membership fee amount (excl)</p>
                                </div>

                            </Card>
                            <div className="groupViewOptions">
                                <p className="txtsub" id={(GroupAdvances)? 'groupViewOptionSelected': 'groupViewOption'} onClick={groupAdvances}><FaUser id={(GroupAdvances)? 'groupViewOptionSelected': 'groupViewOption'}/>Group</p>
                                <p className="txtsub" id={(!GroupAdvances)? 'groupViewOptionSelected': 'groupViewOption'} onClick={ungroupAdvances}><FaUsers id={(!GroupAdvances)? 'groupViewOptionSelected': 'groupViewOption'}/>Show All</p>
                            </div>
                            {(GroupAdvances)? <div>
                                {employeeAdvances.map((employeeadvance, k) => {
                                    console.log(employeeadvance)
                                    let fullName = `${employeeadvance.FirstName} ${employeeadvance.LastName}`;
                                    let fee = Number(employeeadvance.TotalFee).toFixed(2);
                                    let totalAdvanceAmount = employeeadvance.TotalAdvances.toFixed(2);
                                    let IDNumber = employeeadvance.IDNumber;
                    
                                    return(
                                        <Card key={k} style={{borderRadius: '15px', padding: '10px', margin: '10px'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <p>{fullName}</p>
                                                <p>{IDNumber}</p>
                                            </div>
                    
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <p>{`Fee: R${fee}`}</p>
                                                <p>{`Amount: R${totalAdvanceAmount}`}</p>
                                            </div>
                                        </Card>
                                    );
                                })}
                            </div> 
                            : 
                            <div>
                                {advances.map((advance, j) => {
                                    let fullName = `${advance.employee.employeeinfo.FirstName} ${advance.employee.employeeinfo.LastName}`;
                                    let amount = advance.AdvanceAmount;
                                    let fee = advance.AdvanceFee;
                    
                                    return(
                                        <Card key={j} style={{borderRadius: '15px', padding: '10px', margin: '10px'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <p>{fullName}</p>
                                                <p className="txtsub">{advance.AdvanceRequestDate}</p>
                                            </div>
                    
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <p>{`Fee: R${fee}`}</p>
                                                <p>{`Amount: R${amount}`}</p>
                                            </div>
                                        </Card>
                                    );
                                })}
                            </div>}
                            {/* {advances} */}
                        </div>
                    </div>

                    

                </View>
            );
        }
        
})

export default CompanyAdvances;
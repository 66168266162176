import React, { useEffect } from 'react';
//React Router
import { isMobile } from 'is-mobile';
import { useStore } from './stores/Store';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom";
import './App.scss';

import PrivateRouter from './privateRouter';
import AdminRouter from './adminRouter';



import { LoadingScreen } from './components';

import SignIn from './pages/signin';
import SignUp from './pages/signup';
import VerifyIdFallback from './pages/signup/verifyIdFallback';
import VerifiedId from './pages/signup/verifiedId';
import IDVerificationFailed from './pages/signup/idVerificationFailed';
import SecurityOptions from './pages/signup/securityOptions';
import PasswordAuth from './pages/signup/passwordAuth';
import AuthSuccess from './pages/signup/authSuccess'
import ConfirmProfile from './pages/signup/confirmProfile';
import Agreement from './pages/signup/agreement';
import Dashboard from './pages/dash';
import Agreements from './pages/userInfoUpdates/agreements';
import Profile from './pages/userInfoUpdates/profile';
import Contact from './pages/userInfoUpdates/contactInfo';
import BankAccounts from './pages/userInfoUpdates/bankAccounts';
import WithdrawConfirm from './pages/withdraw';
import WithdrawSuccess from './pages/withdraw/withdrawSuccess';
import ContactUs from './pages/contact';
import AddPassword from './pages/addPassword';
import ChangePassword from './pages/changePassword';
import EmployeeSupportRequests from './pages/dash/support';
import AdminDashboard from './pages/adminDash';
import NewUser from './pages/adminDash/users/newUser';
import WhatsappLogin from './pages/whatsapp';
import WhatsappCode from './pages/whatsapp/whatsappCode';
import ResetPassword from './pages/resetPassword';
import ConfirmBankAccount from './pages/signup/confirmBankAccount';
import ClientDashboard from './pages/clientDash';
import BaselineAssessment from './pages/baselineAssessment';

import Maintenance from './pages/maintenance';

import Test from './pages/test';

const App = (() => {
  const store = useStore();
  useEffect(() => {
    store.checkAppVersion().then((ret) => {
      if (ret === false) {
        if (1 === 1) {
          if ('caches' in window) {
            caches.keys().then(function (cacheNames) {
              cacheNames.forEach(function (cacheName) {
                caches.delete(cacheName);
              });
            });
          }

          // Clear the cache by appending a random query string to the current URL
          var currentUrl = window.location.href;
          var newUrl = currentUrl + (currentUrl.indexOf('?') !== -1 ? '&' : '?') + 'nocache=' + Date.now();
          window.location.href = newUrl;
        }
      }
    })
  }, []);
  return (
    <Router>
      <div className="App">
        <LoadingScreen />
        <Routes>
          <Route path="/test" element={<Test />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verifyidfallback" element={<VerifyIdFallback />} />
          <Route path="/verifiedid" element={<VerifiedId />} />
          <Route path="/idverificationfailed" element={<IDVerificationFailed />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/securityoptions" element={<SecurityOptions />} />
          <Route path="/passwordauth" element={<PasswordAuth />} />
          <Route path="/authsuccess" element={<AuthSuccess />} />
          <Route path="/confirmprofile" element={<ConfirmProfile />} />
          <Route path="/confirmbankaccount" element={<ConfirmBankAccount />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/resetpassword" element={<ResetPassword />} />


          <Route path="/home" element={<PrivateRouter><Dashboard /></PrivateRouter>} />
          <Route path="/downloadagreements" element={<PrivateRouter><Agreements /></PrivateRouter>} />
          <Route path="/profile" element={<PrivateRouter><Profile /></PrivateRouter>} />
          <Route path="/contact" element={<PrivateRouter><Contact /></PrivateRouter>} />
          <Route path="/bankaccounts" element={<PrivateRouter><BankAccounts /></PrivateRouter>} />
          <Route path="/withdraw" element={<PrivateRouter><WithdrawConfirm /></PrivateRouter>} />
          <Route path="/withdrawsuccess" element={<PrivateRouter><WithdrawSuccess /></PrivateRouter>} />
          <Route path="/addpassword" element={<PrivateRouter><AddPassword /></PrivateRouter>} />
          <Route path="/changepassword" element={<PrivateRouter><ChangePassword /></PrivateRouter>} />
          <Route path="/employeesupportrequest" element={<PrivateRouter><EmployeeSupportRequests /></PrivateRouter>} />

          <Route path="/admindashboard" element={<PrivateRouter><AdminDashboard /></PrivateRouter>} />
          <Route path="/clientdashboard" element={<PrivateRouter><ClientDashboard /></PrivateRouter>} />
          <Route path="/newuser" element={<PrivateRouter><NewUser /></PrivateRouter>} />

          <Route path="/whatsapplogin" element={<WhatsappLogin />} />
          <Route path="/whatsappcode" element={<PrivateRouter><WhatsappCode /></PrivateRouter>} />

          <Route path='/' element={<Navigate to='/signin' />} />
          <Route path='*' element={<Navigate to='/signin' />} />

          <Route path='/baseline' element={<PrivateRouter><BaselineAssessment /></PrivateRouter>} />


          {/* <Route path="/maintenence" element={<Maintenance/>}/>
          <Route path='/' element={<Navigate to='/maintenence'/>}/>
          <Route path='*' element={<Navigate to='/maintenence'/>}/>  */}
        </Routes>
      </div>
    </Router>
  );
})

export default App;

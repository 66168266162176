import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';
import { FaBan, FaCheck, FaFilePdf, FaFileExcel } from 'react-icons/fa';
import { PDFInvoice } from '../../../components';
import { PDFDownloadLink } from "@react-pdf/renderer";
import './style.scss';
import utils from 'excel4node/distribution/lib/utils';

const XLSX = require('xlsx');
const fs = require('fs');
const { format, parseISO } = require('date-fns');

const Invoices = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [SelectedCompanyID, setSelectedCompanyID] = useState('all');
    const [Clients, setClients] = useState([]);
    const [Invoices, setInvoices] = useState([]);
    const [LoadedInvoices, setLoadedInvoices] = useState([]);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        loadInvoices();
        loadClients();
    }, []);


    function loadClients() {
        commonStore.setLoading(true);
        commonStore.getAllClients().then((ret) => {
            setClients(ret);
            commonStore.setLoading(false);
        })
    }
    function loadInvoices() {
        commonStore.getInvoices().then((ret) => {
            setInvoices(ret);

            commonStore.setLoading(false);
        })
    }

    function clientSelected(e) {
        setSelectedCompanyID(e.target.value);
    }

    function createNewInvoice(e) {
        console.log('Create new invoice')

    }

    function cancelInvoice(e) {
        if (!window.confirm('Are you sure you want to mark this invoice as cancelled?')) {
            return
        }
        let id = e.currentTarget.id
        let data = {
            Status: 'Cancelled'
        }
        commonStore.setLoading(true);
        commonStore.updateInvoice(id, data).then((ret) => {
            window.alert('Invoice updated successfully');
            commonStore.setLoading(false);
            loadInvoices()
        })
    }
    function approveInvoice(e) {
        if (!window.confirm('Are you sure you want to mark this invoice as paid?')) {
            return
        }
        let id = e.currentTarget.id
        let data = {
            Status: 'Cancelled'
        }
        commonStore.setLoading(true);
        commonStore.updateInvoice(id, data).then((ret) => {
            window.alert('Invoice updated successfully');
            commonStore.setLoading(false);
            loadInvoices()
        })
    }
    function loadInvoice(e) {
        let inv = LoadedInvoices;
        inv.push(Number(e.currentTarget.id));
        setLoadedInvoices(inv);
        forceUpdate();
        addToSagePayslip(Number(e.currentTarget.id));
    }

    function addToSagePayslip(n) {
        let employeesToWrite = [];

        if (Invoices[n].Businessentity.api_name === "Sage") {
            const promiseArray = Invoices[n].Advances.map((item) => {
                return commonStore.getEmployee(item.IDNumber).then((emp) => {
                    let employeeCode = emp.EmployeeCode || '';
                    let companyCode = emp.CompanyCode || '';
                    let companyRuleCode = emp.CompanyRuleCode || '';
                    let amount = item.TotalAdvances + item.TotalFee;
                    let employeeToPush = {
                        IDNumber: item.IDNumber,
                        employeeCode: employeeCode,
                        companyCode: companyCode,
                        companyRuleCode: companyRuleCode,
                        amount: amount
                    };

                    employeesToWrite.push(employeeToPush);
                });
            });

            Promise.all(promiseArray)
                .then(() => {
                    const userConfirmed = window.confirm("Do you want to write these advances onto Payslip");
                    if (userConfirmed) {
                        let writePromises = [];

                        employeesToWrite.forEach((empl) => {
                            writePromises.push(commonStore.writeToSagePaySlip(Invoices[n].Businessentity._id, empl));
                        });

                        Promise.all(writePromises)
                            .then((results) => {
                                let successfulWrites = results.filter((result) => result !== 'Failed to write');
                                alert(`${successfulWrites.length} Successful writes to payslip`);
                            })
                            .catch((error) => {
                                console.error("An error occurred:", error);
                            });
                    } else {
                        return;
                    }
                })
                .catch((error) => {
                    console.error("An error occurred:", error);
                });
        }
    }



    function handleExcelDownload(employeeAdvances, client, invoiceNumber, invoiceDate, StartDate, EndDate) {
        console.log(client)
        let reportingFormatted = []

        reportingFormatted = reportingFormatted.concat(employeeAdvances.map((adv, index) => {
            const date = parseISO(adv.Date)
            const day = format(date, "dd")
            const month = format(date, "MMMM")
            const year = format(date, "yyyy")

            return {
                Date: `${day} ${month} ${year}`,
                AdvanceAmount: `R${adv.TotalAdvances.toLocaleString()}`,
                AdvanceMembershipFee: `R${11.5}`,
                AdvanceTransactionFee: `R${adv.TotalFee.toLocaleString()}`,
                Company: client.name
            };
        }));

        const workSheet = XLSX.utils.json_to_sheet(reportingFormatted);
        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, "LevelSheet");

        const buffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });

        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;

        a.download = `${client.name} ${(new Date(StartDate)).toLocaleDateString()} - ${(new Date(EndDate)).toLocaleDateString()} invoice.xlsx`;

        // Trigger a click event to start the download
        a.click();

        // Clean up resources
        window.URL.revokeObjectURL(url);

        //for base 64 convertion
        // const base64String = buffer.toString('base64');

        // fs.writeFileSync('excel_file_base64.txt', base64String, 'utf-8');

        // XLSX.writeFile(workBook, "Specd Recruitment June Statement-created-10-05.xlsx");

    }

    if (isMobile()) {
        return (
            <div style={{ width: '100%', height: '100%', padding: '10px', overflow: 'scroll' }}>
                <p className="txtlead txtLeft" style={{ marginBottom: '0px' }}>Invoices</p>
                <select value={SelectedCompanyID} onChange={clientSelected}>
                    <option value='all'>All Invoices</option>
                    {Clients.map((client, index) => {
                        return (
                            <option key={index} value={client._id}>{client.name}</option>
                        )
                    })}
                </select>
                <hr />

                {Invoices.map((invoice, index) => {
                    if (SelectedCompanyID !== 'all' && SelectedCompanyID !== invoice.Businessentity._id) {
                        return
                    }
                    return (
                        <Card key={index} id={invoice._id} className='invoiceCard'>
                            <div>
                                <p><b>Invoice Number: </b>{invoice.InvoiceNumber}</p>
                                <p><b>Date: </b>{(new Date(invoice.InvoiceDate)).toLocaleDateString()}</p>
                                <p><b>Company Name: </b>{invoice.Businessentity.name}</p>
                                <p><b>Date Range: </b>{`${(new Date(invoice.StartDate)).toLocaleDateString()} - ${(new Date(invoice.EndDate)).toLocaleDateString()}`}</p>
                                <div style={{ display: 'flex', fontSize: '20px' }}>
                                    <div>

                                    </div>
                                    {(LoadedInvoices.includes(index) ?
                                        <>
                                            <PDFDownloadLink
                                                document=
                                                {
                                                    <PDFInvoice employeeAdvances={invoice.Advances}
                                                        client={invoice.Businessentity}
                                                        invoiceNumber={invoice.InvoiceNumber}
                                                        invoiceDate={invoice.InvoiceDate}
                                                    />
                                                } filename="test.pdf" >
                                                {
                                                    ({ loading }) =>
                                                    (loading ? <Button>Loading Document...</Button> :
                                                        <FaFilePdf style={{ height: '20px', color: 'black', cursor: 'pointer' }}>Download</FaFilePdf>)
                                                }
                                            </PDFDownloadLink>
                                            <FaFileExcel onClick={() =>
                                                handleExcelDownload(invoice.Advances,
                                                    invoice.Businessentity,
                                                    invoice.InvoiceNumber,
                                                    invoice.InvoiceDate,
                                                    invoice.StartDate,
                                                    invoice.EndDate)} />
                                        </>
                                        :
                                        <Button id={index} onClick={loadInvoice}>Load Invoice</Button>
                                    )}
                                    {/* <FaFileDownload id={invoice._id} style={{cursor: 'pointer'}} onClick={downloadInvoice}/> */}
                                    {(invoice.Status === 'Pending') ? <FaBan id={invoice._id} style={{ color: 'red', cursor: 'pointer' }} onClick={cancelInvoice} /> : null}
                                    {(invoice.Status === 'Pending') ? <FaCheck id={invoice._id} style={{ color: 'green', cursor: 'pointer' }} onClick={approveInvoice} /> : null}
                                </div>
                            </div>

                            <div style={{ textAlign: 'right' }}>
                                <p className={`${(invoice.Status === 'Paid') ? 'advPaid' : ''} ${(invoice.Status === 'Pending') ? 'advPending' : ''} ${(invoice.Status === 'Cancelled') ? 'advFailed' : ''}`}>{invoice.Status}</p>
                                <p><b>Advance Amount: </b>{invoice.AdvanceAmount}</p>
                                <p><b>Fee Amount: </b>{invoice.FeeAmount}</p>
                                <p><b>Membership Amount: </b>{invoice.MembershipAmount}</p>
                                <hr style={{ margin: '0px' }} />
                                <p><b>Total Excl.: </b>{invoice.TotalExcl}</p>
                                <p><b>VAT: </b>{invoice.VAT}</p>
                                <hr style={{ margin: '0px' }} />
                                <p><b>Total Incl.: </b>{invoice.TotalIncl}</p>
                            </div>

                        </Card>
                    )
                })}
            </div>
        )
    } else {
        return (
            <View style={{ backgroundColor: '#ffffff' }}>
                <header style={{ backgroundColor: '#ffffff' }}>
                    <div>
                        <p className="txtlead txtLeft" style={{ marginBottom: '0px' }}>Invoices</p>
                    </div>
                </header>

                <div id="onDemandPay" style={{ backgroundColor: '#ffffff' }}>
                    <div className="desktopDashHeader">
                        <div className="advanceDateSelection">
                            <select value={SelectedCompanyID} onChange={clientSelected}>
                                <option value='all'>All Invoices</option>
                                {Clients.map((client, index) => {
                                    return (
                                        <option key={index} value={client._id}>{client.name}</option>
                                    )
                                })}
                            </select>

                        </div>
                        <Button className="transparentButton" block size="sm" onClick={createNewInvoice} style={{ maxWidth: '20%' }}>New Invoice</Button>
                    </div>

                    <hr />

                    {Invoices.map((invoice, index) => {
                        if (SelectedCompanyID !== 'all' && SelectedCompanyID !== invoice.Businessentity._id) {
                            return
                        }
                        return (

                            <Card key={index} id={invoice._id} className='invoiceCard'>
                                <div>
                                    <p><b>Invoice Number: </b>{invoice.InvoiceNumber}</p>
                                    <p><b>Date: </b>{(new Date(invoice.InvoiceDate)).toLocaleDateString()}</p>
                                    <p><b>Company Name: </b>{invoice.Businessentity.name}</p>
                                    <p><b>Date Range: </b>{`${(new Date(invoice.StartDate)).toLocaleDateString()} - ${(new Date(invoice.EndDate)).toLocaleDateString()}`}</p>
                                    <div style={{ display: 'flex', fontSize: '20px' }}>
                                        <div>

                                        </div>
                                        {(LoadedInvoices.includes(index) ?
                                            <>
                                                <PDFDownloadLink
                                                    document=
                                                    {
                                                        <PDFInvoice employeeAdvances={invoice.Advances}
                                                            client={invoice.Businessentity}
                                                            invoiceNumber={invoice.InvoiceNumber}
                                                            invoiceDate={invoice.InvoiceDate}
                                                        />
                                                    } filename="test.pdf" >
                                                    {
                                                        ({ loading }) =>
                                                        (loading ? <Button>Loading Document...</Button> :
                                                            <FaFilePdf style={{ height: '20px', color: 'black', cursor: 'pointer' }}>Download</FaFilePdf>)
                                                    }
                                                </PDFDownloadLink>
                                                <FaFileExcel onClick={() =>
                                                    handleExcelDownload(invoice.Advances,
                                                        invoice.Businessentity,
                                                        invoice.InvoiceNumber,
                                                        invoice.InvoiceDate,
                                                        invoice.StartDate,
                                                        invoice.EndDate)} />
                                            </>
                                            :
                                            <Button id={index} onClick={loadInvoice}>Load Invoice</Button>
                                        )}
                                        {/* <FaFileDownload id={invoice._id} style={{cursor: 'pointer'}} onClick={downloadInvoice}/> */}
                                        {(invoice.Status === 'Pending') ? <FaBan id={invoice._id} style={{ color: 'red', cursor: 'pointer' }} onClick={cancelInvoice} /> : null}
                                        {(invoice.Status === 'Pending') ? <FaCheck id={invoice._id} style={{ color: 'green', cursor: 'pointer' }} onClick={approveInvoice} /> : null}
                                    </div>
                                </div>

                                <div style={{ textAlign: 'right' }}>
                                    <p className={`${(invoice.Status === 'Paid') ? 'advPaid' : ''} ${(invoice.Status === 'Pending') ? 'advPending' : ''} ${(invoice.Status === 'Cancelled') ? 'advFailed' : ''}`}>{invoice.Status}</p>
                                    <p><b>Advance Amount: </b>{invoice.AdvanceAmount}</p>
                                    <p><b>Fee Amount: </b>{invoice.FeeAmount}</p>
                                    <p><b>Membership Amount: </b>{invoice.MembershipAmount}</p>
                                    <hr style={{ margin: '0px' }} />
                                    <p><b>Total Excl.: </b>{invoice.TotalExcl}</p>
                                    <p><b>VAT: </b>{invoice.VAT}</p>
                                    <hr style={{ margin: '0px' }} />
                                    <p><b>Total Incl.: </b>{invoice.TotalIncl}</p>
                                </div>

                            </Card>
                        )
                    })}
                </div>
            </View>


        )
    }

})

export default Invoices
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';

import { FaWallet, FaTh, FaEllipsisH, FaComments, FaSignOutAlt, FaHome, FaRegUserCircle } from 'react-icons/fa';


import '../style.scss';
import './style.scss';

import Dash from './dashboard';
import Withdraw from './withdraw';
import More from './more';
import EmployeeSupportRequests from './support';


const Dashboard = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();
    const [searchParams] = useSearchParams();


    const [activePage, setActivePage] = useState('');


    useEffect(() => {
        console.log(searchParams.toString());
        if (activePage !== 'More' && searchParams !== '?More') {
            commonStore.loadBankAccounts(); //Async func
        }
        let initialPage = 'Dashboard';
        if (searchParams.toString().includes('More')) {
            initialPage = 'More';
        }
        if (searchParams === '?Withdraw') {
            initialPage = 'Withdraw';
        }
        if (searchParams === '?support' && !isMobile()) {
            initialPage = 'ContactUs';
        }
        setActivePage(initialPage);

        if (isMobile()) {
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
        window.addEventListener('resize', () => { resizeView() });
    }, []);

    function resizeView() {
        if (isMobile()) {
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }
    function setWithdraw() {
        setActivePage("Withdraw");
    }

    function optionSelected(e) {
        setActivePage(e.target.value);
    }

    function logout() {
        commonStore.logout();
    }

    function setLoading(val) {
        commonStore.setLoading(val);
    }

    if (isMobile()) {
        return (
            <div id="mobileDashView" className="view" style={{ justifyContent: 'flex-start' }}>
                <div>
                    <MobileTopBarClean />
                </div>
                <div className="dashboardContent">
                    {(activePage === 'Dashboard') ? <Dash setWithdraw={setWithdraw} /> : null}
                    {(activePage === 'Withdraw') ? <Withdraw /> : null}
                    {(activePage === 'More') ? <More /> : null}
                </div>
                <Card className="tabBar">
                    <Form className="tabBarForm" onChange={optionSelected} style={{ overflow: 'hidden' }}>

                        <div className="tabBarItem"> {/* Dashboard */}
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="dashboardTab" value="Dashboard" />
                            <FaHome className={(activePage === "Dashboard") ? "fa fa-thin fa-wallet tabSelected" : "fa fa-thin fa-wallet"} htmlFor="dashboardTab" style={{ height: '100%' }} />
                            <label className={(activePage === "Dashboard") ? "tabSelected" : null} htmlFor="dashboardTab">Home</label>
                        </div>

                        <div className="tabBarItem"> {/* Withdraw */}
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="withdrawTab" value="Withdraw" />
                            <FaWallet className={(activePage === "Withdraw") ? "fa fa-thin fa-wallet tabSelected" : "fa fa-thin fa-wallet"} htmlFor="withdrawTab" style={{ height: '100%' }} />
                            <label className={(activePage === "Withdraw") ? "tabSelected" : null} htmlFor="withdrawTab">Withdraw</label>
                        </div>

                        <div className="tabBarItem"> {/* More */}
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="moreTab" value="More" />
                            <FaRegUserCircle className={(activePage === "More") ? "fa fa-thin fa-wallet tabSelected" : "fa fa-thin fa-wallet"} htmlFor="moreTab" style={{ height: '100%' }} />
                            <label className={(activePage === "More") ? "tabSelected" : null} htmlFor="moreTab">Profile</label>
                        </div>
                    </Form>

                </Card>
            </div>
        );
    } else {
        return (
            <View>
                <img className="dashImg" alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                <div id="desktopContainer">

                    <div id="leftDiv">
                        <Form className="tabBarFormDesktop" onChange={optionSelected}>

                            <div className="tabBarItemDesktop" id={(activePage === "Withdraw") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="withdrawTab" value="Withdraw" />
                                <FaWallet className='tabBarItemDesktopIcon' htmlFor="withdrawTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="withdrawTab">Withdraw</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "Dashboard") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="dashboardTab" value="Dashboard" />
                                <FaTh className='tabBarItemDesktopIcon' htmlFor="dashboardTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="dashboardTab">Dashboard</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "More") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="moreTab" value="More" />
                                <FaEllipsisH className='tabBarItemDesktopIcon' htmlFor="moreTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="moreTab">More</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "ContactUs") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="contactusTab" value="ContactUs" />
                                <FaComments className='tabBarItemDesktopIcon' htmlFor="contactusTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="contactusTab">Contact us</label>
                            </div>
                        </Form>
                        <div className="tabBarItemDesktop">
                            <FaSignOutAlt className='tabBarItemDesktopIcon' style={{ marginRight: '2px', height: '100%' }} />
                            <label onClick={logout}>Logout</label>
                        </div>
                    </div>


                    <div id="rightDiv">
                        <Card className="desktopCard">
                            <CardBody className="desktopContent">
                                {(activePage === 'Dashboard') ? <Dash setWithdraw={setWithdraw} setLoading={setLoading} /> : null}
                                {(activePage === 'Withdraw') ? <Withdraw /> : null}
                                {(activePage === 'More') ? <More /> : null}
                                {(activePage === 'ContactUs') ? <EmployeeSupportRequests /> : null}
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </View>
        );
    }



})
export default Dashboard;

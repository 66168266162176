import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Card, CardBody, Button } from '../../../components/basic';
import { View, CustomInput } from '../../../shared/components';



import './style.scss';

const BankAccounts = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [IDNumber, setIDNumber] = useState('');
    const [bankAccounts, setBankAccounts] = useState([]);
    const [PrimaryBankAccount, setPrimaryBankAccount] = useState({});
    const [SecondaryBankAccount, setSecondaryBankAccount] = useState({});
    const [AddBankAccount, setAddBankAccount] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [BankAccount, setBankAccount] = useState({
        IDNumber: commonStore.employeeDetail.IDNumber,
        accountnumber: '',
        bankname: 'ABSA',
        branchcode: '632005',
        branchname: '',
        type: '01'
    });
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        loadData();
        // setIDNumber(commonStore.employeeDetail.IDNumber);
        // let ba = commonStore.retrieveBankAccounts();
        // setBankAccounts(ba);
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function loadData(){
        commonStore.getBankAccountsByID(commonStore.employeeDetail.IDNumber).then((ret) => {
            setPrimaryBankAccount(commonStore.employeeDetail.bankaccount || {});
            setSecondaryBankAccount(commonStore.employeeDetail.secondaryBankAccount || {});
        })
    }

    function addBackAccountClicked(){
        setAddBankAccount(true);
    }
    function cancelAddBankAccount(){
        setAddBankAccount(false);
        setBankAccount(
            {
                IDNumber: commonStore.employeeDetail.IDNumber,
                accountnumber: '',
                bankname: 'ABSA',
                branchcode: '632005',
                branchname: '',
                type: '01'
            }
        )
    }

    function bankNameChanged(e){
        let acc = BankAccount;
        acc.bankname = e.target.value;
        acc.branchcode = getBranchCode(e.target.value);
        setBankAccount(acc);
        forceUpdate();
    }

    function bankTypeChanged(e){
        let acc = BankAccount;
        acc.type = e.target.value;
        setBankAccount(acc);
        forceUpdate();
    }

    function getBranchCode(bank){
        var branchCode;
        switch(bank){
            case 'ABSA': branchCode = '632005'; break;
            case 'Access Bank': branchCode = ''; break;
            case 'African Bank': branchCode = '430000'; break;
            case 'Albaraka Bank': branchCode = '800000'; break;
            case 'Bank Of Athens': branchCode = '410506'; break;
            case 'Bank Windhoek': branchCode = ''; break;
            case 'Bidvest Bank': branchCode = '462005'; break;
            case 'Capitec Bank': branchCode = '470010'; break;
            case 'CitiBank': branchCode = '350005'; break;
            case 'Diamond Bank': branchCode = ''; break;
            case 'Discovery Bank': branchCode = '679000'; break;
            case 'FirstBank': branchCode = ''; break;
            case 'FNB Namibia': branchCode = ''; break;
            case 'FNB': branchCode = '250655'; break;
            case 'GTBank': branchCode = ''; break;
            case 'HSBC Bank': branchCode = '587000'; break;
            case 'Investec': branchCode = '580105'; break;
            case 'Mercantile Bank': branchCode = '450105'; break;
            case 'Nedbank': branchCode = '198765'; break;
            case 'Olympus Mobile': branchCode = '585001'; break;
            case 'RMB': branchCode = '250655'; break;
            case 'Sasfin Bank': branchCode = '683000'; break;
            case 'Standard Bank': branchCode = '051001'; break;
            case 'Standard Bank Namibia': branchCode = ''; break;
            case 'Standard Chartered Bank': branchCode = '730020'; break;
            case 'TymeBank': branchCode = '678910'; break;
            case 'United Bank for Africa': branchCode = ''; break;
            case 'Ubank Limited': branchCode = '431010'; break;
            case 'Zenith Bank': branchCode = ''; break;
            default: branchCode = ''; break;
        }
        return branchCode
    }
    function bankAccountChanged(e){
        let acc = BankAccount;
        if (e.currentTarget.id === 'accNum'){
            acc.accountnumber = e.target.value;
        }

        if (e.currentTarget.id === 'bName'){
            acc.branchname = e.target.value;
        }

        if (e.currentTarget.id === 'accType'){
            acc.type = e.target.value;
        }

        setBankAccount(acc);
        forceUpdate();
    }

    function createBankAccount(){
        if (BankAccount.accountnumber === '' || BankAccount.type === ''){
            window.alert('Please complete all fields.');
            return
        }

        let confirmed = window.confirm('The details entered are correct?');
        if (!confirmed){
            return
        }

        var data = {
            bankAccount: BankAccount
        };
        if (Object.keys(PrimaryBankAccount).length === 0){
            data.type = 'primary';
        }else if(Object.keys(SecondaryBankAccount).length === 0){
            data.type = 'secondary';
        }else{
            window.alert('You cannot add any more bank accounts, please contact support to change your bank account.')
            return
        }
        commonStore.setLoading(true);
        commonStore.addBankAccount(data).then((ret) => {
            if (ret === true){
                window.alert('Your bank account was added successfully.');
                commonStore.setLoading(false);
                loadData();
                cancelAddBankAccount();
            }else{
                window.alert('Your bank account could not be added, please try again.');
                commonStore.setLoading(false);
            }
        });


    }

    function createBankAccountDesktop(){
        if (BankAccount.accountnumber === '' || BankAccount.type === ''){
            window.alert('Please complete all fields.');
            return
        }

        let confirmed = window.confirm('The details entered are correct?');
        if (!confirmed){
            return
        }

        var data = {
            bankAccount: BankAccount
        };
        if (Object.keys(PrimaryBankAccount).length === 0){
            data.type = 'primary';
        }else if(Object.keys(SecondaryBankAccount).length === 0){
            data.type = 'secondary';
        }else{
            window.alert('You cannot add any more bank accounts, please contact support to change your bank account.')
            return
        }
        commonStore.setLoading(true);
        commonStore.addBankAccount(data).then((ret) => {
            if (ret === true){
                window.alert('Your bank account was added successfully.');
                commonStore.setLoading(false);
                goBack();
            }else{
                window.alert('Your bank account could not be added, please try again.');
                commonStore.setLoading(false);
            }
        });


    }

    function goBack(){
        navigate({pathname: '/home', search: 'More'});
    }
    function addBankAccount(){
        navigate({pathname: '/newbankaccount', search: 'BankAccounts'});
    }

        if (isMobile()){
            return(
                <div id="mobileDashView" className="view">
                    <Card>
                        <CardBody>
                            <header className="site-head d-flex align-items-center justify-content-between" style={{backgroundColor: '#ffffff'}}>
                                <div className="wrap mr-4">
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/back.png" alt='mnu' onClick={goBack} style={{cursor: 'pointer', width: '18px', height: '18px'}}/>
                                </div>
                                <div className="right-elems ml-auto d-flex wrap mr-4">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" style={{cursor: 'pointer', width: '125px'}}/>  
                                </div>
                            </header>
                        </CardBody>
                    </Card>
                    
                    <div className="viewContent view-pages view-session d-flex flex-column" style={{overflow: 'scroll'}}>
                        <p className="txtlead txtleft"><i className="fa fa-credit-card" aria-hidden="true"/>Bank accounts</p>
                        <p className='sectionHeader'>Primary Account</p>
                        {(Object.keys(PrimaryBankAccount).length > 0)? 
                        <Card className='bankaccCard'>
                            <div>
                                <p className='bankaccHeader'>{PrimaryBankAccount.bankname || ''}</p>
                                <p className='bankaccSub'>{`**** ${PrimaryBankAccount.accountnumber.substr(PrimaryBankAccount.accountnumber.length -4)}`}</p>
                            </div>
                        </Card>
                        : <p>No primary account added</p>}

                        <p className='sectionHeader'>Secondary Account</p>
                        {(Object.keys(SecondaryBankAccount).length > 0)? 
                        <Card className='bankaccCard'>
                            <div>
                                <p className='bankaccHeader'>{SecondaryBankAccount.bankname || ''}</p>
                                <p className='bankaccSub'>{`**** ${SecondaryBankAccount.accountnumber.substr(SecondaryBankAccount.accountnumber.length -4)}`}</p>
                            </div>
                        </Card>
                        : <p>No secondary account added</p>}

                        {((Object.keys(SecondaryBankAccount).length > 0) && (Object.keys(PrimaryBankAccount).length > 0)) ? null :
                        <Button className='cornered-button' onClick={addBackAccountClicked}>Add account</Button>
                        }

                        {(AddBankAccount) ?
                        <Card className='newBankAccountModal'>
                            <p className="txtlead txtleft">Add a Bank Account</p>
                            <label className="custom-field" style={{width: '90%'}}>
                                <select className='inp' value={BankAccount.bankname} onChange={bankNameChanged} required>
                                    <option value='ABSA'>ABSA</option>
                                    <option value='Access Bank'>Access Bank</option>
                                    <option value='African Bank'>African Bank</option>
                                    <option value='Capitec Bank'>Capitec Bank</option>
                                    <option value='Discovery Bank'>Discovery Bank</option>
                                    <option value='FNB'>FNB</option>
                                    <option value='Investec'>Investec</option>
                                    <option value='Nedbank'>Nedbank</option>
                                    <option value='Sasfin Bank'>Sasfin Bank</option>
                                    <option value='Standard Bank'>Standard Bank</option>
                                    <option value='TymeBank'>TymeBank</option>
                                </select>
                                <span className="placeholder">Bank</span>
                            </label>
                            <div style={{width: '90%'}}>
                                <CustomInput className='custom-field' type='text' id='accNum' value={(BankAccount.accountnumber)} placeholder='Account Number' onChange={bankAccountChanged}/>
                            </div>

                            <label className="custom-field" style={{width: '90%'}}>
                                <select className='inp' value={BankAccount.type} onChange={bankTypeChanged} required>
                                    <option value='01'>Current/Cheque</option>
                                    <option value='02'>Savings</option>
                                    <option value='03'>Transmission</option>
                                    <option value='04'>Bond</option>
                                    <option value='06'>Subscription Share</option>
                                    <option value='00'>Other</option>
                                </select>
                                <span className="placeholder">Account Type</span>
                            </label>
                            <Button className='cornered-button' onClick={createBankAccount} style={{marginTop: '20px'}}>Add Account</Button>
                            <Button className='transparentButton' onClick={cancelAddBankAccount}>Cancel</Button>
                        </Card>
                        :null}

                        
                    </div>
                    
                </div>
            );

        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                    <p className="txtlead"><i className="fa fa-credit-card" aria-hidden="true"/>Add Bank Account</p>
                                    <br/>
                                </header>
                                <Form style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <label className="custom-field" style={{width: '90%'}}>
                                        <select className='inp' value={BankAccount.bankname} onChange={bankNameChanged} required>
                                            <option value='ABSA'>ABSA</option>
                                            <option value='Access Bank'>Access Bank</option>
                                            <option value='African Bank'>African Bank</option>
                                            <option value='Capitec Bank'>Capitec Bank</option>
                                            <option value='Discovery Bank'>Discovery Bank</option>
                                            <option value='FNB'>FNB</option>
                                            <option value='Investec'>Investec</option>
                                            <option value='Nedbank'>Nedbank</option>
                                            <option value='Sasfin Bank'>Sasfin Bank</option>
                                            <option value='Standard Bank'>Standard Bank</option>
                                            <option value='TymeBank'>TymeBank</option>
                                        </select>
                                        <span className="placeholder">Bank</span>
                                    </label>
                                    <div style={{width: '90%'}}>
                                        <CustomInput className='custom-field' type='text' id='accNum' value={(BankAccount.accountnumber)} placeholder='Account Number' onChange={bankAccountChanged}/>
                                    </div>
                                    <label className="custom-field" style={{width: '90%'}}>
                                        <select className='inp' value={BankAccount.type} onChange={bankTypeChanged} required>
                                            <option value='01'>Current/Cheque</option>
                                            <option value='02'>Savings</option>
                                            <option value='03'>Transmission</option>
                                            <option value='04'>Bond</option>
                                            <option value='06'>Subscription Share</option>
                                            <option value='00'>Other</option>
                                        </select>
                                        <span className="placeholder">Account Type</span>
                                    </label>
                                    <Button onClick={createBankAccountDesktop} style={{width: '90%', marginTop: '20px'}}>Add Account</Button>
                                    <p className="backButton" onClick={goBack}>Back</p>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                   
                </View>
            );
        }
        
})

export default BankAccounts
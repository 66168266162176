import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../../components/basic';
import { View, MobileTopBarClean } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

const SecurityOptions = observer(() => {
    const navigate = useNavigate();
    const commonStore = useStore();

    const [error, setError] = useState('');


    useEffect(() => {
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);


    function nextClicked(){
        let { employeeDetail } = commonStore; 
        if (window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable){
            if (employeeDetail.IDNumber){
                commonStore.setLoading(true);
                commonStore.registerWebauthn(employeeDetail.IDNumber).then((ret) => {
                    console.log(ret);
                    if(ret === true){
                        commonStore.setLoading(false);
                        navigate('/authsuccess');
                    }else{
                        navigate('/passwordauth');
                    }
                });
            }else{
                console.log('ID not available');
                setError('There was an error authenticating your account, please contact support.')
            }
        }else{
            navigate('/passwordauth');
        }

    }

    function goBack(){
        navigate('/verifiedid')
    }

        if (isMobile()){
            return(
                <div id="mobileDashView" className="view">
                    <MobileTopBarClean/>
                    <div className="viewContent view-pages view-session d-flex flex-column">
                        <div className="svgDiv">
                            <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/addAuth.svg" alt="icon"/>
                        </div>
                        <p className="txtlead">Secure with device authentication</p>
                        <p className="txtsub">You can protect your account with your device authentication.</p>
                        <Form className="align-items-center">

                            <Button block size="lg" onClick={nextClicked}>Next &gt;</Button>

                        </Form>
                    </div>
                </div>
            );
        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg"/>
                                    <p className="txtlead">Secure with device authentication</p>
                                    <p className="txtsub">You can protect your account with your device authentication.</p>
                                </header>
                                <div className="svgDiv">
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/addAuth.svg" alt="icon"/>
                                </div>
                                    <Form>

                                    <Button block size="lg" onClick={nextClicked}>Next &gt;</Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                    
                </View>
            );
        }
    
})

export default SecurityOptions
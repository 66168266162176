import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Label, Button, Card, CardBody } from '../../../components/basic';
import { View, MobileTopBarClean } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

const PasswordAuth = observer(() => {
    const navigate = useNavigate();
    const commonStore = useStore();
    
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [err, setErr] = useState('');


    useEffect(() => {
        commonStore.setLoading(false)
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function passwordChanged(e){
        setPassword(e.target.value);

    }
    function password2Changed(e){
        setPassword2(e.target.value)
    }

    function doValidation(){


        if(!password2) {
            setErr('Please confirm your password');
            return false
        }
        else if(password !== password2) {
            setErr('Passwords do not match');
            return false
        }

        if (!strongRegex.test(password)){
            setErr('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return false
        }

        return true
    }


    function nextClicked(){
        let { employeeDetail } = commonStore; 
        let validated = doValidation();

        if(validated && employeeDetail.IDNumber && password){
            commonStore.setLoading(true);
            // Process Registration
            commonStore.register(employeeDetail.IDNumber, password).then((ret) => {
                if(ret && (ret === true)){
                    commonStore.setLoading(false);
                    navigate('/authsuccess'); // Registration Done.
                } else {
                    setErr('Someting went wrong, please contact the system administrator.');
                    commonStore.setLoading(false);
                    console.error('[Register]: ', ret);
                }
            }).catch((err) => {
                setErr('Someting went wrong, please contact the system administrator.');
                commonStore.setLoading(false);
                console.error('[Register]: ', err);
            });
        }

    }

    function goBack(){
        navigate('/securityoptions')
    }



        if (isMobile()){
            return(
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>    
                    </div>
                    
                    <div id="mainView" className="viewContent view-pages view-session justify-content-center d-flex flex-column">
                        <p className="txtlead">Secure with password</p>
                        <p className="txtsub">Your account could not be secured with device authentication, please provide a password.</p>
                        <Form style={{overflow: 'scroll'}}>
                            <label className="custom-field">
                                <input className='inp' type="password" id="password" value={password} onChange={passwordChanged} required/>
                                <span className="placeholder">Password</span>
                            </label>
                            <label className="custom-field">
                                <input className='inp' type="password" id="password2" value={password2} onChange={password2Changed} required/>
                                <span className="placeholder">Verify Password</span>
                            </label>
                            {(err && err.length > 0)?<Label className="err">{err}</Label>:null}
                            <Button block size="lg" onClick={nextClicked}>Next &gt;</Button>
                            <p className="backButton" onClick={goBack}>Back</p>
                        </Form>
                    </div>
                </div>
            );
        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg"/>
                                    <p className="txtlead">Secure with password</p>
                                    <p className="txtsub">Your account could not be secured with device authentication, please provide a password.</p>
                                </header>
                                <Form>
                                    <label className="custom-field">
                                        <input className='inp' type="password" id="password" value={password} onChange={passwordChanged} required/>
                                        <span className="placeholder">Password</span>
                                    </label>
                                    <label className="custom-field">
                                        <input className='inp' type="password" id="password2" value={password2} onChange={password2Changed} required/>
                                        <span className="placeholder">Verify Password</span>
                                    </label>
                                    {(err && err.length > 0)?<Label className="err">{err}</Label>:null}
                                    <Button block size="lg" onClick={nextClicked}>Next &gt;</Button>
                                    <p className="backButton" onClick={goBack}>Back</p>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                    
                </View>
            );
        }

})

export default PasswordAuth
import axios from 'axios';
import storageUtil from '../stores/storageUtil';

// #region Config
let middleware_base = process.env.REACT_APP_MIDDLEWARE_URL;
// let middleware_base = 'http://127.0.0.1:3003/api'

axios.defaults.baseURL = middleware_base;
const getAccessToken = () => {
  const loginToken = (storageUtil.hasItem('tkn')) ? storageUtil.getItem('tkn') : '';
  return loginToken;
};
// #endregion

// #region Axios Middleware
let middlewareRequest = axios.create({
  baseUrl: middleware_base,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Authorization': `Bearer ${getAccessToken()}`
  }
});

// #endregion

// #region Auth
const Auth = {
  isRegistered: (data) => middlewareRequest.post('/auth/register', data),
  setPassword: (id, data) => middlewareRequest.patch(`/auth/register/password/${id}`, data),
  getWebauthn: (IDNumber) => middlewareRequest.get(`/auth/register/webauthn/${IDNumber}`),
  setWebauthn: (id, data) => middlewareRequest.patch(`/auth/register/webauthn/${id}`, data),
  passwordLogin: (data) => middlewareRequest.post('/auth/login/password', data),
  checkWebauthn: (IDNumber) => middlewareRequest.get(`/auth/login/webauthn/${IDNumber}`),
  webauthnLogin: (data) => middlewareRequest.post('/auth/login/webauthn', data),
  changePassword: (id, data) => middlewareRequest.patch(`/auth/update/password/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  addPassword: (id, data) => middlewareRequest.put(`/auth/update/password/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  addWebauthn: (id, data) => middlewareRequest.put(`/auth/update/webauthn/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getQuestions: (id, data) => middlewareRequest.post(`/auth/verifyid/questions/${id}`, data),
  verifyQuestion: (data) => middlewareRequest.post(`/auth/verifyid/questions`, data),
  getSessionToken: (data) => middlewareRequest.post('/auth/verifyid/token', data),
  verifySelfie: (id, data) => middlewareRequest.post(`/auth/verifyid/selfie/${id}`, data),
  generatePasswordReset: (id) => middlewareRequest.get(`/auth/password/reset/${id}`),
  resetPassword: (jwtkn, data) => middlewareRequest.put('/auth/password/reset', data, { headers: { 'Authorization': `Bearer ${jwtkn}` } }),
  loadSapInfo: (id) => middlewareRequest.post(`/populateSap/${id}`),
};
// #endregion

// #region ID Verification
const IDVerification = {
  getIDVerificationByID: (id) => middlewareRequest.get(`/idverification/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createIDVerification: (id, data) => middlewareRequest.post(`/idverification/admin/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } })
}
// #endregion

// #region DataAccess
const DataAccess = {
  createDataAccessPermission: (data) => middlewareRequest.post('/dataAccessPermission', data)
};
// #endregion

// #region Agreement
const Agreement = {
  createAgreement: (data) => middlewareRequest.post('/agreement', data)
};
// #endregion

// #region Advance
const Advance = {
  process: (id, data) => middlewareRequest.post(`/advance/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  processManual: (id, data) => middlewareRequest.post(`/advance/admin/manual/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  processManualOzow: (id, data) => middlewareRequest.post(`/advance/admin/ozow/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  processBankAccount: (id, data) => middlewareRequest.post(`/advance/bankaccount/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateTransactionStatus: (data) => middlewareRequest.patch(`/advance/admin/status`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvancesForClientAndDate: (id, data) => middlewareRequest.post(`/advance/client/date/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvances: () => middlewareRequest.get(`/advance`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvanceHistory: (id) => middlewareRequest.get(`/advance/history/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  checkOzowPayoutStatus: (id) => middlewareRequest.get(`/advance/ozow/status/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvanceHistoryForClient: (id) => middlewareRequest.get(`/advance/client/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  importAdvances: (data) => middlewareRequest.post(`/advance/import`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAdvanceHistoryPlain: () => middlewareRequest.get(`/advance/plain`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion
// #region Transaction History
const TransactionHistory = {
  getTransactionHistory: () => middlewareRequest.get(`/transactionhistory`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
}
// #endregion
// #region Invoice
const Invoice = {
  writeToSagePayslip: (id, data) => middlewareRequest.post(`/invoice/payslip/write/sage/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getInvoices: () => middlewareRequest.get(`/invoice`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getInvoicesByClient: (id) => middlewareRequest.get(`/invoice/client/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createInvoice: (data) => middlewareRequest.post(`/invoice`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateInvoice: (id, data) => middlewareRequest.patch(`/invoice/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Businessentities
const Businessentities = {
  getClients: () => middlewareRequest.get('/clients', { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateClientById: (id, data) => middlewareRequest.patch(`/clients/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createClient: (data) => middlewareRequest.post(`/clients`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  testLabournet: (data) => middlewareRequest.patch(`/clients/testApi/Labournet`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  testPaySpace: (data) => middlewareRequest.patch(`/clients/testApi/PaySpace`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  sapTakeon: (id, data) => middlewareRequest.patch(`/clients/sap/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  sageTakeon: (id, data) => middlewareRequest.patch(`/clients/sage/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  payspaceTakeon: (id, data) => middlewareRequest.patch(`/clients/payspace/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Bankaccounts
const Bankaccounts = {
  getBankAccounts: (id) => middlewareRequest.get(`/BankAccount/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createBankAccount: (data) => middlewareRequest.post(`/BankAccount`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  deleteBankAccount: (id) => middlewareRequest.delete(`/BankAccount/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  addBankAccount: (id, data) => middlewareRequest.post(`/BankAccount/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateBankAccount: (data) => middlewareRequest.patch(`/BankAccount`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Reporting
const Reporting = {
  getUserSignUpsPerDate: (data) => middlewareRequest.post(`/reporting/usersSignedUp/dates`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Employees
const Employees = {
  getEmployee: (id) => middlewareRequest.get(`/employees/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getEmployees: () => middlewareRequest.get(`/employees`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateEmployee: (id, data) => middlewareRequest.patch(`/employees/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  loadLabourNetInfo: (id) => middlewareRequest.post(`/employees/payroll/labournet/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  loadPaySpaceInfo: (id) => middlewareRequest.post(`/employees/payroll/payspace/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  loadSapInfo: (id) => middlewareRequest.patch(`/employees/populateSap/${id}`),
  loadSageInfo: (id) => middlewareRequest.patch(`/employees/payroll/populateSage/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  loadCSVFee: (id) => middlewareRequest.patch(`/employees/populateCsvFee/${id}`),
  getEmployeeByBusinessEntity: (id) => middlewareRequest.get(`/employees/businessentity/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  uploadCSV: (data) => middlewareRequest.post(`/employees/csv`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  checkIfCSVExists: (data) => middlewareRequest.post(`/employees/csv/exists`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateCSV: (data) => middlewareRequest.patch(`/employees/csv`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createEmployee: (data) => middlewareRequest.post(`/employees`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  deleteEmployee: (id) => middlewareRequest.delete(`/employees/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createEmployeeBaselineAssessment: (data) => middlewareRequest.post(`/baselineassessment`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getBaselineAssessmentsById: (id) => middlewareRequest.get(`/baselineassessment/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getBaselineAssessments: () => middlewareRequest.get(`/baselineassessment`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  bulkUpdateEmployees: (data) => middlewareRequest.patch(`/employees/bulk`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } })
};
// #endregion

// #region Employeeadvancehistories
const Employeeadvancehistories = {
  getHistory: (id) => middlewareRequest.get(`calculateAmountAvailable/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Employeecontacts
const Employeecontacts = {
  update: (id, data) => middlewareRequest.patch(`/employeeContact/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } })
};
// #endregion

// #region Employeeinfos
const Employeeinfos = {
  update: (id, data) => middlewareRequest.patch(`/employeeInfo/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getAll: () => middlewareRequest.get(`/employeeInfo/all`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } })
};
// #endregion

// #region Errors
const Errors = {
  getErrors: () => middlewareRequest.get(`/errors`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateErrors: (id, data) => middlewareRequest.patch(`/errors/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Whatsapp
const Whatsapp = {
  getWhatsappToken: (id) => middlewareRequest.get(`whatsapp/token/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Contact Submissions
const ContactSubmission = {
  createContactSubmission: (data) => middlewareRequest.post(`/contactsubmission`, data),
  getContactSubmissionById: (id) => middlewareRequest.get(`/contactsubmission/id/${id}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateContactSubmissionById: (id, data) => middlewareRequest.patch(`/contactsubmission/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  getContactSubmissios: (id) => middlewareRequest.get(`/contactsubmission`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region User 
const User = {
  getUserByRole: (role) => middlewareRequest.get(`/users/role/${role}`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  updateUserByUsername: (id, data) => middlewareRequest.patch(`/users/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  createUser: (data) => middlewareRequest.post(`/users`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  blockUser: (id, data) => middlewareRequest.patch(`/users/block/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
  unblockUser: (id, data) => middlewareRequest.patch(`/users/unblock/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Employeesalaries
const Employeesalaries = {
  updateSalary: (id, data) => middlewareRequest.patch(`/employeesalary/${id}`, data, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
};
// #endregion

// #region Ozow
const Ozow = {
  getBalance: () => middlewareRequest.get(`/ozow/balance`, { headers: { 'Authorization': `Bearer ${getAccessToken()}` } }),
}
// #endregion

// #region Config
const Config = {
  getAppVersion: () => middlewareRequest.get(`/config/version`),
}
// #endregion

export {
  Auth,
  DataAccess,
  Advance,
  Businessentities,
  Bankaccounts,
  Employees,
  Employeeadvancehistories,
  Employeecontacts,
  Employeeinfos,
  Employeesalaries,
  User,
  Reporting,
  Errors,
  ContactSubmission,
  Whatsapp,
  Agreement,
  IDVerification,
  Invoice,
  Ozow,
  Config,
  TransactionHistory
};
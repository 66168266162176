import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';

import '../../style.scss';
import './style.scss';



const Withdraw = observer(() => {
    const navigate = useNavigate();
    const commonStore = useStore();

    const [amountAvailable, setAmountAvailable] = useState(1);
    const [amountAllowed, setAmountAllowed] = useState(0);
    const [history, setHistory] = useState([]);
    const [withdrawalAmount, setWithdrawalAmount] = useState('');
    const [maxHistory, setMaxHistory] = useState('');
    const [error, setError] = useState('');
    const [formattedAmountAllowed, setFormattedAmountAllowed] = useState('');
    const [baselineCompleted, setBaselineCompleted] = useState(false)

    useEffect(() => {
        loadData();
    }, []);


    function loadData() {
        setAmountAllowed(commonStore.amountAllowed);
        setFormattedAmountAllowed(amountAllowed.toLocaleString())
        setAmountAvailable(commonStore.amountAvailable);
        let advHist = commonStore.advanceHistories;
        advHist.sort((b, a) => new Date(a.AdvanceRequestDate) - new Date(b.AdvanceRequestDate));
        setHistory(advHist);
    }

    function handleChange(e) {

        setError('');
        let inputStr = e.target.value;
        inputStr = inputStr.replace(/\s/g, '');
        if ((!isNaN(inputStr) && !isNaN(parseFloat(inputStr))) || inputStr === '') {
            setWithdrawalAmount(inputStr);
        } else {
            setError('Please enter an amount.');
        }


    }

    function viewMore() {

        setMaxHistory(maxHistory + 5);
    }

    const checkAssessment = async () => {
        commonStore.setLoading(true);
        try {
            const ret = await commonStore.getBaselineAssessmentsById(commonStore.employeeDetail.IDNumber);
            console.log(ret);

            if (Object.keys(ret).length === 0) { //object is empty
                return 'pre';
            } else {
                //post assessment
                if (ret.BaselineAssessments) {
                    if (ret.BaselineAssessments.length <= 1 && ret.BaselineAssessments[0].DateTaken) {
                        const firstAssessmentDate = new Date(ret.BaselineAssessments[0].DateTaken);
                        const threeMonthsAgo = new Date();
                        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

                        if (firstAssessmentDate.getTime() <= threeMonthsAgo.getTime()) {
                            return 'post';
                        } else {
                            return 'too soon';
                        }

                    } else {
                        return 'done';
                    }
                }
            }
        } catch (error) {
            console.error('An error occurred while checking assessment:', error);
            return 'error';
        }
    };


    async function withdraw() {
        commonStore.setLoading(true);
        const checkAssessmentResult = await checkAssessment()
        console.log(checkAssessmentResult)

        if (checkAssessmentResult === 'pre') {
            console.log(true)
            commonStore.setLoading(false);
            navigate({ pathname: '/baseline', search: 'pre' })
        }
        else if (checkAssessmentResult === 'post') {
            console.log(true)
            commonStore.setLoading(false);
            navigate({ pathname: '/baseline', search: 'post' })
        }
        else if (checkAssessmentResult === 'error') {
            commonStore.setLoading(false);
            setError("Error, please try again")
        }

        else if (checkAssessmentResult === 'done' || checkAssessmentResult === 'too soon') {

            if (isNaN(withdrawalAmount) || isNaN(parseFloat(withdrawalAmount))) {
                setError('Please enter an amount.');
                commonStore.setLoading(false);
            } else if (parseFloat(withdrawalAmount) > parseFloat(amountAllowed)) {
                setError('The amount entered exceeds the amount you have available.');
                commonStore.setLoading(false);
            } else if ((parseFloat(withdrawalAmount) * 1.03) > parseFloat(amountAllowed)) {
                setError('The amount entered and transaction fee exceed the amount you have available.');
                commonStore.setLoading(false);
            } else if (parseFloat(withdrawalAmount) <= 0) {
                setError('Please enter an amount greater than 0.');
                commonStore.setLoading(false);
            } else {
                commonStore.setLoading(true);
                await commonStore.loadBankAccounts();
                navigate({ pathname: '/withdraw', search: withdrawalAmount });
            }
        }

    }

    if (isMobile()) {
        return (
            <View>
                <div className="viewContent view-pages view-session d-flex flex-column">
                    <header>
                        <p className="txtlead txtLeft">Withdraw</p>
                    </header>

                    <div id="onDemandPay">
                        <Card id="dashCard">
                            <div style={{ padding: '10px' }}>
                                <p style={{ fontWeight: '700' }} className="txtsub txtLeft">Access your pay</p>
                                <hr style={{ color: 'rgb(0 0 0 / 28%)' }} />

                                <svg viewBox="13 0 100 75" xmlns="http://www.w3.org/2000/svg">
                                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
                                        <stop offset="0%" stopColor="#56c4fb" />
                                        <stop offset="100%" stopColor="#0baeff" />
                                    </linearGradient>
                                    <path className="grey" d="M22 61A40 40 0 11104 61" fill='none' />
                                    <g filter="url(#filter0_d_2414_15661)">
                                        <path id="blue" fill='none' strokeDashoffset={130 - (130 * (parseInt(amountAllowed) / parseInt(amountAvailable)))} className="blue" d="M22 61A40 40 0 11104 61" />
                                    </g>
                                    <text className="withdrawTxtHead" x="64%" y="65%" dominantBaseline="middle" textAnchor="middle">{`R ${amountAllowed.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}</text>
                                    <text className="withdrawTxtSub" x="64%" y="80%" dominantBaseline="middle" textAnchor="middle">Available balance</text>
                                    <defs>
                                        <filter id="filter0_d_2414_15661" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="0" dy="0" />
                                            <feGaussianBlur stdDeviation="10" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.223529 0 0 0 0 0.580392 0 0 0 0.4 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2414_15661" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2414_15661" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>

                                <text style={{ textAlign: 'center' }} className='txtsub'>Enter an amount you would like to withdraw to your bank account</text>
                                <Form>
                                    <label className="custom-field">
                                        <input className='inp' type="text" id="withdrawAmount" value={withdrawalAmount} onKeyDown={(e) => { if (e && e.key && e.key === 'Enter') { e.preventDefault(); withdraw(); } }} onChange={handleChange} required />
                                        <span>Withdrawal amount</span>
                                    </label>
                                    {(error.length > 0) ? <p style={{ color: 'red' }}>{error}</p> : null}
                                    <Button className='border-radius-button' block size="lg" onClick={withdraw} style={{ marginTop: '10px' }}>Level me</Button>
                                </Form>
                            </div>

                        </Card>
                    </div>

                    <div id="withdrawalHistory">
                        <p className="txtlead txtLeft">Withdrawal history</p>
                        {history.map((histObj, index) => {
                            let date = new Date(histObj.AdvanceRequestDate);
                            let displayDate = `${date.getDate().toString()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear().toString()}`;
                            let status = (histObj.transactionhistory && histObj.transactionhistory.TransactionStatus) || 'Paid';
                            if (status === 'Pending') {
                                status = 'Processing';
                            }
                            let amount = histObj.AdvanceAmount;
                            return (
                                <Card className="transCard" id="dashCard" key={index}>
                                    <div className="transHistory">
                                        <div className="transDetails">
                                            <p style={{ fontWeight: '400' }} className="transactionDate">{displayDate}</p>
                                            <p className={`transactionStatus ${status}`}>{status}</p>
                                        </div>
                                        <div style={{ width: '45%' }} className="transAmount">
                                            <p className="transactionAmount">{`R${amount}`}</p>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })}
                    </div>

                </div>

            </View>
        );
    } else {
        return (
            <View style={{ backgroundColor: '#ffffff' }}>
                <header style={{ backgroundColor: '#ffffff' }}>
                    <div>
                        <p className="txtlead txtLeft" style={{ marginBottom: '0px' }}>Withdraw</p>
                    </div>
                </header>
                <div id="onDemandPay" style={{ backgroundColor: '#ffffff' }}>
                    <div className="desktopDashHeader">
                        <p style={{ fontWeight: '700' }} className="txtsub txtLeft">Access your pay</p>
                        <Button className="transparentButton" block size="sm" onClick={withdraw} style={{ maxWidth: '20%' }}>Level me</Button>
                    </div>
                    <hr style={{ color: 'rgb(0 0 0 / 28%);' }} />
                    <div className="progressBarContainer">
                        <div className="onDemandBase"></div>
                        <div className="onDemandProgress" style={{ width: `${parseInt(amountAllowed) / parseInt(amountAvailable) * 100}%` }}></div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <div></div> */}
                            <p className="withdrawTxtDesktop"><span style={{ color: '#868685', fontSize: '100%' }}>Amount available: </span>{`R${amountAllowed.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}</p>
                            <label className="custom-field" style={{ width: '40%' }}>
                                <input className='inp' type="text" id="withdrawAmount" value={withdrawalAmount} onChange={handleChange} required />
                                <span className="placeholder">Withdrawal amount</span>
                            </label>

                        </div>
                        {(error.length > 0) ? <p style={{ color: 'red' }}>{error}</p> : null}

                    </div>
                </div>

                <div id="withdrawalHistory" style={{ backgroundColor: '#ffffff' }}>
                    <div className="desktopDashHeader">
                        <p className="txtsub txtLeft">Withdrawal history</p>
                        <Button className="transparentButton" block size="sm" onClick={viewMore} style={{ maxWidth: '20%' }}>View more</Button>
                    </div>
                    <hr />
                    {history.map((histObj, index) => {
                        let date = new Date(histObj.AdvanceRequestDate);
                        let displayDate = `${date.getDate().toString()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear().toString()}`;
                        let status = (histObj.transactionhistory && histObj.transactionhistory.TransactionStatus) || 'Paid';
                        if (status === 'Pending') {
                            status = 'Processing';
                        }
                        let amount = histObj.AdvanceAmount;
                        return (
                            <Card className="transCard" id="dashCard" key={index}>
                                <div className="transHistory">
                                    <div className="transDetails">
                                        <p className="transactionDate">{displayDate}</p>
                                        <p className={`transactionStatus ${status}`}>{status}</p>
                                    </div>
                                    <div className="transAmount">
                                        <p className="transactionAmount">{`R${amount}`}</p>
                                    </div>
                                </div>
                            </Card>
                        )
                    })}
                </div>

            </View>
        );
    }


})

export default Withdraw;
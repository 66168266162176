import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Card, CardBody, Button } from '../../../components/basic';
import { View } from '../../../shared/components';



import './style.scss';

const Agreements = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    useEffect(() => {
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function goBack(){
        navigate({pathname: '/home', search: 'More'});
    }
    
    function downloadPrivacyPolicy(){
        commonStore.downloadPrivacyPolicy();
    }

    function downloadTsCs(){
        commonStore.downloadTsCs();
    }

    function downloadAgreement(){
        commonStore.downloadAgreement();
    }


        if (isMobile()){
            return(
                <div id="mobileDashView" className="view">
                    <Card>
                        <CardBody>
                            <header className="site-head d-flex align-items-center justify-content-between" style={{backgroundColor: '#ffffff'}}>
                                <div className="wrap mr-4">
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/back.png" alt='mnu' onClick={goBack} style={{cursor: 'pointer', width: '18px', height: '18px'}}/>
                                </div>
                                <div className="right-elems ml-auto d-flex wrap mr-4">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" style={{cursor: 'pointer', width: '125px'}}/>  
                                </div>
                            </header>
                        </CardBody>
                    </Card>
                    
                    <div className="viewContent view-pages view-session d-flex flex-column" style={{overflow: 'scroll'}}>
                        <p className="txtlead txtleft"><i className="fa fa-download" aria-hidden="true"/>Agreements</p>
                        <Form>
                        <Button className='cornered-button'  block size="lg" onClick={downloadAgreement} style={{marginTop: '10px', marginBottom: '10px'}}>Download agreement</Button>
                        <Button className='cornered-button' block size="lg" onClick={downloadPrivacyPolicy} style={{marginTop: '10px', marginBottom: '10px'}}>Download privacy policy</Button>
                        <Button className='cornered-button' block size="lg" onClick={downloadTsCs} style={{marginTop: '10px', marginBottom: '10px'}}>Download terms and conditions</Button>
                        </Form>
                    </div>
                    
                </div>
            );

        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                    <p className="txtlead"><i className="fa fa-user" aria-hidden="true"/>Profile</p>
                                    <br/>
                                </header>
                                <Form>
                                    <Button  block size="lg" onClick={downloadAgreement} style={{marginTop: '10px', marginBottom: '10px'}}>Download agreement</Button>
                                    <Button  block size="lg" onClick={downloadPrivacyPolicy} style={{marginTop: '10px', marginBottom: '10px'}}>Download privacy policy</Button>
                                    <Button  block size="lg" onClick={downloadTsCs} style={{marginTop: '10px', marginBottom: '10px'}}>Download terms and conditions</Button>
                                    <p className="backButton" onClick={goBack}>Back</p>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                   
                </View>
            );
        }
        
}) 

export default Agreements
import React, {useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../../components/basic';
import { View, MobileTopBarClean } from '../../../shared/components';

import '../../style.scss';
import './style.scss';

const VerifiedId = observer(() => {
    const navigate = useNavigate();
    

    useEffect(() => {
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function nextClicked(){

        navigate('/agreement');
    }

    function goBack(){

        navigate('/verifyid')
    }

        if (isMobile()){
            return(
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>
                    </div>
                    
                    <div className="viewContent view-pages view-session d-flex justify-content-center flex-column">
                        <div className="svgDiv" style={{margin: '1px', height: '35%'}}>
                            <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/verifiedId.svg" alt="icon"/>
                        </div>
                        <p className="txtlead">Identity verified</p>
                        <p className="txtsub">Your identity has been verified. Next you will need to secure your user account.</p>
                        <Button block size="lg" onClick={nextClicked}>Next &gt;</Button>
                    </div>
                </div>
            );
        }else{
            return(
                <View>
                    <div id="mainView" className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg"/>
                                    <p className="txtlead">Identity verified</p>
                                    <p className="txtsub">Your identity has been verified. Next you will need to secure your user account.</p>
                                </header>
                                <div className="svgDiv">
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/verifiedId.svg" alt="icon"/>
                                </div>
                                <Form>
                                    <Button block size="lg" onClick={nextClicked}>Next &gt;</Button>

                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                    
                </View>
            );
        }
})

export default VerifiedId
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

// style
import './style.scss';

@inject('commonStore')
@observer
export default class Loading extends React.Component {
    render() {
        let { overwrite } = this.props;
        let { isLoading } = this.props.commonStore;
        let check = document.getElementsByClassName("loading-overlay").length;

        if((overwrite || isLoading) && check <= 0){
            return (
                <div id="loading-overlay" className="loading-overlay">
                    <div className="lds-ripple"><div></div><div></div><div></div></div>
                </div>
            );
        } else {
            return null;
        }
    }
}

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { View } from '../../../shared/components';

import { FaFileExcel } from 'react-icons/fa';



import '../../style.scss';
import './style.scss';

const XLSX = require('xlsx');

const Assessments = observer(() => {

    const commonStore = useStore();

    const [Assessments, setAssessments] = useState([]);

    const [Clients, setClients] = useState([]);
    const [SelectedCompanyIndex, setSelectedCompanyIndex] = useState('-1');
    const [SelectedCompany, setSelectedCompany] = useState({});
    const [Employees, setEmployees] = useState(0);
    const [EmployeeList, setEmployeeList] = useState([]);
    const [EmployeesSignedUp, setEmployeesSignedUp] = useState(0)
    const [showQA, setShowQA] = useState({});

    useEffect(() => {
        loadClients();
        loadAssessments();

    }, []);


    function loadClients() {
        commonStore.setLoading(true);
        commonStore.getAllClients().then((ret) => {
            setClients(ret);
            commonStore.setLoading(false);
        })
    }

    function loadAssessments() {
        commonStore.setLoading(true);
        commonStore.getBaselineAssessments().then((ret) => {

            let assessments = ret;
            let Arr = [];
            assessments.forEach((item) => {
                if (!item.IDNumber || !item.BaselineAssessments || item.BaselineAssessments.length === 0) {
                    return
                }
                else {
                    item.BaselineAssessments.forEach((assess) => {
                        if (!assess.SpendScore) {
                            return
                        }
                        else {
                            Arr.push(item)
                        }
                    })

                }
            })
            setAssessments(Arr);
            commonStore.setLoading(false);
        })
    }

    function countObjectsWithBusinessEntityId(data, businessEntityId) {
        return data.reduce((count, obj) => {
            if (businessEntityId === '-1') {
                if (obj.BusinessEntity && obj.BusinessEntity._id && obj.SaveScore) {
                    return count + 1;
                }
            }
            if (obj.BusinessEntity && obj.BusinessEntity._id === businessEntityId) {
                return count + 1;
            }
            return count;
        }, 0);
    }


    const calculateAverageOverall = (data, businessEntityId) => {
        let count = countObjectsWithBusinessEntityId(data, businessEntityId);
        let sum = 0;
        data.forEach((assess) => {
            if (assess.BusinessEntity._id === businessEntityId) {
                assess.BaselineAssessments.forEach((item) => {
                    sum = sum + item.OverallScore
                })
            }
        })

        return sum / count
    }


    const calculateExecutiveReportData = (data, businessEntityId) => {
        let report = {
            demographic: {
                genX: 0,
                genZ: 0,
                millenial: 0,
                babyBoomers: 0
            },
            employmentLength: {
                lessYear: 0,
                oneToThree: 0,
                threeToSix: 0,
                sixToTen: 0,
                tenToFifteen: 0,
                moreFifteen: 0
            },
            householdBudget: {
                yes: 0,
                no: 0,
                maybe: 0
            },
            householdBudgetDiscipline: {
                yes: 0,
                no: 0,
            },
            spendingVsIncome: {
                less: 0,
                littleLess: 0,
                equal: 0,
                littleMore: 0,
                more: 0
            },
            bill12months: {
                onTime: 0,
                nearlyOnTime: 0,
                mostOnTime: 0,
                someOnTime: 0,
                fewOnTime: 0
            },
            currentSpending: {
                sixMonths: 0,
                threeToFiveMonths: 0,
                oneToTwoMonths: 0,
                oneToThreeWeeks: 0,
                lessWeek: 0
            },
            longTerm: {
                very: 0,
                moderately: 0,
                somewhat: 0,
                slightly: 0,
                not: 0
            },
            debt: {
                no: 0,
                manageable: 0,
                bitMoreManageable: 0,
                farMoreManageable: 0
            },
            credit: {
                excellent: 0,
                veryGood: 0,
                good: 0,
                fair: 0,
                poor: 0,
                idk: 0
            },
            insurance: {
                very: 0,
                moderately: 0,
                somewhat: 0,
                slightly: 0,
                not: 0,
                noOne: 0
            },
            plan: {
                agreeStrongly: 0,
                agreeSomewhat: 0,
                neither: 0,
                disagreeSomewhat: 0,
                disagreeStrongly: 0,
            },
            householdIncome: {
                less500k: 0,
                btwn50k150k: 0,
                btwn150k300k: 0,
                btwn300k500k: 0,
                more500k: 0
            },
            stress: {
                high: 0,
                moderate: 0,
                some: 0,
                none: 0
            },
            stressImpact: {
                veryOften: 0,
                often: 0,
                seldom: 0,
                never: 0
            }
        }

        let total = 0
        let client = ''

        data.forEach((assess) => {
            if (assess.BusinessEntity._id === businessEntityId) {
                client = assess.BusinessEntity
                assess.BaselineAssessments.forEach((item) => {
                    total += 1
                    switch (item.QuestionAnswerPairs[0].answer) {
                        case "Millennial (1981-1996)":
                            report.demographic.millenial += 1;
                            break;
                        case "Gen X (1965-1980)":
                            report.demographic.genX += 1;
                            break;
                        case "Gen Z (1997-2012)":
                            report.demographic.genZ += 1;
                            break;
                        case "Baby Boomers (1946-1964)":
                            report.demographic.babyBoomers += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[1].answer) {
                        case "Less than a year":
                            report.employmentLength.lessYear += 1;
                            break;
                        case "1-3 years":
                            report.employmentLength.oneToThree += 1;
                            break;
                        case "3-6 years":
                            report.employmentLength.threeToSix += 1;
                            break;
                        case "6-10 years":
                            report.employmentLength.sixToTen += 1;
                            break;
                        case "10-15 years":
                            report.employmentLength.tenToFifteen += 1;
                            break;
                        case "15+ years":
                            report.employmentLength.moreFifteen += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[2].answer) {
                        case "Yes":
                            report.householdBudget.yes += 1;
                            break;
                        case "No":
                            report.householdBudget.no += 1;
                            break;
                        case "Maybe":
                            report.householdBudget.maybe += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[3].answer) {
                        case "Yes":
                            report.householdBudgetDiscipline.yes += 1;
                            break;
                        case "No":
                            report.householdBudgetDiscipline.no += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[4].answer) {
                        case "Spending was much less than income":
                            report.spendingVsIncome.less += 1;
                            break;
                        case "Spending was a little less than income":
                            report.spendingVsIncome.littleLess += 1;
                            break;
                        case "Spending was about equal to income":
                            report.spendingVsIncome.equal += 1;
                            break;
                        case "Spending was a little more than income":
                            report.spendingVsIncome.littleMore += 1;
                            break;
                        case "Spending was much more than income":
                            report.spendingVsIncome.more += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[5].answer) {
                        case "Pay all of our bills on time":
                            report.bill12months.onTime += 1;
                            break;
                        case "Pay nearly all of our bills on time":
                            report.bill12months.nearlyOnTime += 1;
                            break;
                        case "Pay most of our bills on time":
                            report.bill12months.mostOnTime += 1;
                            break;
                        case "Pay some of our bills on time":
                            report.bill12months.someOnTime += 1;
                            break;
                        case "Pay very few of our bills on time":
                            report.bill12months.fewOnTime += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[6].answer) {
                        case "6 months or more":
                            report.currentSpending.sixMonths += 1;
                            break;
                        case "3-5 months":
                            report.currentSpending.threeToFiveMonths += 1;
                            break;
                        case "1-2 months":
                            report.currentSpending.oneToTwoMonths += 1;
                            break;
                        case "1-3 weeks":
                            report.currentSpending.oneToThreeWeeks += 1;
                            break;
                        case "Less than 1 week":
                            report.currentSpending.lessWeek += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[7].answer) {
                        case "Very confident":
                            report.longTerm.very += 1;
                            break;
                        case "Moderately confident":
                            report.longTerm.moderately += 1;
                            break;
                        case "Somewhat confident":
                            report.longTerm.somewhat += 1;
                            break;
                        case "Slightly confident":
                            report.longTerm.slightly += 1;
                            break;
                        case "Not at all confident":
                            report.longTerm.not += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[8].answer) {
                        case "Do not have any debt":
                            report.debt.no += 1;
                            break;
                        case "Have a manageable amount of debt":
                            report.debt.manageable += 1;
                            break;
                        case "Have a bit more debt than is manageable":
                            report.debt.bitMoreManageable += 1;
                            break;
                        case "Have far more debt than is manageable":
                            report.debt.farMoreManageable += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")

                    }

                    switch (item.QuestionAnswerPairs[9].answer) {
                        case "Excellent":
                            report.credit.excellent += 1;
                            break;
                        case "Very Good":
                            report.credit.veryGood += 1;
                            break;
                        case "Good":
                            report.credit.good += 1;
                            break;
                        case "Fair":
                            report.credit.fair += 1;
                            break;
                        case "Poor":
                            report.credit.poor += 1;
                            break;
                        case "I don't know":
                            report.credit.idk += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[10].answer) {
                        case "Very confident":
                            report.insurance.very += 1;
                            break;
                        case "Moderately confident":
                            report.insurance.moderately += 1;
                            break;
                        case "Somewhat confident":
                            report.insurance.somewhat += 1;
                            break;
                        case "Slightly confident":
                            report.insurance.slightly += 1;
                            break;
                        case "Not at all confident":
                            report.insurance.not += 1;
                            break;
                        case "No one in my household has any insurance":
                            report.insurance.noOne += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[11].answer) {
                        case "Agree strongly":
                            report.plan.agreeStrongly += 1;
                            break;
                        case "Agree somewhat":
                            report.plan.agreeSomewhat += 1;
                            break;
                        case "Neither agree nor disagree":
                            report.plan.neither += 1;
                            break;
                        case "Disagree somewhat":
                            report.plan.disagreeSomewhat += 1;
                            break;
                        case "Disagree strongly":
                            report.plan.disagreeStrongly += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[12].answer) {
                        case "Less than a 50 000 net per annum":
                            report.householdIncome.less500k += 1;
                            break;
                        case "50 000 - 150 000 net per annum":
                            report.householdIncome.btwn50k150k += 1;
                            break;
                        case "150 000 - 300 000 net per annum":
                            report.householdIncome.btwn150k300k += 1;
                            break;
                        case "300 000 - 500 000 net per annum":
                            report.householdIncome.btwn300k500k += 1;
                            break;
                        case "500 000+":
                            report.householdIncome.more500k += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[13].answer) {
                        case "High stress":
                            report.stress.high += 1;
                            break;
                        case "Moderate":
                            report.stress.moderate += 1;
                            break;
                        case "Some stress":
                            report.stress.some += 1;
                            break;
                        case "None":
                            report.stress.none += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }

                    switch (item.QuestionAnswerPairs[14].answer) {
                        case "Very often":
                            report.stressImpact.veryOften += 1;
                            break;
                        case "Often":
                            report.stressImpact.often += 1;
                            break;
                        case "Seldom":
                            report.stressImpact.seldom += 1;
                            break;
                        case "Never":
                            report.stressImpact.never += 1;
                            break;
                        default:
                            console.log("Answer doesn't match")
                    }
                })
            }
        })

        report.demographic.babyBoomers = report.demographic.babyBoomers / total * 100
        report.demographic.genX = report.demographic.genX / total * 100
        report.demographic.genZ = report.demographic.genZ / total * 100
        report.demographic.millenial = report.demographic.millenial / total * 100

        report.credit.excellent = report.credit.excellent / total * 100;
        report.credit.veryGood = report.credit.veryGood / total * 100;
        report.credit.good = report.credit.good / total * 100;
        report.credit.fair = report.credit.fair / total * 100;
        report.credit.poor = report.credit.poor / total * 100;
        report.credit.idk = report.credit.idk / total * 100;

        report.employmentLength.lessYear = report.employmentLength.lessYear / total * 100;
        report.employmentLength.oneToThree = report.employmentLength.oneToThree / total * 100;
        report.employmentLength.threeToSix = report.employmentLength.threeToSix / total * 100;
        report.employmentLength.sixToTen = report.employmentLength.sixToTen / total * 100;
        report.employmentLength.tenToFifteen = report.employmentLength.tenToFifteen / total * 100;
        report.employmentLength.moreFifteen = report.employmentLength.moreFifteen / total * 100;

        report.householdBudget.yes = report.householdBudget.yes / total * 100;
        report.householdBudget.no = report.householdBudget.no / total * 100;
        report.householdBudget.maybe = report.householdBudget.maybe / total * 100;

        report.householdBudgetDiscipline.yes = report.householdBudgetDiscipline.yes / total * 100;
        report.householdBudgetDiscipline.no = report.householdBudgetDiscipline.no / total * 100;

        report.spendingVsIncome.less = report.spendingVsIncome.less / total * 100;
        report.spendingVsIncome.littleLess = report.spendingVsIncome.littleLess / total * 100;
        report.spendingVsIncome.equal = report.spendingVsIncome.equal / total * 100;
        report.spendingVsIncome.littleMore = report.spendingVsIncome.littleMore / total * 100;
        report.spendingVsIncome.more = report.spendingVsIncome.more / total * 100;

        report.longTerm.very = report.longTerm.very / total * 100;
        report.longTerm.moderately = report.longTerm.moderately / total * 100;
        report.longTerm.somewhat = report.longTerm.somewhat / total * 100;
        report.longTerm.slightly = report.longTerm.slightly / total * 100;
        report.longTerm.not = report.longTerm.not / total * 100;

        report.debt.no = report.debt.no / total * 100;
        report.debt.manageable = report.debt.manageable / total * 100;
        report.debt.bitMoreManageable = report.debt.bitMoreManageable / total * 100;
        report.debt.farMoreManageable = report.debt.farMoreManageable / total * 100;

        report.bill12months.onTime = report.bill12months.onTime / total * 100;
        report.bill12months.nearlyOnTime = report.bill12months.nearlyOnTime / total * 100;
        report.bill12months.mostOnTime = report.bill12months.mostOnTime / total * 100;
        report.bill12months.someOnTime = report.bill12months.someOnTime / total * 100;
        report.bill12months.fewOnTime = report.bill12months.fewOnTime / total * 100;

        report.insurance.very = report.insurance.very / total * 100;
        report.insurance.moderately = report.insurance.moderately / total * 100;
        report.insurance.somewhat = report.insurance.somewhat / total * 100;
        report.insurance.slightly = report.insurance.slightly / total * 100;
        report.insurance.not = report.insurance.not / total * 100;
        report.insurance.noOne = report.insurance.noOne / total * 100;

        report.plan.agreeStrongly = report.plan.agreeStrongly / total * 100;
        report.plan.agreeSomewhat = report.plan.agreeSomewhat / total * 100;
        report.plan.neither = report.plan.neither / total * 100;
        report.plan.disagreeSomewhat = report.plan.disagreeSomewhat / total * 100;
        report.plan.disagreeStrongly = report.plan.disagreeStrongly / total * 100;

        report.currentSpending.sixMonths = report.currentSpending.sixMonths / total * 100;
        report.currentSpending.threeToFiveMonths = report.currentSpending.threeToFiveMonths / total * 100;
        report.currentSpending.oneToTwoMonths = report.currentSpending.oneToTwoMonths / total * 100;
        report.currentSpending.oneToThreeWeeks = report.currentSpending.oneToThreeWeeks / total * 100;
        report.currentSpending.lessWeek = report.currentSpending.lessWeek / total * 100;

        report.householdIncome.less500k = report.householdIncome.less500k / total * 100;
        report.householdIncome.btwn50k150k = report.householdIncome.btwn50k150k / total * 100;
        report.householdIncome.btwn150k300k = report.householdIncome.btwn150k300k / total * 100;
        report.householdIncome.btwn300k500k = report.householdIncome.btwn300k500k / total * 100;
        report.householdIncome.more500k = report.householdIncome.more500k / total * 100;

        report.stress.high = report.stress.high / total * 100;
        report.stress.moderate = report.stress.moderate / total * 100;
        report.stress.some = report.stress.some / total * 100;
        report.stress.none = report.stress.none / total * 100;

        report.stressImpact.veryOften = report.stressImpact.veryOften / total * 100;
        report.stressImpact.often = report.stressImpact.often / total * 100;
        report.stressImpact.seldom = report.stressImpact.seldom / total * 100;
        report.stressImpact.never = report.stressImpact.never / total * 100;

        createExcelSheet(report, client);
        console.log(report)

    }

    const createExcelSheet = (report, client) => {

        //Formatting with tables that look a specific way is complicated.
        //So you have to convert the excel format you into json, then vise versa
        let ExecutiveReport = [
            {
                "Which demographic do you fall within?": "Gen X (1965-1980)",
                "Column2": report.demographic.genX,
                "How long have you been employed?": "Less than a year",
                "Column4": report.employmentLength.lessYear,
                "Does your household have a budget?": "Yes",
                "Column6": report.householdBudget.yes,
                "Does your household stick to your budget?": "Yes",
                "Column8": report.householdBudgetDiscipline.yes,
                "Which of the following statements best describes your household's total income compared to total spending, over the last 12 months?": "Spending was much less than income",
                "Column10": report.spendingVsIncome.less,
                "Which of the following statement best describes how you have paid your bills over the last 12 months?": "Pay our bills on time",
                "Column12": report.bill12months.onTime,
                "At the current level of spending, if you had to live off money you have readily available, without drawing money from retirement or borrowing; how long could you and your household afford to cover…": "6 months or more",
                "Column14": report.currentSpending.sixMonths,
                "Thinking about your household’s longer term financial goals such as saving for a vacation, starting a business, buying or paying off a home, saving up for education, putting money away for retirem…": "Very confident",
                "Column16": report.longTerm.very,
                "Thinking about all of your household’s current debts, including mortgages, bank loans, student loans, money owed to people, medical debt, past-due bills, and credit card balances that are carried …": "Have a manageable amount of debt",
                "Column18": report.debt.manageable,
                "How would you rate your credit score? ": "Excellent",
                "Column20": report.credit.excellent,
                "Taking all the types of insurance you and others in your household currently might have, including health insurance, vehicle insurance, home or rental insurance, life insurance, and disability in": "Extremely confident",
                "Column22": report.insurance.very,
                "To what extent do you agree or disagree with the following statement: “My household plans ahead financially.": "Agree strongly",
                "Column24": report.plan.agreeStrongly,
                "What is your approximate annual household income?": "50 000 - 150 000 net per annum",
                "Column26": report.householdIncome.btwn50k150k,
                "Over the last 12 months, how much stress, if any, did your finances cause you?": "None",
                "Column28": report.stress.none,
                "How much time do you spend thinking about your financial stress?": "Seldom",
                "Column30": report.stressImpact.seldom,

            },
            {
                "Which demographic do you fall within?": "Gen Z (1997-2012)",
                "Column2": report.demographic.genZ,
                "How long have you been employed?": "1-3 years",
                "Column4": report.employmentLength.oneToThree,
                "Does your household have a budget?": "No",
                "Column6": report.householdBudget.no,
                "Does your household stick to your budget?": "No",
                "Column8": report.householdBudgetDiscipline.no,
                "Which of the following statements best describes your household's total income compared to total spending, over the last 12 months?": "Spending was a little less than income",
                "Column10": report.spendingVsIncome.littleLess,
                "Which of the following statement best describes how you have paid your bills over the last 12 months?": "Pay nearly all our bills on time",
                "Column12": report.bill12months.nearlyOnTime,
                "At the current level of spending, if you had to live off money you have readily available, without drawing money from retirement or borrowing; how long could you and your household afford to cover…": "3-5 months",
                "Column14": report.currentSpending.threeToFiveMonths,
                "Thinking about your household’s longer term financial goals such as saving for a vacation, starting a business, buying or paying off a home, saving up for education, putting money away for retirem…": "Moderately confident",
                "Column16": report.longTerm.moderately,
                "Thinking about all of your household’s current debts, including mortgages, bank loans, student loans, money owed to people, medical debt, past-due bills, and credit card balances that are carried …": "Have a bit more debt than is manageable",
                "Column18": report.debt.bitMoreManageable,
                "How would you rate your credit score? ": "Very good",
                "Column20": report.credit.veryGood,
                "Taking all the types of insurance you and others in your household currently might have, including health insurance, vehicle insurance, home or rental insurance, life insurance, and disability in": "Moderately confident",
                "Column22": report.insurance.moderately,
                "To what extent do you agree or disagree with the following statement: “My household plans ahead financially.": "Agree somewhat",
                "Column24": report.plan.agreeSomewhat,
                "What is your approximate annual household income?": "150 000 - 300 000 net per annum",
                "Column26": report.householdIncome.btwn150k300k,
                "Over the last 12 months, how much stress, if any, did your finances cause you?": "Some stress",
                "Column28": report.stress.some,
                "How much time do you spend thinking about your financial stress?": "Often",
                "Column30": report.stressImpact.often,

            },
            {
                "Which demographic do you fall within?": "Millennial (1981-1996)",
                "Column2": report.demographic.millenial,
                "How long have you been employed?": "3-6 years",
                "Column4": report.employmentLength.threeToSix,
                "Does your household have a budget?": "Maybe",
                "Column6": report.householdBudget.maybe,
                "Which of the following statements best describes your household's total income compared to total spending, over the last 12 months?": "Spending was equal to income",
                "Column10": report.spendingVsIncome.equal,
                "Which of the following statement best describes how you have paid your bills over the last 12 months?": "Pay most of our bills on time",
                "Column12": report.bill12months.mostOnTime,
                "At the current level of spending, if you had to live off money you have readily available, without drawing money from retirement or borrowing; how long could you and your household afford to cover…": "1-2 months",
                "Column14": report.currentSpending.oneToTwoMonths,
                "Thinking about your household’s longer term financial goals such as saving for a vacation, starting a business, buying or paying off a home, saving up for education, putting money away for retirem…": "Somewhat confident",
                "Column16": report.longTerm.somewhat,
                "Thinking about all of your household’s current debts, including mortgages, bank loans, student loans, money owed to people, medical debt, past-due bills, and credit card balances that are carried …": "Have far more debt than is manageable",
                "Column18": report.debt.farMoreManageable,
                "How would you rate your credit score? ": "Good",
                "Column20": report.credit.good,
                "Taking all the types of insurance you and others in your household currently might have, including health insurance, vehicle insurance, home or rental insurance, life insurance, and disability in": "Somewhat confident",
                "Column22": report.insurance.somewhat,
                "To what extent do you agree or disagree with the following statement: “My household plans ahead financially.": "Neither agree nor disagree",
                "Column24": report.plan.neither,
                "What is your approximate annual household income?": "300 000 - 500 000 net per annum",
                "Column26": report.householdIncome.btwn300k500k,
                "Over the last 12 months, how much stress, if any, did your finances cause you?": "Moderate stress",
                "Column28": report.stress.moderate,
                "How much time do you spend thinking about your financial stress?": "Never",
                "Column30": report.stressImpact.never,

            },
            {
                "Which demographic do you fall within?": "Baby Boomers (1946-1964)",
                "Column2": report.demographic.babyBoomers,
                "How long have you been employed?": "6-10 years",
                "Column4": report.employmentLength.sixToTen,
                "Which of the following statements best describes your household's total income compared to total spending, over the last 12 months?": "Spending was a little more than income",
                "Column10": report.spendingVsIncome.littleMore,
                "Which of the following statement best describes how you have paid your bills over the last 12 months?": "Pay some of our bills on time",
                "Column12": report.bill12months.someOnTime,
                "At the current level of spending, if you had to live off money you have readily available, without drawing money from retirement or borrowing; how long could you and your household afford to cover…": "1-3 weeks",
                "Column14": report.currentSpending.oneToThreeWeeks,
                "Thinking about your household’s longer term financial goals such as saving for a vacation, starting a business, buying or paying off a home, saving up for education, putting money away for retirem…": "Slightly confident",
                "Column16": report.longTerm.slightly,
                "Thinking about all of your household’s current debts, including mortgages, bank loans, student loans, money owed to people, medical debt, past-due bills, and credit card balances that are carried …": "Do not have any debt",
                "Column18": report.debt.no,
                "How would you rate your credit score? ": "Fair",
                "Column20": report.credit.fair,
                "Taking all the types of insurance you and others in your household currently might have, including health insurance, vehicle insurance, home or rental insurance, life insurance, and disability in": "Slightly confident",
                "Column22": report.insurance.slightly,
                "To what extent do you agree or disagree with the following statement: “My household plans ahead financially.": "Disagree somewhat",
                "Column24": report.plan.disagreeSomewhat,
                "What is your approximate annual household income?": "500 000+",
                "Column26": report.householdIncome.more500k,
                "Over the last 12 months, how much stress, if any, did your finances cause you?": "High stress",
                "Column28": report.stress.high,
                "How much time do you spend thinking about your financial stress?": "Very",
                "Column30": report.stressImpact.veryOften,

            },
            {
                "How long have you been employed?": "10-15 years",
                "Column4": report.employmentLength.tenToFifteen,
                "Which of the following statements best describes your household's total income compared to total spending, over the last 12 months?": "Spending was much more than income",
                "Column10": report.spendingVsIncome.more,
                "Which of the following statement best describes how you have paid your bills over the last 12 months?": "Pay very few of our bills on time",
                "Column12": report.bill12months.fewOnTime,
                "At the current level of spending, if you had to live off money you have readily available, without drawing money from retirement or borrowing; how long could you and your household afford to cover…": "Less than 1 week",
                "Column14": report.currentSpending.lessWeek,
                "Thinking about your household’s longer term financial goals such as saving for a vacation, starting a business, buying or paying off a home, saving up for education, putting money away for retirem…": "Not confident at all",
                "Column16": report.longTerm.not,
                "How would you rate your credit score? ": "Poor",
                "Column20": report.credit.poor,
                "Taking all the types of insurance you and others in your household currently might have, including health insurance, vehicle insurance, home or rental insurance, life insurance, and disability in": "Not confident at all",
                "Column22": report.insurance.not,
                "To what extent do you agree or disagree with the following statement: “My household plans ahead financially.": "Disagree strongly",
                "Column24": report.plan.disagreeStrongly,
                "What is your approximate annual household income?": "Less than 50 000 net per annum",
                "Column26": report.householdIncome.less500k
            },
            {
                "How long have you been employed?": "15+ years ",
                "Column4": report.employmentLength.moreFifteen,
                "How would you rate your credit score? ": "I don't know",
                "Column20": report.credit.idk,
                "Taking all the types of insurance you and others in your household currently might have, including health insurance, vehicle insurance, home or rental insurance, life insurance, and disability in": "No one in my household has insurance.",
                "Column22": report.insurance.noOne
            },
        ];


        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(ExecutiveReport);

        XLSX.utils.book_append_sheet(workBook, workSheet, 'LevelSheet');

        const buffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });

        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;

        a.download = `${client.name} baseline assessment report.xlsx`;

        // Trigger a click event to start the download
        a.click();

        // Clean up resources
        window.URL.revokeObjectURL(url);
    };

    const toggleQA = (assessmentId, index) => {
        setShowQA(prevState => ({
            ...prevState,
            [assessmentId]: {
                ...prevState[assessmentId],
                [index]: !prevState[assessmentId]?.[index]
            }
        }));
    };

    function clientSelected(e) {
        clearClientData()
        if (e.target.value === '-1') {
            setSelectedCompanyIndex(e.target.value);
            return
        }
        setSelectedCompanyIndex(e.target.value);
        setSelectedCompany(Clients[Number(e.target.value)]);
        loadClientData(Clients[Number(e.target.value)]);
        commonStore.setLoading(false);
    }

    function clearClientData() {

        setSelectedCompany({});
        setEmployeeList([]);
        setEmployees(0);
        setEmployeesSignedUp(0);

    }

    function loadClientData(client) {
        commonStore.setLoading(true);
        getEmployees(client._id);
    }

    function getEmployees(clientId) {
        commonStore.setLoading(true);
        commonStore.getEmployeesByBusinessEntity(clientId).then((ret) => {
            setEmployeeList(ret);
            setEmployees(ret.length);
            let empSignedUp = ret.filter(emp => emp.user && emp.user.confirmed);
            setEmployeesSignedUp(empSignedUp.length)
        })
    }

    const getScoreColor = (score) => {
        if (score >= 80) {
            return 'green';
        } else if (score >= 40) {
            return 'orange';
        } else {
            return 'red';
        }
    };


    if (isMobile()) {
        return (
            <div style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
                <div style={{ height: '7%', marginLeft: '5px', marginRight: '5px' }}>
                    <label className="custom-field" style={{ padding: '0px', marginTop: '5px' }}>
                        <select className='inp' value={SelectedCompanyIndex} onChange={clientSelected}>
                            <p>Filter by clients</p>
                            <option value={'-1'}>All</option>
                            {Clients.map((client, index) => {
                                return (
                                    <option key={index} value={index}>{client.name}</option>
                                )
                            })}
                        </select>
                    </label>
                </div>

                {/* assessments start */}
                {SelectedCompanyIndex === '-1' ?
                    <div >
                        <p style={{ paddingLeft: '30px', paddingTop: '30px' }}>All Assessments</p>
                        <p style={{ paddingLeft: '30px' }}>Total Taken Assessment: <span style={{ fontWeight: '700' }}>{Assessments.length}</span></p>
                        {Assessments.map((item) => {

                            const assessmentId = item._id;

                            return (
                                <div className='assessment-display-container' key={assessmentId}>
                                    <div className="assessment-display-card">
                                        {item.BaselineAssessments.map((assessment, index) => {
                                            if (!assessment.SpendScore) {
                                                return null;
                                            }

                                            return (
                                                <div key={index} className="assessment-info">
                                                    <p>IDNumber: {item.IDNumber}</p>
                                                    <p>Client: {item.BusinessEntity ? item.BusinessEntity.name : "Not saved"}</p>
                                                    <p>Date Taken: {new Date(assessment.DateTaken).toLocaleDateString()}</p>
                                                    <p>Scores: Spend - <span style={{ fontWeight: '800', color: getScoreColor(assessment.SpendScore) }}>{assessment.SpendScore}</span>,
                                                        Save - <span style={{ fontWeight: '800', color: getScoreColor(assessment.SaveScore) }}>{assessment.SaveScore}</span>,
                                                        Borrow - <span style={{ fontWeight: '800', color: getScoreColor(assessment.BorrowScore) }}>{assessment.BorrowScore}</span>,
                                                        Plan - <span style={{ fontWeight: '800', color: getScoreColor(assessment.PlanScore) }}>{assessment.PlanScore}</span>,
                                                        Overall - <span style={{ fontWeight: '800', color: getScoreColor(assessment.OverallScore) }}>{assessment.OverallScore}</span></p>
                                                    <button className="assessment-display-toggle-btn" onClick={() => toggleQA(assessmentId, index)}>View Questions & Answers</button>
                                                    {showQA[assessmentId] && showQA[assessmentId][index] && (
                                                        <div className="assessment-display-question-answer">
                                                            {assessment.QuestionAnswerPairs.map((qa, qaIndex) => (
                                                                <div key={qaIndex}>
                                                                    <p>{qa.question}: <span style={{ fontWeight: '700' }}>{qa.answer}</span></p>
                                                                    <hr />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    :
                    <div>
                        <p style={{ paddingLeft: '30px', paddingTop: '30px' }}>Assessments - <span style={{ fontWeight: '700' }}>{SelectedCompany.name}</span></p>
                        <p style={{ paddingLeft: '30px' }}>Total Employees: <span style={{ fontWeight: '700' }}>{Employees}</span></p>
                        <p style={{ paddingLeft: '30px' }}>Total Employees Signed up: <span style={{ fontWeight: '700' }}>{EmployeesSignedUp}</span></p>
                        <p style={{ paddingLeft: '30px' }}>Total Assessments Taken: <span style={{ fontWeight: '700' }}>{countObjectsWithBusinessEntityId(Assessments, SelectedCompany._id)}</span></p>
                        <p style={{ paddingLeft: '30px' }}>Average Overall Financial Score: <span style={{ fontWeight: '700', color: getScoreColor(calculateAverageOverall(Assessments, SelectedCompany._id)) }}>{calculateAverageOverall(Assessments, SelectedCompany._id)}</span></p>
                        <p>{ }</p>

                        {Assessments.sort((a, b) => new Date(b.BaselineAssessments[0].DateTaken) - new Date(a.BaselineAssessments[0].DateTaken)).map((item) => {
                            if (!item.IDNumber || !item.BaselineAssessments || item.BaselineAssessments.length === 0
                                || !item.BusinessEntity || item.BusinessEntity._id !== SelectedCompany._id) {
                                return null;
                            }

                            const assessmentId = item._id;

                            return (
                                <div className='assessment-display-container'>
                                    <p style={{ marginBottom: '1px', display: 'flex', gap: '4px' }}>Download excel report
                                        <FaFileExcel label={"d"} style={{ marginLeft: '0px', height: '100%', cursor: "pointer" }}
                                            onClick={() =>
                                                calculateExecutiveReportData(Assessments, SelectedCompany._id)}
                                        />
                                    </p>
                                    <div key={assessmentId} className="assessment-display-card">
                                        {item.BaselineAssessments.map((assessment, index) => {
                                            if (!assessment.SpendScore) {
                                                return null
                                            }

                                            return (<div key={index} className="assessment-info">
                                                <p>IDNumber: <span style={{ fontWeight: '700' }}>{item.IDNumber}</span></p>
                                                <p>Client: {item.BusinessEntity ? item.BusinessEntity.name : <span style={{ fontWeight: '700' }}>Not saved</span>}</p>
                                                <p>Date Employee signed up: <span style={{ fontWeight: '700' }}>{new Date(EmployeeList.find(emp => emp.IDNumber === item.IDNumber)?.user.confirmedDate).toLocaleDateString()}</span></p>
                                                <p>Date Taken: <span style={{ fontWeight: '700' }}>{new Date(assessment.DateTaken).toLocaleDateString()}</span></p>
                                                <p>Scores: Spend - <span style={{ fontWeight: '800', color: getScoreColor(assessment.SpendScore) }}>{assessment.SpendScore}</span>,
                                                    Save - <span style={{ fontWeight: '800', color: getScoreColor(assessment.SaveScore) }}>{assessment.SaveScore}</span>,
                                                    Borrow - <span style={{ fontWeight: '800', color: getScoreColor(assessment.BorrowScore) }}>{assessment.BorrowScore}</span>,
                                                    Plan - <span style={{ fontWeight: '800', color: getScoreColor(assessment.PlanScore) }}>{assessment.PlanScore}</span>,
                                                    Overall - <span style={{ fontWeight: '800', color: getScoreColor(assessment.OverallScore) }}>{assessment.OverallScore}</span></p>
                                                <button className="assessment-display-toggle-btn" onClick={() => toggleQA(assessmentId, index)}>View Questions & Answers</button>
                                                {showQA[assessmentId] && showQA[assessmentId][index] && (
                                                    <div className="assessment-display-question-answer">
                                                        {assessment.QuestionAnswerPairs.map((qa, qaIndex) => (
                                                            <>
                                                                <p key={qaIndex}>{qa.question}: <span style={{ fontWeight: '700' }}>{qa.answer}</span> </p>
                                                                <hr />
                                                            </>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }
                {/* assessments end */}

            </div>
        );
    } else {
        return (
            <View style={{ backgroundColor: '#ffffff' }}>
                <header style={{ backgroundColor: '#ffffff' }}>
                    <div>
                        <p className="txtlead txtLeft" style={{ marginBottom: '0px' }}>Assessments</p>
                    </div>
                </header>
                <div id="onDemandPay" style={{ backgroundColor: '#ffffff' }}>
                    <div className="desktopDashHeader">
                        <div style={{ width: '40%' }}>
                            <select value={SelectedCompanyIndex} onChange={clientSelected}>
                                <p>Filter by clients</p>
                                <option value={'-1'}>All Clients</option>
                                {Clients.map((client, index) => {
                                    return (
                                        <option key={index} value={index}>{client.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <hr />

                    {/* assessments start */}
                    {SelectedCompanyIndex === '-1' ?
                        <div>
                            <p>All Assessments</p>
                            <p>Total Taken Assessment: <span style={{ fontWeight: '700' }}>{Assessments.length}</span></p>
                            {Assessments.map((item) => {

                                const assessmentId = item._id;

                                return (
                                    <div className='assessment-display-container' key={assessmentId}>
                                        <div className="assessment-display-card">
                                            {item.BaselineAssessments.map((assessment, index) => {
                                                if (!assessment.SpendScore) {
                                                    return null;
                                                }

                                                return (
                                                    <div key={index} className="assessment-info">
                                                        <p>IDNumber: {item.IDNumber}</p>
                                                        <p>Client: {item.BusinessEntity ? item.BusinessEntity.name : "Not saved"}</p>
                                                        <p>Date Taken: {new Date(assessment.DateTaken).toLocaleDateString()}</p>
                                                        <p>Scores: Spend - <span style={{ fontWeight: '800', color: getScoreColor(assessment.SpendScore) }}>{assessment.SpendScore}</span>,
                                                            Save - <span style={{ fontWeight: '800', color: getScoreColor(assessment.SaveScore) }}>{assessment.SaveScore}</span>,
                                                            Borrow - <span style={{ fontWeight: '800', color: getScoreColor(assessment.BorrowScore) }}>{assessment.BorrowScore}</span>,
                                                            Plan - <span style={{ fontWeight: '800', color: getScoreColor(assessment.PlanScore) }}>{assessment.PlanScore}</span>,
                                                            Overall - <span style={{ fontWeight: '800', color: getScoreColor(assessment.OverallScore) }}>{assessment.OverallScore}</span></p>
                                                        <button className="assessment-display-toggle-btn" onClick={() => toggleQA(assessmentId, index)}>View Questions & Answers</button>
                                                        {showQA[assessmentId] && showQA[assessmentId][index] && (
                                                            <div className="assessment-display-question-answer">
                                                                {assessment.QuestionAnswerPairs.map((qa, qaIndex) => (
                                                                    <div key={qaIndex}>
                                                                        <p>{qa.question}: <span style={{ fontWeight: '700' }}>{qa.answer}</span></p>
                                                                        <hr />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        :
                        <div>
                            <p>Assessments - <span style={{ fontWeight: '700' }}>{SelectedCompany.name}</span></p>
                            <p>Total Employees: <span style={{ fontWeight: '700' }}>{Employees}</span></p>
                            <p>Total Employees Signed up: <span style={{ fontWeight: '700' }}>{EmployeesSignedUp}</span></p>
                            <p>Total Assessments Taken: <span style={{ fontWeight: '700' }}>{countObjectsWithBusinessEntityId(Assessments, SelectedCompany._id)}</span></p>
                            <p style={{ paddingLeft: '30px' }}>Average Overall Financial Score: <span style={{ fontWeight: '700', color: getScoreColor(calculateAverageOverall(Assessments, SelectedCompany._id)) }}>{calculateAverageOverall(Assessments, SelectedCompany._id)}</span></p>

                            {Assessments.map((item) => {
                                if (!item.IDNumber || !item.BaselineAssessments || item.BaselineAssessments.length === 0
                                    || !item.BusinessEntity || item.BusinessEntity._id !== SelectedCompany._id) {
                                    return null;
                                }

                                const assessmentId = item._id;

                                return (
                                    <div className='assessment-display-container'>
                                        <p style={{ marginBottom: '1px', display: 'flex', gap: '4px' }}>Download excel report
                                            <FaFileExcel label={"d"} style={{ marginLeft: '0px', height: '100%', cursor: "pointer" }}
                                                onClick={() =>
                                                    calculateExecutiveReportData(Assessments, SelectedCompany._id)}
                                            />
                                        </p>
                                        <div key={assessmentId} className="assessment-display-card">
                                            {item.BaselineAssessments.map((assessment, index) => {
                                                if (!assessment.SpendScore) {
                                                    return null
                                                }

                                                return (<div key={index} className="assessment-info">
                                                    <p>IDNumber: <span style={{ fontWeight: '700' }}>{item.IDNumber}</span></p>
                                                    <p>Client: {item.BusinessEntity ? item.BusinessEntity.name : <span style={{ fontWeight: '700' }}>Not saved</span>}</p>
                                                    <p>Date Employee signed up: <span style={{ fontWeight: '700' }}>{new Date(EmployeeList.find(emp => emp.IDNumber === item.IDNumber)?.user.confirmedDate).toLocaleDateString()}</span></p>
                                                    <p>Date Taken: <span style={{ fontWeight: '700' }}>{new Date(assessment.DateTaken).toLocaleDateString()}</span></p>
                                                    <p>Scores: Spend - <span style={{ fontWeight: '800', color: getScoreColor(assessment.SpendScore) }}>{assessment.SpendScore}</span>,
                                                        Save - <span style={{ fontWeight: '800', color: getScoreColor(assessment.SaveScore) }}>{assessment.SaveScore}</span>,
                                                        Borrow - <span style={{ fontWeight: '800', color: getScoreColor(assessment.BorrowScore) }}>{assessment.BorrowScore}</span>,
                                                        Plan - <span style={{ fontWeight: '800', color: getScoreColor(assessment.PlanScore) }}>{assessment.PlanScore}</span>,
                                                        Overall - <span style={{ fontWeight: '800', color: getScoreColor(assessment.OverallScore) }}>{assessment.OverallScore}</span></p>
                                                    <button className="assessment-display-toggle-btn" onClick={() => toggleQA(assessmentId, index)}>View Questions & Answers</button>
                                                    {showQA[assessmentId] && showQA[assessmentId][index] && (
                                                        <div className="assessment-display-question-answer">
                                                            {assessment.QuestionAnswerPairs.map((qa, qaIndex) => (
                                                                <>
                                                                    <p key={qaIndex}>{qa.question}: <span style={{ fontWeight: '700' }}>{qa.answer}</span> </p>
                                                                    <hr />
                                                                </>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    }
                    {/* assessments end */}


                </div>

            </View>
        );
    }

})

export default Assessments;
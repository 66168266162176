import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';
import { FaBuilding, FaUsersCog, FaFileInvoiceDollar, FaUserPlus, FaComments, FaRegFileAlt, FaChartLine, FaBars, FaSignOutAlt, FaUserAlt, FaFileInvoice, FaHome, FaCog, FaUserMinus, FaEdit } from 'react-icons/fa'



import '../style.scss';
import './style.scss';

import Users from './users';
import AdminLogs from './logs';
import SupportRequests from './support';
import CompanyAdvances from './companyAdvances';
import Reporting from './reporting';
import Employee from './employee';
import NewEmployee from './newEmployee';
import Invoices from './invoices';
import Client from './client';
import Home from './home';
import Settings from './settings';
import DeleteEmployees from './deleteEmployee';
import Assessments from './assessments';
import AllAdvances from './allAdvances';

const AdminDashboard = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [activePage, setActivePage] = useState('');
    const [name, setName] = useState('');
    const [SlideoutOpen, setSlideoutOpen] = useState(false);


    useEffect(() => {
        if (commonStore.user.role !== '5d8e0b079f51d42bbece9201') {
            commonStore.logout();
            navigate('/signin');
            return
        }
        setActivePage('Home');
        setName(`${commonStore.user.firstname || ''} (${commonStore.user.username})`);
    }, []);

    function optionSelected(e) {
        setActivePage(e.target.value);
        setSlideoutOpen(false);
    }
    function showSettings() {
        setActivePage('Settings');
    }

    function logout() {
        commonStore.logout();
        navigate('/signin');
    }

    function toggleSlideout() {
        setSlideoutOpen(!SlideoutOpen);
    }

    if (isMobile()) {
        return (
            <div id="mobileDashView" className="view" style={{ justifyContent: 'flex-start' }}>
                <div>
                    <MobileTopBarClean />
                    <FaBars className="adminSlideoutToggle" onClick={toggleSlideout} />
                </div>

                <div className='adminMobileContent' style={{ height: `${window.innerHeight - 92}px` }}>
                    {(activePage === 'Client') ? <Client /> : null}
                    {(activePage === 'Users') ? <Users /> : null}
                    {(activePage === 'CompanyAdvances') ? <CompanyAdvances /> : null}
                    {(activePage === 'Invoices') ? <Invoices /> : null}
                    {(activePage === 'Support') ? <SupportRequests /> : null}
                    {(activePage === 'Logs') ? <AdminLogs /> : null}
                    {(activePage === 'Reporting') ? <Reporting /> : null}
                    {(activePage === 'Employee') ? <Employee /> : null}
                    {(activePage === 'NewEmployee') ? <NewEmployee /> : null}
                    {(activePage === 'Home') ? <Home /> : null}
                    {(activePage === 'Settings') ? <Settings /> : null}
                    {(activePage === 'DeleteEmployees') ? <DeleteEmployees /> : null}
                    {(activePage === 'Assessments') ? <Assessments /> : null}
                </div>

                <Card className={`${(SlideoutOpen) ? 'adminSlideOutOpen' : 'adminSlideOutClosed'}`} style={{ height: `${window.innerHeight - 93}px`, overflow: 'scroll' }}>
                    <Form className="tabBarFormDesktop" onChange={optionSelected}>
                        <div className="tabBarItemDesktop" id={(activePage === "Home") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="homeTab" value="Home" />
                            <FaHome className='tabBarItemDesktopIcon' htmlFor="homeTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="homeTab">Home</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "Reporting") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="reportingTab" value="Reporting" />
                            <FaChartLine className='tabBarItemDesktopIcon' htmlFor="reportingTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="reportingTab">Reporting</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "Client") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="clientTab" value="Client" />
                            <FaBuilding className='tabBarItemDesktopIcon' htmlFor="clientTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="clientTab">Client</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "Assessments") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="assessmentsTab" value="Assessments" />
                            <FaEdit className='tabBarItemDesktopIcon' htmlFor="assessmentsTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="assessmentsTab">Assessments</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "CompanyAdvances") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="companyAdvancesTab" value="CompanyAdvances" />
                            <FaFileInvoiceDollar className='tabBarItemDesktopIcon' htmlFor="companyAdvancesTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="companyAdvancesTab">Advances</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "AllAdvances") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="allAdvancesTab" value="AllAdvances" />
                            <FaFileInvoiceDollar className='tabBarItemDesktopIcon' htmlFor="allAdvancesTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="allAdvancesTab">All Advances</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "Invoices") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="invoicesTab" value="Invoices" />
                            <FaFileInvoice className='tabBarItemDesktopIcon' htmlFor="invoicesTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="invoicesTab">Invoices</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "Employee") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="employeeTab" value="Employee" />
                            <FaUserAlt className='tabBarItemDesktopIcon' htmlFor="employeeTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="employeeTab">Employee</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "NewEmployee") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="newEmployeeTab" value="NewEmployee" />
                            <FaUserPlus className='tabBarItemDesktopIcon' htmlFor="newEmployeeTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="newEmployeeTab">New Employee</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "DeleteEmployees") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="deleteEmployeeTab" value="DeleteEmployees" />
                            <FaUserMinus className='tabBarItemDesktopIcon' htmlFor="deleteEmployeeTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="deleteEmployeeTab">Delete Employees</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "Users") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="usersTab" value="Users" />
                            <FaUsersCog className='tabBarItemDesktopIcon' htmlFor="usersTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="usersTab">Admin Users</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "Support") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="supportTab" value="Support" />
                            <FaComments className='tabBarItemDesktopIcon' htmlFor="supportTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="supportTab">Support Requests</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "Logs") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="logsTab" value="Logs" />
                            <FaRegFileAlt className='tabBarItemDesktopIcon' htmlFor="logsTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="logsTab">Logs</label>
                        </div>

                        <div className="tabBarItemDesktop" id={(activePage === "Settings") ? 'selected' : null} style={{ width: '90%' }}>
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="settingsTab" value="Settings" />
                            <FaCog className='tabBarItemDesktopIcon' htmlFor="settingsTab" style={{ marginRight: '2px', height: '100%' }} />
                            <label htmlFor="settingsTab">Settings</label>
                        </div>

                    </Form>
                    <div className="tabBarItemDesktop">
                        <FaSignOutAlt className='tabBarItemDesktopIcon' style={{ marginRight: '2px', height: '100%' }} />
                        <label onClick={logout}>Logout</label>
                    </div>
                </Card>
            </div>
        );
    } else {
        return (
            <View style={{ height: `${window.innerHeight}px` }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <img className="dashImg" alt='logo' src="https://levelfinance.azureedge.net/siteassets/logo2.png" />
                    <div style={{ display: 'flex' }}>
                        <p className="txtsub" style={{ marginRight: '10px' }}>{`Welcome, ${name}`}</p>
                        <FaCog id='Settings' onClick={showSettings} style={{ height: '10px', margin: '0px', marginTop: '5px', marginRight: '5px', cursor: 'pointer' }} />
                    </div>

                </div>
                <div id="desktopContainer">

                    <div id="leftDiv" style={{ height: `${window.innerHeight - 30}px`, overflow: 'scroll' }}>
                        <Form className="tabBarFormDesktop" onChange={optionSelected}>
                            <div className="tabBarItemDesktop" id={(activePage === "Home") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="homeTab" value="Home" />
                                <FaHome className='tabBarItemDesktopIcon' htmlFor="homeTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="homeTab">Home</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "Reporting") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="reportingTab" value="Reporting" />
                                <FaChartLine className='tabBarItemDesktopIcon' htmlFor="reportingTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="reportingTab">Reporting</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "Client") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="clientTab" value="Client" />
                                <FaBuilding className='tabBarItemDesktopIcon' htmlFor="clientTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="clientTab">Client</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "Assessments") ? 'selected' : null} style={{ width: '90%' }}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="assessmentsTab" value="Assessments" />
                                <FaEdit className='tabBarItemDesktopIcon' htmlFor="assessmentsTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="assessmentsTab">Assessments</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "CompanyAdvances") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="companyAdvancesTab" value="CompanyAdvances" />
                                <FaFileInvoiceDollar className='tabBarItemDesktopIcon' htmlFor="companyAdvancesTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="companyAdvancesTab">Advances</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "AllAdvances") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="allAdvancesTab" value="AllAdvances" />
                                <FaFileInvoiceDollar className='tabBarItemDesktopIcon' htmlFor="allAdvancesTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="allAdvancesTab">All Advances</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "Invoices") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="invoicesTab" value="Invoices" />
                                <FaFileInvoice className='tabBarItemDesktopIcon' htmlFor="invoicesTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="invoicesTab">Invoices</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "Employee") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="employeeTab" value="Employee" />
                                <FaUserAlt className='tabBarItemDesktopIcon' htmlFor="employeeTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="employeeTab">Employee</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "NewEmployee") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="newEmployeeTab" value="NewEmployee" />
                                <FaUserPlus className='tabBarItemDesktopIcon' htmlFor="newEmployeeTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="newEmployeeTab">New Employee</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "DeleteEmployees") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="deleteEmployeeTab" value="DeleteEmployees" />
                                <FaUserMinus className='tabBarItemDesktopIcon' htmlFor="deleteEmployeeTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="deleteEmployeeTab">Delete Employees</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "Users") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="usersTab" value="Users" />
                                <FaUsersCog className='tabBarItemDesktopIcon' htmlFor="usersTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="usersTab">Admin Users</label>
                            </div>

                            {/* <div className="tabBarItemDesktop" id={(activePage === "Employees")?'selected': null}>
                                    <input className="tabBar-tools" name="tabBarItems" type="radio" id="employeesTab" value="Employees"/>
                                    <FaUsers className='tabBarItemDesktopIcon'  htmlFor="employeesTab" style={{marginRight: '2px', height: '100%'}}/>
                                    <label htmlFor="employeesTab">Employees</label>
                                </div> */}

                            <div className="tabBarItemDesktop" id={(activePage === "Support") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="supportTab" value="Support" />
                                <FaComments className='tabBarItemDesktopIcon' htmlFor="supportTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="supportTab">Support Requests</label>
                            </div>

                            <div className="tabBarItemDesktop" id={(activePage === "Logs") ? 'selected' : null}>
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="logsTab" value="Logs" />
                                <FaRegFileAlt className='tabBarItemDesktopIcon' htmlFor="logsTab" style={{ marginRight: '2px', height: '100%' }} />
                                <label htmlFor="logsTab">Logs</label>
                            </div>

                        </Form>
                        <div className="tabBarItemDesktop">
                            <FaSignOutAlt className='tabBarItemDesktopIcon' style={{ marginRight: '2px', height: '100%' }} />
                            <label onClick={logout}>Logout</label>
                        </div>
                    </div>


                    <div id="rightDiv">
                        <Card className="desktopCard">
                            <CardBody className="desktopContent">
                                {(activePage === 'Client') ? <Client /> : null}
                                {(activePage === 'Users') ? <Users /> : null}
                                {(activePage === 'CompanyAdvances') ? <CompanyAdvances /> : null}
                                {(activePage === 'AllAdvances') ? <AllAdvances /> : null}
                                {(activePage === 'Invoices') ? <Invoices /> : null}
                                {(activePage === 'Support') ? <SupportRequests /> : null}
                                {(activePage === 'Logs') ? <AdminLogs /> : null}
                                {(activePage === 'Reporting') ? <Reporting /> : null}
                                {(activePage === 'Employee') ? <Employee /> : null}
                                {(activePage === 'NewEmployee') ? <NewEmployee /> : null}
                                {(activePage === 'Home') ? <Home /> : null}
                                {(activePage === 'Settings') ? <Settings /> : null}
                                {(activePage === 'DeleteEmployees') ? <DeleteEmployees /> : null}
                                {(activePage === 'Assessments') ? <Assessments /> : null}
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </View>
        );
    }
})

export default AdminDashboard

import React, {useState,useEffect} from 'react';
import { useNavigate, useSearchParams }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Card, CardBody } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';
import { FaHome, FaSignOutAlt, FaFileInvoice, FaWallet, FaTh, FaEllipsisH, FaUserTie } from 'react-icons/fa'



import '../style.scss';
import './style.scss';

import Home from './home';
import Invoices from './invoices';
import Employees from './employees';

const ClientDashboard = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [activePage, setActivePage] = useState('');
    const [name, setName] = useState('');


    useEffect(() => {
        if (commonStore.user.role !== '62bd55b7c5793941427c702d'){
            commonStore.logout();
            navigate('/signin');
            return
        }
        setActivePage('Invoices');
    }, []);

    function optionSelected(e){
        if (e.target.value === 'Logout'){
            logout();
        }
        setActivePage(e.target.value);
    }

    function logout(){
        commonStore.logout();
        navigate('/signin');
    }

        if (isMobile()){
            return(
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>
                    </div>
                    <div className="dashboardContent">
                        {(activePage === 'Invoices')?<Invoices/>:null} 
                    </div>
                    <Card className="tabBar">
                        <Form className="tabBarForm" onChange={optionSelected} style={{overflow: 'hidden'}}>

                            <div className="tabBarItem"> 
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="invoicesTab" value="Invoices"/>
                                <FaFileInvoice className={(activePage === "Invoices")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="invoicesTab" style={{height: '100%'}}/>
                                <label className={(activePage === "Invoices")? "tabSelected":null} htmlFor="invoicesTab">Invoices</label>
                            </div>

                           

                            <div className="tabBarItem"> {/* More */}
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="logoutTab" value="Logout"/>
                                <FaSignOutAlt className={(activePage === "Logout")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="logoutTab" style={{height: '100%'}}/>
                                <label className={(activePage === "Logout")? "tabSelected":null} htmlFor="logoutTab">Logout</label>
                            </div>

                        </Form>
                        
                    </Card>
                </div>
            );
        }else{
            return(
                <View style={{height: `${window.innerHeight}px`}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <img className="dashImg" alt='logo' src="https://levelfinance.azureedge.net/siteassets/logo2.png"/>
                        <p className="txtsub" style={{marginRight: '10px'}}>{`Welcome, ${commonStore.user.businessentity.name || ''}`}</p>
                    </div>
                    <div id="desktopContainer">
                        
                        <div id="leftDiv" style={{height: `${window.innerHeight - 30}px`, overflow: 'scroll'}}>
                            <Form className="tabBarFormDesktop" onChange={optionSelected}>

                                <div className="tabBarItemDesktop" id={(activePage === "Employees")?'selected': null}>
                                    <input className="tabBar-tools" name="tabBarItems" type="radio" id="employeesTab" value="Employees"/>
                                    <FaUserTie className='tabBarItemDesktopIcon'  htmlFor="employeesTab" style={{marginRight: '2px', height: '100%'}}/>
                                    <label htmlFor="employeesTab">Employees</label>
                                </div>

                                <div className="tabBarItemDesktop" id={(activePage === "Invoices")?'selected': null}>
                                    <input className="tabBar-tools" name="tabBarItems" type="radio" id="invoicesTab" value="Invoices"/>
                                    <FaFileInvoice className='tabBarItemDesktopIcon'  htmlFor="invoicesTab" style={{marginRight: '2px', height: '100%'}}/>
                                    <label htmlFor="invoicesTab">Invoices</label>
                                </div>


                            </Form>
                            <div className="tabBarItemDesktop">
                            <FaSignOutAlt className='tabBarItemDesktopIcon' style={{marginRight: '2px', height: '100%'}}/>
                                <label onClick={logout}>Logout</label>
                            </div>
                        </div>


                        <div id="rightDiv">
                            <Card className="desktopCard">
                                <CardBody className="desktopContent">
                                    {(activePage === 'Invoices')? <Invoices />:null}
                                    {(activePage === 'Employees')? <Employees />:null}
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </View>
            );
        }
})

export default ClientDashboard

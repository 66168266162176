import React, {useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../../components/basic';
import { View, MobileTopBarClean } from '../../../shared/components';


import '../../style.scss';
import './style.scss';

const WithdrawSuccess = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    useEffect(() => {
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function nextClicked(){
        commonStore.amountAllowed = -1;
        commonStore.amountAvailable = -1;
        navigate('/home');
    }

        if (isMobile()){
            return(
                <div id="mobileDashView" className="view" style={{justifyContent: 'flex-start'}}>
                    <div>
                        <MobileTopBarClean/>
                    </div>
                    
                    <div id="mainView" className="viewContent view-pages view-session justify-content-center d-flex flex-column">
                        <div className="svgDiv">
                            <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/success.svg" alt="icon"/>
                        </div>
                        <p className="txtlead">Success</p>
                        <p className="txtsub">Your payment was successful. Please note that the amount will take a few hours to reflect in your bank account.</p>
                        <Form>
                            <Button block size="lg" onClick={nextClicked}>Return to dashboard</Button>
                        </Form>
                    </div>
                </div>
            );
        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg"/>
                                    <p className="txtlead">Success</p>
                                    <p className="txtsub">Your payment was successful. Please note that the amount will take a few hours to reflect in your bank account.</p>
                                </header>
                                <div className="svgDiv">
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/success.svg" alt="icon"/>
                                </div>
                                <Form>
                                    <Button block size="lg" onClick={nextClicked}>Return to dashboard</Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                    
                </View>
            );
        }
})

export default WithdrawSuccess
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button, Card, CardBody } from '../../../components/basic';
import { View } from '../../../shared/components';


import './style.scss';

const Profile = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [IDNumber, setIDNumber] = useState('');
    const [salary, setSalary] = useState('');
    const [profile, setProfile] = useState({
        FirstName: '',
        LastName: ''
    });

    const [businessentity, setBusinessentity] = useState({});
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    
    useEffect(() => {
        setIDNumber(commonStore.employeeDetail.IDNumber);
        let prf = profile;
        prf.FirstName = commonStore.employeeDetail.employeeinfo.FirstName;
        prf.LastName = commonStore.employeeDetail.employeeinfo.LastName;
        setProfile(prf);
        setSalary(commonStore.employeeDetail.employeesalary.Amount.toString());
        if (isMobile()){
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function goBack(){
        navigate({pathname: '/home', search: 'More'});
    }

    function firstNameChange(e){
        let prf = profile;
        prf.FirstName = e.target.value;
        setProfile(prf);
        setError('');
        setSuccess('');
        forceUpdate();
    }

    function lastNameChange(e){
        let prf = profile;
        prf.LastName = e.target.value;
        setProfile(prf);
        setError('');
        setSuccess('');
        forceUpdate();
    }

    function updateProfile(){
        

        //Validation
        if (profile.FirstName === ''){
            setError('Please fill in your first name.');
            return
        }
        if (profile.LastName === ''){
            setError('Please fill in your last name.');
            return
        }

        //Update Profile
        commonStore.setLoading(true);
        commonStore.updateEmployeeInfo(IDNumber, profile).then((res) => {
            if (!res){
                setError('An error occured, please try again later.');
                commonStore.setLoading(false);
            }else{
                setSuccess('Your profile has been updated successfully.');
                commonStore.setLoading(false);
            }
        });
    }

        if (isMobile()){
            return(
                <div id="mobileDashView" className="view">
                    <Card>
                        <CardBody>
                            <header className="site-head d-flex align-items-center justify-content-between" style={{backgroundColor: '#ffffff'}}>
                                <div className="wrap mr-4">
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/back.png" alt='mnu' onClick={goBack} style={{cursor: 'pointer', width: '18px', height: '18px'}}/>
                                </div>
                                <div className="right-elems ml-auto d-flex wrap mr-4">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" style={{cursor: 'pointer', width: '125px'}}/>  
                                </div>
                            </header>
                        </CardBody>
                    </Card>
                    
                    <div className="viewContent view-pages view-session d-flex flex-column" style={{overflow: 'scroll'}}>
                        <p className="txtlead txtleft"><i className="fa fa-user" aria-hidden="true"/>Profile</p>
                        <Form>
                            <label className="custom-field">
                                <input className='inp' type="text" id="idNumber" value={IDNumber} required disabled/>
                                <span className="placeholder">ID Number</span>
                            </label> 

                            <label className="custom-field">
                                <input className='inp' type="text" id="firstName" value={profile.FirstName} onChange={firstNameChange} required/>
                                <span className="placeholder">First name</span>
                            </label> 

                            <label className="custom-field">
                                <input className='inp' type="text" id="lastName" value={profile.LastName} onChange={lastNameChange} required/>
                                <span className="placeholder">Last name</span>
                            </label> 

                            <label className="custom-field">
                                <input className='inp' type="text" id="salary" value={`R${salary}`} required disabled/>
                                <span className="placeholder">Net Salary</span>
                            </label> 

                            {(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}
                            {(success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}
                            <Button className='cornered-button' block size="lg" onClick={updateProfile}>Update profile</Button>
                        </Form>
                    </div>
                    
                </div>
            );

        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                            <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                                <header className="mb-4 topLogin">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                    <p className="txtlead"><i className="fa fa-user" aria-hidden="true"/>Profile</p>
                                    <br/>
                                </header>
                                <Form>
                                    <label className="custom-field">
                                        <input className='inp' type="text" id="idNumber" value={IDNumber} required disabled/>
                                        <span className="placeholder">ID Number</span>
                                    </label> 

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="firstName" value={profile.FirstName} onChange={firstNameChange} required/>
                                        <span className="placeholder">First name</span>
                                    </label> 

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="lastName" value={profile.LastName} onChange={lastNameChange} required/>
                                        <span className="placeholder">Last name</span>
                                    </label> 

                                    <label className="custom-field">
                                        <input className='inp' type="text" id="salary" value={`R${salary}`} required disabled/>
                                        <span className="placeholder">Net Salary</span>
                                    </label> 

                                    {(error.length > 0)? <p style={{color: 'red'}}>{error}</p>:null}
                                    {(success.length > 0)? <p style={{color: 'green'}}>{success}</p>:null}
                                    <Button className='cornered-button' block size="lg" onClick={updateProfile}>Update profile</Button>
                                    <p className="backButton" onClick={goBack}>Back</p>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                   
                </View>
            );
        }
        
})

export default Profile
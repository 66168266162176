import React, { useRef, useState } from 'react';
import PropTypes from "prop-types"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';


function ReportingEWAGraph({className, id, offset=0, advances = [], employees=[], period='monthly', style={}, dataItems=6, company='all', segment='all'}) {
  const chartRef = useRef();
  const [SelectedData, setSelectedData] = useState(-1);
  var currentEmployees = [];
    switch (Number(segment)){
        case 1: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount <= 8000); break;
        case 2: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 8001 && emp.employeesalary.Amount <= 15000); break;
        case 3: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 15001 && emp.employeesalary.Amount <= 30000); break;
        case 4: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 30001 && emp.employeesalary.Amount <= 50000); break;
        case 5: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 50001); break;
    }

    let validEmp = [];

    for (let i = 0; i < currentEmployees.length; i++){
        validEmp.push(currentEmployees[i].IDNumber)
    }

  let customStyle = style;
    if (period === 'monthly'){
      let currentDate = new Date();
      let month = currentDate.getMonth();
      let year = currentDate.getFullYear();
      month = month - offset;
      if (month < 0){
        month = 12 + month;
        year = year - 1;
      }
      let adv = [];
      let fee = [];
      let mem = [];
      let fullData = [];
      for (let i = 0; i < dataItems; i++){
        adv.push(0);
        fee.push(0);
        mem.push(0);
        fullData.push({
          month: '',
          advances: 0,
          fees: 0,
          memberships: [],
          numAdvances: 0
        })
      }
      let lbls = [];



      for (let i = 0; i < dataItems; i++){
          let mth = month - i;
          let yr = year
          if (mth < 0){
              mth = 12 + mth;
              yr = yr - 1;
          }
          var mthName;
          switch (mth){
              case 0: mthName = 'January'; break;
              case 1: mthName = 'February'; break;
              case 2: mthName = 'March'; break;
              case 3: mthName = 'April'; break;
              case 4: mthName = 'May'; break;
              case 5: mthName = 'June'; break;
              case 6: mthName = 'July'; break;
              case 7: mthName = 'August'; break;
              case 8: mthName = 'September'; break;
              case 9: mthName = 'October'; break;
              case 10: mthName = 'November'; break;
              case 11: mthName = 'December'; break;
          }
          lbls.unshift(mthName);
          fullData[dataItems - 1 -i].month = mthName;
          for (let j = 0; j < advances.length; j++){
              let curAdv = advances[j];
              if (company !== 'all' && curAdv.businessentity !== company){
                continue;
              }
              if (segment !== 'all' && !validEmp.includes(curAdv.IDNumber)){
                continue;
              }
              if ((new Date(curAdv.AdvanceRequestDate)).getMonth() === mth && (new Date(curAdv.AdvanceRequestDate)).getFullYear() === yr){
                  let amt = curAdv.AdvanceAmount || 0;
                  let feeAmt = curAdv.AdvanceFee || 0;
                  adv[dataItems - 1 - i] = adv[dataItems - 1 - i] + amt;
                  fee[dataItems - 1 - i] = fee[dataItems - 1 - i] + feeAmt;
                  fullData[dataItems -1 - i].advances = fullData[dataItems - 1 - i].advances + amt;
                  fullData[dataItems - 1 - i].fees = fullData[dataItems - 1 - i].fees + feeAmt;
                  fullData[dataItems - 1 - i].numAdvances = fullData[dataItems - 1 - i].numAdvances + 1;
                  if (!fullData[dataItems - 1 - i].memberships.includes(curAdv.IDNumber)){
                    fullData[dataItems - 1 - i].memberships.push(curAdv.IDNumber);
                    mem[dataItems - 1 - i] = mem[dataItems - 1 - i] + 10;
                  }
                }
              
          }
    }


      ChartJS.register(
          CategoryScale,
          LinearScale,
          BarElement,
          Title,
          Tooltip,
          Legend
      );

      const options = {
          responsive: true,
          onClick: (e, elements) => {
            if (elements.length > 0){
              setSelectedData(elements[0]?.index);
            }
            
          },
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Monthly Advances',
            },
          },
        };

      const labels = lbls;
        const data = {
          labels,
          datasets: [
            {
              label: 'Advances',
              data: adv,
              backgroundColor: '#772FD7',
            },
            {
              label: 'Fees',
              // data: [1, 2, 3, 4, 5, 6, 7],
              data: fee,
              backgroundColor: '#FF64AB',
            },
            {
              label: 'Membership Fee',
              // data: [1, 2, 3, 4, 5, 6, 7],
              data: mem,
              backgroundColor: '#001949',
            },
          ],
        };
      return(
        <div style={customStyle}>
          <Bar options={options} data={data} ref={chartRef} />
          {(SelectedData === -1)? null : 
          <div>
            <p><b>Month:</b> {`${fullData[SelectedData].month || ''}`}</p>
            <p><b>Advances:</b> R{`${fullData[SelectedData].advances.toFixed(2) || ''}`}</p>
            <p><b>Fees:</b> R{`${fullData[SelectedData].fees.toFixed(2) || ''}`}</p>
            <p><b>Subscriptions:</b> R{`${fullData[SelectedData].memberships.length * 10 || ''}`}</p>
            <p><b>Number of Unique Users:</b> {`${fullData[SelectedData].memberships.length || ''}`}</p>
            <p><b>Number of Advances:</b> {`${fullData[SelectedData].numAdvances || ''}`}</p>
          </div>}
        </div>
      ) 
    }

    if (period === 'weekly'){
      let currentDate = new Date();
      if (currentDate.getDay() > 1){
        currentDate = new Date(currentDate.getTime() -  ((currentDate.getDay() - 1) * 24 * 60 * 60 * 1000));
      }
      if (currentDate.getDay() === 0){
        currentDate = new Date(currentDate.getTime() -  (6 * 24 * 60 * 60 * 1000));
      }

      let adv = [];
      let fee = [];
      let mem = [];
      let fullData = [];
      for (let i = 0; i < dataItems; i++){
        adv.push(0);
        fee.push(0);
        mem.push(0);
        fullData.push({
          startDate: '',
          endDate: '',
          advances: 0,
          fees: 0,
          memberships: [],
          numAdvances: 0
        })
      }
      let lbls = [];

      for (let i = 0; i < dataItems; i++){
        let startDate = new Date(currentDate.getTime() - ((7 * i) * 24 * 60 * 60 * 1000));
        let endDate = new Date(startDate.getTime() + ((6) * 24 * 60 * 60 * 1000));

        lbls.unshift(`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`);
        fullData[dataItems - 1 -i].startDate = startDate.toLocaleDateString();
        fullData[dataItems - 1 -i].endDate = endDate.toLocaleDateString();

        for (let j = 0; j < advances.length; j++){
          let curAdv = advances[j];
          if (company !== 'all' && curAdv.businessentity !== company){
            continue;
          }
          if (segment !== 'all' && !validEmp.includes(curAdv.IDNumber)){
            continue;
          }
          let advDate = new Date(curAdv.AdvanceRequestDate);
          if (advDate >= startDate && advDate <= endDate){
            let amt = curAdv.AdvanceAmount || 0;
              let feeAmt = curAdv.AdvanceFee || 0;
              adv[dataItems - 1 - i] = adv[dataItems - 1 - i] + amt;
              fee[dataItems - 1 - i] = fee[dataItems - 1 - i] + feeAmt;
              fullData[dataItems - 1 - i].advances = fullData[dataItems - 1 - i].advances + amt;
              fullData[dataItems - 1 - i].fees = fullData[dataItems - 1 - i].fees + feeAmt;
              fullData[dataItems - 1 - i].numAdvances = fullData[dataItems - 1 - i].numAdvances + 1;
              if (!fullData[dataItems - 1 - i].memberships.includes(curAdv.IDNumber)){
                fullData[dataItems - 1 - i].memberships.push(curAdv.IDNumber);
                mem[dataItems - 1 - i] = mem[dataItems - 1 - i] + 10;
              }
          }
        }
      }

      ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        onClick: (e, elements) => {
          if (elements.length > 0){
            setSelectedData(elements[0]?.index);
          }
          
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Weekly Advances',
          },
        },
      };

    const labels = lbls;
      const data = {
        labels,
        datasets: [
          {
            label: 'Advances',
            data: adv,
            backgroundColor: '#772FD7',
          },
          {
            label: 'Fees',
            // data: [1, 2, 3, 4, 5, 6, 7],
            data: fee,
            backgroundColor: '#FF64AB',
          },
          // {
          //   label: 'Membership Fee',
          //   // data: [1, 2, 3, 4, 5, 6, 7],
          //   data: mem,
          //   backgroundColor: '#001949',
          // },
        ],
      };
    return(
      <div style={customStyle}>
        <Bar options={options} data={data} ref={chartRef} />
        {(SelectedData === -1)? null : 
        <div>
          <p><b>Week:</b> {`${fullData[SelectedData].startDate || ''} - ${fullData[SelectedData].endDate || ''}`}</p>
          <p><b>Advances:</b> R{`${fullData[SelectedData].advances.toFixed(2) || ''}`}</p>
          <p><b>Fees:</b> R{`${fullData[SelectedData].fees.toFixed(2) || ''}`}</p>
          {/* <p><b>Subscriptions:</b> R{`${fullData[SelectedData].memberships.length * 10 || ''}`}</p> */}
          <p><b>Number of Unique Users:</b> {`${fullData[SelectedData].memberships.length || ''}`}</p>
          <p><b>Number of Advances:</b> {`${fullData[SelectedData].numAdvances || ''}`}</p>
        </div>}
      </div>
    ) 
    }


    if (period === 'yearly'){
      let currentDate = new Date();
      let year = currentDate.getFullYear();

      let adv = [];
      let fee = [];
      let mem = [];
      let fullData = [];
      for (let i = 0; i < dataItems; i++){
        adv.push(0);
        fee.push(0);
        mem.push(0);
        fullData.push({
          year: '',
          advances: 0,
          fees: 0,
          memberships: [],
          numAdvances: 0
        })
      }
      let lbls = [];



      for (let i = 0; i < dataItems; i++){
          let yr = year - i
          lbls.unshift(yr);
          fullData[dataItems - 1 -i].year = yr;
          for (let j = 0; j < advances.length; j++){
              let curAdv = advances[j];
              if (company !== 'all' && curAdv.businessentity !== company){
                continue;
              }
              if (segment !== 'all' && !validEmp.includes(curAdv.IDNumber)){
                continue;
              }
              if ((new Date(curAdv.AdvanceRequestDate)).getFullYear() === yr){
                  let amt = curAdv.AdvanceAmount || 0;
                  let feeAmt = curAdv.AdvanceFee || 0;
                  adv[dataItems - 1 - i] = adv[dataItems - 1 - i] + amt;
                  fee[dataItems - 1 - i] = fee[dataItems - 1 - i] + feeAmt;
                  fullData[dataItems -1 - i].advances = fullData[dataItems - 1 - i].advances + amt;
                  fullData[dataItems - 1 - i].fees = fullData[dataItems - 1 - i].fees + feeAmt;
                  fullData[dataItems - 1 - i].numAdvances = fullData[dataItems - 1 - i].numAdvances + 1;
                  if (!fullData[dataItems - 1 - i].memberships.includes(curAdv.IDNumber)){
                    fullData[dataItems - 1 - i].memberships.push(curAdv.IDNumber);
                    mem[dataItems - 1 - i] = mem[dataItems - 1 - i] + 10;
                  }
                }
              
          }
    }


      ChartJS.register(
          CategoryScale,
          LinearScale,
          BarElement,
          Title,
          Tooltip,
          Legend
      );

      const options = {
          responsive: true,
          onClick: (e, elements) => {
            if (elements.length > 0){
              setSelectedData(elements[0]?.index);
            }
            
          },
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Yearly Advances',
            },
          },
        };

      const labels = lbls;
        const data = {
          labels,
          datasets: [
            {
              label: 'Advances',
              data: adv,
              backgroundColor: '#772FD7',
            },
            {
              label: 'Fees',
              // data: [1, 2, 3, 4, 5, 6, 7],
              data: fee,
              backgroundColor: '#FF64AB',
            },
            // {
            //   label: 'Membership Fee',
            //   // data: [1, 2, 3, 4, 5, 6, 7],
            //   data: mem,
            //   backgroundColor: '#001949',
            // },
          ],
        };
      return(
        <div style={customStyle}>
          <Bar options={options} data={data} ref={chartRef} />
          {(SelectedData === -1)? null : 
          <div>
            <p><b>Year:</b> {`${fullData[SelectedData].year || ''}`}</p>
            <p><b>Advances:</b> R{`${fullData[SelectedData].advances.toFixed(2) || ''}`}</p>
            <p><b>Fees:</b> R{`${fullData[SelectedData].fees.toFixed(2) || ''}`}</p>
            {/* <p><b>Subscriptions:</b> R{`${fullData[SelectedData].memberships.length * 10 || ''}`}</p> */}
            <p><b>Number of Unique Users:</b> {`${fullData[SelectedData].memberships.length || ''}`}</p>
            <p><b>Number of Advances:</b> {`${fullData[SelectedData].numAdvances || ''}`}</p>
          </div>}
        </div>
      ) 
    }

    
}


export default ReportingEWAGraph;
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import {Form, Button, Card, CardBody } from '../../../../components/basic';
import { View } from '../../../../shared/components';


import '../../../style.scss';
import './style.scss';

const NewUser = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [err, setErr] = useState('');
    const [Permissions, setPermissions] = useState([]);
    const [Role, setRole] = useState('Admin');
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    function nameChanged(e){
        
        setErr('');
        setName(e.target.value);
    }
    
    function lastNameChanged(e){
        setErr('');
        setLastName(e.target.value);
    }

    function emailChanged(e){
        
        setErr('');
        setEmail(e.target.value);
    }

    function passwordChanged(e){
        
        setErr('');
        setPassword1(e.target.value);
    }

    function confirmPasswordChanged(e){
        
        setErr('');
        setPassword2(e.target.value);
    }

    function roleChanged(e){
        setRole(e.target.value);
    }

    function permissionChanged(e){
        let perm = Permissions;
        if (perm.includes(e.currentTarget.id)){
            const index = perm.indexOf(e.currentTarget.id);
            if (index !== -1) {
                perm.splice(index, 1);
            }
        }else{
            perm.push(e.currentTarget.id);
        }
        setPermissions(perm);
        forceUpdate();
    }

    function validatePassword(){

        if (password1.length < 6){
            setErr('Password is too short');
            return false
        }

        if (password1 !== password2){
            setErr('Passwords do not match.');
            return false
        }

        return true

    }

    function addUser(){
        
        
        commonStore.setLoading(true);
        if (validatePassword()){
            let user = {
                username: email,
                email: email, 
                firstname: name,
                lastname: lastName,
                password: password1,
                confirmed: true,
                blocked: false,
                role: '5d8e0b079f51d42bbece9201',
                adminPermissions: Permissions,
                adminRole: Role
            }

            commonStore.createUser(user).then((ret) => {
                if (ret === true){
                    commonStore.setLoading(false);
                    navigate('/admindashboard');
                }else{
                    setErr(ret);
                    commonStore.setLoading(false);
                }
            });
        }else{
            commonStore.setLoading(false);
        }
    }

    function goBack(){
        navigate('/admindashboard');
    }

        

        if (isMobile()){
            return(
                <View>
                    
                </View>
            );
        }else{
            return(
                <View>
                    <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                        <Card className="mb-3 form-card loginCard" style={{maxHeight: `${window.innerHeight -100}px`}}>
                        <CardBody style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
                            <header className="mb-4 topLogin">
                                <img alt='logo' src="https://levelfinance.azureedge.net/siteassets/logo2.png" />
                                <p className="txtlead">New Admin User</p>
                                <p className="txtsub">A new admin user will be added. Admin users do not have access to requesting advances and only have access to the admin dashboard.</p>
                                <br/>
                            </header>
                            <Form>
                                <label className="custom-field">
                                    <input className='inp' type="text" id="name" value={name} onChange={nameChanged} required/>
                                    <span className="placeholder">First name</span>
                                </label> 

                                <label className="custom-field">
                                    <input className='inp' type="text" id="lastname" value={lastName} onChange={lastNameChanged} required/>
                                    <span className="placeholder">Last name</span>
                                </label> 

                                <label className="custom-field">
                                    <input className='inp' type="email" id="email" value={email} onChange={emailChanged} required/>
                                    <span className="placeholder">Email</span>
                                </label> 

                                <label className="custom-field">
                                    <select className='inp' value={Role} onChange={roleChanged} required>
                                        <option value='Admin'>Admin</option>
                                        <option value='Support'>Support</option>
                                    </select>
                                    <span className="placeholder">Role</span>
                                </label>

                                <label className="custom-field">
                                    <input className='inp' type="password" id="password" value={password1} onChange={passwordChanged} required/>
                                    <span className="placeholder">Password</span>
                                </label> 

                                <label className="custom-field">
                                    <input className='inp' type="password" id="confirmpassword" value={password2} onChange={confirmPasswordChanged} required/>
                                    <span className="placeholder">Confirm password</span>
                                </label> 
                                <p>Permissions</p>
                                <div>
                                    <label id='Reporting' className="checkboxContainer">Reporting
                                        <input type="checkbox" onChange={permissionChanged} id='Reporting' checked={Permissions.includes('Reporting')}/>
                                        <span id='Reporting' className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label id='Client' className="checkboxContainer">Client
                                        <input type="checkbox" onChange={permissionChanged} id='Client' checked={Permissions.includes('Client')}/>
                                        <span id='Client' className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label id='Advances' className="checkboxContainer">Advances
                                        <input type="checkbox" onChange={permissionChanged} id='Advances' checked={Permissions.includes('Advances')}/>
                                        <span id='Advances' className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label id='Invoices' className="checkboxContainer">Invoices
                                        <input type="checkbox" onChange={permissionChanged} id='Invoices' checked={Permissions.includes('Invoices')}/>
                                        <span id='Invoices' className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label id='Employee' className="checkboxContainer">Employee
                                        <input type="checkbox" onChange={permissionChanged} id='Employee' checked={Permissions.includes('Employee')}/>
                                        <span id='Employee' className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label id='NewEmployee' className="checkboxContainer">New Employee
                                        <input type="checkbox" onChange={permissionChanged} id='NewEmployee' checked={Permissions.includes('NewEmployee')}/>
                                        <span id='NewEmployee' className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label id='AdminUsers' className="checkboxContainer">Admin Users
                                        <input type="checkbox" onChange={permissionChanged} id='AdminUsers' checked={Permissions.includes('AdminUsers')}/>
                                        <span id='AdminUsers' className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label id='SupportRequests' className="checkboxContainer">Support Requests
                                        <input type="checkbox" onChange={permissionChanged} id='SupportRequests' checked={Permissions.includes('SupportRequests')}/>
                                        <span id='SupportRequests' className="checkmark"></span>
                                    </label>
                                </div>

                                <div>
                                    <label id='Logs' className="checkboxContainer">Logs
                                        <input type="checkbox" onChange={permissionChanged} id='Logs' checked={Permissions.includes('Logs')}/>
                                        <span id='Logs' className="checkmark"></span>
                                    </label>
                                </div>

                                

                                {(err.length > 0) ? <p style={{color: 'red'}}>{err}</p>:null}
                                <Button block size="lg" onClick={addUser}>Add user</Button>
                                <Button block size="lg" className='transparentButton' onClick={goBack}>Cancel</Button>

                            </Form>
                        </CardBody>
                        </Card>
                    </div>
                </View>
            );
        }
        
})

export default NewUser


import React from 'react';


function ReportingSegmentAverages({ className, id, employees=[], advances=[], segment=1}){
    var currentEmployees = [];
    switch (segment){
        case 1: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount <= 8000); break;
        case 2: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 8001 && emp.employeesalary.Amount <= 15000); break;
        case 3: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 15001 && emp.employeesalary.Amount <= 30000); break;
        case 4: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 30001 && emp.employeesalary.Amount <= 50000); break;
        case 5: currentEmployees = employees.filter(emp => emp.employeesalary && emp.employeesalary.Amount &&  emp.employeesalary.Amount >= 50001); break;
    }

    let validEmp = [];

    for (let i = 0; i < currentEmployees.length; i++){
        validEmp.push(currentEmployees[i].IDNumber)
    }
    let total = 0;
    let totalAdv = 0;
    let totalFee = 0;
    for (let i = 0; i < advances.length; i++){
        let advance = advances[i];
        if (validEmp.includes(advance.IDNumber)){
            total += 1;
            totalAdv += advance.AdvanceAmount || 0;
            totalFee += advance.AdvanceFee || 0;
        }
    }


    return (
        <div>
            <p><b>Average Advance Amount: </b> {`R${(totalAdv/total).toFixed(2)}`}</p>
            <p><b>Average Advance Fee: </b> {`R${(totalFee/total).toFixed(2)}`}</p>
        </div>
    )
    
}


export default ReportingSegmentAverages;
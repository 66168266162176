import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';
import { AllAdvancesPDF } from '../../../components';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FaChevronLeft, FaChevronRight, FaFilePdf } from 'react-icons/fa';



import '../../style.scss';
import './style.scss';
const XLSX = require('xlsx');
const fs = require('fs');

const AllAdvances = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();
    const [FromDate, setFromDate] = useState(new Date());
    const [ToDate, setToDate] = useState(new Date());
    const [PaidAdvances, setPaidAdvances] = useState([]);
    const [CurrentAdvances, setCurrentAdvances] = useState([]);
    const [ExportLoading, setExportLoading] = useState(false);
    const [Page, setPage] = useState(0);
    const [MaxPages, setMaxPages] = useState(0);
    

 
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        getAdvances();
        setPage(0);
    }, []);

    const getAdvances = async() => {
        commonStore.setLoading(true);
        let startDate = new Date();
        startDate.setDate(1);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        startDate.setHours(2)
        setFromDate(startDate);
        console.log(startDate);
        let endDate = new Date();
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        endDate.setMilliseconds(99);
        setToDate(endDate);
        let allAdvances = await commonStore.getAdvancesPlain();
        let allTransactionHistory = await commonStore.getTransactionHistory();
        let allClients = await commonStore.getAllClients();
        let allEmployeeInfos = await commonStore.getAllEmployeeInfo();
        let successfulAdvances = [];
        for (let i = 0; i < allTransactionHistory.length; i++) {
            let currentTransactionHistory = allTransactionHistory[i];
            if (currentTransactionHistory.TransactionStatus !== 'Paid'){
                continue;
            }
            let empAdvHist = allAdvances.filter((adv) => adv.transactionhistory === currentTransactionHistory._id);
            if (empAdvHist.length === 0){
                console.log(`Error finding ${currentTransactionHistory._id}`);
                continue;
            }
            let fullObject = empAdvHist[0];
            fullObject.transactionhistory = currentTransactionHistory;
            let currentComp = allClients.filter((a) => a._id === fullObject.businessentity);
            if (currentComp.length > 0){
                fullObject.company = currentComp[0].name;
            }
            let currentUsr = allEmployeeInfos.filter((a) => a.IDNumber === fullObject.IDNumber);
            if (currentUsr.length > 0){
                fullObject.FirstName = currentUsr[0].FirstName;
                fullObject.LastName = currentUsr[0].LastName;
            }
            successfulAdvances.push(fullObject);
        }
        successfulAdvances.map(obj => obj.method = obj.transactionhistory.TransactionMessage.includes("Manual advance") ? 'Manual' : 'Ozow');
        successfulAdvances.sort((a, b) => new Date(a.AdvanceRequestDate) - new Date(b.AdvanceRequestDate));
        let addedFees = [];
        for (let i = 0; i < successfulAdvances.length; i++){
            let advdte = new Date(successfulAdvances[i].AdvanceRequestDate);
            let str = `${successfulAdvances[i].IDNumber}${advdte.getMonth()}${advdte.getFullYear()}`;
            if (addedFees.includes(str)){
                successfulAdvances[i].monthlyFee = 0;
            }else{
                successfulAdvances[i].monthlyFee = 10;
                addedFees.push(str);
            }
        }
        setPaidAdvances(successfulAdvances);
        forceUpdate();
        commonStore.setLoading(false);
        initialiseCurrentAdvances(successfulAdvances, startDate, endDate);
        
    }

    function fromDateChanged(e){
        console.log(e.target.value);
        let dateArray = e.target.value.split('-');
        let tempDate = FromDate;
        tempDate.setFullYear(Number(dateArray[0]));
        tempDate.setMonth(Number(dateArray[1]) - 1);
        tempDate.setDate(Number(dateArray[2]));
        tempDate.setMinutes(0);
        tempDate.setSeconds(0);
        tempDate.setMilliseconds(0);
        tempDate.setHours(2)
        setFromDate(tempDate);
        setExportLoading(false);
        setPage(0);
        forceUpdate();
        populateCurrentAdvances();

    }

    function toDateChanged(e){
        console.log(e.target.value);
        let dateArray = e.target.value.split('-');
        let tempDate = ToDate;
        tempDate.setFullYear(Number(dateArray[0]));
        tempDate.setMonth(Number(dateArray[1]) - 1);
        tempDate.setDate(Number(dateArray[2]));
        tempDate.setMinutes(0);
        tempDate.setSeconds(0);
        tempDate.setMilliseconds(0);
        tempDate.setHours(2)
        setToDate(tempDate);
        setExportLoading(false);
        setPage(0);
        forceUpdate(); 
        populateCurrentAdvances();
    }

    function populateCurrentAdvances(){
        let curAdvances = PaidAdvances.filter((adv) => (new Date(adv.AdvanceRequestDate)) >= FromDate && (new Date(adv.AdvanceRequestDate)) <= ToDate);
        console.log(curAdvances);
        setCurrentAdvances(curAdvances);
        let basePages = Math.floor(curAdvances.length / 10);
        let rem = curAdvances.length % 10;
        if (rem !== 0){
            basePages += 1;
        }
        setMaxPages(basePages);
        forceUpdate();
    }

    function initialiseCurrentAdvances(allAdv, fDate, tDate){
        let curAdvances = allAdv.filter((adv) => (new Date(adv.AdvanceRequestDate)) >= fDate && (new Date(adv.AdvanceRequestDate)) <= tDate);
        setCurrentAdvances(curAdvances);
        let basePages = Math.floor(curAdvances.length / 10);
        let rem = curAdvances.length % 10;
        if (rem !== 0){
            basePages += 1;
        }
        setMaxPages(basePages);
        forceUpdate();
    }

    function loadExport(){
        setExportLoading(true);
    }

    function increasePage(){
        let pg = Page + 1;
        if (pg === MaxPages){
            return
        }
        setPage(Page + 1);
    }

    function decreasePage(){
        if (Page === 0){
            return
        }
        setPage(Page - 1);
    }

    const exportToExcel = async() => {
        // let buffer = await commonStore.exportExcelOfAllAdvances(CurrentAdvances, FromDate, ToDate)
        // console.log(buff.toString('base64'));
        let allData = [];
        let advanceTotal = 0;
        let feeTotal = 0;
        let monthlyTotal = 0;
        let totalExcl = 0;
        let totalIncl = 0;
        for (let i = 0; i < CurrentAdvances.length; i++ ){
            let advance = CurrentAdvances[i];
            let obj = {
                Date: (new Date(advance.AdvanceRequestDate)).toLocaleDateString(),
                IDNumber: advance.IDNumber,
                Name: `${advance.FirstName} ${advance.LastName}`,
                Company: advance.company,
                Method: advance.method,
                Advance: advance.AdvanceAmount,
                Fee: advance.AdvanceFee,
                Monthly_Fee: advance.monthlyFee,
                Total_VAT_Excl: Number((advance.AdvanceAmount + advance.AdvanceFee + advance.monthlyFee).toFixed(2)),
                Total_VAT_Incl: Number((advance.AdvanceAmount + advance.AdvanceFee + advance.monthlyFee + ((advance.AdvanceFee + advance.monthlyFee) * 15 / 100)).toFixed(2))
            }
            allData.push(obj);
            advanceTotal += advance.AdvanceAmount;
            feeTotal += advance.AdvanceFee;
            monthlyTotal += advance.monthlyFee;
            totalExcl += advance.AdvanceAmount + advance.AdvanceFee + advance.monthlyFee;
            totalIncl += (advance.AdvanceAmount + advance.AdvanceFee + advance.monthlyFee + ((advance.AdvanceFee + advance.monthlyFee) * 15 / 100));
        }
        allData.push({
            Date: 'Total',
            IDNumber: '',
            Name: '',
            Company: '',
            Method: '',
            Advance: Number(advanceTotal.toFixed(2)),
            Fee: Number(feeTotal.toFixed(2)),
            Monthly_Fee: Number(monthlyTotal.toFixed(2)),
            Total_VAT_Excl: Number(totalExcl.toFixed(2)),
            Total_VAT_Incl: Number(totalIncl.toFixed(2))
        })
        const workSheet = XLSX.utils.json_to_sheet(allData);
        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, "LevelSheet");
        const buffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${FromDate.toLocaleDateString()}-${ToDate.toLocaleDateString()}.xlsx`;
        a.click();
    }



    

        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%', padding: '10px', overflow: 'scroll'}}>

                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                   <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>All Advances</p>
                        </div>
                    </header>

                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <label htmlFor="fromDate">From:
                                <input type="date" id="fromDate" name="fromDate" value={FromDate.toISOString().split('T')[0]} onChange={fromDateChanged}/>
                            </label>
                            <label htmlFor="toDate">To:
                                <input type="date" id="toDate" name="toDate" value={ToDate.toISOString().split('T')[0]} onChange={toDateChanged}/>
                            </label>
                            {(ExportLoading) ?  
                            <PDFDownloadLink
                            document=
                            {
                                <AllAdvancesPDF employeeAdvances={CurrentAdvances} fromDate={FromDate} toDate={ToDate}/>
                            } fileName={`${FromDate.toLocaleDateString()} - ${ToDate.toLocaleDateString()} Advances Export.pdf`} >
                            {
                                ({ loading }) =>
                                (loading ? <Button>Loading Document...</Button> :
                                    <FaFilePdf style={{ height: '20px', color: 'black', cursor: 'pointer' }}>Download</FaFilePdf>)
                            }
                            </PDFDownloadLink>
                            : <Button onClick={loadExport}>Load Export</Button>}
                            <Button onClick={exportToExcel}>Excel</Button>
                        </div>
                    </div>
                    <hr/>
                    <div className='pageStepperContainer'>
                        <p>Total: {CurrentAdvances.length}</p>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <FaChevronLeft style={{color: 'black', fontSize: '25px', cursor: 'pointer'}} onClick={decreasePage}/>
                            <p style={{margin: '0px'}}>Page {Page + 1} of {MaxPages}</p>
                            <FaChevronRight style={{color: 'black', fontSize: '25px', cursor: 'pointer'}} onClick={increasePage}/>
                        </div>
                    </div>
                    {CurrentAdvances.map((adv, index) => {
                        let startIndex = Page * 10;
                        let endIndex = startIndex + 9;
                        if (index < startIndex || index > endIndex) {
                            return
                        }
                        return (
                            <Card style={{borderRadius: '15px', padding: '10px', margin: '10px'}}>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <p>{`${adv.IDNumber} (${adv.method})`}</p>
                                    <p className="txtsub">{(new Date(adv.AdvanceRequestDate)).toLocaleString()}</p>
                                </div>
        
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <p>{`Fee: R${adv.AdvanceFee}`}</p>
                                    <p>{`Amount: R${adv.AdvanceAmount}`}</p>
                                </div>
                            </Card>
                        )
                    })}



                    

                </View>
            );
        }
        
})

export default AllAdvances;
import React from 'react';


function View({children, style}) {

    return (
        <div className="view" style={style}>
                {/* <Loading /> */}
                {children}
            </div>
    )
    
    
}

export default View;


import React from 'react';
import './style.css';

function ToggleSwitch({isOn, handleToggle}) {



    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className="react-switch-checkbox"
                id={`react-switch-new`}
                type="checkbox"
            />
            <label
                style={{ background: isOn && '#4c1ba5' }}
                className="react-switch-label"
                htmlFor={`react-switch-new`}
            >
                <span className={`react-switch-button`} />
            </label>
        </>
    )
    
    
}

export default ToggleSwitch;
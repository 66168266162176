import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Label, FormGroup, Button, Card, CardBody } from '../../components/basic';
import { View, AndroidInstallPrompt, IOSInstallPrompt, CustomInput } from '../../shared/components';


import { FaAngleRight, FaCheck } from 'react-icons/fa';

import '../style.scss';
import './style.scss';

const SignUp = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();


    const [data, setData] = useState({
        idpassport: '',
        error: '',
        sidebarHidden: true,
        dataAccessGiven: false,
        modalViewHidden: true,
        displayPrompt: true,
        device: '',
        prompt: {}
    });
    const [err, setErr] = useState(null);
    const [isReg, setIsReg] = useState(false);

    const [IDNumber, setIDNumber] = useState('');
    const [dataAccessGiven, setDataAccessGiven] = useState(false);
    const [modalViewHidden, setModalViewHidden] = useState(true);

    function setStateData(val) {
        if (Object.keys(val)[0] === 'data') {
            setData(val.data)
        }

        if (Object.keys(val)[0] === 'err') {
            setErr(val.err)
        }

        if (Object.keys(val)[0] === 'isReg') {
            setIsReg(val.isReg)
        }
    }

    useEffect(() => {
        clearStorage();
        if (isMobile()) {
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
        if (isMobile()) {
            let sidebarHeight = window.innerHeight - document.getElementById('headerCard').clientHeight;
            document.getElementById('sidebarCard').style.height = `${sidebarHeight}px`;
            document.getElementById('sidebarDiv').style.top = `${document.getElementById('headerCard').clientHeight}px`
        }

        if (isMobile()) {
            let modalHeight = window.innerHeight - 60;
            document.getElementById('modalCard').style.height = `${modalHeight}px`;

        }

        if (isMobile()) { //Install Prompt

            if (window.matchMedia('(display-mode: standalone)').matches) {
                //If already running PWA
                return
            }
            const ua = window.navigator.userAgent;
            if (!!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Mac/i)) {
                //iOS
                loadIosPrompt();
            } else {
                //Android
                window.addEventListener('beforeinstallprompt', (event) => {
                    console.log('[beforeinstallprompt] Called');
                    // Prevent Chrome <= 67 from automatically showing the prompt
                    event.preventDefault();
                    // Stash the event so it can be triggered later.
                    data.prompt = event;
                    // Update the install UI to notify the user app can be installed
                    // document.querySelector('#install-button').disabled = false;
                    loadPrompt();
                });

            }
        }
    }, []);



    function loadPrompt() {

        if (window.matchMedia('(display-mode: standalone)').matches) {
            //If already running PWA
            return
        }
        const ua = window.navigator.userAgent;

        if (!!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Mac/i)) {
            //iOS
            // data.device = 'iOS';
            // setStateData({ data: data});
            return
        } else {
            //Android
            data.device = 'Android';
            setStateData({ data: data });
        }
    }

    function loadIosPrompt() {

        data.device = 'iOS';
        setStateData({ data: data });
    }

    function closeInstallPrompt() {

        data.displayPrompt = false;
        setStateData({ data: data });
    }

    function installPromptInstall() {

        data.prompt.prompt();
        data.displayPrompt = false;
        setStateData({ data: data });
    }

    function clearStorage() {

        commonStore.clearStorage();
    }

    function handleChange(e) {
        setIDNumber(e.target.value);
        setErr('');
    }

    function doValidation() {

        let _err = '';
        let errors = [];
        let hidden = data.sidebarHidden;

        if (!IDNumber || IDNumber.length < 5) {
            setErr('Please confirm your ID or Passport Number');
            return false
        }
        return true
    }

    function doReg() {


        commonStore.setLoading(true);
        // // Validate & Todo
        if (dataAccessGiven === false || !dataAccessGiven) {
            setErr('Please allow us access to your data to continue');
            // setStateData({ data: data});
            commonStore.setLoading(false);
            return
        }
        if (doValidation()) {
            let idpassport = IDNumber;

            commonStore.isRegistered(idpassport).then((isReg) => {
                if (isReg === true) {
                    commonStore.getEmployees(idpassport).then((res) => {
                        if (res) {
                            commonStore.dataAccessPermission();
                            commonStore.setLoading(false);
                            //navigate('/verifyid'); // SKIPPED FOR TESTING
                            //  navigate('/verifiedid'); //TESTING
                            navigate('/verifyidfallback');
                        } else {
                            commonStore.setLoading(false);
                        }
                    })
                } else if (isReg === 'Blocked') {
                    data.error = 'This account has been blocked, please contact support.';
                    setStateData({ data: data });
                    commonStore.setLoading(false);
                    return

                } else if (isReg === 'Does not exist') {
                    data.error = 'Unable to process your request, please contact support.';
                    setStateData({ data: data });
                    commonStore.setLoading(false);
                    return

                } else {
                    //Fail
                    setErr('User already registered, please log in.')
                    // commonStore.setUnmatched(true);
                    commonStore.setLoading(false);
                    setStateData({ isReg: true });
                }
            })

        } else {
            commonStore.setLoading(false);
        }
    }

    function toggleSidebar() {

        const sidebar = document.getElementById('sidebarDiv');
        if (data.sidebarHidden) {
            sidebar.classList.add('is-open')
        } else {
            sidebar.classList.remove('is-open');
        }


        data.sidebarHidden = !data.sidebarHidden;
        setStateData({ data: data });

    }

    function dataAccessChange() {

        setErr('');
        let tmp = !dataAccessGiven;
        setDataAccessGiven(!dataAccessGiven);

        if (tmp) {
            showModal();
        }
    }

    function login() {

        navigate('/signin');
    }

    function contactUs() {

        navigate('/contactus');
    }

    function showModal() {
        setModalViewHidden(false);
    }
    function allowAccess() {
        setDataAccessGiven(true);
        setModalViewHidden(true);
    }

    function retractAccess() {

        setDataAccessGiven(false);
        setModalViewHidden(true);
    }

    if (isMobile()) {
        return (
            <div id="mobileDashView" className="view" style={{ justifyContent: 'flex-start' }}>

                <div className='modalAccessDiv' hidden={modalViewHidden}>
                    <Card id="modalCard">
                        <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" style={{ width: '50%' }} />
                        <header style={{ alignItems: 'center' }}>

                            <p className="txtlead">Data access</p>
                            <p className="txtsub">To register your account, we need to access your personal data like your:</p>
                        </header>

                        <div className="dataAccessDiv">
                            <p style={{ marginBottom: '5px' }}><FaCheck style={{ color: '#6aad85', marginRight: '10px' }} />Full Name</p>
                            <hr style={{ marginBottom: '10px', marginTop: '1px' }} />
                        </div>
                        <div className="dataAccessDiv">
                            <p style={{ marginBottom: '5px' }}><FaCheck style={{ color: '#6aad85', marginRight: '10px' }} />Contact information</p>
                            <hr style={{ marginBottom: '10px', marginTop: '1px' }} />
                        </div>
                        <div className="dataAccessDiv">
                            <p style={{ marginBottom: '5px' }}><FaCheck style={{ color: '#6aad85', marginRight: '10px' }} />Salary information</p>
                            <hr style={{ marginBottom: '10px', marginTop: '1px' }} />
                        </div>
                        <div className="dataAccessDiv">
                            <p style={{ marginBottom: '5px' }}><FaCheck style={{ color: '#6aad85', marginRight: '10px' }} />Bank account details</p>
                            <hr style={{ marginBottom: '10px', marginTop: '1px' }} />
                        </div>
                        <Button className='cornered-button' block size="lg" onClick={allowAccess}>Allow access</Button>
                        <Button className="cornered-button transparentButton" block size="lg" onClick={retractAccess}>Cancel</Button>
                    </Card>
                </div>
                <div>
                    <Card id='headerCard'>
                        <CardBody>
                            <header className="site-head d-flex align-items-center justify-content-between" style={{ backgroundColor: '#ffffff' }}>
                                <div className="wrap mr-4">
                                    <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" style={{ cursor: 'pointer', width: '75px' }} />
                                </div>
                                <div className="right-elems ml-auto d-flex">
                                    <img src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/menu.svg" alt='mnu' onClick={toggleSidebar} style={{ cursor: 'pointer', width: '18px', height: '18px' }} />
                                </div>
                            </header>
                        </CardBody>
                    </Card>
                </div>

                <div id="mainView" className="viewContent view-pages view-session d-flex justify-content-center flex-column" style={{ width: '100%' }}>
                    <p className="txtlead">Sign up</p>
                    <p className="txtsub">Let's first verify your identity profile. Please enter your ID number.</p>
                    <Form>
                        <CustomInput type='text' id='id' value={IDNumber} placeholder='ID/Passport number' onChange={handleChange} onEnter={doReg} />

                        <FormGroup className="text-right">


                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <label style={{ textAlign: 'left' }}>
                                    <input id="dataAccessCheckbox" type="checkbox" onChange={dataAccessChange} checked={dataAccessGiven} style={{ verticalAlign: 'bottom', position: 'relative', top: '-1px', marginRight: '10px' }} />
                                    Allow Level Finance to access my personal data
                                </label>
                            </div>

                            <p className="txtleft" style={{ color: 'red' }}>{err}</p>
                            <Button className='cornered-button' block size="lg" onClick={doReg} disabled={(data.modalViewHidden) ? false : true}>Next</Button>
                            <br />
                            <Label className='txtlogin'>Already have an account? <a href='/signin'>Log In</a></Label>
                        </FormGroup>
                    </Form>
                </div>

                <div id='sidebarDiv'>
                    <Card id="sidebarCard">

                        <Card id='sidebarDashCard' onClick={login}>
                            <p className="txtlead"><i className="fa fa-sign-in-alt" />Sign in</p>
                        </Card>

                        <Card id='sidebarDashCard' onClick={contactUs}>
                            <p className="txtlead"><i className="fa fa-comments" />Contact us</p>
                        </Card>
                    </Card>
                </div>
                {(data.displayPrompt) ?
                    (data.device === 'Android') ? <AndroidInstallPrompt onClose={closeInstallPrompt} onInstall={installPromptInstall} /> :
                        (data.device === 'iOS') ? <IOSInstallPrompt onClose={closeInstallPrompt} onInstall={installPromptInstall} /> : null

                    : null
                }
            </div>
        );

    } else {
        return (
            <View>

                <div className='modalAccessDivDesktop' hidden={modalViewHidden}>
                    <Card id="modalCard" style={{ height: '75vh' }}>
                        <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" style={{ width: '50%' }} />
                        <header style={{ alignItems: 'center' }}>

                            <p className="txtlead">Data access</p>
                            <p className="txtsub">To register your account, we need to access your personal data like your:</p>
                        </header>

                        <div className="dataAccessDiv">
                            <p style={{ marginBottom: '5px' }}><FaCheck style={{ color: '#6aad85', marginRight: '10px' }} />Full Name</p>
                            <hr style={{ marginBottom: '10px', marginTop: '1px' }} />
                        </div>
                        <div className="dataAccessDiv">
                            <p style={{ marginBottom: '5px' }}><FaCheck style={{ color: '#6aad85', marginRight: '10px' }} />Contact information</p>
                            <hr style={{ marginBottom: '10px', marginTop: '1px' }} />
                        </div>
                        <div className="dataAccessDiv">
                            <p style={{ marginBottom: '5px' }}><FaCheck style={{ color: '#6aad85', marginRight: '10px' }} />Salary information</p>
                            <hr style={{ marginBottom: '10px', marginTop: '1px' }} />
                        </div>
                        <div className="dataAccessDiv">
                            <p style={{ marginBottom: '5px' }}><FaCheck style={{ color: '#6aad85', marginRight: '10px' }} />Bank account details</p>
                            <hr style={{ marginBottom: '10px', marginTop: '1px' }} />
                        </div>
                        <Button block size="lg" onClick={allowAccess} style={{ width: '50%' }}>Allow access</Button>
                        <Button className="transparentButton" block size="lg" onClick={retractAccess} style={{ width: '50%' }}>Cancel</Button>
                    </Card>
                </div>

                <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                    <Card className="mb-3 form-card loginCard" style={{ maxHeight: `${window.innerHeight - 100}px` }}>
                        <CardBody style={{ display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
                            <header className="mb-4 topLogin">
                                <img alt='logo' src="https://d2xts8p0gw7i1c.cloudfront.net/siteAssets/Logo.svg" />
                                {(commonStore.unmatched) ?
                                    <>
                                        <p className="txtlead">Oops!</p>
                                        {(isReg) ?
                                            <p className="txtsub">Sorry, ID number already registered, please login.</p>
                                            : <p className="txtsub">Sorry, we cannot find a matching ID number with the employer you specified. Please verify the details below.</p>}
                                    </> :
                                    <>
                                        <p className="txtlead">Sign up</p>
                                        <p className="txtsub">Let's first verify your identity profile. Please enter your ID number.</p>
                                    </>
                                }
                            </header>
                            <Form>
                                <CustomInput type='text' id='id' value={IDNumber} placeholder='ID/Passport number' onChange={handleChange} onEnter={doReg} />



                                <FormGroup className="text-right">

                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <label style={{ textAlign: 'left' }}>
                                            <input id="dataAccessCheckbox" type="checkbox" onChange={dataAccessChange} checked={dataAccessGiven} style={{ verticalAlign: 'bottom', position: 'relative', top: '-1px', marginRight: '10px' }} />
                                            Allow Level Finance to access my personal data
                                        </label>
                                    </div>

                                    <p className="txtleft" style={{ color: 'red' }}>{data.error}</p>
                                    <Button className='cornered-button' block size="lg" onClick={doReg} disabled={(data.modalViewHidden) ? false : true}>Next &gt;</Button>
                                    <br />
                                    <Label className='txtlogin'>Already have an account? <a href='/signin'>Log In</a></Label>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </View>
        );
    }

})

export default SignUp
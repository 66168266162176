import React from 'react';
// import PropTypes from "prop-types"
// import './Input.css';
// import Burger from '../../assets/images/BurgerMenu.svg';
// import { FaUserAlt } from 'react-icons/fa';
// import * as FontAwesome from 'react-icons/fa';


function CustomInput({ onChange, className, type, id, placeholder, value, onEnter, accept, style = {} }) {

    return (
        <label className="custom-field">
            <input className={`inp CustomInput ${className}`} accept={accept} value={value} type={type} id={`${id}`} onChange={onChange} onKeyDown={(e) => { if (e && e.key && e.key === 'Enter') { e.preventDefault(); onEnter(); } }} required />
            <span className="placeholder">{placeholder}</span>
        </label>
    )


}

export default CustomInput;
import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card, Form } from '../../../components/basic';
import { View, CustomInput } from '../../../shared/components';

import { FaStop, FaPlay, FaRegEyeSlash, FaRegEye, FaBan, FaCheck, FaFilePdf, FaRegTimesCircle, FaMoneyCheckAlt, FaDollarSign, FaSearch, FaInfoCircle } from 'react-icons/fa';
import { SlMagnifier } from 'react-icons/sl';
import { PDFEmployeeAdvances } from '../../../components';
import { PDFDownloadLink } from "@react-pdf/renderer";



import '../../style.scss';
import './style.scss';

const Employee = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    // #region State
    const [IDNumber, setIDNumber] = useState('');
    const [Employee, setEmployee] = useState({});
    const [Contact, setContact] = useState({
        Mobile: '', 
        PrivateEmail: ''
    });
    const [Salary, setSalary] = useState({
        Amount: 0,
        ElectedAdvancePerc: 0,
        FeeAmount: 0
    });
    const [ShowSalary, setShowSalary] = useState(false);

    const [BankAccount, setBankAccount] = useState({
        IDNumber: '',
        accountnumber: '',
        bankname: 'ABSA',
        branchcode: '632005',
        branchname: '',
        type: ''
    });
    const [BankAccountExist, setBankAccountExists] = useState(false);
    const [ShowAccountNum, setShowAccountNum] = useState(false);
    const [Advances, setAdvances] = useState({
        amountAllowed: -1,
        amountAvailable: -1,
        history: []
    })
    const [NewAdvanceAmount, setNewAdvanceAmount] = useState(0);
    const [NewAdvanceFee, setNewAdvanceFee] = useState(0);
    const [NewAdvancePaymentMethod, setNewAdvancePaymentMethod] = useState('Manual');
    const [NewAdvanceRef, setNewAdvanceRef] = useState('');
    const [NewAdvanceDate, setNewAdvanceDate] = useState('');
    const [UserAuthenticated, setUserAuthenticated] = useState(true);
    const [SuccessfulAdvances, setSuccessfulAdvances] = useState([]);
    const [OzowPayoutStatus, setOzowPayoutStatus] = useState('');
    const [ShowStatus, setShowStatus] = useState(false);
    const [PayoutStatus, setPayoutStatus] = useState('');
    const [PayoutStatusDesc, setPayoutStatusDesc] = useState('');
    const [PayoutSubStatus, setPayoutSubStatus] = useState('');
    const [PayoutSubStatusDesc, setPayoutSubStatusDesc] = useState('');
    const [PayoutError, setPayoutError] = useState('');

    const [activePage, setActivePage] = useState('Search');
    // #endregion

    useEffect(() => {
    }, []);

    // #region Find User

    function IDNumberChanged(e){
        setIDNumber(e.target.value);
    }

    function findUser(){
        if (IDNumber === ''){
            window.alert('Please enter an ID number');
            return
        }
        commonStore.setLoading(true);
        commonStore.getEmployee(IDNumber).then((ret) => {
            if (!ret || ret === 'False'){
                window.alert('User not found');
                commonStore.setLoading(false);
                return
            }
            console.log(ret)
            setEmployee(ret)
            setSalary(ret.employeesalary);
            setContact(ret.employeecontact);
            let acc = {
                IDNumber: IDNumber,
                accountnumber: '',
                bankname: 'ABSA',
                branchcode: '632005',
                branchname: '',
                type: ''
            }
            if (Object.keys(ret).includes('bankaccount') ){
                if (ret.bankaccount !== null && ret.bankaccount !== 'null'){
                    if (Object.keys(ret.bankaccount).includes('bankname') && Object.keys(ret.bankaccount).includes('accountnumber') && Object.keys(ret.bankaccount).includes('branchcode')){
                        setBankAccount(ret.bankaccount);
                        setBankAccountExists(true);
                    }else{
                        setBankAccount(acc);
                        setBankAccountExists(false);
                    }
                }else{
                    setBankAccount(acc);
                    setBankAccountExists(false);
                }
                
            }else{
                setBankAccount(acc);
                setBankAccountExists(false);
            }

            // commonStore.setLoading(false);
            loadAdvances();
        })
    }

    function loadAdvances(){
        commonStore.setLoading(true);
        commonStore.loadAdvanceHistory(IDNumber).then((ret) => {
            let adv = ret.history.sort((a , b) => {return new Date(a.AdvanceRequestDate) - new Date(b.AdvanceRequestDate)});
            ret.history = adv;
            console.log(ret.history);
            setAdvances(ret);
            let succAdv = adv.filter((ad) => ad.transactionhistory && ad.transactionhistory.TransactionStatus !== 'Failed')
            setSuccessfulAdvances(succAdv);
            // commonStore.setLoading(false);
            getIDVerification();
            commonStore.setLoading(false);
        })
    }

    function getIDVerification(){
        console.log('get verification')
        commonStore.getIDVerificationById(IDNumber).then((ret) => {
            if (ret.length > 0){
                setUserAuthenticated(true);
            }else{
                setUserAuthenticated(false);
            }
            commonStore.setLoading(false);
        })
    }

    function clearData() {
        setIDNumber('');
        setEmployee({});
        setContact({ Mobile: '', PrivateEmail: '' });
        setSalary({ Amount: 0, ElectedAdvancePerc: 0, FeeAmount: 0 });
        setShowSalary(false);
        setBankAccount({ IDNumber: '', accountnumber: '', bankname: 'ABSA', branchcode: '632005', branchname: '', type: '' });
        setBankAccountExists(false);
        setShowAccountNum(false);
        setAdvances({ amountAllowed: -1, amountAvailable: -1, history: [] });
        setNewAdvanceAmount(0);
        setNewAdvanceFee(0);
        setNewAdvancePaymentMethod('Manual');
        setNewAdvanceRef('');
        setNewAdvanceDate('');
        setUserAuthenticated(true);
        setSuccessfulAdvances([]);
        setOzowPayoutStatus('');
        setShowStatus(false);
        setPayoutStatus('');
        setPayoutStatusDesc('');
        setPayoutSubStatus('');
        setPayoutSubStatusDesc('');
        setPayoutError('');
        setActivePage('Search');
      }
      

    // #endregion

    // #region Block/Unblock user
    function unblockUser(e){
        
        
        if (window.confirm(`Are you sure you want to unblock ${Employee.employeeinfo.FirstName}?`)){
            commonStore.setLoading(true);
            let id = IDNumber;
            commonStore.unblockUser(id, `The user has been unblocked by ${commonStore.user.username}.`).then((ret) => {
                if (ret === true){
                    findUser();
                    window.alert('User unblocked');
                    commonStore.setLoading(false);
                }else{
                    window.alert('Unable to block user')
                    commonStore.setLoading(false);
                }
            });
        }
    }

    function blockUser(e){
        
        if (window.confirm(`Are you sure you want to block ${Employee.employeeinfo.FirstName}?`)){
            commonStore.setLoading(true);
            let id = IDNumber;
            commonStore.blockUser(id, `The user has been blocked by ${commonStore.user.username}.`).then((ret) => {
                if (ret === true){
                    findUser();
                    window.alert('User blocked');
                    commonStore.setLoading(false);
                }else{
                    window.alert('Unable to block user');
                    commonStore.setLoading(false);
                }
            });
        }
    }
    // #endregion 

    // #region Salary
    function salaryChanged(e){
        let sal = Salary;
        if (e.currentTarget.id === 'salAmount'){
            sal.Amount = Number(e.target.value);
        }
        if (e.currentTarget.id === 'salPerc'){
            sal.ElectedAdvancePerc = Number(e.target.value);
        }
        if (e.currentTarget.id === 'salFee'){
            sal.FeeAmount = Number(e.target.value);
        }
        if (e.currentTarget.id === 'salPay'){
            sal.PayDate = Number(e.target.value);
        }

        setSalary(sal);
        forceUpdate();
        
    }

    function toggleShowSalary(){
        setShowSalary(!ShowSalary)
    }

    function updateSalary(){
        commonStore.setLoading(true);
        commonStore.updateEmployeeSalary(IDNumber, Salary).then((ret) => {
            window.alert('Salary Updated');
            findUser();
            commonStore.setLoading(false);
        })
    }

    // #endregion

    // #region BankAccount
    function bankAccountChanged(e){
        let acc = BankAccount;
        if (e.currentTarget.id === 'accNum'){
            acc.accountnumber = e.target.value;
        }

        if (e.currentTarget.id === 'bName'){
            acc.branchname = e.target.value;
        }

        if (e.currentTarget.id === 'accType'){
            acc.type = e.target.value;
        }

        setBankAccount(acc);
        forceUpdate();
    }

    function bankNameChanged(e){
        let acc = BankAccount;
        acc.bankname = e.target.value;
        acc.branchcode = getBranchCode(e.target.value);
        setBankAccount(acc);
        forceUpdate();
    }

    function getBranchCode(bank){
        var branchCode;
        switch(bank){
            case 'ABSA': branchCode = '632005'; break;
            case 'Access Bank': branchCode = ''; break;
            case 'African Bank': branchCode = '430000'; break;
            case 'Albaraka Bank': branchCode = '800000'; break;
            case 'Bank Of Athens': branchCode = '410506'; break;
            case 'Bank Windhoek': branchCode = ''; break;
            case 'Bidvest Bank': branchCode = '462005'; break;
            case 'Capitec Bank': branchCode = '470010'; break;
            case 'CitiBank': branchCode = '350005'; break;
            case 'Diamond Bank': branchCode = ''; break;
            case 'Discovery Bank': branchCode = '679000'; break;
            case 'FirstBank': branchCode = ''; break;
            case 'FNB Namibia': branchCode = ''; break;
            case 'FNB': branchCode = '250655'; break;
            case 'GTBank': branchCode = ''; break;
            case 'HSBC Bank': branchCode = '587000'; break;
            case 'Investec': branchCode = '580105'; break;
            case 'Mercantile Bank': branchCode = '450105'; break;
            case 'Nedbank': branchCode = '198765'; break;
            case 'Olympus Mobile': branchCode = '585001'; break;
            case 'RMB': branchCode = '250655'; break;
            case 'Sasfin Bank': branchCode = '683000'; break;
            case 'Standard Bank': branchCode = '051001'; break;
            case 'Standard Bank Namibia': branchCode = ''; break;
            case 'Standard Chartered Bank': branchCode = '730020'; break;
            case 'TymeBank': branchCode = '678910'; break;
            case 'United Bank for Africa': branchCode = ''; break;
            case 'Ubank Limited': branchCode = '431010'; break;
            case 'Zenith Bank': branchCode = ''; break;
            default: branchCode = ''; break;
        }
        return branchCode
    }

    function updateBankAccount(){
        if (BankAccount.accountnumber === '' || BankAccount.bankname === ''){
            window.alert('Please complete all fields');
            return
        }

        if (window.confirm('Are all fields correct?')){
            commonStore.setLoading(true);
            commonStore.updateBankAccount(BankAccount).then((ret) => {
                if (ret) {
                    window.alert('Bank account created.');
                    commonStore.setLoading(false);
                }else{
                    window.alert('Unable to create bank account.');
                    commonStore.setLoading(false);
                }
            })
        }
    }

    function createBankAccount(){
        if (BankAccount.accountnumber === '' || BankAccount.bankname === ''){
            window.alert('Please complete all fields');
            return
        }

        if (window.confirm('Are all fields correct?')){
            commonStore.setLoading(true);
            commonStore.createBankAccount(BankAccount).then((ret) => {
                if (ret) {
                    window.alert('Bank account created.');
                    commonStore.setLoading(false);
                }else{
                    window.alert('Unable to create bank account.');
                    commonStore.setLoading(false);
                }
            })
        }
        
    }

    function showBankAccountNum(){
        setShowAccountNum(!ShowAccountNum);
    }
    // #endregion

    // #region Advance
    function newAmountAdvanceChanged(e){
        setNewAdvanceAmount(Number(e.target.value));
        let feePerc = Employee.employeesalary.FeeAmount || 3;
        setNewAdvanceFee(e.target.value * feePerc/100);
    }

    function payoutMethodChanged(e){
        setNewAdvancePaymentMethod(e.target.value);
    }

    function newAdvanceRefChanged(e){
        setNewAdvanceRef(e.target.value);
    }

    function newAdvanceDateChanged(e){
        setNewAdvanceDate(e.target.value);
    }

    function processAdvance(){
        if (NewAdvanceAmount < 50){
            window.alert('Please enter an amount greater than R50');
            return
        }
        if ((NewAdvanceAmount + NewAdvanceFee) > Advances.amountAllowed){
            window.alert('The total amount of this advance is greater than the amount available');
            return
        }

        if (NewAdvancePaymentMethod === 'Manual' && NewAdvanceRef === ''){
            window.alert('Please enter a reference');
            return
        }

        if (NewAdvancePaymentMethod === 'Manual'){
            processManualAdvance();
        }

        if (NewAdvancePaymentMethod === 'BankAccount'){
            processBankAccountAdvance();
        }
    }

    function processBankAccountAdvance(){
        let adv = {
            totalAmount: Number(NewAdvanceAmount) + Number(NewAdvanceFee),
            advanceAmount: Number(NewAdvanceAmount),
            feeAmount: Number(NewAdvanceFee),
            bankAccount: BankAccount
        }

        commonStore.setLoading(true);
        commonStore.processAdminOzowAdvance(IDNumber, adv).then((ret) => {
            if (!ret){
                window.alert('Failed to process advance');
                findUser();
            }
            window.alert('Manual advance processed');
            findUser();
            setNewAdvanceAmount(0);
            setNewAdvanceDate('');
            setNewAdvanceFee(0);
            setNewAdvanceRef('')
        });

    }

    function processManualAdvance(){
        let adv = {
            totalAmount: Number(NewAdvanceAmount) + Number(NewAdvanceFee),
            advanceAmount: Number(NewAdvanceAmount),
            advanceFee: Number(NewAdvanceFee),
            Ref: NewAdvanceRef,
            id: commonStore.user.username,
            date: NewAdvanceDate,
            businessentity: Employee.businessentity._id
        }
        commonStore.setLoading(true)

        commonStore.processAdminManualAdvance(IDNumber, adv).then(() => {
            window.alert('Manual advance processed');
            findUser();
            setNewAdvanceAmount(0);
            setNewAdvanceDate('');
            setNewAdvanceFee(0);
            setNewAdvanceRef('')
        });

    }

    function updateTransactionStatusPaid(e){
        if (!window.confirm('Are you sure you want to mark this transaction as paid?')){
            return
        }
        let data = {
            status: 'Paid',
            IDNumber: commonStore.user.username,
            transactionId: e.currentTarget.id
        }

        commonStore.setLoading(true);
        commonStore.adminUpdateTransactionStatus(data).then(() => {
            findUser();
        })
    }
    function updateTransactionStatusFailed(e){
        if (!window.confirm('Are you sure you want to mark this transaction as failed?')){
            return
        }
        let data = {
            status: 'Failed',
            IDNumber: commonStore.user.username,
            transactionId: e.currentTarget.id
        }

        commonStore.setLoading(true);
        commonStore.adminUpdateTransactionStatus(data).then(() => {
            findUser();
        })
    }

    function checkPayoutStatus(e){
        let id = e.currentTarget.id;
        if (id === ''){
            id = '1'
        }
        commonStore.setLoading(true);
        commonStore.checkOzowPayoutStatus(id).then((ret) => {
            setOzowPayoutStatus(JSON.stringify(ret, null, 2));
            var pStatus = '';
            var pSubStatus = '';
            var pError = '';
            var pStatusDesc = '';
            var pSubStatusDesc = '';
            if (ret.payoutStatus){
                pStatus = ret.payoutStatus.status;
                pSubStatus = ret.payoutStatus.subStatus;
                pError = ret.payoutStatus.errorMessage;
            }
            console.log(pStatus)
            switch (pStatus){
                case 0: pStatusDesc = 'Payout Not Found'; break;
                case 1: pStatusDesc = 'Payout Received'; break;
                case 2: pStatusDesc = 'Verification'; break;
                case 3: pStatusDesc = 'Submitted For Processing'; break;
                case 4: pStatusDesc = 'Payout Processing Error'; break;
                case 5: pStatusDesc = 'Payout Complete'; break;
                case 6: pStatusDesc = 'Payout Pending Investigation'; break;
                case 99: pStatusDesc = 'Payout Cancelled'; break;
                default: break;
            }
            switch (pSubStatus){
                case 100: pSubStatusDesc = 'Payout Unclassified'; break;
                case 201: pSubStatusDesc = 'Pending'; break;
                case 202: pSubStatusDesc = 'Failed'; break;
                case 203: pSubStatusDesc = 'Success'; break;
                case 204: pSubStatusDesc = 'Error'; break;
                case 205: pSubStatusDesc = 'Account Number Description Failed'; break;
                case 301: pSubStatusDesc = 'Payout Added To Batch'; break;
                case 302: pSubStatusDesc = 'Payout Submitted To Batch'; break;
                case 401: pSubStatusDesc = 'Payout Rejected'; break;
                case 402: pSubStatusDesc = 'Payout Cancelled'; break;
                case 403: pSubStatusDesc = 'Insufficient Balance'; break;
                default: break;
            }
            setPayoutStatus(pStatus);
            setPayoutSubStatus(pSubStatus);
            setPayoutStatusDesc(pStatusDesc);
            setPayoutSubStatusDesc(pSubStatusDesc);
            setPayoutError(pError);
            setShowStatus(true);
            commonStore.setLoading(false);
        })
    }

    function closePayoutStatus(){
        setOzowPayoutStatus('');
        setPayoutStatus('');
        setPayoutSubStatus('');
        setPayoutStatusDesc('');
        setPayoutSubStatusDesc('');
        setPayoutError('');
        setShowStatus(false);
    }
    // #endregion
   
   // #region Contact
   function contactChanged(e){
    let con = Contact;
    if (e.currentTarget.id === 'conMobile'){
        con.Mobile = e.target.value;
    }
    if (e.currentTarget.id === 'conEmail'){
        con.PrivateEmail = e.target.value;
    }
    setContact(con);
    forceUpdate();
   }

   function updateContact(){
    let con = {
        Mobile: Contact.Mobile,
        Landline: Contact.Mobile,
        PrivateEmail: Contact.PrivateEmail,
        WorkEmail: Contact.PrivateEmail
    }
    commonStore.setLoading(true);
    commonStore.adminUpdateEmployeeContact(IDNumber, con).then((ret) => {
        window.alert('Contact Updated');
        findUser();
        commonStore.setLoading(false);
    })
   }

   // #endregion 
    function authenticateUser(){

        if (!window.confirm(`Are you sure you want to authenticate user ${IDNumber}?`)){
            return
        }
        let data = {
            admin: commonStore.user.username
        }

        commonStore.setLoading(true);
        commonStore.createIDVerification(IDNumber, data).then(() => {
            findUser();
        })
    }

    function optionSelected(e){
        setActivePage(e.target.value);
    }
    function releaseEmployee(){
        clearData();
    }

    function deleteEmployee(){
        let verify = window.confirm(`Are you sure you want to delete ${IDNumber}?`);
        if (!verify){
            return
        }

        commonStore.setLoading(true);
        commonStore.deleteEmployee(IDNumber).then((ret) => {
            if (!ret){
                window.alert(`Failed to delete ${IDNumber}`);
                commonStore.setLoading(false);
                return
            }
            window.alert(`Deleted ${IDNumber}`);
            commonStore.setLoading(false);
            clearData();
        })

    }

        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%'}}>
                    <div style={{height: '10%'}}>
                        <p className="txtlead txtLeft" style={{marginBottom: '0px', marginLeft: '5%'}}>{`${(Employee === {} || Object.keys(Employee).length === 0) ? 'Employee' : `${Employee.employeeinfo?.FirstName || ''} ${Employee.employeeinfo?.LastName || ''} (${Employee.businessentity.name})`}`}</p>
                        <hr style={{marginBottom: '0px', marginTop: '1px'}}/>
                    </div>

                    {(activePage === 'Search') ? 
                    <div style={{width: '100%', height: '79%', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', overflow: 'scroll'}}>
                        <div style={{width: '90%'}}>
                            <CustomInput type='text' id='IDNumber' value={IDNumber} placeholder='ID/Passport number' onChange={IDNumberChanged} onEnter={findUser} />
                        </div>
                        <Button block size="sm" onClick={findUser} style={{width: '80%'}}>Search</Button> 
                        {(Employee === {} || Object.keys(Employee).length === 0) ? null :
                        <div style={{paddingLeft: '5%', paddingRight: '5%', marginTop: '20px'}}>
                            {(UserAuthenticated) ? null : 
                            (Employee.user === null || !Employee.user?.confirmed) ? null :
                            <div className='adminEmployeeAuthentication'>
                                <p>This user's ID has not been verified.</p>
                                <div>
                                    <FaCheck onClick={authenticateUser} style={{cursor: 'pointer'}}/>
                                </div>
                            </div>
                            }
                            {(Employee.businessentity.invoiceDate !== 0 && (new Date()).getDate() >= Employee.businessentity.invoiceDate && (new Date()).getDate() < Employee.employeesalary.PayDate) ? 
                            <div className='adminEmployeeAuthentication'>
                                <p>{`Service paused due to invoice date passed. Service will resume on day ${Employee.employeesalary.PayDate} of this month.`}</p>
                            </div>
                            :null}
                            <div className='adminEmployeeInfo'>
                                <p><b>Signup Date:</b> {`${(Employee.user === null || !Employee.user?.confirmed) ? 'User not signed up' : `${Employee.user.confirmedDate || 'Signup date not available'}`}`}</p>
                                <div>
                                    {(Employee.user === null || !Employee.user?.confirmed) ? null : <div>
                                        {(!Employee.user.blocked)? <div><FaStop onClick={blockUser} style={{cursor: 'pointer'}}/><p style={{color: 'green'}}>Active</p></div>: <div><FaPlay onClick={unblockUser} style={{cursor: 'pointer'}}/><p style={{color: 'red'}}>Blocked</p></div>}
                                    </div>}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    : null}

                    {(activePage === 'Info') ? 
                    <div style={{width: '100%', height: '79%', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', overflow: 'scroll'}}>
                        <div style={{marginTop: '10px', marginBottom: '10px', paddingLeft: '15px', paddingRight: '15px'}}>
                            <p style={{fontSize: '15px', fontWeight: 'bold'}}>Salary</p>
                            <div style={{width: '100%', display: 'flex'}}>
                                <CustomInput type={(ShowSalary) ? 'number' : 'password'} id='salAmount' value={(Salary.Amount || 0)} placeholder='Amount' onChange={salaryChanged} />
                                {(ShowSalary) ? <FaRegEyeSlash onClick={toggleShowSalary} style={{width: '5%', marginLeft: '10px'}}/> : <FaRegEye onClick={toggleShowSalary} style={{width: '5%', marginLeft: '10px'}}/>}
                            </div>
                            <CustomInput type='number' id='salPerc' value={(Salary.ElectedAdvancePerc)} placeholder='Advance Percent' onChange={salaryChanged} />
                            <CustomInput type='number' id='salFee' value={(Salary.FeeAmount)} placeholder='Advance Fee' onChange={salaryChanged} />
                            <CustomInput type='number' id='salPay' value={(Salary.PayDate)} placeholder='Pay Date' onChange={salaryChanged} />
                            <Button color="info" block size="sm" onClick={updateSalary}>Update Salary</Button>
                            <hr/>
                        </div>
                        <div style={{marginTop: '10px', marginBottom: '10px'}}>
                            <p style={{fontSize: '15px', fontWeight: 'bold'}}>Contact</p>
                            <CustomInput type='text' id='conMobile' value={(Contact.Mobile)} placeholder='Mobile' onChange={contactChanged} />
                            <CustomInput type='text' id='conEmail' value={(Contact.PrivateEmail)} placeholder='Email' onChange={contactChanged} />
                            <Button color="info" block size="sm" onClick={updateContact}>Update Contact</Button>
                            <hr/>
                        </div>
                        
                    </div>
                    : null}

                    {(activePage === 'Bank') ? 
                    <div style={{width: '100%', height: '79%', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', overflow: 'scroll'}}>
                        <div style={{margin: '10px'}}>
                            <p style={{fontSize: '15px', fontWeight: 'bold'}}>Bank Account</p>
                            <div style={{width: '100%', display: 'flex'}}>
                                <CustomInput type={(ShowAccountNum) ? 'text' : 'password'} id='accNum' value={(BankAccount.accountnumber)} placeholder='Account Number' onChange={bankAccountChanged} />
                                {(ShowAccountNum) ? <FaRegEyeSlash onClick={showBankAccountNum} style={{width: '5%', marginLeft: '10px'}}/> : <FaRegEye onClick={showBankAccountNum} style={{width: '5%', marginLeft: '10px'}}/>}
                            </div>
                            <label className="custom-field">
                                <select className='inp' value={BankAccount.bankname} onChange={bankNameChanged} required>
                                    <option value='ABSA'>ABSA</option>
                                    <option value='Access Bank'>Access Bank</option>
                                    <option value='African Bank'>African Bank</option>
                                    <option value='Albaraka Bank'>Albaraka Bank</option>
                                    <option value='Bank Of Athens'>Bank Of Athens</option>
                                    <option value='Bank Windhoek'>Bank Windhoek</option>
                                    <option value='Bidvest Bank'>Bidvest Bank</option>
                                    <option value='Capitec Bank'>Capitec Bank</option>
                                    <option value='CitiBank'>CitiBank</option>
                                    <option value='Diamond Bank'>Diamond Bank</option>
                                    <option value='Discovery Bank'>Discovery Bank</option>
                                    <option value='FirstBank'>FirstBank</option>
                                    <option value='FNB Namibia'>FNB Namibia</option>
                                    <option value='FNB'>FNB</option>
                                    <option value='GTBank'>GTBank</option>
                                    <option value='HSBC Bank'>HSBC Bank</option>
                                    <option value='Investec'>Investec</option>
                                    <option value='Mercantile Bank'>Mercantile Bank</option>
                                    <option value='Nedbank'>Nedbank</option>
                                    <option value='Olympus Mobile'>Olympus Mobile</option>
                                    <option value='RMB'>RMB</option>
                                    <option value='Sasfin Bank'>Sasfin Bank</option>
                                    <option value='Standard Bank'>Standard Bank</option>
                                    <option value='Standard Bank Namibia'>Standard Bank Namibia</option>
                                    <option value='Standard Chartered Bank'>Standard Chartered Bank</option>
                                    <option value='TymeBank'>TymeBank</option>
                                    <option value='United Bank for Africa'>United Bank for Africa</option>
                                    <option value='Ubank Limited'>Ubank Limited</option>
                                    <option value='Zenith Bank'>Zenith Bank</option>   
                                </select>
                                <span className="placeholder">Bank</span>
                            </label>
                            <CustomInput type='text' id='bCode' value={(BankAccount.branchcode)} placeholder='Branch Code' onChange={bankAccountChanged}/>
                            <CustomInput type='text' id='bName' value={(BankAccount.branchname)} placeholder='Branch Name' onChange={bankAccountChanged}/>
                            <CustomInput type='text' id='accType' value={(BankAccount.type)} placeholder='Account Type' onChange={bankAccountChanged}/>
                            {(BankAccountExist) ? <Button color="info" block size="sm" onClick={updateBankAccount}>Update</Button> : <Button color="info" block size="sm" onClick={createBankAccount}>Create</Button>}
                        </div>
                    </div>
                    : null}

                    {(activePage === 'Advance') ? 
                    <div style={{width: '100%', height: '79%', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', overflow: 'scroll'}}>
                        <div style={{margin: '10px'}}>
                            <p style={{fontSize: '15px', fontWeight: 'bold'}}>New Advance</p>
                            <div className="progressBarContainer">
                                <div className="onDemandBase"></div>
                                <div className="onDemandProgress" style={{width: `${parseInt(Advances.amountAllowed)/parseInt(Advances.amountAvailable)*100}%`}}></div>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div></div>
                                    <p className="withdrawTxtDesktop"><span style={{color: '#868685', fontSize: '100%'}}>Amount available: </span>{`R${Advances.amountAllowed}`}</p>
                                </div>
                            </div>
                            <CustomInput type='number' id='advAmt' value={String(NewAdvanceAmount)} placeholder='Advance Amount' onChange={newAmountAdvanceChanged} />
                            <label className="custom-field">
                                <select className='inp' value={NewAdvancePaymentMethod} onChange={payoutMethodChanged} required>
                                    <option value='Manual'>Manual Payment</option>
                                    {(BankAccountExist) ? <option value='BankAccount'>{`${BankAccount.bankname}: **** ${BankAccount.accountnumber.substring(BankAccount.accountnumber.length - 4)}`}</option> : null}
                                </select>
                            </label>
                            {(NewAdvancePaymentMethod === 'Manual') ? <CustomInput type='text' id='advRef' value={NewAdvanceRef} placeholder='Reference' onChange={newAdvanceRefChanged} /> : null}
                            {(NewAdvancePaymentMethod === 'Manual') ? <CustomInput type='date' id='advDate' value={NewAdvanceDate} placeholder='Reference' onChange={newAdvanceDateChanged} /> : null}
                            <table style={{width: '100%', marginBottom: '10px', marginTop: '10px'}}>
                                <tbody>
                                    <tr>
                                        <td>Advance Amount</td>
                                        <td style={{textAlign: 'right'}}>{`R${NewAdvanceAmount}`}</td>
                                    </tr>
                                    <tr>
                                        <td>{`Fee (${Employee.employeesalary.FeeAmount || 3}%)`}</td>
                                        <td style={{textAlign: 'right'}}>{`R${NewAdvanceFee}`}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Deduction</td>
                                        <td style={{textAlign: 'right'}}>{`R${NewAdvanceFee + NewAdvanceAmount}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Button color="info" block size="sm" onClick={processAdvance}>Process</Button>
                            <hr/>
                        </div>

                        <div style={{margin: '10px'}}>
                            <p style={{fontSize: '15px', fontWeight: 'bold'}}>Advance History</p>
                            {Advances.history.sort((a,b) => (new Date(b.AdvanceRequestDate)) - (new Date(a.AdvanceRequestDate))).map((hist, index) => {
                                return(
                                    <Card className='advHistCard' style={{width: '100%'}}>
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div>
                                                <p>{(new Date(hist.AdvanceRequestDate)).toISOString()}</p>
                                                <p><b>Advance Amount: </b>{`R${hist.AdvanceAmount}`}</p>
                                                <p><b>Fee: </b>{`R${hist.AdvanceFee}`}</p>
                                            </div>
                                            <div>
                                                <p className={`${(hist.transactionhistory && hist.transactionhistory.TransactionStatus && hist.transactionhistory.TransactionStatus === 'Paid') ? 'advPaid' : ''} ${(hist.transactionhistory && hist.transactionhistory.TransactionStatus === 'Pending') ? 'advPending' : ''} ${(hist.transactionhistory && hist.transactionhistory.TransactionStatus === 'Failed') ? 'advFailed' : ''}`}>{hist.transactionhistory && hist.transactionhistory.TransactionStatus}</p>
                                                {(hist.transactionhistory && hist.transactionhistory.TransactionStatus === 'Pending') ? <div style={{display: 'flex', marginBottom: '5px'}}>
                                                    <FaBan id={hist.transactionhistory &&  hist.transactionhistory._id} onClick={updateTransactionStatusFailed}style={{color: 'red'}}/>
                                                    <FaCheck id={hist.transactionhistory &&  hist.transactionhistory._id} onClick={updateTransactionStatusPaid} style={{color: 'green'}}/>
                                                </div>: null}
                                                <SlMagnifier id={hist.transactionhistory &&  hist.transactionhistory.TransactionID || ''} onClick={checkPayoutStatus} style={{marginTop: '5px'}}/>
                                            </div>
                                        </div>
                                        <div>
                                            <p><b>Transaction ID: </b>{hist.transactionhistory && hist.transactionhistory.TransactionID || ''}</p>
                                            <p><b>Transaction Message: </b>{hist.transactionhistory && hist.transactionhistory.TransactionMessage || ''}</p>
                                            <p><b>Transaction Reference: </b>{hist.transactionhistory && hist.transactionhistory.TransactionRef || ''}</p>
                                        </div>
                                    </Card>
                                )
                            })}
                        </div>
                    </div>
                    : null}

                    <Card className="tabBar" style={{height: '11%'}}>
                        <Form className="tabBarForm" onChange={optionSelected} style={{overflow: 'hidden'}}>

                            <div className="tabBarItem">
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="infoTab" value="Info"/>
                                <FaInfoCircle className={(activePage === "Info")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="infoTab" style={{height: '100%'}}/>
                                <label className={(activePage === "Info")? "tabSelected":null} htmlFor="infoTab">Info</label>
                            </div>

                            <div className="tabBarItem"> 
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="bankTab" value="Bank"/>
                                <FaMoneyCheckAlt className={(activePage === "Bank")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="bankTab" style={{height: '100%'}}/>
                                <label className={(activePage === "Bank")? "tabSelected":null} htmlFor="bankTab">Bank</label>
                            </div>

                            <div className="tabBarItem"> 
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="advanceTab" value="Advance"/>
                                <FaDollarSign className={(activePage === "Advance")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="advanceTab" style={{height: '100%'}}/>
                                <label className={(activePage === "Advance")? "tabSelected":null} htmlFor="advanceTab">Advance</label>
                            </div>


                            <div className="tabBarItem">
                                <input className="tabBar-tools" name="tabBarItems" type="radio" id="searchTab" value="Search"/>
                                <FaSearch className={(activePage === "Search")? "fa fa-thin fa-wallet tabSelected":"fa fa-thin fa-wallet"} htmlFor="searchTab" style={{height: '100%'}}/>
                                <label className={(activePage === "Search")? "tabSelected":null} htmlFor="searchTab">Search</label>
                            </div>
                        </Form>
                        
                    </Card>

                    {(!ShowStatus) ? null : <Card className='payoutStatusCard'>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <p><b>Payout Details</b></p>
                            <FaRegTimesCircle onClick={closePayoutStatus} style={{height: '20px', margin: '0px', cursor: 'pointer'}}/>
                        </div>
                        <p><b>Status: </b>{`${PayoutStatus} - ${PayoutStatusDesc}`}</p>
                        <p><b>SubStatus: </b>{`${PayoutSubStatus} - ${PayoutSubStatusDesc}`}</p>
                        <p><b>Error: </b>{`${PayoutError}`}</p>
                        <p>{OzowPayoutStatus}</p>
                    </Card>}
                    
                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>{`Employee ${(Employee === {} || Object.keys(Employee).length === 0) ? '' : `- ${Employee.employeeinfo?.FirstName || ''} ${Employee.employeeinfo?.LastName || ''} (${Employee.businessentity.name})`}`}</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <div className="desktopDashHeader">
                            <div style={{width: '40%'}}>
                                <CustomInput type='text' id='IDNumber' value={IDNumber} placeholder='ID/Passport number' onChange={IDNumberChanged} onEnter={findUser} />
                                {(Employee === {} || Object.keys(Employee).length === 0) ? null : <Button className='deleteButton' onClick={deleteEmployee}>Delete Employee</Button>}
                            </div>
                                 
                                {(Employee === {} || Object.keys(Employee).length === 0) ? <Button className="transparentButton" block size="sm" onClick={findUser} style={{width: '10%'}}>Search</Button> : <Button className='transparentButton' onClick={releaseEmployee}>Release Employee</Button>}
                        </div>
                        <hr/>
                        {(Employee === {} || Object.keys(Employee).length === 0) ? null : 
                        <div>
                            {(UserAuthenticated) ? null : 
                            (Employee.user === null || !Employee.user?.confirmed) ? null :
                            <div className='adminEmployeeAuthentication'>
                                <p>This user's ID has not been verified.</p>
                                <div>
                                    <FaCheck onClick={authenticateUser} style={{cursor: 'pointer'}}/>
                                </div>
                            </div>
                            }

                            {(Employee.businessentity.invoiceDate !== 0 && (new Date()).getDate() >= Employee.businessentity.invoiceDate && (new Date()).getDate() < Employee.employeesalary.PayDate) ? 
                            <div className='adminEmployeeAuthentication'>
                                <p>{`Service paused due to invoice date passed. Service will resume on day ${Employee.employeesalary.PayDate} of this month.`}</p>
                            </div>
                            :null}
                            <div className='adminEmployeeInfo'>
                                <p><b>Signup Date:</b> {`${(Employee.user === null || !Employee.user?.confirmed) ? 'User not signed up' : `${Employee.user.confirmedDate || 'Signup date not available'}`}`}</p>
                                <div>
                                    {(Employee.user === null || !Employee.user?.confirmed) ? null : <div>
                                        {(!Employee.user.blocked)? <div><FaStop onClick={blockUser} style={{cursor: 'pointer'}}/><p style={{color: 'green'}}>Active</p></div>: <div><FaPlay onClick={unblockUser} style={{cursor: 'pointer'}}/><p style={{color: 'red'}}>Blocked</p></div>}
                                    </div>}
                                </div>
                            </div>

                            <div className='adminEmployeeContent'>
                                <div className='adminEmployeeContentSide'>
                                    <div className='adminEmployeeContentSection' id='adminEmployeeSalary'>
                                        <p className='adminEmployeeHeader'>Salary</p>
                                        <div style={{width: '100%', display: 'flex'}}>
                                            <CustomInput type={(ShowSalary) ? 'number' : 'password'} id='salAmount' value={(Salary.Amount || 0)} placeholder='Amount' onChange={salaryChanged} />
                                            {(ShowSalary) ? <FaRegEyeSlash onClick={toggleShowSalary} style={{width: '5%', marginLeft: '10px'}}/> : <FaRegEye onClick={toggleShowSalary} style={{width: '5%', marginLeft: '10px'}}/>}
                                        </div>
                                        <CustomInput type='number' id='salPerc' value={(Salary.ElectedAdvancePerc)} placeholder='Advance Percent' onChange={salaryChanged} />
                                        <CustomInput type='number' id='salFee' value={(Salary.FeeAmount)} placeholder='Advance Fee' onChange={salaryChanged} />
                                        <CustomInput type='number' id='salPay' value={(Salary.PayDate)} placeholder='Pay Date' onChange={salaryChanged} />
                                        <Button color="info" block size="sm" onClick={updateSalary}>Update</Button>
                                    </div>

                                    <div className='adminEmployeeContentSection' id='adminEmployeeBankAccount'>
                                        <p className='adminEmployeeHeader'>Bank Account</p>
                                        <div style={{width: '100%', display: 'flex'}}>
                                            <CustomInput type={(ShowAccountNum) ? 'text' : 'password'} id='accNum' value={(BankAccount.accountnumber)} placeholder='Account Number' onChange={bankAccountChanged} />
                                            {(ShowAccountNum) ? <FaRegEyeSlash onClick={showBankAccountNum} style={{width: '5%', marginLeft: '10px'}}/> : <FaRegEye onClick={showBankAccountNum} style={{width: '5%', marginLeft: '10px'}}/>}
                                        </div>
                                        <label className="custom-field">
                                            <select className='inp' value={BankAccount.bankname} onChange={bankNameChanged} required>
                                                <option value='ABSA'>ABSA</option>
                                                <option value='Access Bank'>Access Bank</option>
                                                <option value='African Bank'>African Bank</option>
                                                <option value='Albaraka Bank'>Albaraka Bank</option>
                                                <option value='Bank Of Athens'>Bank Of Athens</option>
                                                <option value='Bank Windhoek'>Bank Windhoek</option>
                                                <option value='Bidvest Bank'>Bidvest Bank</option>
                                                <option value='Capitec Bank'>Capitec Bank</option>
                                                <option value='CitiBank'>CitiBank</option>
                                                <option value='Diamond Bank'>Diamond Bank</option>
                                                <option value='Discovery Bank'>Discovery Bank</option>
                                                <option value='FirstBank'>FirstBank</option>
                                                <option value='FNB Namibia'>FNB Namibia</option>
                                                <option value='FNB'>FNB</option>
                                                <option value='GTBank'>GTBank</option>
                                                <option value='HSBC Bank'>HSBC Bank</option>
                                                <option value='Investec'>Investec</option>
                                                <option value='Mercantile Bank'>Mercantile Bank</option>
                                                <option value='Nedbank'>Nedbank</option>
                                                <option value='Olympus Mobile'>Olympus Mobile</option>
                                                <option value='RMB'>RMB</option>
                                                <option value='Sasfin Bank'>Sasfin Bank</option>
                                                <option value='Standard Bank'>Standard Bank</option>
                                                <option value='Standard Bank Namibia'>Standard Bank Namibia</option>
                                                <option value='Standard Chartered Bank'>Standard Chartered Bank</option>
                                                <option value='TymeBank'>TymeBank</option>
                                                <option value='United Bank for Africa'>United Bank for Africa</option>
                                                <option value='Ubank Limited'>Ubank Limited</option>
                                                <option value='Zenith Bank'>Zenith Bank</option>   
                                            </select>
                                            <span className="placeholder">Bank</span>
                                        </label>
                                        <CustomInput type='text' id='bCode' value={(BankAccount.branchcode)} placeholder='Branch Code' onChange={bankAccountChanged}/>
                                        <CustomInput type='text' id='bName' value={(BankAccount.branchname)} placeholder='Branch Name' onChange={bankAccountChanged}/>
                                        <CustomInput type='text' id='accType' value={(BankAccount.type)} placeholder='Account Type' onChange={bankAccountChanged}/>
                                        {(BankAccountExist) ? <Button color="info" block size="sm" onClick={updateBankAccount}>Update</Button> : <Button color="info" block size="sm" onClick={createBankAccount}>Create</Button>}
                                    </div>
                                </div>

                                <div className='adminEmployeeContentSide'>

                                    <div className='adminEmployeeContentSection' id='adminEmployeeNewAdvance'>
                                        <p className='adminEmployeeHeader'>New Advance</p>
                                        <div className="progressBarContainer">
                                            <div className="onDemandBase"></div>
                                            <div className="onDemandProgress" style={{width: `${parseInt(Advances.amountAllowed)/parseInt(Advances.amountAvailable)*100}%`}}></div>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div></div>
                                                <p className="withdrawTxtDesktop"><span style={{color: '#868685', fontSize: '100%'}}>Amount available: </span>{`R${Advances.amountAllowed}`}</p>
                                            </div>
                                        </div>
                                        <CustomInput type='number' id='advAmt' value={String(NewAdvanceAmount)} placeholder='Advance Amount' onChange={newAmountAdvanceChanged} />
                                        <label className="custom-field">
                                            <select className='inp' value={NewAdvancePaymentMethod} onChange={payoutMethodChanged} required>
                                                <option value='Manual'>Manual Payment</option>
                                                {(BankAccountExist) ? <option value='BankAccount'>{`${BankAccount.bankname}: **** ${BankAccount.accountnumber.substring(BankAccount.accountnumber.length - 4)}`}</option> : null}
                                            </select>
                                        </label>
                                        {(NewAdvancePaymentMethod === 'Manual') ? <CustomInput type='text' id='advRef' value={NewAdvanceRef} placeholder='Reference' onChange={newAdvanceRefChanged} /> : null}
                                        {(NewAdvancePaymentMethod === 'Manual') ? <CustomInput type='date' id='advDate' value={NewAdvanceDate} placeholder='Reference' onChange={newAdvanceDateChanged} /> : null}
                                        <table style={{width: '100%', marginBottom: '10px', marginTop: '10px'}}>
                                            <tbody>
                                                <tr>
                                                    <td>Advance Amount</td>
                                                    <td style={{textAlign: 'right'}}>{`R${NewAdvanceAmount}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>{`Fee (${Employee.employeesalary.FeeAmount || 3}%)`}</td>
                                                    <td style={{textAlign: 'right'}}>{`R${NewAdvanceFee}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Deduction</td>
                                                    <td style={{textAlign: 'right'}}>{`R${NewAdvanceFee + NewAdvanceAmount}`}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Button color="info" block size="sm" onClick={processAdvance}>Process</Button>
                                    </div>

                                    <div className='adminEmployeeContentSection' id='adminEmployeeSalary'>
                                        <p className='adminEmployeeHeader'>Contact</p>
                                        <CustomInput type='text' id='conMobile' value={(Contact.Mobile)} placeholder='Mobile' onChange={contactChanged} />
                                        <CustomInput type='text' id='conEmail' value={(Contact.PrivateEmail)} placeholder='Email' onChange={contactChanged} />
                                        <Button color="info" block size="sm" onClick={updateContact}>Update</Button>
                                    </div>
                                </div>

                            </div>
                            <div className='adminEmployeeContentSection' id='adminEmployeeHistory'>
                            <p className='adminEmployeeHeader'>Advance History</p>
                            <PDFDownloadLink document={<PDFEmployeeAdvances employeeAdvances={SuccessfulAdvances} employee={Employee} />} filename="test.pdf">
                                {({loading}) => (loading ? <Button>Loading Document...</Button> : <FaFilePdf style={{height: '20px', color: 'black'}}>Download</FaFilePdf> )}
                            </PDFDownloadLink>
                            {Advances.history.sort((a,b) => (new Date(b.AdvanceRequestDate)) - (new Date(a.AdvanceRequestDate))).map((hist, index) => {
                                return(
                                    <Card className='advHistCard'>
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div>
                                                <p>{(new Date(hist.AdvanceRequestDate)).toISOString()}</p>
                                                <p><b>Advance Amount: </b>{`R${hist.AdvanceAmount}`}</p>
                                                <p><b>Fee: </b>{`R${hist.AdvanceFee}`}</p>
                                            </div>
                                            <div>
                                                <p className={`${(hist.transactionhistory && hist.transactionhistory.TransactionStatus && hist.transactionhistory.TransactionStatus === 'Paid') ? 'advPaid' : ''} ${(hist.transactionhistory && hist.transactionhistory.TransactionStatus === 'Pending') ? 'advPending' : ''} ${(hist.transactionhistory && hist.transactionhistory.TransactionStatus === 'Failed') ? 'advFailed' : ''}`}>{hist.transactionhistory && hist.transactionhistory.TransactionStatus}</p>
                                                {/* <p>TEst</p> */}
                                                {(hist.transactionhistory && hist.transactionhistory.TransactionStatus === 'Pending') ? <div style={{display: 'flex'}}>
                                                    <FaBan id={hist.transactionhistory &&  hist.transactionhistory._id} onClick={updateTransactionStatusFailed}style={{color: 'red'}}/>
                                                    <FaCheck id={hist.transactionhistory &&  hist.transactionhistory._id} onClick={updateTransactionStatusPaid} style={{color: 'green'}}/>
                                                </div>: null}
                                                <SlMagnifier id={hist.transactionhistory &&  hist.transactionhistory.TransactionID || ''} onClick={checkPayoutStatus}/>
                                            </div>
                                        </div>
                                        <div>
                                            <p><b>Transaction ID: </b>{hist.transactionhistory && hist.transactionhistory.TransactionID || ''}</p>
                                            <p><b>Transaction Message: </b>{hist.transactionhistory && hist.transactionhistory.TransactionMessage || ''}</p>
                                            <p><b>Transaction Reference: </b>{hist.transactionhistory && hist.transactionhistory.TransactionRef || ''}</p>
                                        </div>
                                    </Card>
                                )
                            })}
                        </div>

                        </div>}
                        
                    </div>

                    {(!ShowStatus) ? null : <Card className='payoutStatusCard'>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <p><b>Payout Details</b></p>
                            <FaRegTimesCircle onClick={closePayoutStatus} style={{height: '20px', margin: '0px', cursor: 'pointer'}}/>
                        </div>
                        <p><b>Status: </b>{`${PayoutStatus} - ${PayoutStatusDesc}`}</p>
                        <p><b>SubStatus: </b>{`${PayoutSubStatus} - ${PayoutSubStatusDesc}`}</p>
                        <p><b>Error: </b>{`${PayoutError}`}</p>
                        <p>{OzowPayoutStatus}</p>
                    </Card>}

                </View>
            );
        }
        
})

export default Employee;
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import { Form, Button } from '../../components/basic';
import { View, MobileTopBarClean } from '../../shared/components';
import '../style.scss';
import './style.scss';
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");


const AddPassword = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [err, setErr] = useState('');
    const [success, setSuccess] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);

    useEffect(() => {
        if (isMobile()) {
            document.getElementById("mobileDashView").style.height = `${window.innerHeight}px`;
        }
    }, []);

    function newPasswordChange(e) {
        setErr('');
        setSuccess('');
        setNewPassword(e.target.value);
    }

    function confirmPasswordChange(e) {
        setErr('');
        setSuccess('');
        setConfirmPassword(e.target.value);
    }

    function doValidation() {

        if (newPassword === '') {
            setErr('Please enter a new password.');
            return false
        }

        if (!strongRegex.test(newPassword)) {
            setErr('Your password must be at least 8 characters with at least 1 number, special character and uppercase character.');
            return false
        }

        if (newPassword !== confirmPassword) {
            setErr('Passwords do not match.');
            return false
        }

        return true

    }

    function changePasswordClicked() {

        commonStore.setLoading(true);
        if (doValidation()) {
            let args = {
                newPassword: newPassword
            }

            commonStore.addPassword(args).then((ret) => {
                if (ret !== true) {
                    setErr(ret);
                    commonStore.setLoading(false);
                    return
                }
                setSuccess('Your password has been created successfully');
                setPasswordChanged(true);
                commonStore.setLoading(false);

            });
        } else {
            commonStore.setLoading(false);
        }
    }

    function goBack() {
        navigate({ pathname: '/home', search: 'More' });
    }

    if (isMobile()) {
        return (
            <div id="mobileDashView" className="view">
                <MobileTopBarClean />
                <div className="viewContent view-pages view-session d-flex flex-column">
                    <p className="txtlead txtLeft">Change password</p>
                    <Form style={{ overflow: 'scroll' }}>

                        <label className="custom-field">
                            <input className='inp' type="password" id="newPassword" value={newPassword} onChange={newPasswordChange} required />
                            <span className="placeholder">New password</span>
                        </label>

                        <label className="custom-field">
                            <input className='inp' type="password" id="confirmPassword" value={confirmPassword} onChange={confirmPasswordChange} required />
                            <span className="placeholder">Confirm password</span>
                        </label>
                        {(err.length > 0) ? <p style={{ color: 'red' }}>{err}</p> : null}
                        {(success.length > 0) ? <p style={{ color: 'green' }}>{success}</p> : null}
                        {(passwordChanged) ?
                            <Button block size="lg" onClick={goBack} style={{ marginTop: '10px', marginBottom: '10px' }}>Return to dashboard</Button> :
                            <><Button block size="lg" onClick={changePasswordClicked} style={{ marginTop: '10px', marginBottom: '10px' }}>Create password</Button>
                                <p className="backButton" onClick={goBack}>Back</p></>
                        }

                    </Form>
                </div>
            </div>
        );
    } else {
        return (
            <div id="mobileDashView" className="view">
                <MobileTopBarClean />
                <div className="viewContent view-pages view-session d-flex flex-column">
                    <p className="txtlead txtLeft">Change password</p>
                    <Form style={{ overflow: 'scroll' }}>

                        <label className="custom-field">
                            <input className='inp' type="password" id="newPassword" value={newPassword} onChange={newPasswordChange} required />
                            <span className="placeholder">New password</span>
                        </label>

                        <label className="custom-field">
                            <input className='inp' type="password" id="confirmPassword" value={confirmPassword} onChange={confirmPasswordChange} required />
                            <span className="placeholder">Confirm password</span>
                        </label>
                        {(err.length > 0) ? <p style={{ color: 'red' }}>{err}</p> : null}
                        {(success.length > 0) ? <p style={{ color: 'green' }}>{success}</p> : null}
                        {(passwordChanged) ?
                            <Button block size="lg" onClick={goBack} style={{ marginTop: '10px', marginBottom: '10px' }}>Return to dashboard</Button> :
                            <><Button block size="lg" onClick={changePasswordClicked} style={{ marginTop: '10px', marginBottom: '10px' }}>Create password</Button>
                                <p className="backButton" onClick={goBack}>Back</p></>
                        }

                    </Form>
                </div>
            </div>
        );
    }
})

export default AddPassword
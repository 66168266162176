import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';
import { FaBan, FaCheck, FaFilePdf } from 'react-icons/fa';
import { PDFInvoice } from '../../../components';
import { PDFDownloadLink } from "@react-pdf/renderer";
import './style.scss';

const Invoices = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [Invoices, setInvoices] = useState([]);
    const [LoadedInvoices, setLoadedInvoices] = useState([]);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        loadInvoices();
    }, []);

    function loadInvoices(){
        commonStore.setLoading(true);
        commonStore.getInvoicesByClient(commonStore.user.businessentity._id).then((ret) => {
            setInvoices(ret);
            console.log(ret)
            commonStore.setLoading(false);
            forceUpdate()
        })
    }

    function loadInvoice(e){
        let inv = LoadedInvoices;
        inv.push(Number(e.currentTarget.id));
        setLoadedInvoices(inv);
        forceUpdate();
    }

    if (isMobile()) {
        return(
            <View>
                <div className="viewContent view-pages view-session d-flex flex-column">
                    <header>
                        <p className="txtlead txtLeft">Invoices</p>
                    </header>
                    <hr/>

                    {Invoices.map((invoice, index) => {
                            return (
                                <Card key={index} id={invoice._id} className='invoiceCard'>
                                    <div>
                                        <p><b>Invoice Number: </b>{invoice.InvoiceNumber}</p>
                                        <p><b>Date: </b>{(new Date(invoice.InvoiceDate)).toLocaleDateString()}</p>
                                        <p><b>Company Name: </b>{invoice.Businessentity.name}</p>
                                        <p><b>Date Range: </b>{`${(new Date(invoice.StartDate)).toLocaleDateString()} - ${(new Date(invoice.EndDate)).toLocaleDateString()}`}</p>
                                        <div style={{display: 'flex', fontSize: '20px'}}>
                                        <div>

                                        </div> 
                                            {(LoadedInvoices.includes(index) ? 
                                            <PDFDownloadLink document={<PDFInvoice employeeAdvances={invoice.Advances} client={commonStore.user.businessentity} invoiceNumber={invoice.InvoiceNumber} invoiceDate={invoice.InvoiceDate} />} filename="test.pdf" >
                                            {({loading}) => (loading ? <Button>Loading Document...</Button> : <FaFilePdf style={{height: '20px', color: 'black', cursor: 'pointer'}}>Download</FaFilePdf> )}
                                            </PDFDownloadLink>
                                            : 
                                            <Button id={index} onClick={loadInvoice}>Load Invoice</Button>
                                            )}
                                        </div>
                                    </div>

                                    <div style={{textAlign: 'right'}}>
                                        <p className={`${(invoice.Status === 'Paid') ? 'advPaid' : ''} ${(invoice.Status === 'Pending') ? 'advPending' : ''} ${(invoice.Status === 'Cancelled') ? 'advFailed' : ''}`}>{invoice.Status}</p>
                                        <p><b>Advance Amount: </b>{invoice.AdvanceAmount}</p>
                                        <p><b>Fee Amount: </b>{invoice.FeeAmount}</p>
                                        <p><b>Membership Amount: </b>{invoice.MembershipAmount}</p>
                                        <hr style={{margin: '0px'}}/>
                                        <p><b>Total Excl.: </b>{invoice.TotalExcl}</p>
                                        <p><b>VAT: </b>{invoice.VAT}</p>
                                        <hr style={{margin: '0px'}}/>
                                        <p><b>Total Incl.: </b>{invoice.TotalIncl}</p>
                                    </div>
                                    
                                </Card>
                            )
                        })}

                    {/* <div id="onDemandPay">
                        
                    </div> */}

                    
                </div>
                    
                </View>
        )
    }else{
        return(
            <View style={{backgroundColor: '#ffffff'}}>
                   <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Invoices</p>
                        </div>
                    </header>

                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        

                        <hr/>

                        {Invoices.map((invoice, index) => {
                            return (
                                <Card key={index} id={invoice._id} className='invoiceCard'>
                                    <div>
                                        <p><b>Invoice Number: </b>{invoice.InvoiceNumber}</p>
                                        <p><b>Date: </b>{(new Date(invoice.InvoiceDate)).toLocaleDateString()}</p>
                                        <p><b>Company Name: </b>{invoice.Businessentity.name}</p>
                                        <p><b>Date Range: </b>{`${(new Date(invoice.StartDate)).toLocaleDateString()} - ${(new Date(invoice.EndDate)).toLocaleDateString()}`}</p>
                                        <div style={{display: 'flex', fontSize: '20px'}}>
                                        <div>

                                        </div> 
                                            {(LoadedInvoices.includes(index) ? 
                                            <PDFDownloadLink document={<PDFInvoice employeeAdvances={invoice.Advances} client={commonStore.user.businessentity} invoiceNumber={invoice.InvoiceNumber} invoiceDate={invoice.InvoiceDate} />} filename="test.pdf" >
                                            {({loading}) => (loading ? <Button>Loading Document...</Button> : <FaFilePdf style={{height: '20px', color: 'black', cursor: 'pointer'}}>Download</FaFilePdf> )}
                                            </PDFDownloadLink>
                                            : 
                                            <Button id={index} onClick={loadInvoice}>Load Invoice</Button>
                                            )}
                                        </div>
                                    </div>

                                    <div style={{textAlign: 'right'}}>
                                        <p className={`${(invoice.Status === 'Paid') ? 'advPaid' : ''} ${(invoice.Status === 'Pending') ? 'advPending' : ''} ${(invoice.Status === 'Cancelled') ? 'advFailed' : ''}`}>{invoice.Status}</p>
                                        <p><b>Advance Amount: </b>{invoice.AdvanceAmount}</p>
                                        <p><b>Fee Amount: </b>{invoice.FeeAmount}</p>
                                        <p><b>Membership Amount: </b>{invoice.MembershipAmount}</p>
                                        <hr style={{margin: '0px'}}/>
                                        <p><b>Total Excl.: </b>{invoice.TotalExcl}</p>
                                        <p><b>VAT: </b>{invoice.VAT}</p>
                                        <hr style={{margin: '0px'}}/>
                                        <p><b>Total Incl.: </b>{invoice.TotalIncl}</p>
                                    </div>
                                    
                                </Card>
                            )
                        })}
                    </div>
            </View>


        )
    }

})

export default Invoices